import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function SelectOption({ options, onSelectionChange, name, width, error, selectedOption }) {
    const [value, setValue] = useState(null);

    useEffect(() => {
        // Encontrar el objeto en options que coincida con selectedOption y establecerlo como valor
        const selectedValue = options.find(option => option.id === selectedOption) || null;
        setValue(selectedValue);
    }, [selectedOption, options]);

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            sx={{ width: width, marginY: 1 }}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                onSelectionChange(newValue ? newValue.id : null);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={name}
                    error={error && !selectedOption}
                    helperText={error && !selectedOption ? '* Este campo es obligatorio' : ''}
                    sx={{
                        "& .MuiInputBase-root": {
                            height: "45px",
                            alignItems: "center",
                            padding: '2px 8px',
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            top: 0,
                        },
                        "& .MuiInputBase-input": {
                            padding: "8px 8px",
                        },
                        "& .MuiInputLabel-outlined": {
                            transform: "translate(14px, 14px) scale(1)",
                            "&.MuiInputLabel-shrink": {
                                transform: "translate(14px, -6px) scale(0.75)",
                            },
                        },
                        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
                            paddingTop: '0px',
                            paddingBottom: '0px',
                        },
                    }}
                />
            )}
        />
    );
}

export default SelectOption;
