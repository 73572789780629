import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Button, Typography, Grid, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchComponent from '../components/directory/SearchComponent';
import ProfileCard from '../components/directory/ProfileCard';
import Pagination from '../components/directory/Pagination';
import { publicAxios } from '../apiClients';
import { useAuth } from '../hooks/useAuth';
import TypeFilterComponent from '../components/directory/TypeFilterComponent';
import QualificationFilterComponent from '../components/directory/QualificationFilterComponent';
import { useScrollPosition } from '../components/directory/DirectoryContext';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NotificationApp from './NotificationApp';
import NotificationQualification from './NotificationQualification';

const Directory = () => {
    const [profiles, setProfiles] = useState([]);
    const [filtrar, setFiltrar] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const isUnverified = user?.userRole === 'unverified';
    const [data, setData] = useState({
        universities: [],
        countries: [],
        firms: [],
        qualifications: [],
        areas: []
    });
    const [pages, setPages] = useState({
        currentPage: 1,
        totalItems: 1,
        totalPages: 1,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isTypeFilterVisible, setIsTypeFilterVisible] = useState(false);
    const [isQualificationVisible, setIsQualificationVisible] = useState(false);
    const [buttonPosition1, setButtonPosition1] = useState({ top: 0, left: 0 });
    const [buttonPosition2, setButtonPosition2] = useState({ top: 0, left: 0 });
    const [showModal, setShowModal] = useState(false);
    const { scrollPosition, searchParams, setSearchParams, shouldRestore } = useScrollPosition();
    const [notificationAppModal, setNotificationAppModal] = useState(false);
    const [notificationQualificationModal, setNotificationQualificationModal] = useState(false);
    useEffect(() => {
        if (user?.qualification && Object.keys(user.qualification).length > 0) {
            setFiltrar(true);
        } else {
            setFiltrar(false);
        }
    }, [user]);

    useEffect(() => {
        const notificationAppDirectory = JSON.parse(localStorage.getItem('notificationAppDirectory'));
        const notificationQualificationDirectory = JSON.parse(localStorage.getItem('notificationQualificationDirectory'));
        if (!notificationAppDirectory?.active && notificationAppDirectory?.count % 10 === 0 && notificationAppDirectory?.count !== 0) {
            setNotificationAppModal(true);
        }
        if (notificationQualificationDirectory?.active && notificationQualificationDirectory?.count % 16 === 0 && notificationQualificationDirectory?.count !== 0) {
            setNotificationQualificationModal(true);
        }

        const newCountApp = notificationAppDirectory?.count + 1;
        const newCountQualification = notificationQualificationDirectory?.count + 1;
        localStorage.setItem('notificationAppDirectory', JSON.stringify({ active: notificationAppDirectory?.active, count: newCountApp }));
        localStorage.setItem('notificationQualificationDirectory', JSON.stringify({ active: notificationQualificationDirectory?.active, count: newCountQualification }));
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const endpoints = [
                    `/static-data/universities`,
                    `/static-data/countries`,
                    `/static-data/firms`,
                    `/static-data/qualifications`,
                    `/static-data/expertise-areas`
                ];
                const responses = await Promise.all(endpoints.map(url => publicAxios.get(url)));
                const filteredCountries = responses[1].data.filter(country =>
                    [11, 13, 20, 50, 61, 87].includes(country.id)
                );
                setData({
                    universities: responses[0].data,
                    countries: filteredCountries,
                    firms: responses[2].data,
                    qualifications: responses[3].data,
                    areas: responses[4].data
                });
                let searchQuery = '';
                if (location.search) {
                    const params = new URLSearchParams(location.search);
                    searchQuery = params.get('search') || '';
                    setSearchParams(prev => ({ ...prev, search: searchQuery }));
                } else if (Object.keys(searchParams).length) {
                    searchQuery = searchParams.search || '';
                    const universityIds = searchParams.university.length ? searchParams.university.map(option => option.id) : [];
                    const countryIds = searchParams.country.length ? searchParams.country.map(option => option.id) : [];
                    const firmIds = searchParams.firm.length ? searchParams.firm.map(option => option.id) : [];
                    const expertiseAreasIds = searchParams.expertiseAreas.length ? searchParams.expertiseAreas.map(option => option.id) : [];
                    const values = {
                        search: searchParams.search,
                        university: universityIds,
                        country: countryIds,
                        firm: firmIds,
                        expertiseAreas: expertiseAreasIds,
                        qualification: searchParams.qualification,
                        qualificationType: searchParams.qualificationType,
                        page: searchParams.page,
                    };
                    await performSearch(values);
                } else {
                    await performSearch({});
                }
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [location.search]);


    useEffect(() => {
        if (shouldRestore) {
            window.scrollTo(0, scrollPosition);
        }
    }, [shouldRestore, scrollPosition]);

    const performSearch = async (params) => {
        try {
            const response = await publicAxios.get(`/users/search-more-filters`, { params });
            if (response.data.users.length === 0) {
                setPages({
                    currentPage: 1,
                });
            } else {
                setPages({
                    currentPage: response.data.currentPage,
                    totalItems: response.data.totalItems,
                    totalPages: response.data.totalPages,
                });
            }
            setProfiles(response.data.users.map(profile => ({
                id: profile.id,
                name: profile.name,
                city: profile.city,
                country: profile.country,
                linkPhoto: profile?.linkPhoto,
                profession: profile.profession,
                university: profile.university,
                firm: profile.firm,
                rating: profile.qualification,
            })));
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };

    const handleVerifyAccount = () => {
        navigate('/video-call');  // Asumiendo que tienes una ruta para verificar la cuenta
    };

    const renderProfileCard = (profile, index) => (
        <Grid item xs={12} sm={6} md={4} lg={2.3} key={profile.id} style={isUnverified && index >= 20 ? blurStyle : null}>
            <Box>
                <ProfileCard {...profile} />
                {isUnverified && index >= 20 && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        backdropFilter: 'blur(2px)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Typography variant="body1">Verifica tu cuenta para ver más perfiles</Typography>
                    </Box>
                )}
            </Box>
        </Grid>
    );

    return (
        <Box>
            <SearchComponent
                onPerformSearch={performSearch}
                data={data}
                currentPage={pages.currentPage}
                selectedTypes={searchParams.qualificationType || []}
                setSelectedType={(types) => setSearchParams(prev => ({ ...prev, qualificationType: types }))}
                isTypeFilterVisible={isTypeFilterVisible}
                setIsTypeFilterVisible={setIsTypeFilterVisible}
                selectedQualifications={searchParams.qualification || []}
                setSelectedQualifications={(quals) => setSearchParams(prev => ({ ...prev, qualification: quals }))}
                isQualificationVisible={isQualificationVisible}
                setIsQualificationVisible={setIsQualificationVisible}
                setButtonPosition1={setButtonPosition1}
                setButtonPosition2={setButtonPosition2}
                filtrar={filtrar}
                searchQuery={searchParams.search}
                selectedUniversity={searchParams.university || []}
                selectedCountry={searchParams.country || []}
                selectedFirm={searchParams.firm || []}
                selectedExpertiseAreas={searchParams.expertiseAreas || []}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <Box sx={{ backgroundColor: 'black', pt: 10, pb: 5, position: 'relative' }}>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={2} justifyContent="center">
                        {profiles.slice(0, 20).map((profile, index) => renderProfileCard(profile, index))}
                    </Grid>
                )}
            </Box>
            {!isUnverified && <Pagination pages={pages} setPages={setPages} />}
            {isTypeFilterVisible && (
                <TypeFilterComponent
                    selectedTypes={searchParams.qualificationType || []}
                    onSelectType={(types) => setSearchParams(prev => ({ ...prev, qualificationType: types }))}
                    setIsTypeFilterVisible={setIsTypeFilterVisible}
                    buttonPosition={buttonPosition1}
                />
            )}
            {isQualificationVisible && (
                <QualificationFilterComponent
                    selectedQualifications={searchParams.qualification || []}
                    onSelectQualification={(quals) => setSearchParams(prev => ({ ...prev, qualification: quals }))}
                    setIsQualificationVisible={setIsQualificationVisible}
                    buttonPosition={buttonPosition2}
                />
            )}
            <Dialog open={showModal} onClose={() => setShowModal(false)}>
                <DialogTitle>
                    Acceso Restringido
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography>Debes calificarte para utilizar estas opciones de filtro.</Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'flex-end' }}>
                    <Button onClick={() => navigate('/qualification')} color="primary">
                        Califícate
                    </Button>
                </DialogActions>
            </Dialog>
            {notificationAppModal && <NotificationApp isModalOpen={notificationAppModal} setIsModalOpen={setNotificationAppModal} />}
            {notificationQualificationModal && <NotificationQualification isModalOpen={notificationQualificationModal} setIsModalOpen={setNotificationQualificationModal} />}
        </Box>
    );
};

const blurStyle = {
    filter: 'blur(8px)',
    userSelect: 'none',
    pointerEvents: 'none'
};

export default Directory;
