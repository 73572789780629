import React, { useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Modal,
    Divider,
    IconButton,
    Link,
    Button,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../snackbar/SnackbarContext';
import RenderQualification from './RenderQualification';
import QualificationBody from './QualificationBody';
import RatingsComparison from './RatingsComparison';
import difuminado from '../../assets/logo/difuminado.png';

const Qualification = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showWebView, setShowWebView] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { user } = useAuth();
    const { showSnackbar } = useSnackbar();
    const id = user?.id;

    const handleQualification = async (type) => {
        try {
            const params = {
                id,
                qualificationType: type.type,
            }
            navigate('/instruction-qualification', { state: params });
        } catch (error) {
            console.error('Error initiating payment: ', error);
            showSnackbar('Failed to initiate payment', 'error');
        }
    };

    const getImprovementMessage = () => {
        return "Contamos con un simulador, el cual te permitirá evaluar en abstracto tus distintas opciones profesionales y académicas, como en la búsqueda de un trabajo, o en la elección de Universidad. Con el, podrás saber cual es el mejor camino a seguir";
    };

    const qualifications = user?.qualification || {};
    const hasMultipleQualifications = Object.keys(qualifications).length > 1;
    const currentQualification = qualifications.professional || qualifications.academic || qualifications.student;

    return (
        <>
            <Box sx={{ flex: 1, padding: isMobile ? 2 : 6 }}>
                {isLoading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress color="primary" />
                    </Box>
                )}
                <Modal open={showWebView} onClose={() => setShowWebView(false)}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <IconButton onClick={() => setShowWebView(false)} sx={{ position: 'absolute', top: 10, left: 10, backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 8 }}>
                            <ArrowBackIosNewIcon sx={{ color: '#fff' }} />
                        </IconButton>
                    </Box>
                </Modal>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 6, ml: isMobile ? 2 : 6, mb: 6 }}>
                    <Divider sx={{ width: 6, height: 40, backgroundColor: '#F67147', mr: 3 }} orientation="vertical" />
                    <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Poppins', textAlign: 'left' }}>Calificación Actual</Typography>
                </Box>
                {currentQualification ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 4 }}>
                        {hasMultipleQualifications ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', mb: 2 }}>
                                    {qualifications.professional && (
                                        <RenderQualification type="professional" qualification={qualifications.professional} isMultiple={hasMultipleQualifications} />
                                    )}
                                    {qualifications.academic && (
                                        <RenderQualification type="academic" qualification={qualifications.academic} isMultiple={hasMultipleQualifications} />
                                    )}
                                    {qualifications.student && (
                                        <RenderQualification type="student" qualification={qualifications.student} isMultiple={hasMultipleQualifications} />
                                    )}
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <RatingsComparison qualification={currentQualification} />
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start', ml: isMobile ? 0 : 2, mb: 4 }}>
                                <Box sx={{ flex: 1, mr: isMobile ? 0 : 2, minHeight: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    {qualifications.professional && (
                                        <RenderQualification type="professional" qualification={qualifications.professional} isMultiple={hasMultipleQualifications} />
                                    )}
                                    {qualifications.academic && (
                                        <RenderQualification type="academic" qualification={qualifications.academic} isMultiple={hasMultipleQualifications} />
                                    )}
                                    {qualifications.student && (
                                        <RenderQualification type="student" qualification={qualifications.student} isMultiple={hasMultipleQualifications} />
                                    )}
                                </Box>
                                <Box sx={{ flex: 2, ml: isMobile ? 0 : 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '300px', mt: isMobile ? 2 : 0 }}>
                                    <RatingsComparison qualification={currentQualification} />
                                </Box>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', ml: isMobile ? 0 : 4, mb: 14, justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: isMobile ? 0 : 8 }}>
                                <img src={difuminado} alt="difuminado" style={{ width: isMobile ? '150px' : '200px', height: isMobile ? '150px' : '200px' }} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', mt: isMobile ? 2 : 0 }}>
                                <Typography variant="h5" sx={{ fontWeight: 'normal', color: '#333', mb: 3 }}>
                                    Usted no tiene ninguna calificación.
                                </Typography>
                                <Typography variant="h6" sx={{ color: '#666', mb: 3 }}>
                                    Escoja una categoría y califíquese.
                                </Typography>
                                <Button variant="primary" href="#rate-me-section" sx={{ fontSize: '1.25rem', padding: '10px 20px' }} endIcon={<ExpandMoreIcon />}>
                                    Calificarme
                                </Button>
                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 6, mb: 5, px: 10 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                                <Divider sx={{ my: 2 }} />
                                Conoce el significado de las calificaciones {' '}
                                <Link component={RouterLink} to="/ratings" sx={{ color: '#F67147', textDecoration: 'none', fontWeight: 'bold' }}>
                                    AQUÍ
                                </Link>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>Simulador de Calificaciones</Typography>
                                <Typography variant="body1">{getImprovementMessage()}</Typography>
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Button
                                    variant="outlined"
                                    sx={{ color: '#F67147', borderColor: '#F67147' }}
                                    onClick={() => navigate('/simulator')}
                                >
                                    Ir al simulador
                                </Button>
                            </Box>
                        </Box>
                        {/* <Box sx={{ mb: 2 }}>
                        </Box> */}
                    </>
                )}
            </Box>
            <Box id="rate-me-section" sx={{ width: '100%', backgroundColor: '#EEF1F4', p: isMobile ? 2 : 6 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 6, ml: isMobile ? 2 : 6 }}>
                    <Divider sx={{ width: 6, height: 40, backgroundColor: '#F67147', mr: 3 }} orientation="vertical" />
                    <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Poppins', textAlign: 'left' }}>Califícate</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: isMobile ? 0 : 8 }}>
                    <QualificationBody handleQualification={handleQualification} />
                </Box>
            </Box>
        </>
    );
};

export default Qualification;
