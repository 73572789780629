import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import SelectOption from '../../SelectOption';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { publicAxios } from '../../../apiClients';
import { getFullDate } from '../../../utils/Validators';

function ProfessionalExperienceForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [formData, setFormData] = useState({
        institutionType: '',
        publicServiceType: '',
        institutionID: '',
        institutionName: '',
        positionID: '',
        positionName: '',
        startDate: null,
        endDate: null,
        currentExperience: false,
    });
    const [positions, setPositions] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [publicServiceTypes, setPublicServiceTypes] = useState([]);
    const [error, setError] = useState(false);

    const institutionTypes = [
        { id: 'Firm', name: 'Firma Legal' },
        { id: 'Company', name: 'Compañía' },
        { id: 'PublicService', name: 'Servicio Público' },
        { id: 'Independient', name: 'Independiente' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (formData.institutionType === 'Firm') {
                    const firmsResponse = await publicAxios.get('/static-data/firms');
                    setInstitutions(firmsResponse.data);
                    const positionsResponse = await publicAxios.get('/static-data/positions?institution=Firm');
                    setPositions(positionsResponse.data);
                } else if (formData.institutionType === 'Company') {
                    const companiesResponse = await publicAxios.get('/static-data/companies');
                    setInstitutions(companiesResponse.data);
                    const positionsResponse = await publicAxios.get('/static-data/positions?institution=Company');
                    setPositions(positionsResponse.data);
                } else if (formData.institutionType === 'PublicService') {
                    const publicServiceResponse = await publicAxios.get('/static-data/public-service-type');
                    setPublicServiceTypes(publicServiceResponse.data);
                }
            } catch (error) {
                console.error('Error fetching data', error);
                setError(true);
            }
        };

        fetchData();
    }, [formData.institutionType]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (formData.publicServiceType !== '' && formData.institutionType === 'PublicService') {
                    const institutionsResponse = await publicAxios.get(`/static-data/public-institutions?institutionTypeID=${formData.publicServiceType}`);
                    setInstitutions(institutionsResponse.data);
                    const positionsResponse = await publicAxios.get(`/static-data/public-positions?institutionTypeID=${formData.publicServiceType}`);
                    setPositions(positionsResponse.data);
                }
            } catch (error) {
                console.error('Error fetching institutions or positions', error);
            }
        };

        fetchData();
    }, [formData.publicServiceType]);

    const handleDateChange = (date, field) => {
        setFormData({
            ...formData,
            [field]: date
        });
    };

    const handleInstitutionChange = (value) => {
        const selectedInstitution = institutions.find(institution => institution.id === value);
        setFormData({
            ...formData,
            institutionID: value,
            institutionName: selectedInstitution ? selectedInstitution.name : '',
        });
    };

    const handlePositionChange = (value) => {
        const selectedPosition = positions.find(position => position.id === value);
        setFormData({
            ...formData,
            positionID: value,
            positionName: selectedPosition ? selectedPosition.name : '',
        });
    };

    const handleSubmit = () => {
        if (!formData.institutionType ||
            (formData.institutionType === 'PublicService' && !formData.publicServiceType) ||
            (formData.institutionType !== 'Independient' && !formData.institutionID) ||
            (formData.institutionType !== 'Independient' && !formData.positionID) ||
            !formData.startDate ||
            (!antecedentType.id === 14 && !formData.endDate)) {
            setError(true);
            return;
        }

        onAddAntecedent({
            id: antecedentType.id,
            institutionType: formData.institutionType === 'PublicService' ? 'Servicio Público' : formData.institutionType,
            publicServiceType: formData?.publicServiceType,
            institutionID: formData.institutionID,
            institutionName: formData.institutionName,
            positionID: formData.positionID,
            positionName: formData.positionName,
            startDate: getFullDate(formData.startDate),
            endDate: antecedentType.id === 14 ? 'present' : getFullDate(formData.endDate),
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Agregar {antecedentType.name}
                </Typography>
                <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                    <Grid item xs={12} sm={4}>
                        <Typography>Tipo de institución</Typography>
                        <SelectOption
                            options={institutionTypes}
                            onSelectionChange={(value) => setFormData({ ...formData, institutionType: value })}
                            name={'Escoge el tipo de institución'}
                            width="80%"
                            selectedOption={formData.institutionType}
                            error={error && !formData.institutionType}
                            helperText={error && !formData.institutionType ? 'Este campo es obligatorio' : ''}
                        />
                    </Grid>
                    {formData.institutionType === 'PublicService' && (
                        <Grid item xs={12} sm={4}>
                            <Typography>Tipo de servicio público</Typography>
                            <SelectOption
                                options={publicServiceTypes}
                                onSelectionChange={(value) => setFormData({ ...formData, publicServiceType: value })}
                                name={'Escoge el tipo de servicio público'}
                                width="80%"
                                selectedOption={formData.publicServiceType}
                                error={error && !formData.publicServiceType}
                                helperText={error && !formData.publicServiceType ? 'Este campo es obligatorio' : ''}
                            />
                        </Grid>
                    )}
                    {formData.institutionType !== 'Independient' && (
                        <Grid item xs={12} sm={4}>
                            <Typography>Institución</Typography>
                            <SelectOption
                                options={institutions}
                                onSelectionChange={handleInstitutionChange}
                                name={'Escoge la institución'}
                                width="80%"
                                selectedOption={formData.institutionID}
                                error={error && !formData.institutionID}
                                helperText={error && !formData.institutionID ? 'Este campo es obligatorio' : ''}
                            />
                        </Grid>
                    )}
                    {formData.institutionType !== 'Independient' && (
                        <Grid item xs={12} sm={4}>
                            <Typography>Posición</Typography>
                            <SelectOption
                                options={positions}
                                onSelectionChange={handlePositionChange}
                                name={'Escoge la posición'}
                                width="80%"
                                selectedOption={formData.positionID}
                                error={error && !formData.positionID}
                                helperText={error && !formData.positionID ? 'Este campo es obligatorio' : ''}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{ mb: 2 }}>Fecha de inicio {antecedentType.id === 14 && '- Presente'}</Typography>
                        <DatePicker
                            label="Fecha de inicio"
                            value={formData.startDate}
                            onChange={(date) => handleDateChange(date, 'startDate')}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: error && !formData.startDate,
                                    helperText: error && !formData.startDate ? 'Este campo es obligatorio' : '',
                                },
                            }}
                        />
                    </Grid>
                    {antecedentType.id === 13 && (
                        <Grid item xs={12} sm={4}>
                            <Typography sx={{ mb: 2 }}>Fecha de término</Typography>
                            <DatePicker
                                label="Fecha de término"
                                value={formData.endDate}
                                onChange={(date) => handleDateChange(date, 'endDate')}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: error && !formData.endDate,
                                        helperText: error && !formData.endDate ? 'Este campo es obligatorio' : '',
                                    },
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                    <Button variant="primary" onClick={handleSubmit}>
                        Agregar Antecedente
                    </Button>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
}

export default ProfessionalExperienceForm;
