import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, IconButton, Divider, useMediaQuery, Modal, Button } from '@mui/material';
import { WorkOutlineRounded, School, EditNote, TaskAltRounded, DoNotDisturbAlt, AccessTimeRounded, MoreHoriz } from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../snackbar/SnackbarContext';
import InfoModal from '../pending/InfoModal';
import MoreVert from '@mui/icons-material/MoreVert';
import { userAxios } from '../../apiClients';

const getIconProps = (state) => {
  switch (state) {
    case 'verified':
      return { icon: <TaskAltRounded color="success" sx={{ mr: 2 }} />, color: '#4CAF50' };
    case 'pending':
      return { icon: <AccessTimeRounded sx={{ stroke: '#ffd24d', mr: 2 }} />, color: '#FFC107' };
    case 'rejected':
      return { icon: <DoNotDisturbAlt color="error" sx={{ mr: 2 }} />, color: '#F44336' };
    default:
      return { icon: <DoNotDisturbAlt color="disabled" sx={{ mr: 2 }} />, color: '#9E9E9E' };
  }
};

function ProfileBody({ userData, isCurrentUser: isCurrentUserProp }) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { user } = useAuth();
  const [isCurrentUser, setIsCurrentUser] = useState(isCurrentUserProp);
  const [modalPermissionsUser, setModalPermissionsUser] = useState(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (user && user?.userRole === 'institutional') {
      const institutionUserIds = JSON.parse(localStorage.getItem('institutionUserIds') || '[]').map(Number); // Convertir todos los elementos a números
      const numericParamId = Number(userData.id);
      if (institutionUserIds.includes(numericParamId)) {
        const adminPermissionArray = userData?.adminPermission?.data || [];
        if (adminPermissionArray.length > 0) {
          let findPermission = false;
          adminPermissionArray.forEach(permission => {
            if (permission.institutionID === user?.adminInstitution?.institutionID && permission.institutionType === user?.adminInstitution?.institutionType) {
              if (permission.state) {
                setIsCurrentUser(true);
              } else {
                showSnackbar('El usuario ha INHABILITADO los permisos administrativos para que la institución pueda gestionar y visualizar los antecedentes que no son públicos.', 'error');
              }
              findPermission = true;
            }
          });
          if (!findPermission) {
            const userID = userData?.id;
            if (!user?.adminNotifications?.includes(userID)) {
              setModalPermissionsUser(true);
            }
            setIsCurrentUser(true);
          }
        } else {
          const userID = userData?.id;
          if (!user?.adminNotifications?.includes(userID)) {
            setModalPermissionsUser(true);
          }
          setIsCurrentUser(true);
        }
      }
    }
  }, [user]);

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [item, setItem] = useState([]);

  const [openSections, setOpenSections] = useState({
    'Professional Experience': true,
    'Academic Background': true,
    'Other': true,
  });

  const handleOpenModal = (item, type) => {
    setItem({ ...item, type });
    setOpenInfoModal(true);
  };

  const handleCloseModal = () => {
    setItem([]);
    setOpenInfoModal(false);
  };

  const toggleSection = (section) => {
    setOpenSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const handleExperiencePress = async (type, experience) => {
    localStorage.setItem('paramId', userData.id);
    const routes = {
      'education': { path: '/upload-education', params: { title: 'Pregrado', description: 'Carga el diploma o certificado que acredite tu grado de licenciado. Este antecedente será sometido a nuestro sistema de verificación cruzada.', degree: 1 } },
      'title': { path: '/upload-professional-title', params: { title: 'Título profesional', description: 'Debes acompañar una copia del título de abogado(a) emitido por la Corte Suprema. También podrás cargar un certificado de abogado emitido por el Poder Judicial.', degree: 4 } },
      'postgrado': { path: '/upload-education', params: { title: 'Postgrado', description: 'Cargar el diploma o certificado que acredite tu grado de magíster o doctor (Phd). Este antecedente será sometido a nuestro sistema de verificación cruzada.', degree: 2 } },
      'cursos': { path: '/upload-education', params: { title: 'Cursos y diplomas', description: 'Acompaña el certificado respectivo, y lo someteremos a verificación cruzada. El valor de los cursos online es inferior al de aquellos realizados de forma presencial.', degree: 3 } },
      'academic-past': { path: '/upload-academic', params: { title: 'Docencia pasada', description: 'Debes acompañar algún documento que demuestre tu actividad docente (un contrato, por ejemplo), o copiar algún link oficial de la institución en la que enseñas. Este antecedente será sometido a nuestro sistema de verificación cruzada.', previus: true } },
      'academic-present': { path: '/upload-academic', params: { title: 'Docencia actual', description: 'Debes acompañar algún documento que demuestre tu actividad docente (un contrato, por ejemplo), o copiar algún link oficial de la institución en la que enseñas. Este antecedente será sometido a nuestro sistema de verificación cruzada.', previus: false } },
      'publication': { path: '/upload-publication', params: { title: 'Publicaciones', description: 'Para demostrar tu autoría de un artículo o de un libro, no es necesario que adjuntes el documento. Basta con que nos indiques algún link en el que se encuentre disponible la publicación (por ejemplo, Scielo), o el sitio web de alguna librería o biblioteca.' } },
      'scholarship': { path: '/upload-scholarship', params: { title: 'Becas académicas', description: 'Si has obtenido alguna beca en reconocimiento a tu buen desempeño académico y/o liderazgo, demuéstralo acompañando documentación oficial, o con algún link oficial de la institución que haya otorgado la beca. Las becas socioeconómicas no son consideradas en la calificación.' } },
      'int-accreditation': { path: '/upload-int-accreditation', params: { title: 'Habilitación internacional', description: 'Si estás habilitado para ejercer la profesión en un lugar distinto de Chile (por ejemplo, si aprobaste el BAR Exam en algún Estado de Estados Unidos), demuéstralo acompañando el certificado respectivo, o algún link oficial de la institución examinadora.' } },
      'idiom': { path: '/upload-idiom', params: { title: 'Idiomas', description: 'El manejo de otro idioma debe demostrarse con certificaciones oficiales, o con algún link de la institución examinadora.' } },
      'professional-present': { path: '/upload-professional', params: { title: 'Puesto de trabajo actual', description: 'Debes acompañar algún documento que demuestre tu lugar de trabajo (contrato de trabajo, por ejemplo), o copiar algún link oficial de la institución. Este antecedente será sometido a nuestro sistema de verificación cruzada.', previus: false } },
      'professional-past': { path: '/upload-professional', params: { title: 'Puesto de trabajo anterior', description: 'Debes acompañar algún documento que demuestre tu lugar de trabajo (contrato de trabajo, por ejemplo), o copiar algún link oficial de la institución. Este antecedente será sometido a nuestro sistema de verificación cruzada. Mientras más cargos subas, más posibilidades tienes de subir tu calificación.', previus: true } },
      'ranking': { path: '/upload-ranking', params: { title: 'Rankings internacionales', description: 'Si estás rankeado personalmente por Chambers & Partners, The Legal 500, Who is Who Legal, o Leaders League, copia el link en que apareces mencionado.' } },
      'relevant-experience': { path: '/upload-relevant-experience', params: { title: 'Experiencias relevantes', description: 'Carga los asuntos relevantes en los que has participado, acompañando algún documento (un escrito judicial o una escritura pública, por ejemplo), o copiando el link de algún medio de comunicación serio que te mencione expresamente. Linkedin no es prueba suficiente.' } },
      'other': { path: '/upload-other-background', params: { title: 'Otros antecedentes', description: 'Si cuentas con otros antecedentes profesionales o académicos relevantes, que no se encuentran mencionados en el listado anterior, puedes incorporarlos aquí (por ejemplo, ser o haber sido abogado integrante, o árbitro). Ten en cuenta que no todos los antecedentes influyen en tu calificación.' } },
    };

    if (routes[type]) {
      const { path, params } = routes[type];
      navigate(path, { state: { ...params, experience } });
    } else {
      console.log('Tipo de experiencia desconocida');
    }
  };

  const educationTypeTranslations = {
    'undergraduate': 'Pregrado',
    'title': 'Título',
    'magister': 'Magíster',
    'doctorate': 'Doctorado',
    'diplomat': 'Diplomado',
    'course': 'Curso'
  };

  const getEducationTypeTranslation = (type) => {
    return educationTypeTranslations[type] || type;
  };

  const truncateText = (text, maxChars) => {
    if (text?.length <= maxChars) return text;
    return text.substring(0, maxChars) + '...';
  };

  const renderExperience = (experience, index) => (
    <Box key={index} sx={{ pl: isMobile ? 2 : 6, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ direction: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getIconProps(experience?.state).icon}
          <Typography sx={{ fontFamily: 'Poppins' }}>
            {experience?.institutionType === 'Independient' ? (
              <strong>Abogado Independiente</strong>
            ) : (
              <>
                <strong>{experience?.position?.name}</strong>
                {experience?.institution?.name && (
                  experience?.position?.name ? (
                    <>
                      &nbsp;en&nbsp;
                      {(experience?.institution?.name === 'Otro' || experience?.institution?.name === 'Otra')
                        ? experience?.institutionName
                        : experience?.institution?.name}
                    </>
                  ) : (
                    <>
                      <strong>{experience?.institutionName}</strong>
                      {!experience?.position?.name && ' en Alstom'}
                      < br />
                    </>
                  )
                )}
              </>
            )}
            {(experience?.startDate || experience?.endDate) && (
              <>
                &nbsp;(
                {experience?.startDate ? experience?.startDate?.slice(0, 4) : ''}
                {experience?.startDate && ' - '}
                {experience?.endDate !== 'present' ? experience?.endDate?.slice(0, 4) : 'Presente'}
                )
              </>
            )}
          </Typography>
        </Box>
      </Box>
      {(isCurrentUser && experience.state === 'preload') || (user?.state === 'admin') ? (
        <IconButton onClick={() => handleExperiencePress(experience?.endDate !== 'present' ? 'professional-past' : 'professional-present', experience)}>
          <EditNote />
        </IconButton>
      ) : isCurrentUser && experience.state === 'verified' && (experience.url || experience.urlAWS) && (
        <IconButton onClick={() => handleOpenModal(experience, 'professional')}>
          <MoreVert />
        </IconButton>)}
    </Box>
  );

  const renderEducation = (education, index) => (
    <Box
      key={index}
      sx={{
        pl: isMobile ? 2 : 6,
        mt: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100%',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: 'calc(100% - 48px)' }}>
        {getIconProps(education?.state).icon}
        <Typography
          component="span"
          sx={{
            fontFamily: 'Poppins',
            display: 'inline-block',
            alignItems: 'center',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'normal',
            minWidth: 0,
            flexShrink: 1,
            maxWidth: '100%',
            wordBreak: 'break-word',
          }}
        >
          <strong>{getEducationTypeTranslation(education?.educationType)}</strong>
          {education?.educationType !== 'title' && (
            <>
              &nbsp;en&nbsp;
              {education?.University?.name === 'Otra'
                ? education?.universityName
                : education?.University?.name}
            </>
          )}
          {education?.educationType !== 'undergraduate' && education?.endDate && (
            <>
              &nbsp;(
              {education?.endDate?.slice(0, 4)}
              )
            </>
          )}
        </Typography>
      </Box>
      <Box sx={{ flexShrink: 0 }}>
        {(isCurrentUser && education.state === 'preload') || (user?.state === 'admin') ? (
          <IconButton
            onClick={() =>
              handleExperiencePress(
                education?.educationType === 'undergraduate'
                  ? 'education'
                  : education?.educationType === 'title'
                    ? 'title'
                    : education?.educationType === 'magister' || education?.educationType === 'doctorate'
                      ? 'postgrado'
                      : education?.educationType === 'diplomat' || education?.educationType === 'course'
                        ? 'cursos'
                        : education?.educationType,
                education
              )
            }
          >
            <EditNote />
          </IconButton>
        ) : (
          isCurrentUser &&
          education.state === 'verified' &&
          (education.url || education.urlAWS) && (
            <IconButton onClick={() => handleOpenModal(education, 'education')}>
              <MoreVert />
            </IconButton>
          )
        )}
      </Box>
    </Box>
  );

  const renderPublication = (publication, index) => {
    const maxChars = 150;
    const truncatedName = truncateText(publication?.name || '', maxChars);
    const editorialName = publication?.Editorial?.name !== 'Otro' ? publication?.Editorial?.name : publication?.editorialName;

    return (
      <Box key={index} sx={{ pl: isMobile ? 1 : 6, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getIconProps(publication?.state).icon}
          <Typography component="span" sx={{ fontFamily: 'Poppins', fontWeight: 'normal' }}>
            {truncatedName}
            {editorialName && publication?.publicationDate && (
              <>
                {','}
                <br />
                {' '}{editorialName} ({publication.publicationDate.slice(0, 4)})
              </>
            )}
          </Typography>
        </Box>
        {isCurrentUser && publication.state === 'preload' ? (
          <IconButton onClick={() => handleExperiencePress('publication', publication)}>
            <EditNote />
          </IconButton>
        ) : isCurrentUser && publication.state === 'verified' && (publication.url || publication.urlAWS) && (
          <IconButton onClick={() => handleOpenModal(publication, 'publication')}>
            <MoreVert />
          </IconButton>)}
      </Box>
    );
  };

  const renderAcademicExperience = (academic, index) => (
    <Box key={index} sx={{ pl: isMobile ? 1 : 6, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {getIconProps(academic?.state).icon}
        <Typography component="span" sx={{ fontFamily: 'Poppins', fontWeight: 'normal' }}>
          {academic?.TeachingDegree?.name ? (<>Profesor/a <strong>{academic.TeachingDegree.name}</strong></>) : (<>Profesor/a</>)}
          &nbsp;en&nbsp;
          {academic?.University?.name === 'Otra'
            ? academic?.universityName
            : academic?.University?.name}
          {(academic?.startDate || academic?.endDate) && (
            <>
              &nbsp;(
              {academic?.startDate?.slice(0, 4)}
              {academic?.startDate && ' - '}
              {academic?.endDate !== 'present' ? academic?.endDate?.slice(0, 4) : 'Actual'}
              )
            </>
          )}
        </Typography>
      </Box>
      {(isCurrentUser && academic.state === 'preload') || (user?.state === 'admin') ? (
        <IconButton onClick={() => handleExperiencePress(academic?.endDate !== 'present' ? 'academic-past' : 'academic-present', academic)}>
          <EditNote />
        </IconButton>
      ) : isCurrentUser && academic.state === 'verified' && (academic.url || academic.urlAWS) && (
        <IconButton onClick={() => handleOpenModal(academic, 'academic')}>
          <MoreVert />
        </IconButton>)}
    </Box>
  );

  const renderIdiomExperience = (idiom, index) => (
    <Box key={index} sx={{ pl: isMobile ? 1 : 6, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {getIconProps(idiom?.state).icon}
        <Typography component="span" sx={{ fontFamily: 'Poppins', fontWeight: 'normal' }}>
          {idiom?.Idiom?.name}
          {' '}- {idiom?.exchangeStudies ? 'Experiencia en otro país' : `Certificado por ${idiom?.CertificationIdiom?.name}`}
        </Typography>
      </Box>
      {isCurrentUser && idiom.state === 'preload' ? (
        <IconButton onClick={() => handleExperiencePress('idiom', idiom)}>
          <EditNote />
        </IconButton>
      ) : isCurrentUser && idiom.state === 'verified' && (idiom.url || idiom.urlAWS) && (
        <IconButton onClick={() => handleOpenModal(idiom, 'idiom')}>
          <MoreVert />
        </IconButton>)}
    </Box>
  );

  const renderRelevantExperience = (experience, index) => {
    const companyName = experience?.companyName;
    const roleDescription = userData?.userRole === 'leyend' ? companyName : `Asesor de ${companyName}`;
    const fontWeight = userData?.userRole === 'leyend' ? 'bold' : 'normal';

    return (
      <Box key={index} sx={{ pl: isMobile ? 1 : 6, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getIconProps(experience?.state).icon}
          <Typography
            component="span"
            sx={{
              fontFamily: 'Poppins',
              fontWeight: fontWeight,
            }}
          >
            {roleDescription}
            {experience?.date && (
              <> ({experience?.date?.slice(0, 4)})</>
            )}
          </Typography>
        </Box>
        {isCurrentUser && experience.state === 'preload' ? (
          <IconButton onClick={() => handleExperiencePress('relevant-experience', experience)}>
            <EditNote />
          </IconButton>
        ) : isCurrentUser && experience.state === 'verified' && (experience.url || experience.urlAWS) && (
          <IconButton onClick={() => handleOpenModal(experience, 'relevant')}>
            <MoreVert />
          </IconButton>)}
      </Box>
    );
  };

  const renderInternationalAccreditation = (accreditation, index) => (
    <Box key={index} sx={{ pl: isMobile ? 1 : 6, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {getIconProps(accreditation?.state).icon}
        <Typography component="span" sx={{ fontFamily: 'Poppins', fontWeight: 'normal' }}>
          Acreditado en {accreditation?.Jurisdiction?.name !== 'Otro' ? accreditation?.Jurisdiction?.name : accreditation?.jurisdictionName}
        </Typography>
      </Box>
      {isCurrentUser && accreditation.state === 'preload' ? (
        <IconButton onClick={() => handleExperiencePress('int-accreditation', accreditation)}>
          <EditNote />
        </IconButton>
      ) : isCurrentUser && accreditation.state === 'verified' && (accreditation.url || accreditation.urlAWS) && (
        <IconButton onClick={() => handleOpenModal(accreditation, 'accreditation')}>
          <MoreVert />
        </IconButton>)}
    </Box>
  );

  const renderScholarship = (scholarship, index) => {
    const scholarshipTypeName = scholarship?.ScholarshipType?.name !== 'Otra' ? scholarship?.ScholarshipType?.name : scholarship?.name;
    const firstSpaceIndex = scholarshipTypeName.indexOf(' ');
    const firstWord = firstSpaceIndex !== -1 ? scholarshipTypeName.slice(0, firstSpaceIndex) : scholarshipTypeName;
    const restOfName = firstSpaceIndex !== -1 ? scholarshipTypeName.slice(firstSpaceIndex) : '';

    return (
      <Box key={index} sx={{ pl: isMobile ? 1 : 6, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getIconProps(scholarship?.state).icon}
          <Typography component="span" sx={{ fontFamily: 'Poppins', fontWeight: 'normal' }}>
            {firstWord}
            {restOfName && (
              <> {restOfName}</>
            )}
          </Typography>
        </Box>
        {isCurrentUser && scholarship.state === 'preload' ? (
          <IconButton onClick={() => handleExperiencePress('scholarship', scholarship)}>
            <EditNote />
          </IconButton>
        ) : isCurrentUser && scholarship.state === 'verified' && (scholarship.url || scholarship.urlAWS) && (
          <IconButton onClick={() => handleOpenModal(scholarship, 'scholarship')}>
            <MoreVert />
          </IconButton>)}
      </Box>
    );
  };

  const renderOtherBackground = (background, index) => (
    <Box key={index} sx={{ pl: isMobile ? 1 : 6, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {getIconProps(background?.state).icon}
        <Typography component="span" sx={{ fontFamily: 'Poppins', fontWeight: 'normal' }}>
          {background?.OtherPosition?.id === 1 ? (
            <>{background?.positionName} en {background?.whereWork} </>
          ) : (
            <>{background?.OtherPosition?.name} en {background?.whereWork}</>
          )}
        </Typography>
      </Box>
      {isCurrentUser && background.state === 'preload' ? (
        <IconButton onClick={() => handleExperiencePress('other', background)}>
          <EditNote />
        </IconButton>
      ) : isCurrentUser && background.state === 'verified' && (background.url || background.urlAWS) && (
        <IconButton onClick={() => handleOpenModal(background, 'other')}>
          <MoreVert />
        </IconButton>)}
    </Box>
  );

  const renderRankingExperience = (ranking, index) => (
    <Box key={index} sx={{ pl: isMobile ? 1 : 6, mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {getIconProps(ranking?.state).icon}
        <Typography component="span" sx={{ fontFamily: 'Poppins', fontWeight: 'normal' }}>
          Tiene ranking internacional {ranking?.Ranking?.name ? `en ${ranking.Ranking.name}, (${new Date(ranking.date).getFullYear()})` : ''}
        </Typography>
      </Box>
      {isCurrentUser && ranking.state === 'preload' ? (
        <IconButton onClick={() => handleExperiencePress('ranking', ranking)}>
          <EditNote />
        </IconButton>
      ) : isCurrentUser && ranking.state === 'verified' && (ranking.url || ranking.urlAWS) && (
        <IconButton onClick={() => handleOpenModal(ranking, 'ranking')}>
          <MoreVert />
        </IconButton>)}
    </Box>
  );

  const renderSection = (title, icon, items, renderFunction) => (
    items?.length > 0 && (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: isMobile ? 0 : 4 }}>
          {icon}
          <Typography variant="h6" sx={{ ml: 2, fontFamily: 'Poppins', fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        {items?.map((item, index) => renderFunction(item, index))}
      </Box>
    )
  );

  const renderOtherSection = (title, icon, items, renderFunctions) => (
    Object.keys(items).some(key => items[key]?.length > 0) && (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', ml: isMobile ? 0 : 4 }}>
          {icon}
          <Typography variant="h6" sx={{ ml: 2, fontFamily: 'Poppins', fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        {Object.keys(items).map((key) => (
          items[key]?.length > 0 && (
            <Box key={key} sx={{ ml: isMobile ? 2 : 4 }}>
              <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', mt: 2 }}>
                {key}:
              </Typography>
              {items[key].map((item, index) => renderFunctions[key](item, index))}
            </Box>
          )
        ))}
      </Box>
    )
  );

  const otherItems = {
    Idioma: userData?.IdiomExperiences?.filter(idiom => (idiom.state === 'verified') || isCurrentUser),
    Beca: userData?.AcademicScholarships?.filter(scholarship => (scholarship.state === 'verified') || isCurrentUser),
    'Experiencia Relevante': userData?.userRole !== 'leyend' && userData?.RelevantExperiences?.filter(exp => (exp.state === 'verified' && exp.publicVisible) || isCurrentUser),
    Acreditación: userData?.InternationalAccreditations?.filter(acc => (acc.state === 'verified') || isCurrentUser),
    ...(isCurrentUser && { 'Ranking internacionales': userData?.RankingNominations?.filter(ranking => (ranking.state === 'verified') || isCurrentUser) }),
    Antecedentes: userData?.OtherBackgrounds?.filter(background => (background.state === 'verified') || isCurrentUser),
  };

  const handleCloseModalPermissionsUser = () => {
    setModalPermissionsUser(false);
  };

  const sendPermissionNotificationEmail = async () => {
    try {
      const payload1 = {
        userID: userData.id,
        institutionName: user?.fullName,
      };
      await userAxios.post('/users/send-notification-institution-permission', payload1);
      let adminNotificationsData = user.adminNotifications;
      adminNotificationsData.push(userData.id);
      const payload2 = {
        userInstitutionID: user.id,
        adminNotifications: { data: adminNotificationsData },
      };
      await userAxios.patch('/users/add-admin-notification', payload2);
      showSnackbar('Correo enviado con éxito', 'success');
    } catch (error) {
      console.error(error);
      showSnackbar('Error al notificar al usuario', 'error');
    }
    handleCloseModalPermissionsUser();
  };

  return (
    <>
      <Paper sx={{ p: 2, mb: 2, backgroundColor: '#EEF1F4' }}>
        <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', mb: 2, ml: isMobile ? 0 : 4 }}>
          <WorkOutlineRounded sx={{ mr: 1 }} />
          Experiencia
        </Typography>
        {renderSection(
          'Actual',
          null,
          userData?.ProfessionalExperiences?.filter(exp => exp.endDate === 'present' && ((exp.state === 'verified') || isCurrentUser)),
          renderExperience
        )}
        {renderSection(
          'Pasado',
          null,
          userData?.ProfessionalExperiences?.filter(exp => exp.endDate !== 'present' && ((exp.state === 'verified') || isCurrentUser)),
          renderExperience
        )}
        {userData?.userRole === 'leyend' && renderSection(
          null,
          null,
          userData?.RelevantExperiences?.filter(exp => (exp.state === 'verified') || isCurrentUser),
          renderRelevantExperience
        )}

        <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', mt: 4, mb: 2, ml: isMobile ? 0 : 4 }}>
          <School sx={{ mr: 1 }} />
          Educación
        </Typography>
        {(() => {
          const educationOrder = ['title', 'undergraduate', 'magister', 'doctorate', 'course', 'diplomat'];

          const orderedEducation = userData?.EducationExperiences
            ?.filter(edu => (edu.state === 'verified') || isCurrentUser)
            ?.sort((a, b) => {
              const indexA = educationOrder.indexOf(a.educationType);
              const indexB = educationOrder.indexOf(b.educationType);
              return indexA - indexB;
            });

          return renderSection(
            null,
            null,
            orderedEducation,
            renderEducation
          );
        })()}
        {renderSection(
          'Publicaciones',
          null,
          userData?.Publications?.filter(pub => (pub.state === 'verified') || isCurrentUser),
          renderPublication
        )}
        {renderSection(
          'Actividades Académicas',
          null,
          userData?.AcademicExperiences?.filter(aca => (aca.state === 'verified') || isCurrentUser),
          renderAcademicExperience
        )}

        {Object.values({
          ...otherItems,
          ...(userData?.userRole === 'leyend' && { 'Experiencia Relevante': [] })
        }).flat().length > 0 && (
            <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', mt: 4, mb: 2, ml: isMobile ? 0 : 4 }}>
              <MoreHoriz sx={{ mr: 1 }} />
              Otros
            </Typography>
          )}
        {renderOtherSection(
          '',
          null,
          otherItems,
          {
            Idioma: renderIdiomExperience,
            Beca: renderScholarship,
            ...(userData?.userRole !== 'leyend' && { 'Experiencia Relevante': renderRelevantExperience }),
            Acreditación: renderInternationalAccreditation,
            ...(isCurrentUser ? { 'Ranking internacionales': renderRankingExperience } : {}),
            Antecedentes: renderOtherBackground,
          }
        )}
        <InfoModal open={openInfoModal} handleClose={handleCloseModal} item={item} />
      </Paper>

      <Modal open={modalPermissionsUser} onClose={handleCloseModalPermissionsUser}>
        <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 1, maxWidth: 400, margin: 'auto', mt: '10%', alignItems: 'center', textAlign: 'center' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
              <b>Notificar Usuario</b>
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
              Estimado administrador, actualmente usted está habilitado para gestionar los antecedentes de este usuario, aunque el usuario aún no le ha dado autorización directamente en su panel administrativo. Si lo desea, Soubid puede notificarle al usuario mediante un correo para informarle de esta situación. Si al usuario no le parece esta autorización, podrá cambiar su estado a "Inhabilitado" desde su panel administrativo.
              <br />
              <br />
              ¿Desea notificar al usuario?
          </Typography>
          <Button variant="primary" onClick={sendPermissionNotificationEmail} sx={{ mr: 2 }}>
              Sí
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCloseModalPermissionsUser}>
              No
          </Button>
        </Box>
      </Modal>
    </>
  );
}

ProfileBody.defaultProps = {
  onSeeMoreClick: () => { },
};

export default ProfileBody;
