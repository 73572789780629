import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Grid, Box, Avatar, Button, Typography, Checkbox, Divider, FormControlLabel, useMediaQuery, TextField } from '@mui/material';
import image from '../../utils/LogoBlack.png';
import SelectOption from '../../components/SelectOption';
import { publicAxios, userAxios } from '../../apiClients';
import Loading from '../../components/Loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import FileUploadStatus from '../../components/FileUpload';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';
import { useAuth } from '../../hooks/useAuth';
import BackButton from '../../components/BackButton';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function UploadIdiom() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, description } = location.state || {};
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [certifications, setCertifications] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedCertification, setSelectedCertification] = useState('');
  const [studiesAbroad, setStudiesAbroad] = useState(false);
  const [urlInput, setUrlInput] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [userProfileId, setUserProfileId] = useState(null);

  useEffect(() => {
    if (user) {
      if (user?.userRole === 'institutional' && localStorage.getItem('paramId')) {
        setUserProfileId(localStorage.getItem('paramId'));
      } else {
        setUserProfileId(user.id);
      }
    }
  }, [user]);

  useEffect(() => {
    // Hace scroll al inicio al montar el componente
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const responseLanguages = await publicAxios.get('/static-data/languages');
        const responseCertifications = await publicAxios.get('/static-data/idiom-certifications');
        setLanguages(responseLanguages.data);
        setCertifications(responseCertifications.data);
      } catch (error) {
        console.error('Error al obtener certificaciones e idiomas', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  const handleSendLanguageProficiency = async () => {
    setIsLoading(true);
    if (!selectedLanguage || (!studiesAbroad && !selectedCertification)) {
      setIsLoading(false);
      setError(true);
      return;
    }
    if (!studiesAbroad && !urlInput && !file) {
      setIsLoading(false);
      showSnackbar('Debes adjuntar un archivo o un link', 'error');
      return;
    }

    const languageProficiencyData = new FormData();
    languageProficiencyData.append('userID', userProfileId);
    languageProficiencyData.append('idiomID', selectedLanguage);
    if (!studiesAbroad) {
      languageProficiencyData.append('certificationIdiomID', selectedCertification);
    }
    languageProficiencyData.append('exchangeStudies', studiesAbroad);
    if (file) {
      languageProficiencyData.append('file', file);
    }
    if (urlInput) {
      languageProficiencyData.append('url', urlInput);
    }

    try {
      const response = await userAxios.post(`/idiom`, languageProficiencyData);
      if (response.data.success) {
        setIsLoading(false);
        showSnackbar('Certificación de idioma creada con éxito', 'success');
        navigate('/choose-document', { replace: true });


      } else {
        setIsLoading(false);
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al crear la certificación de idioma', error);
      showSnackbar('Error al crear la certificación de idioma', 'error');
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ flexGrow: 1, padding: 2, width: '100%' }}>
        <Grid container spacing={2} direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <BackButton path={"/choose-document"} back={true} />
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item xs={12} sx={{ my: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', direction: 'column' }}>
                    <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 4 }}>
                    {description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item>
                  <Typography>Idioma</Typography>
                </Grid>
                <Grid item>
                  <SelectOption
                    options={languages}
                    onSelectionChange={setSelectedLanguage}
                    name={'Selecciona un idioma'}
                    width={isMobile ? "100%" : "70%"}
                    selectedOption={selectedLanguage}
                    error={error && !selectedLanguage}
                    helperText={error && !selectedLanguage ? 'Este campo es obligatorio' : ''}
                  />
                </Grid>
              </Grid>
              {!studiesAbroad && (
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                  <Grid item>
                    <Typography>Tipo de certificación</Typography>
                  </Grid>
                  <Grid item>
                    <SelectOption
                      options={certifications}
                      onSelectionChange={setSelectedCertification}
                      name={'Selecciona el tipo de certificación'}
                      width={isMobile ? "100%" : "70%"}
                      selectedOption={selectedCertification}
                      error={error && !selectedCertification}
                      helperText={error && !selectedCertification ? 'Este campo es obligatorio' : ''}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={studiesAbroad}
                      onChange={() => setStudiesAbroad(!studiesAbroad)}
                      color="primary"
                    />
                  }
                  label="Marca el casillero si obtuviste tu grado de magíster o doctor (PhD) en un país de otra habla. (Debes tener verificado en tu perfil ese antecedente en particular)"
                  sx={{ alignItems: 'flex-start', '& .MuiFormControlLabel-label': { textAlign: 'justify' } }}
                />
              </Grid>
              {!studiesAbroad && (
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }} >
                  <Grid item container >
                    <Grid item xs={6} sx={{ mt: 2, mr: -2 }} >
                      <Grid item >
                        <Typography >
                          Adjunta Link (Opcional)
                        </Typography>
                      </Grid>
                      <TextField
                        margin="normal"
                        sx={{ width: isMobile ? "100%" : "87%" }}
                        size="small"
                        id="referenceText"
                        placeholder="Escribe aquí..."
                        label="Link"
                        value={urlInput}
                        onChange={(event) => handleInputChange(event, setUrlInput)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10} sx={{ mt: 2, mr: 3 }}>
                      <Grid item sx={{ mb: 2 }}>
                        <Typography >
                          Documento de respaldo
                          <Tooltip
                            title="Para acreditar tu experiencia puedes utilizar cualquier antecedente escrito, como un contrato, un escrito judicial, recortes de prensa, etc. El formato también es libre, pudiendo ser fotografías, archivos .pdf, links, o incluso pantallazos ¡Bienvenida la creatividad!"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  maxWidth: isMobile ? '150px' : '400px', // Ancho según si es móvil
                                  fontSize: isMobile ? '10px' : '13.15px',  // Tamaño de fuente según si es móvil
                                  padding: isMobile ? '6px' : '11px',    // Padding según si es móvil
                                  whiteSpace: 'normal', // Ajusta el texto al ancho del tooltip
                                  textAlign: 'center',  // Centra el texto en el tooltip
                                  backgroundColor: 'rgba(128, 128, 128, 0.975)',
                                },
                              },
                            }}
                          >
                            <HelpOutlineOutlinedIcon sx={{ ml: 1 }} />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item >
                        <FileUploadStatus setFile={setFile} file={file} />
                        {error && !file && <Typography variant="caption" color="error">* Sube un documento para comprobar esta certificación</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {!isMobile &&
              <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
                <Avatar variant="rounded" src={image} sx={{ width: '200px', height: '200px' }} />
              </Grid>
            }
            <Grid container item justifyContent="center" xs={12} sx={{ ml: isMobile ? 1 : 10, mb: 3 }}>
              <Button
                variant="primary"
                onClick={handleSendLanguageProficiency}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}

export default UploadIdiom;
