import React, { useState } from 'react';
import { Box, Typography, Button, Container, Modal, IconButton, Grid, useMediaQuery } from '@mui/material';
import Logo from '../utils/LogoBlack.png'; // Asegúrate de que la ruta a tu imagen del logo sea correcta
import AppleStoreBadge from '../assets/home/appstore.png'; // Asegúrate de que la ruta a la imagen sea correcta
import GooglePlayBadge from '../assets/home/google.png'; // Asegúrate de que la ruta a la imagen sea correcta
import QRApp from '../assets/images/qr-app.png'; // Asegúrate de que la ruta a la imagen sea correcta


const NotificationApp = ({ isModalOpen, setIsModalOpen}) => {
  const isLargeScreen = useMediaQuery('(min-width:600px)'); 

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 4, minHeight: '80vh' }}>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "80%",
            maxWidth: 700,
            // height: "60%",
            bgcolor: 'white',
            border: '1px solid #000',
            borderRadius: 5,
            boxShadow: 24,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around', 
            textAlign: 'center',
        }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
            ¡Conoce nuestra APP!
            </Typography>
            <Box component="img" src={Logo} alt="Logo" 
            sx={{ 
                height: 100,
                width: 100, 
                mb: 2 ,
                alignSelf: 'center'
            }} 
            />
            {isLargeScreen ? (
            <>
                <Typography variant="body1" sx={{ color: 'black', mb: 4 }}>
                    Escanea el QR y descarga directamente nuestra app.
                </Typography>
                <Box component="img" src={QRApp} alt="QR Code" 
                sx={{ 
                    height: 150, 
                    width: 150, 
                    alignSelf: 'center', 
                    mb: 2 
                }} 
                />
            </>
            ) : (
            <>
                <Typography variant="body1" sx={{ color: 'black', mb: 4 }}>
                    Haz clic en la sección inferior para ir a nuestra aplicación.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <a href="https://apps.apple.com/cl/app/soubid/id6578461746" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={AppleStoreBadge} alt="Download on the App Store" sx={{ height: 40, marginRight: 2 }} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.soubid.NewSoubid" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={GooglePlayBadge} alt="Get it on Google Play" sx={{ height: 40 }} />
                </a>
                </Box>
            </>

            )}
            <Button onClick={handleCloseModal} sx={{ color: 'black', justifyContent: 'flex-end', mb:1}}>
            cerrar
            </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default NotificationApp;
