// StyleTheme.js
import { createTheme } from '@mui/material';

const soubidColors = {
  main: '#F67147', // Naranjo principal
  gray1: '#323232', // Gris oscuro
  gray2: '#595959', // Gris medio oscuro
  gray3: '#75838E', // Gris medio
  gray4: '#D4DDE4', // Gris claro
  gray5: '#EEF1F4', // Gris más claro
  black: '#000000', // Negro
  white: '#FFFFFF', // Blanco
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#F67147', // Color naranja para el botón primario
    },
    secondary: {
      main: '#75838E', // Color gris para el botón secundario
    },
    neutral: {
      main: '#D4DDE4', // Color gris claro para el botón neutral
    },
    main: soubidColors.main, // Naranjo principal
    gray1: soubidColors.gray1, // Gris oscuro
    gray2: soubidColors.gray2, // Gris medio oscuro
    gray3: soubidColors.gray3, // Gris medio
    gray4: soubidColors.gray4, // Gris claro
    gray5: soubidColors.gray5, // Gris más claro
    black: soubidColors.black, // Negro
    white: soubidColors.white,
    // ... otras definiciones de color
  },
  typography: {
    fontFamily: 'Poppins, sans-serif', // Cambio a Work Sans para todos los textos
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
      color: 'white', // Texto blanco por defecto en botones
      fontFamily: 'Poppins, sans-serif', // Cambio a Work Sans específicamente para botones
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: { // Estilos aplicados a la raíz del componente Link
          color: soubidColors.gray3, // Establece el color del texto del enlace a blanco
          textDecoration: 'none', // Elimina el subrayado de todos los enlaces
          '&:hover': { // Estilos aplicados al pasar el mouse por encima
            textDecoration: 'none' // Mantiene los enlaces sin subrayado también al pasar el mouse
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            color: soubidColors.white, // Texto blanco
            backgroundColor: soubidColors.main, // Fondo naranja
            '&:hover': {
              backgroundColor: '#e65a36', // Un poco más oscuro al pasar el mouse
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            color: soubidColors.white, // Texto blanco
            backgroundColor: soubidColors.gray2, // Fondo gris
            '&:hover': {
              backgroundColor: '#5c6975', // Un gris ligeramente más oscuro al pasar el mouse
            },
          },
        },
        {
          props: { variant: 'neutral' },
          style: {
            color: soubidColors.gray1, // Texto en gris oscuro para contraste
            outline: '1px solid #595959', // Borde gris claro
            border: soubidColors.gray2, // Fondo gris claro
            '&:hover': {
              backgroundColor: '#c1c7cd', // Un gris más oscuro al pasar el mouse
              outline: '1px solid #595959', // Borde gris claro
            },
          },
        },
      ],
      // Puedes seguir manteniendo el estilo original aquí si lo necesitas
      styleOverrides: {
        root: {
          // Estilos generales para todos los botones si los necesitas
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        success: {  // Define el color de fondo para los alertas de éxito
          backgroundColor: '#4caf50'  // Un verde más oscuro
        },
        error: {  // Define el color de fondo para los alertas de error
          backgroundColor: '#f44336'  // Un rojo más oscuro
        },
        info: {  // Define el color de fondo para los alertas informativos
          backgroundColor: '#2196f3'  // Azul
        },
        warning: {  // Define el color de fondo para los alertas de advertencia
          backgroundColor: '#ff9800'  // Naranja
        }
      }
    }
  },
});

export default theme;