import React, { useState } from 'react';
import { TextField, Button, Chip, Paper, Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import { validateEmail } from '../utils/functions'; // Asegúrate de usar la ruta correcta

function EmailChipsInput({ setEmails, emails }) {
  const [emailInput, setEmailInput] = useState('');
  const [error, setError] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAddEmail = () => {
    const validationMessage = validateEmail(emailInput);
    if (validationMessage === true) {
      if (!emails.includes(emailInput)) {
        setEmails([...emails, emailInput]);
        setEmailInput('');
        setError(''); // Limpiar el mensaje de error si se agregó correctamente
      }
    } else {
      setError(validationMessage); // Mostrar el mensaje de error si no es válido
    }
  };

  const handleDeleteEmail = (emailToDelete) => () => {
    setEmails(emails.filter((email) => email !== emailToDelete));
  };

  const handleInputChange = (event) => {
    setEmailInput(event.target.value);
    if (error) {
      setError(''); // Limpiar el mensaje de error cuando el usuario empieza a escribir de nuevo
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddEmail();
    }
  };

  return (
    <Box sx={{ width: isMobile ? '100%' : '30%' }}>
      <Grid container spacing={2} alignItems="center" sx={{ justifyContent: isMobile ? 'center' : 'flex-start' }}>
        <Grid item xs={isMobile ? 12 : 8}>
          <TextField
            label="Correo"
            variant="outlined"
            value={emailInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            fullWidth
            error={Boolean(error)} // Mostrar el error en el TextField
            helperText={error} // Mostrar el mensaje de error
            sx={{
              "& .MuiInputBase-root": {
                height: "40px",
                alignItems: "center",
                padding: '2px 8px',
              },
              "& .MuiInputBase-input": {
                padding: "10px 8px 8px",
              },
              "& .MuiInputLabel-outlined": {
                transform: "translate(14px, 10px) scale(1)",
                "&.MuiInputLabel-shrink": {
                  transform: "translate(14px, -10px) scale(0.75)",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4} sx={{ mt: isMobile ? 2 : 0 }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleAddEmail}
            sx={{ height: '40px', maxWidth: '100%', minWidth: '80px', padding: '0' }}
          >
            Agregar
          </Button>
        </Grid>
        {emails.length > 0 && (
          <Grid item xs={12}>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'start',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 1,
                mb: 1,
                border: '1px solid #bdbdbd',
                minHeight: '50px',
                width: '100%',
                marginTop: '10px'
              }}
              component="ul"
            >
              {emails.map((email, index) => (
                <li key={index}>
                  <Chip
                    label={email}
                    onDelete={handleDeleteEmail(email)}
                    sx={{ mr: 0.5, mb: 0.5 }}
                  />
                </li>
              ))}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default EmailChipsInput;
