import React from 'react';
import { Container, Box, Typography, Paper, Link, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logo from '../assets/logo/LogoOrange.png'; // Asegúrate de ajustar la ruta del logo según sea necesario

const ContactView = () => {
  return (
    <Box sx={{ backgroundColor: '#f0f0f0', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          sx={{
            backgroundColor: '#ffffff',
            color: '#4A4A4A',
            padding: '60px',
            borderRadius: '15px',
            maxWidth: '600px',
            width: '100%',
            textAlign: 'center',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
          }}
        >
          <img src={logo} alt="Logo" style={{ width: '150px', marginBottom: '20px' }} />
          <Typography variant="h4" sx={{ marginBottom: '30px', fontWeight: 'bold', color: '#4A4A4A' }}>
            Contacto
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: '15px', color: '#757575' }}>
            Por correo electrónico:
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '30px', color: '#4A4A4A' }}>
            <Link href="mailto:support@soubid.com" sx={{ color: '#FF7043', textDecoration: 'none' }}>
              support@soubid.com
            </Link>
            <br />
            <br />
            Nuestro equipo de atención al cliente está disponible para responder cualquier pregunta o inquietud que pueda tener. No dude en ponerse en contacto con nosotros por Correo electrónico y le responderemos lo antes posible.
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: '15px', color: '#757575' }}>
            O contáctanos por WhatsApp:
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <IconButton
              href="https://wa.me/56959856601"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                backgroundColor: '#25D366',
                color: '#fff',
                marginRight: '15px',
                padding: '15px',
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: '#128C7E',
                },
              }}
            >
              <WhatsAppIcon sx={{ fontSize: '30px' }} />
            </IconButton>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#4A4A4A', fontSize: '18px' }}>
              +56 9 5985 6601
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ marginTop: '30px', color: '#757575' }}>
            Estamos disponibles para atender sus consultas a través de WhatsApp. Nuestro equipo se esforzará por brindarle una respuesta rápida y eficaz.
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default ContactView;
