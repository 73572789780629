import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography, Box, Button, Grid, Input } from '@mui/material';
import { green } from '@mui/material/colors';
import { useSnackbar } from '../components/snackbar/SnackbarContext';

function StatusCheck({ fileName }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CheckCircleIcon sx={{ color: green[500] }} />
            <Typography variant="body1">
                {fileName}
            </Typography>
        </Box>
    );
}

function PhotoUpload({ setFile, file }) {
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const { showSnackbar } = useSnackbar();

    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Asegura que solo se acepten imágenes JPG y PNG
            const validTypes = ['image/jpeg', 'image/png'];
            if (validTypes.includes(file.type)) {
                setFile(file);
                setUploadedFile(file.name);
            } else {
                showSnackbar('Por favor sube una imagen en formato JPG o PNG', 'error');
            }
        }
    };

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item>
                <Input
                    type="file"
                    onChange={handleUpload}
                    id="upload-button"
                    accept="image/jpeg, image/png"
                    style={{ display: 'none' }}
                />
                <label htmlFor="upload-button">
                    <Button variant="neutral" component="span" sx={{ height: 38 }}>
                        <Typography variant="body1" component="span" sx={{ mr: 1 }}>
                            Subir Imagen
                        </Typography>
                    </Button>
                </label>
            </Grid>
            {uploadedFile && (
                <Grid item>
                    <StatusCheck fileName={uploadedFile} />
                </Grid>
            )}
        </Grid>
    );
}

export default PhotoUpload;
