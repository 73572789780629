import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Divider,
    Avatar,
    Grid,
    useMediaQuery,
    Link,
    TextField,
} from '@mui/material';
import BackButton from '../BackButton';
import { publicAxios } from '../../apiClients';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Loading from '../Loading';
// import CustomModal from '../CustomModal';

const RequalifyView = () => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const location = useLocation();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { user } = useAuth();
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const images = [
        require('../../assets/qualificationAcademic/A.png'),
        require('../../assets/qualificationProfessional/A.png'),
        require('../../assets/qualificationStudent/A.png'),
    ];

    const handleInputChange = (event) => {
        setToken(event.target.value);
    };

    const handleGoToPay = async () => {
        setIsLoading(true);
        try {
            const id = user.id;
            const response = await publicAxios.get(`back-office/check-token?token=${token}&userID=${id}`);
            const qualificationType = response.data.qualificationType;
            if (response.data.success) {
                if (!user) {
                    setIsLoading(false);
                    navigate('/signin');
                }
                showSnackbar('Token valido', 'success');
                const { data: { qualification } } = await publicAxios.get(`/user-qualification/user/${user.id}?type=${qualificationType}&verified=${true}`);
                const qualificationResponse = await publicAxios.post('/user-qualification/create', { userID: user.id, qualificationType, qualificationID: Number(qualification) });
                if (qualificationResponse.data.success) {
                    const params = {
                        qualificationType: qualificationType,
                    }
                    setIsLoading(false);
                    navigate('/show-qualification', { state: params });
                }
            } else {
                if (!user) {
                    setIsLoading(false);
                    navigate('/signin');
                    showSnackbar('No estás loggeado', 'error');
                }
                showSnackbar('Este token no existe', 'error');
            }
        } catch (error) {
            if (!user) {
                setIsLoading(false);
                navigate('/signin');
                showSnackbar('El código es erróneo', 'error');
            }
            console.error('Error confirming payment: ', error);
            showSnackbar('El código es erróneo', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Box sx={{ flexGrow: 1, padding: isMobile ? 1 : 2 }}>
            <Grid container spacing={2} direction="column">
                <Grid item container direction="row" alignItems="center" spacing={2}>
                    <BackButton path={"/myprofile"} />
                </Grid>
                <Grid item container alignItems="center" spacing={2} sx={{ ml: isMobile ? 0 : 4, mt: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "2%" }} />
                        <Typography
                            variant="h5"
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 'bold',
                                display: 'inline-flex',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            Recalificación
                        </Typography>
                    </Box>
                </Grid>

                <Grid item container direction="column" xs={12} justifyContent="center" alignContent="center">
                    <Grid item xs={12} sx={{ my: 2, width: !isMobile ? '70%' : '100%' }}>
                        <Grid container direction="row" justifyContent="center" spacing={2} sx={{ mb: 4, height: isMobile ? 100 : 'auto' }}>
                            {images.map((image, index) => (
                                <Grid
                                    item
                                    key={index}
                                    alignContent={'flex-end'}
                                    sx={{
                                        marginTop: index === 1 ? -10 : 0 // Ajusta el valor -10 según la cantidad de desplazamiento que desees
                                    }}
                                >
                                    <Avatar
                                        src={image}
                                        sx={{
                                            width: isMobile ? (index === 1 ? 100 : 50) : (index === 1 ? 300 : 200),  // Cambia 300 por el tamaño deseado para la segunda imagen
                                            height: isMobile ? (index === 1 ? 100 : 50) : (index === 1 ? 300 : 200)  // Cambia 300 por el tamaño deseado para la segunda imagen
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Typography variant="body1" sx={{ mb: 2, fontFamily: 'Poppins' }}>
                            La calificación es el resultado de una evaluación matemática y objetiva que Soubid realiza de todos los antecedentes académicos y profesionales de sus usuarios; a quienes ubica en alguno de los cuarenta escalafones que considera el modelo.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, fontFamily: 'Poppins' }}>
                            Dicho modelo está determinado en base a criterios objetivos y científicos, cuidadosamente calibrados con la ayuda de los principales líderes de la industria.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 4, fontFamily: 'Poppins' }}>
                            A continuación podrás recalificarte a partir de los antecedentes que se han verificado. Sin subjetividades ni inclinaciones. Es sólo ciencia.
                        </Typography>
                        <Grid container justifyContent="center" flexDirection='row' spacing={2} sx={{ my: 4 }}>
                            <TextField
                                variant="outlined"
                                value={token}
                                onChange={handleInputChange}
                                placeholder="Escribe tu token para recalificarte"
                                sx={{ mr: 2, width: isMobile ? '50%' : '40%', }}
                                InputProps={{
                                    style: {
                                        height: '40px', // Aquí puedes ajustar la altura
                                        fontSize: isMobile ? 10 : 16,
                                    },
                                }}
                            />
                            <Button
                                variant="primary"
                                sx={{
                                    backgroundColor: '#F67147',
                                    color: '#fff',
                                    paddingY: 1.5,
                                    paddingX: 3,
                                    borderRadius: 1,
                                    mb: 4,
                                    fontFamily: 'Poppins',
                                    fontSize: isMobile ? 10 : 16,
                                    width: isMobile ? '25%' : '20%',
                                    height: 40,
                                }}
                                onClick={handleGoToPay}
                            >
                                Recalificarme
                            </Button>
                        </Grid>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, fontFamily: 'Poppins' }}>
                            Qué significa cada grado
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#333' }}>
                            Conoce el significado de las calificaciones {' '}
                            <Link component={RouterLink} to="/ratings" sx={{ color: '#F67147', textDecoration: 'none', fontWeight: 'bold' }}>
                                AQUÍ
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RequalifyView;
