import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Grid, Box, Avatar, Button, Typography, TextField, Divider, useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import image from '../../utils/LogoBlack.png';
import SelectOption from '../../components/SelectOption';
import Loading from '../../components/Loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileUploadStatus from '../../components/FileUpload';
import EmailChipsInput from '../../components/EmailChipsInput';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';
import { userAxios, publicAxios } from '../../apiClients';
import { useAuth } from '../../hooks/useAuth';
import BackButton from '../../components/BackButton';
import { getFullDate } from '../../utils/Validators';

const postGraduateTypes = [
  { id: 'magister', name: 'LL.M' },
  { id: 'doctorate', name: 'Ph.D' },
];

const postTitleTypes = [
  { id: 'diplomat', name: 'Diplomado' },
  { id: 'course', name: 'Curso' },
];

function UploadEducation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, description, degree, experience } = location.state || {};
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [educationType, setEducationType] = useState('');
  const [universityID, setUniversityID] = useState('');
  const [universities, setUniversities] = useState([]);
  const [universidadName, setUniversidadName] = useState(experience?.universityName || '');
  const [startDate, setStartDate] = useState(experience?.startDate ? new Date(experience.startDate) : null);
  const [endDate, setEndDate] = useState(experience?.endDate ? new Date(experience.endDate) : null);
  const [urlInput, setUrlInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState(null);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [emails, setEmails] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);

  useEffect(() => {
    if (user) {
      if ((user?.userRole === 'institutional' || user?.state === 'admin') && localStorage.getItem('paramId')) {
        setUserProfileId(localStorage.getItem('paramId'));
      } else {
        setUserProfileId(user.id);
      }
    }
  }, [user]);

  useEffect(() => {
    // Hace scroll al inicio al montar el componente
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchUniversities = async () => {
      setIsLoading(true);
      try {
        const response = await publicAxios.get(`/static-data/universities`);
        setUniversities(response.data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUniversities();
  }, []);

  useEffect(() => {
    if (experience) {
      setEducationType(experience.educationType || '');
      setUniversityID(experience.University?.id || '');
    }
  }, [experience]);

  const handleSendEducationExp = async () => {
    setIsLoading(true);
    if (user.state !== 'admin') {
      if (!universityID || (degree === 2 || degree === 3) && !educationType || !startDate || !endDate || !file || (universityID === 1 && !universidadName)) {
        setIsLoading(false);
        setError(true);
        return;
      }
    }
    const educationData = new FormData();
    if (user.state !== 'admin') {
      educationData.append('educationType', degree === 1 ? 'undergraduate' : (degree === 2 || degree === 3) ? educationType : degree === 4 ? 'title' : 'education');
      educationData.append('universityID', universityID);
      if (universityID === 1) {
        educationData.append('universityName', universidadName);
      }
      if (emails.length > 0) educationData.append('emails', JSON.stringify(emails));
      educationData.append('startDate', getFullDate(startDate));
      educationData.append('endDate', getFullDate(endDate));
    }
    educationData.append('userID', userProfileId);
    if (urlInput !== '') {
      educationData.append('url', urlInput);
    }
    educationData.append('documentType', degree === 1 ? 'undergraduate' : degree === 2 ? 'postgraduate' : degree === 3 ? 'post_title' : degree === 4 ? 'professional_title' : 'education');
    educationData.append('file', file);

    try {
      let response;
      if (user.state === 'admin') {
        response = await userAxios.patch(`/education/${experience.id}`, educationData);
      } else if (experience) {
        educationData.append('state', 'pending');
        response = await userAxios.patch(`/education/${experience.id}`, educationData);
      } else {
        response = await userAxios.post(`/education`, educationData);
      }
      if (response.data.success) {
        setIsLoading(false);
        const message = response.data.message === 'update' ? 'Certificación actualizada con éxito' : 'Certificación creada con éxito';
        showSnackbar(message, 'success');
        navigate('/choose-document', { replace: true });

      } else {
        setIsLoading(false);
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al crear la certificación', error);
      showSnackbar('Error al crear la certificación', 'error');
    }
  };

  function getValidationMessage() {
    if (startDate === null && endDate === null && error) {
      return <Typography variant="caption" color="error" >
        * Las fechas de inicio y término son obligatorias
      </Typography>
    } else if (startDate === null && error) {
      return <Typography variant="caption" color="error" >
        * La fecha de inicio es obligatoria
      </Typography>
    } else if (endDate === null && error) {
      return <Typography variant="caption" color="error" >
        * La fecha de término es obligatoria
      </Typography>
    }
    return "";
  }

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ flexGrow: 1, padding: 2, width: '100%' }}>
        <Grid container spacing={2} direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <BackButton path={"/choose-document"} back={true} />
          </Grid>
          <Grid item container spacing={2} alignItems="center" >
            <Grid item xs={12} sm={6} >
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item xs={12} sx={{ my: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', direction: 'column' }}>
                    <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 4 }}>
                    {description}
                  </Typography>
                </Grid>
                <Grid item >
                  {(degree === 2 || degree === 3) && (
                    <>
                      <Grid item >
                        <Typography >
                          Selecciona grado:
                        </Typography>
                      </Grid>
                      <SelectOption
                        options={degree === 2 ? postGraduateTypes : postTitleTypes}
                        onSelectionChange={setEducationType}
                        name={'Selecciona grado'}
                        width={isMobile ? "100%" : "70%"}
                        selectedOption={educationType}
                        error={error && !educationType}
                        helperText={error && !educationType ? 'Este campo es obligatorio' : ''}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }} >
                <Grid item >
                  <Typography >
                    Universidad
                  </Typography>
                </Grid>
                <Grid item >
                  <SelectOption
                    options={universities}
                    onSelectionChange={setUniversityID}
                    name={'Escoge universidad'}
                    width={isMobile ? "100%" : "70%"}
                    selectedOption={universityID}
                    error={error && !universityID}
                    helperText={error && !universityID ? 'Este campo es obligatorio' : ''}
                  />
                </Grid>
              </Grid>
              {universityID === 1 && (
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                  <Grid item xs={6} sx={{ mt: 2 }} >
                    <Grid item >
                      <Typography >
                        Nombre de la Universidad
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: isMobile ? "100%" : "70%" }}
                      size="small"
                      id="referenceText"
                      label="Escribe aquí..."
                      value={universidadName}
                      onChange={(event) => handleInputChange(event, setUniversidadName)}
                      error={error && !universidadName}
                      helperText={error && !universidadName ? 'Este campo es obligatorio' : ''}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                <Grid item sx={{ mt: 1 }}>
                  <Typography >
                    Fecha de inicio / Fecha de término
                  </Typography>
                </Grid>
                <Grid item container >
                  <Grid item sx={{ mt: 2, mr: 3 }} >
                    <DatePicker
                      label="Fecha de inicio"
                      value={startDate}
                      slotProps={{ textField: { size: 'small' } }}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      selectedOption={startDate}
                    />
                  </Grid>
                  <Grid item sx={{ mt: 2, mr: 3 }}>
                    <DatePicker
                      label="Fecha de término"
                      value={endDate}
                      slotProps={{ textField: { size: 'small' } }}
                      border="red"
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} />
                      )}
                      selectedOption={endDate}
                    />
                  </Grid>
                  <Grid item sx={{ mr: 3 }}>
                    {getValidationMessage()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }} >
                <Grid item container >
                  <Grid item xs={6} sx={{ mt: 2, mr: -2 }} >
                    <Grid item >
                      <Typography >
                        Adjunta Link (Opcional)
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: isMobile ? "100%" : "87%" }}
                      size="small"
                      id="referenceText"
                      placeholder="Escribe aquí..."
                      label="Link"
                      value={urlInput}
                      onChange={(event) => handleInputChange(event, setUrlInput)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} sx={{ mt: 2, mr: 3 }}>
                    <Grid item sx={{ mb: 2 }}>
                      <Typography >
                        Documento de respaldo
                        <Tooltip 
                          title="Para acreditar tu experiencia puedes utilizar cualquier antecedente escrito, como un contrato, un escrito judicial, recortes de prensa, etc. El formato también es libre, pudiendo ser fotografías, archivos .pdf, links, o incluso pantallazos ¡Bienvenida la creatividad!"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                maxWidth: isMobile ? '150px' : '400px', // Ancho según si es móvil
                                fontSize: isMobile ? '10px' : '13.15px',  // Tamaño de fuente según si es móvil
                                padding: isMobile ? '6px' : '11px',    // Padding según si es móvil
                                whiteSpace: 'normal', // Ajusta el texto al ancho del tooltip
                                textAlign: 'center',  // Centra el texto en el tooltip
                                backgroundColor: 'rgba(128, 128, 128, 0.975)',
                              },
                            },
                          }}
                        >
                          <HelpOutlineOutlinedIcon sx={{ml: 1}}/>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item >
                      <FileUploadStatus setFile={setFile} file={file} />
                      {error && !file && <Typography variant="caption" color="error">* Sube un documento para comprobar la verificación</Typography>}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile &&
              <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
                <Avatar variant="rounded" src={image} sx={{ width: '200px', height: '200px' }} />
              </Grid>
            }
            <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }} >
              <Grid item sx={{ mb: 2 }}>
                <Typography >
                  Danos el Correo electrónico de personas que puedan ayudar con el proceso de verificación.
                </Typography>
              </Grid>
              <Grid item sx={{ maxWidth: '300px' }} >
                <EmailChipsInput setEmails={setEmails} emails={emails} />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} sx={{ ml: isMobile ? 1 : 10, mb: 3 }} >
              <Button
                variant="primary"
                onClick={handleSendEducationExp}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}

export default UploadEducation;
