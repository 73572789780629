import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { publicAxios, userAxios } from '../../apiClients';
import { Box, Typography, Button, useMediaQuery, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';

const AddLegend = ({ onClose }) => {
  const { user } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [email, setEmail] = useState(null);
  const [legendName, setLegendName] = useState('');
  const { showSnackbar } = useSnackbar();

  const handlePostulatePerson = () => {
    if (!email) {
      showSnackbar('Por favor, ingresa un correo electrónico.', 'error');
    } else if (!email.includes('@')) {
      showSnackbar('Por favor, ingresa un correo electrónico válido.', 'error');
    } else if (!legendName.trim()) {
      showSnackbar('Por favor, ingresa el nombre de la leyenda.', 'error');
    } else {
      sendPostulationInstructionsEmail(email, legendName);
    }
  };

  useEffect(() => {
    if (user) {
      if (user.email.includes('@')) {
        setEmail(user.email);
      }
    }
  }, [user]);

  const sendPostulationInstructionsEmail = async (email, legendName) => {
    try {
      const payload = {
        email: email,
        legendName: legendName,
        public: !user,
        userID: user ? user.id : undefined,
      };
      await (user ? userAxios : publicAxios).post('/users/send-add-legend-instructions', payload);
      showSnackbar('Postulación enviada con éxito', 'success');
      onClose();
    } catch (error) {
      console.error(error);
      showSnackbar('Error al enviar el correo electrónico', 'error');
    }
  };

  return (
    <Box sx={{ ...styles.addLegend, padding: isMobile ? '10px' : '20px' }}>
      <Box sx={styles.contentContainer}>
        <Box sx={styles.headerContainer}>
          <Typography variant="h2" sx={{ ...styles.sectionTitle, fontSize: isMobile ? '18px' : '20px' }}>
            Añadir Leyenda
          </Typography>
          <IconButton sx={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={styles.description}>
          En nuestra plataforma, "Legends" reconoce a los abogados que han tenido una formación y una experiencia
          excepcionales, brindando servicios legales de alta calidad a lo largo de su carrera. Estas leyendas son
          ejemplos a seguir y han contribuido significativamente al campo del derecho.
        </Typography>
        <Typography sx={styles.highlight}>🌍 Agrega a esa persona que se lo merece</Typography>
        <Typography sx={styles.details}>
          Estos abogados han demostrado su excelencia a lo largo del tiempo y son una fuente de inspiración para todos.
          ¡Tú también puedes agregar a las personas que crees que se merecen estar en el "Hall of Legends"!
        </Typography>
        <Typography sx={styles.note}>
          ** Para postular a alguien, necesitamos cierta información y certificaciones para validar su inclusión en este
          prestigioso directorio.
        </Typography>
        <Box sx={{ ...styles.gridContainer, gridTemplateColumns: isMobile ? '1fr' : '1fr 2fr 1fr 1fr' }}>
          <Typography sx={styles.gridLabel}>Correo de contacto:</Typography>
          <TextField
            placeholder="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            size="small"
            sx={styles.gridTextField}
            InputProps={{
              startAdornment: (
                <MailOutlineIcon sx={{ marginRight: '8px', fontSize: isMobile ? '16px' : '20px' }} />
              ),
              style: { fontSize: isMobile ? '10px' : '12px' },
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? '10px' : '12px' },
            }}
          />
          {!isMobile && <Box sx={styles.gridEmpty} />}
          {!isMobile && <Box sx={styles.gridEmpty} />}
          <Typography sx={styles.gridLabel}>¿A quién deseas agregar?</Typography>
          <TextField
            placeholder="Nombre de la leyenda"
            value={legendName}
            onChange={(e) => setLegendName(e.target.value)}
            variant="outlined"
            size="small"
            sx={styles.gridTextField}
            InputProps={{
              style: { fontSize: isMobile ? '10px' : '12px' },
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? '10px' : '12px' },
            }}
          />
          {!isMobile && <Box sx={styles.gridEmpty} />}
          <Button
            sx={{
              ...styles.button,
              fontSize: isMobile ? '12px' : '14px',
              padding: isMobile ? '8px 16px' : '12px 12px',
              maxHeight: '38px',
            }}
            onClick={handlePostulatePerson}
          >
            Postular &nbsp;"Legend"
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  addLegend: {
    fontFamily: 'Poppins, sans-serif',
    color: '#FFF',
    backgroundColor: '#2C3E50',
    borderRadius: '8px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  closeButton: {
    color: '#FFF',
  },
  contentContainer: {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#34495E',
    position: 'relative',
  },
  sectionTitle: {
    fontWeight: 'bold',
    color: '#FFF',
  },
  description: {
    fontSize: '16px',
    color: '#BDC3C7',
    marginBottom: '10px',
  },
  highlight: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#F67147',
    marginBottom: '10px',
  },
  details: {
    fontSize: '16px',
    color: '#BDC3C7',
    marginBottom: '10px',
  },
  note: {
    fontSize: '14px',
    color: '#95A5A6',
    marginBottom: '30px',
  },
  gridContainer: {
    display: 'grid',
    gap: '10px',
    alignItems: 'center',
    marginBottom: '20px',
  },
  gridLabel: {
    fontWeight: 'bold',
    color: '#FFF',
    fontSize: '13px',
  },
  gridTextField: {
    backgroundColor: '#FFF',
    borderRadius: '4px',
    width: '100%',
  },
  gridEmpty: {
    width: '100%',
  },
  button: {
    backgroundColor: '#F67147',
    color: '#FFF',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#D4AF37',
    },
  },
};

export default AddLegend;
