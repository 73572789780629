import React from 'react';
import { Box, List, ListItem, ListItemText, Divider, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltRounded from '@mui/icons-material/TaskAltRounded';

const antecedentTypes = {
  1: 'Título profesional',
  2: 'Pregrado',
  3: 'Doctorado',
  4: 'Magíster',
  5: 'Curso',
  6: 'Diplomado',
  7: 'Beca',
  8: 'Docencia pasada',
  9: 'Docencia actual',
  10: 'Publicación',
  11: 'Habilitación internacional',
  12: 'Idioma',
  13: 'Puesto anterior',
  14: 'Puesto actual',
  15: 'Experiencia relevante',
  16: 'Ranking internacional',
  17: 'Otros antecedentes'
};

function AntecedentsList({ antecedents, setAntecedents }) {



  const handleDelete = (index) => {
    const newAntecedents = antecedents.filter((_, i) => i !== index);
    setAntecedents(newAntecedents);
  };

  const renderSecondaryText = (antecedent) => {
    switch (antecedent.id) {
      case 8: // Docencia pasada
      case 9: // Docencia actual
        return `${antecedent.professorTypeName} - ${antecedent.universityName}`;

      case 2: // Pregrado
      case 3: // Doctorado
      case 4: // Magíster
      case 5: // Curso
      case 6: // Diplomado
        return `${antecedent.universityName} (${antecedent.endDate})`;

      case 12: // Idioma
        return `${antecedent.idiomName} - Certificación ${antecedent.certificationName}`;

      case 11: // Habilitación internacional
        return `Habilitado en ${antecedent.jurisdictionName}`;

      case 17: // Otros antecedentes
        return `${antecedent.positionName} en ${antecedent.whereWork}`;

      case 13: // Puesto anterior
      case 14: // Puesto actual
        if (antecedent.institutionType === 'Independient') {
          return `Independiente (${antecedent.startDate} / ${antecedent.endDate})`;
        }
        return `${antecedent.positionName} - ${antecedent.institutionName}`;

      case 10: // Publicación
        return `${antecedent.publicationTypeName} - (${antecedent.publicationDate})`;

      case 16: // Ranking internacional
        return antecedent?.rankingName ? `${antecedent.rankingName} - (${antecedent.date})` : null;

      case 15: // Experiencia relevante
        return `Asesor de ${antecedent.clientName}`;

      case 7: // Beca
        return `${antecedent.scholarshipName}`;

      case 1: // Título profesional
        return `(${antecedent.endDate})`;

      default:
        return 'Información adicional no disponible';
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Antecedentes Agregados
      </Typography>
      <List sx={{ width: '100%' }}>
        {antecedents.map((antecedent, index) => (
          <React.Fragment key={index}>
            <ListItem
              secondaryAction={
                !antecedent.verified && (
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                )
              }
            >
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {antecedent.verified && (
                      <TaskAltRounded color="success" sx={{ mr: 1, fontSize: '18px' }} />
                    )}
                    <Typography component="span">
                      {antecedentTypes[antecedent.id] || 'Antecedente'}
                    </Typography>
                  </Box>
                }
                secondary={
                  <Typography sx={{ pl: antecedent.verified ? 3 : 0 }}>
                    {renderSecondaryText(antecedent)}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}

export default AntecedentsList;
