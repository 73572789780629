// publicAxios
import axios from "axios";

const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "x-Request-Source": "public",
  },
});

export default publicAxios;
