import React, { useState } from 'react';
import { Box, TextField, Button, Typography, IconButton, InputAdornment, useMediaQuery } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Logo from '../utils/LogoBlack.png';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import { useAuth } from '../hooks/useAuth';
import { publicAxios } from '../apiClients';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { resetPassword, confirmResetPassword } = useAuth();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [codeError, setCodeError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleSendCode = async () => {
    if (!validateEmail(email)) {
      showSnackbar('Por favor ingresa un correo válido.', 'error');
      return;
    }
    const checkEmail = await publicAxios.get(`/back-office/check-email?email=${email}`);
    if (!checkEmail.data.success) {
      showSnackbar('El correo ingresado no está registrado.', 'error');
      return;
    }
    try {
      await resetPassword(email);
      setStep(2);
      showSnackbar('Se ha enviado un código de verificación a tu correo.', 'success');
    } catch (error) {
      console.error('Error al enviar el código de verificación:', error);
      showSnackbar('No se pudo enviar el código de verificación.', 'error');
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordError(true);
      showSnackbar('Las contraseñas no coinciden.', 'error');
      return;
    }
    try {
      await confirmResetPassword(email, code, newPassword);
      showSnackbar('Tu contraseña ha sido restablecida correctamente.', 'success');
      navigate('/signin');
    } catch (error) {
      console.error('Error al restablecer la contraseña:', error);
      setCodeError(true);
      showSnackbar('El código de verificación es incorrecto.', 'error');
    }
  };

  const customTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-input': {
      color: 'black',
    },
    '& input::placeholder': {
      color: 'black',
      opacity: 1,
    },
    '& .MuiInputAdornment-root': {
      marginRight: 0,
    },
    '& .MuiIconButton-root': {
      padding: 2,
      color: 'black',
    },
    '& input::placeholder': {
    color: 'black', // Cambia este color según tus necesidades
    opacity: 0.75, // Asegura que el color se vea claramente
    fontSize: '16px', // Ajusta el tamaño de la fuente del placeholder
    }
  };

  return (
    <Box sx={{
      minHeight: '80vh',
      minWidth: "100%",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
      padding: 3
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ height: 120, width: 120, mb: 4 }} />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '400px',
        '& > :not(style)': { m: 1, width: '100%' },
      }}>
        <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mb: 2 }}>
          Recuperar Contraseña
        </Typography>
        <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', mb: 4 }}>
          {step === 1 ? 'Ingresa tu correo para recibir el código de verificación.' : 'Ingresa el código de verificación y tu nueva contraseña.'}
        </Typography>
        {step === 1 ? (
          <>
            <TextField
              sx={customTextFieldStyles}
              placeholder="Correo electrónico"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="secondary"
              fullWidth
              sx={{ mt: 2, bgcolor: 'black', color: 'white' }}
              onClick={handleSendCode}
            >
              Enviar Código
            </Button>
          </>
        ) : (
          <>
            <TextField
              sx={customTextFieldStyles}
              placeholder="Código de Verificación"
              variant="outlined"
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value)}
              error={codeError}
              onFocus={() => setCodeError(false)}
            />
            <TextField
              sx={customTextFieldStyles}
              placeholder="Nueva Contraseña"
              variant="outlined"
              fullWidth
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              sx={customTextFieldStyles}
              placeholder="Confirmar Contraseña"
              variant="outlined"
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={passwordError}
              onFocus={() => setPasswordError(false)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="primary"
              fullWidth
              sx={{ mt: 2, bgcolor: 'black', color: 'white' }}
              onClick={handleResetPassword}
            >
              Restablecer Contraseña
            </Button>
          </>
        )}
        <Button
          sx={{ mt: 2, color: 'black' }}
          onClick={() => navigate('/signin')}
        >
          Volver al Inicio de Sesión
        </Button>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
