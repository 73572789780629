import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import SelectOption from '../../SelectOption';
import { publicAxios } from '../../../apiClients';

function InternationalAccreditationForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [formData, setFormData] = useState({
        jurisdictionID: '',
        jurisdictionName: '',
    });
    const [jurisdictions, setJurisdictions] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchJurisdictions = async () => {
            try {
                const responseJurisdictions = await publicAxios.get(`/static-data/jurisdictions`);
                setJurisdictions(responseJurisdictions.data);

            } catch (error) {
                setError(true);
            }
        };
        fetchJurisdictions();
    }, []);

    const handleSubmit = () => {
        if (!formData.jurisdictionID || (formData.jurisdictionID === 1 && !formData.jurisdictionName)) {
            setError(true);
            return;
        }
        onAddAntecedent({
            id: antecedentType.id,
            antecedentType: antecedentType.name,
            jurisdictionID: formData.jurisdictionID,
            jurisdictionName: jurisdictions.find(jurisdiction => jurisdiction.id === formData.jurisdictionID).name,
        });
    };

    return (
        <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Agregar {antecedentType.name}
            </Typography>
            <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                <Grid item xs={12} sm={4}>
                    <Typography>Jurisdicción</Typography>
                    <SelectOption
                        options={jurisdictions}
                        onSelectionChange={(value) => setFormData({ ...formData, jurisdictionID: value })}
                        name={'Selecciona una jurisdicción'}
                        width="100%"
                        selectedOption={formData.jurisdictionID}
                        error={error && !formData.jurisdictionID}
                        helperText={error && !formData.jurisdictionID ? 'Este campo es obligatorio' : ''}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                <Button variant="primary" onClick={handleSubmit}>
                    Agregar Antecedente
                </Button>
            </Grid>
        </Box>
    );
}

export default InternationalAccreditationForm;
