import React from 'react';
import { Box, Typography, Divider, Button, useMediaQuery } from '@mui/material';
import { ReactComponent as TitleIcon } from '../assets/docs/title.svg';
import { ReactComponent as ExperienceIcon } from '../assets/docs/experience.svg';
import { ReactComponent as WorkIcon } from '../assets/docs/work.svg';
import { ReactComponent as UniversityIcon } from '../assets/docs/university.svg';
import { ReactComponent as CasesIcon } from '../assets/docs/cases.svg';
import { checkUserBackground } from '../utils/functions';
import { useNavigate } from 'react-router-dom';

const initialItems = [
    { label: 'Universidad', icon: TitleIcon, loaded: false },
    { label: 'Educación', icon: UniversityIcon, loaded: false },
    { label: 'Trabajo', icon: WorkIcon, loaded: false },
    { label: 'Casos', icon: CasesIcon, loaded: false },
    { label: 'Experiencias', icon: ExperienceIcon, loaded: false },
    { label: 'Otros', icon: ExperienceIcon, loaded: false },
];

const Docs = ({ user, onClick }) => {
    const navigate = useNavigate();
    const userBackground = checkUserBackground(user.data);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const updateItemsLoaded = (userBackground) => {
        return initialItems.map(item => {
            switch (item.label) {
                case 'Universidad':
                    return { ...item, loaded: userBackground.title };
                case 'Educación':
                    return {
                        ...item,
                        loaded: userBackground.magister || userBackground.undergraduate || userBackground.diplomat || userBackground.title || userBackground.course
                    };
                case 'Trabajo':
                    return { ...item, loaded: userBackground.currentProfessional };
                case 'Casos':
                    return { ...item, loaded: userBackground.relevantExperiences };
                case 'Experiencias':
                    return { ...item, loaded: userBackground.pastProfessional };
                case 'Otros':
                    return {
                        ...item,
                        loaded: userBackground.publications || userBackground.otherBackground || userBackground.academicExperiences || userBackground.rankingNominations || userBackground.idiomExperiences || userBackground.academicScholarships || userBackground.internationalAccreditations
                    };
                default:
                    return item;
            }
        });
    }

    const handleGoToSimulator = () => {
        // agregar al local storage el path anterior
        localStorage.setItem('simulatorPath', window.location.pathname);
        navigate('/simulator');
    };

    const updatedItems = updateItemsLoaded(userBackground);

    return (
        <Box display='flex' flexDirection={"column"} alignItems={"center"} justifyContent={"center"} justifyItems={"center"} alignContent={"center"} sx={{ my: 10 }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'center', width: '100%', mb: 10, px: 6 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: { xs: '100%', md: '50%' }, mb: { xs: 6, md: 0 }, px: 3 }}>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Mejora tu calificación</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
                            La calificación es el resultado de una evaluación objetiva que Soubid realiza de todos los antecedentes académicos y profesionales verificados por los usuarios.
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            Si quieres mejorar tu grado, sube tantos antecedentes como puedas. Ellos serán verificados por la comunidad, y luego quedarán disponibles para ser considerados.
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: { xs: '100%', md: '50%' }, px: 3 }}>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Simula tu Calificación</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
                            Evalúa en abstracto distintas opciones profesionales y académicas, para tomar mejores decisiones (por ejemplo, buscando trabajo o eligiendo Universidad). Encuentra el mejor camino para tu futuro.
                        </Typography>
                        <Box textAlign="center" my={2}>
                            <Button variant="outlined"
                                sx={{ color: '#F67147', borderColor: '#F67147' }}
                                onClick={handleGoToSimulator}
                            >
                                Simulador
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
                sx={{ position: 'relative', width: isMobile ? '100%' : '60%' }}
            >
                <Divider
                    orientation={isMobile ? 'vertical' : 'horizontal'}
                    sx={{
                        position: 'absolute',
                        top: isMobile ? '0' : '40%',
                        left: isMobile ? '50%' : '0',
                        width: isMobile ? '0' : '100%',
                        height: isMobile ? '90%' : '0',
                        borderBottom: isMobile ? 'none' : '1px dashed grey',
                        borderRight: isMobile ? '1px dashed grey' : 'none',
                        zIndex: 0
                    }}
                />
                {updatedItems.map((item, index) => (
                    <Box key={index} textAlign="center" sx={{ position: 'relative', zIndex: 1, mb: isMobile ? 3 : 0 }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 80,
                            height: 80,
                            borderRadius: '50%',
                            backgroundColor: item.loaded ? '#F67147' : '#E0E0E0',
                        }}>
                            <item.icon style={{ fontSize: 40, color: item.loaded ? '#F67147' : 'grey', width: "70%", height: "70%" }} />
                        </Box>
                        <Typography variant="body1">{item.label}</Typography>
                    </Box>
                ))}
            </Box>
            <Button onClick={onClick} variant="primary" color="primary" sx={{ width: isMobile ? '50%' : "10%", alignSelf: 'center', my: 6 }}>
                Cargar más
            </Button>
        </Box>
    );
};

export default Docs;
