import React from 'react';
import TitleForm from './antecedentsForms/TitleForm';
import EducationForm from './antecedentsForms/EducationForm';
import ProfessionalExperienceForm from './antecedentsForms/ProfessionalExperienceForm';
import RankingForm from './antecedentsForms/RankingForm';
import IdiomForm from './antecedentsForms/IdiomForm';
import IntAccreditationForm from './antecedentsForms/IntAccreditationForm';
import OtherBackgroundForm from './antecedentsForms/OtherBackgroundForm';
import ScholarshipForm from './antecedentsForms/ScholarshipForm';
import PublicationForm from './antecedentsForms/PublicationForm';
import AcademicForm from './antecedentsForms/AcademicForm';
import RelevantForm from './antecedentsForms/RelevantForm';

function AntecedentForm({ antecedentType, onAddAntecedent, isMobile }) {
  const renderForm = () => {
    switch (antecedentType.id) {
      case 1:
        return <TitleForm onAddAntecedent={onAddAntecedent} />;
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return <EducationForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
      case 7:
        return <ScholarshipForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
      case 8:
      case 9:
        return <AcademicForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
      case 10:
        return <PublicationForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
      case 11:
        return <IntAccreditationForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
      case 12:
        return <IdiomForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
      case 13:
      case 14:
        return <ProfessionalExperienceForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
      case 15:
        return <RelevantForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
      case 16:
        return <RankingForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
      case 17:
        return <OtherBackgroundForm antecedentType={antecedentType} onAddAntecedent={onAddAntecedent} isMobile={isMobile} />;
            
      default:
        return null; // No renderiza nada si el ID no corresponde a los manejados
    }
  };

  return (
    <>
      {renderForm()}
    </>
  );
}

export default AntecedentForm;
