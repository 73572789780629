import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
  },
});

function SelectOption({ options, onSelectionChange, name, width, error, selectedOption }) {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={{ width: width, marginY: 1, backgroundColor: 'white', borderRadius: 1 }} // Reduciendo el margen vertical
      onChange={(event, value) => {
        onSelectionChange(value ? value.id : null);
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
            <li {...props} key={option.id}>
            {option.name}
            </li>
        );
      }}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          placeholder={name}
          error={error && !selectedOption}
          helperText={error && !selectedOption ? 'Este campo es obligatorio' : ''}
          sx={{
            "& .MuiInputBase-root": {
              height: "55px", // Ajusta la altura
              alignItems: "center",
              padding: '2px 8px', // Reduciendo el padding
            },
            "& .MuiOutlinedInput-notchedOutline": {
              top: 0,
            },
            "& .MuiInputBase-input": {
              padding: "8px 8px", // Reduciendo el padding
            },
            '& input::placeholder': {
              color: 'black', // Cambia este color según tus necesidades
              opacity: 0.75, // Asegura que el color se vea claramente
              fontSize: '16px', // Ajusta el tamaño de la fuente del placeholder
            },
            "& .MuiInputLabel-outlined": {
              transform: "translate(14px, 14px) scale(1)", // Ajusta según sea necesario
              "&.MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
            },
            // Elimina el padding superior para el texto seleccionado
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
              paddingTop: '0px',
              paddingBottom: '0px',
            },
          }}
        />
      )}
    />
  );
}

export default SelectOption;
