import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Grid, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from '@emotion/styled';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';

const Container = styled(Box)({
  padding: '20px',
  backgroundColor: '#EEF1F4',
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  borderRadius: '8px',
});

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '6px',
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    borderRadius: '6px',
  },
  '& .MuiInputAdornment-root': {
    backgroundColor: 'white',
  },
  '& .MuiIconButton-root': {
    padding: 15,
    color: 'black',
  },
  '& .MuiFormHelperText-root': {
    color: 'black',
  },
  '& input::placeholder': {
    color: 'black',
    opacity: 0.75,
    fontSize: '16px',
  },
});

const ChangePassword = ({ onClose }) => {
  const { updatePassword } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const validatePassword = (password) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;

    if (!hasNumber.test(password)) {
      showSnackbar('La contraseña debe contener al menos un número.', 'error', 'Error');
      return false;
    }
    if (!hasSpecialChar.test(password)) {
      showSnackbar('La contraseña debe contener al menos un carácter especial.', 'error', 'Error');
      return false;
    }
    if (!hasUpperCase.test(password)) {
      showSnackbar('La contraseña debe contener al menos una letra mayúscula.', 'error', 'Error');
      return false;
    }
    if (!hasLowerCase.test(password)) {
      showSnackbar('La contraseña debe contener al menos una letra minúscula.', 'error', 'Error');
      return false;
    }
    return true;
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword || !newPassword || !confirmNewPassword ) {
      showSnackbar('Las contraseñas no coinciden', 'error');
      return;
    }
    if (!validatePassword(newPassword)) {
      return;
    }

    try {
      await updatePassword(currentPassword, newPassword);
      showSnackbar('Tu contraseña ha sido cambiada exitosamente', 'success');
      onClose();
    } catch (error) {
      console.error('Error changing password:', error);
      if (error.message.includes('Incorrect username or password')) {
        showSnackbar('La contraseña actual es incorrecta', 'error');
      } else {
        showSnackbar('Hubo un problema al cambiar tu contraseña', 'error');
      }
    }
  };

  return (
    <Container>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <IconButton onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '0 auto' }}>Cambiar Contraseña</Typography>
          <Box sx={{ width: '48px' }} /> {/* Espacio vacío para mantener el diseño */}
        </Grid>

        <Grid item xs={12} sx={{ width: '100%' }}>
          <CustomTextField
            label="Contraseña Actual"
            type={showCurrentPassword ? 'text' : 'password'}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CustomTextField
            label="Nueva Contraseña"
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CustomTextField
            label="Confirmar Nueva Contraseña"
            type={showConfirmNewPassword ? 'text' : 'password'}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                    edge="end"
                  >
                    {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ width: '100%' }}>
          <Button
            variant="primary"
            onClick={handleChangePassword}
            // fullWidth
            sx={{ mt: 2 }}
          >
            Cambiar Contraseña
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChangePassword;
