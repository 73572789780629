import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';

const images = [
  { src: require('../../assets/AppCaptures/CaptureSignIn.png'), title: 'Inicio de Sesión', description: 'Súmate y ayúdanos a cambiar la forma en que los profesionales son evaluados', type: 'image' },
  { src: require('../../assets/AppCaptures/CaptureProfile.png'), title: 'Perfil', description: 'Mantén actualizada y verificada toda tu información, sin mentiras ni exageraciones. En Soubid todo es lo que dice ser.', type: 'image' },
  { src: require('../../assets/AppCaptures/qualification.gif'), title: 'Calificación', description: 'Construye tu marca personal de una forma eficiente y transparente, en la que sólo cuenta el mérito.', type: 'gif' },
  { src: require('../../assets/AppCaptures/cross-verification.gif'), title: 'Verificación Cruzada', description: 'Ayúdanos a construir un ambiente real y confiable, con nuestro modelo de verificación descentralizada.', type: 'gif' },
  { src: require('../../assets/AppCaptures/CaptureDirectory.png'), title: 'Directorio', description: 'Explora y encuentra a otros profesionales de tu país y del mundo.', type: 'image' },
  { src: require('../../assets/AppCaptures/CaptureHome.png'), title: 'Comunidad', description: 'Aporta y gana. Todas tus acciones que vayan en beneficio de la comunidad serán recompensadas.', type: 'image' },
];

const Carousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const getDuration = (index) => {
    if (index === 2) return 9000; // Cualificación video
    if (index === 3) return 7000; // Verificación cruzada video
    return 4500; // Todas las demás imágenes
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, getDuration(currentImageIndex));

    return () => clearInterval(interval);
  }, [currentImageIndex]);

  const handleIndicatorClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 5, mb: 10 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          maxWidth: '1200px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            order: { xs: 0, sm: 1 },
          }}
        >
          <Typography variant="h3" sx={{ mb: 2, textAlign: 'center', minHeight: '60px', fontWeight: 'bold' }}>
            {images[currentImageIndex].title}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', minHeight: '80px' }}>
            {images[currentImageIndex].description}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: "100%",
            overflow: 'hidden',
            padding: '10px',
            order: { xs: 0, sm: 1 },
          }}
        >
          <Box
            sx={{
              width: '300px',
              height: '600px',
              border: '2px solid #000',
              borderRadius: '30px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'black',
            }}
          >
            {images[currentImageIndex].type === 'image' ? (
              <Box
                component="img"
                src={images[currentImageIndex].src}
                alt={`Slide ${currentImageIndex}`}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  backgroundColor: currentImageIndex === 0 ? '#F67147' : 'black',
                }}
              />
            ) : (
              <Box
                component="img"
                src={images[currentImageIndex].src}
                alt={`Slide ${currentImageIndex}`}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}>
        {images.map((_, index) => (
          <IconButton
            key={index}
            onClick={() => handleIndicatorClick(index)}
            sx={{
              padding: '8px',
              margin: '0 12px',
              color: index === currentImageIndex ? '#F67147' : '#D8D8D8',
            }}
          >
            <Box
              sx={{
                height: 20,
                width: 20,
                borderRadius: '50%',
                backgroundColor: index === currentImageIndex ? '#F67147' : '#D8D8D8',
              }}
            />
          </IconButton>
        ))}
      </Box>
    </Box>
  );
};

export default Carousel;
