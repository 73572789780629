import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import logo from '../assets/logo/LogoBlack.png';

const CustomModal = ({ visible, onPrimaryAction, onSecondaryAction, primaryText, secondaryText, children }) => {
    return (
        <Modal
            open={visible}
            onClose={onSecondaryAction}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                }}
            >
                <Box
                    sx={{
                        width: '75%',
                        maxWidth: 350,
                        minHeight: 300,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        p: 3,
                        border: '1px solid black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <img src={logo} alt="logo" style={{ width: 50, height: 50, marginBottom: 20 }} />
                    <Typography id="modal-description" sx={{ fontSize: 16, color: 'black', textAlign: 'center', marginBottom: 2 }}>
                        {children}
                    </Typography>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {primaryText && (
                            <Button
                                variant="primary"
                                onClick={onPrimaryAction}
                                sx={{
                                    bgcolor: '#F67147',
                                    padding: '10px 0',
                                    borderRadius: 1,
                                    marginBottom: 1,
                                    width: '60%',
                                    '&:hover': {
                                        bgcolor: '#e05e35',
                                    },
                                }}
                            >
                                <Typography sx={{ color: 'white', fontWeight: 'bold' }}>{primaryText}</Typography>
                            </Button>
                        )}
                        {secondaryText && (
                            <Button
                                variant="secondary"
                                onClick={onSecondaryAction}
                                sx={{
                                    bgcolor: '#666',
                                    padding: '10px 0',
                                    borderRadius: 1,
                                    width: '60%',
                                    '&:hover': {
                                        bgcolor: '#555',
                                    },
                                }}
                            >
                                <Typography sx={{ color: 'white', fontWeight: 'bold' }}>{secondaryText}</Typography>
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default CustomModal;
