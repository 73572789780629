import React from 'react';
import { Box, Container, Paper, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)({
  padding: '20px',
  borderRadius: '10px',
  borderColor: '#FFFFFF',
  borderWidth: '2px',
  borderStyle: 'solid',
  maxHeight: '80vh',
  overflowY: 'auto',
  boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
  backgroundColor: '#fff'
});

const StyledDivider = styled(Divider)({
  backgroundColor: '#F67147',
  height: '3px',
  margin: '20px 0'
});

const TermsAndConditions = () => {
  return (
    <Container sx={{ mt: 5, mb: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Typography variant="h3" sx={{ color: '#000', fontWeight: 'bold' }}>
          Términos y Condiciones
        </Typography>
      </Box>
      <StyledPaper>
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          1. Términos y Condiciones
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          Estos Términos y Condiciones regulan el uso de la plataforma SOUBID. Cuando nos referimos a la plataforma, nos referimos a www.soubid.com o a la aplicación móvil de SOUBID, indistintamente (la "Plataforma").
          La utilización de la plataforma y de sus distintos recursos, implica la aceptación de estos Términos y Condiciones. Por lo tanto, antes de realizar cualquier acción en o a través de la Plataforma debes leer cuidadosamente todos los Términos y Condiciones, y si no estuvieras de acuerdo, te recomendamos abstenerte de seguir usándola.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          2. Descripción de la Plataforma
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          La Plataforma es un medio a través del cual se ofrecen los servicios de verificación y calificación de antecedentes académicos y profesionales de los Usuarios. Por lo tanto, es parte de la esencia de Soubid la recolección, exhibición y utilización de datos personales para la consecución de sus objetivos. Por lo tanto, por el hecho de cargar un antecedente en la Plataforma, el Usuario consiente en que Soubid lo utilice para los fines especificados. 
          De todas formas, el cuidado de los datos personales es de nuestra máxima prioridad, y estamos constantemente adoptando medidas de seguridad para evitar ataques o el uso inapropiado por parte de terceros.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          3. El modelo SOUBID
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          SOUBID funciona en base a un modelo científico de calificaciones. Dicho modelo es de propiedad de SOUBID, y es secreto y confidencial, sin que los usuarios tengan derecho a acceder al mismo. Si algún usuario está en desacuerdo con la confidencialidad del modelo, se le recomienda no utilizar la plataforma.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          4. Cuentas de Usuarios registrados
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          Toda acción en SOUBID requiere estar registrado en la Plataforma y contar con una Cuenta válida. El Usuario acepta que SOUBID pueda verse impedido de ofrecer los servicios de la Plataforma en todo momento.
          Al registrarte como Usuario Registrado en la Plataforma te comprometes a entregarnos información veraz, correcta y actualizada; y nos facultas a removerla en caso de que se demuestre que ella es falsa, incorrecta o imprecisa.
          Al registrarte como Usuario Registrado y verificar la Cuenta, deberás escoger una clave secreta de acceso a la misma, contraseña o password, siendo tú el único encargado de guardar la confidencialidad de la misma, y por lo tanto de la información de tu Cuenta.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          5. Cierre y cuentas no verificadas
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          Los Usuarios Registrados pueden terminar este acuerdo con SOUBID, en cualquier momento.
          Como contrapartida, el Usuario acepta y acuerda que SOUBID podrá, previo aviso, limitar, suspender o terminar el servicio y las Cuentas, prohibir el acceso a la Plataforma, su contenido, servicios y herramientas, restringir o remover el contenido almacenado, y tomar acciones técnicas y legales para mantener a los Usuarios Registrados fuera de la Plataforma si estima que estos están infringiendo los Términos y Condiciones.
          A mayor abundamiento, SOUBID podría, a su propia discreción, limitar el acceso, suspender o eliminar las Cuentas de Usuarios Registrados, por alguna de las siguientes razones, las cuales se indican sólo a modo de ejemplo. Si un Usuario:
          Intenta acceder sin autorización a la Plataforma o a la Cuenta de otro Usuario Registrado, o proveyendo ayuda asistencia a otros para que terceros lo hagan;
          Interfiere aspectos de la seguridad de la Plataforma que eliminen, limiten o protejan algún tipo de contenido;
          Incurre a criterio de SOUBID en conductas o actos dolosos o fraudulentos;
          Usa la Plataforma para promover actividades ilegales tales como el lavado de activos o dinero, financiamiento del terrorismo, u otras actividades criminales;
          Incumple o contraviene los Términos y Condiciones;
          Paga indebidamente las transacciones realizadas a través de la Plataforma;
          Causa dificultades operacionales en la Plataforma;
          Si no pudiera verificarse la identidad del Usuario Registrado o cualquier información proporcionada por el mismo fuere falsa o errónea; o
          Ante el requerimiento de alguna agencia gubernamental o tribunal de justicia, si la misma se ajusta a las respectivas normas legales y/o procedimentales que sean aplicables.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          6. Disponibilidad de la Plataforma
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          Aceptas y acuerdas que SOUBID no se responsabiliza por daños, perjuicios o pérdidas al Usuario Registrado causados por fallas, interrupciones o errores en el sistema, en el servidor, en Internet o en la Plataforma, salvo en el caso de incumplimientos legales o regulatorios de SOUBID en este sentido. SOUBID no garantiza el acceso y uso continuado o ininterrumpido de la Plataforma. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a SOUBID; en tales casos se procurará restablecerlo con la mayor celeridad posible, y se aplicarán medidas de contingencia en base a lo establecido en la normativa vigente.
          SOUBID realizará los esfuerzos razonables para mantener la Plataforma libre de virus, malware u otro similares, sin embargo, reconoces y declaras que el acceso a la Plataforma lo efectúas por tu cuenta y riesgo.
          Además, aceptas y te haces responsable de los daños y perjuicios provenientes del uso que realices de la Plataforma para fines contrarios a las leyes, las buenas costumbres, el orden público y la legislación vigente, liberando de cualquier responsabilidad a SOUBID.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          7. Derecho de uso limitado
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          A no ser que se exprese lo contrario, todo el material en esta Plataforma es de propiedad de SOUBID y está protegido por derechos de autor, copyright, derechos de marca y otras leyes internacionales que son aplicables. Sin embargo, el usuario puede revisar, compartir, imprimir y/o descargar la información de su Perfil. Lo dispuesto en esta cláusula no se aplica a las fotografías personales de los individuos, las cuáles son de su propiedad, o de quiénes las hayan capturado, según sea el caso.
          La marca y logo SOUBID usados en la Plataforma ("Marca Comercial") son propiedad de SOUBID y sus respectivos dueños. El software, textos, gráficos, información, precios, intercambios, videos y audios utilizados en la Plataforma (el “Material”) son asimismo propiedad de SOUBID, a menos que se indique expresamente lo contrario.
          La Marca Comercial y el Material no deben ser copiados, reproducidos, modificados, republicados, cargados, posteados, transmitidos, descompuestos (scrapped), coleccionados o distribuidos de manera comercial, sea automatizada o manualmente. El uso de cualquier material en otra plataforma o red de computadores, para cualquier fin ajeno al propio del Sitio de SOUBID, queda estrictamente prohibido; cualquier uso sin autorización significará una violación de los derechos de autor, copyright, derechos de marca y otras leyes internacionales que son aplicables, pudiendo resultar en penas civiles o criminales.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          8. Modificaciones de los términos y condiciones
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          SOUBID se reserva el derecho a modificar estos Términos y Condiciones en cualquier momento. Los Usuarios serán debidamente notificados de cualquier cambio a través de su Cuenta. Al ser notificados, es responsabilidad de los Usuarios revisar los cambios en los Términos y Condiciones.
          Si notificados de los cambios en los Términos y Condiciones, los Usuarios continúan usando y accediendo a la Plataforma significará que aceptan dichos cambios.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          9. Divisibilidad
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          Si cualquier término o disposición contenido en los presentes Términos y Condiciones o su aplicación a una persona o circunstancia fuere declarada inválida o inexigible, el resto del mismo, o la aplicación del mismo a cualquier persona o circunstancia, fuera de aquellas respecto de los cuales es tenido como inválido, no se verá afectado con ello y será válido y exigible en la medida más amplia permitida por la Ley, y SOUBID y Usted convendrán en sustituir ese término o disposición con otros términos y disposiciones que, en la medida permitida por la ley, permita a las partes obtener el beneficio del término o disposición así tenido como inválido o inexigible.
        </Typography>
        <StyledDivider />
        <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          10. Contacto
        </Typography>
        <Typography paragraph sx={{ fontSize: '1.2rem' }}>
          Si tienes alguna duda, reclamo, comentario o sugerencia respecto a estos Términos y Condiciones, tus derechos y obligaciones de Usuario desprendidos de éstos y/o el uso de la Plataforma y sus servicios o tu Cuenta de Usuario, te solicitamos contactarnos a support@soubid.com.
        </Typography>
      </StyledPaper>
    </Container>
  );
};

export default TermsAndConditions;
