import React from 'react';
import { Box, Typography, IconButton, Button, Paper, useMediaQuery } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTheme } from '@mui/material/styles';

const qualifications = [
  ['AAA+', 'AAA', 'AAA-', 'AA+', 'AA', 'AA-', 'A+', 'A', 'A-'],
  ['BBB+', 'BBB', 'BBB-', 'BB+', 'BB', 'BB-', 'B+', 'B', 'B-'],
  ['CCC+', 'CCC', 'CCC-', 'CC+', 'CC', 'CC-', 'C+', 'C', 'C-'],
  ['DDD+', 'DDD', 'DDD-', 'DD+', 'DD', 'DD-', 'D+', 'D', 'D-'],
  ['EEE+', 'EEE', 'EEE-', 'EE+', 'EE', 'EE-', 'E+', 'E', 'E-']
];

const qualificationMapping = {
  'AAA+': 45, 'AAA': 44, 'AAA-': 43, 'AA+': 42, 'AA': 41, 'AA-': 40, 
  'A+': 39, 'A': 38, 'A-': 37, 
  'BBB+': 36, 'BBB': 35, 'BBB-': 34, 'BB+': 33, 'BB': 32, 'BB-': 31, 
  'B+': 30, 'B': 29, 'B-': 28, 
  'CCC+': 27, 'CCC': 26, 'CCC-': 25, 'CC+': 24, 'CC': 23, 'CC-': 22, 
  'C+': 21, 'C': 20, 'C-': 19, 
  'DDD+': 18, 'DDD': 17, 'DDD-': 16, 'DD+': 15, 'DD': 14, 'DD-': 13, 
  'D+': 12, 'D': 11, 'D-': 10, 
  'EEE+': 9, 'EEE': 8, 'EEE-': 7, 'EE+': 6, 'EE': 5, 'EE-': 4, 
  'E+': 3, 'E': 2, 'E-': 1
};

const QualificationFilterComponent = ({ selectedQualifications, onSelectQualification, setIsQualificationVisible, buttonPosition }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectQualification = (qualification) => {
    const mappedQualification = qualificationMapping[qualification];
    if (selectedQualifications.includes(mappedQualification)) {
      onSelectQualification(selectedQualifications.filter(q => q !== mappedQualification));
    } else {
      onSelectQualification([...selectedQualifications, mappedQualification]);
    }
  };

  return (
    <Paper sx={{
      position: 'absolute',
      top: buttonPosition.top + 15, // Ajusta la posición hacia abajo
      left: buttonPosition.left, // Ajuste manual para alinearlo mejor
      transform: isMobile ? 'translateX(-5%)' : 'none', // Ajuste de transformación en pantallas pequeñas
      width: isMobile ? '80%' : 'auto', // Ajusta el ancho para pantallas pequeñas
      minWidth: isMobile ? 'auto' : 300, // Ancho mínimo en pantallas grandes
      backgroundColor: '#333333',
      p: 2,
      borderRadius: 2,
      zIndex: 10
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
          Calificación
        </Typography>
        <IconButton onClick={() => setIsQualificationVisible(false)}>
          <CloseRoundedIcon sx={{ color: '#FFFFFF' }} />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
        {qualifications.map((group, index) => (
          <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
            {group.map((qualification) => (
              <Button
                key={qualification}
                variant={selectedQualifications.includes(qualificationMapping[qualification]) ? "contained" : "outlined"}
                onClick={() => handleSelectQualification(qualification)}
                sx={{
                  m: 0.5, // Reduce el margen entre los botones
                  width: isMobile ? 25 : 45, // Ajusta el ancho del botón
                  height: isMobile ? 25 : 45, // Ajusta la altura del botón
                  minWidth: 0,
                  fontSize: isMobile ? 7 :10, // Reduce el tamaño de la fuente
                  padding: 0,
                  borderRadius: '50%',
                  color: '#FFFFFF',
                  fontFamily: 'Poppins',
                  backgroundColor: selectedQualifications.includes(qualificationMapping[qualification]) ? '#F67147' : 'transparent',
                  borderColor: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: selectedQualifications.includes(qualificationMapping[qualification]) ? '#F67147' : 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                {qualification}
              </Button>
            ))}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default QualificationFilterComponent;
