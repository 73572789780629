import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider, ListItemSecondaryAction, Modal, Button, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ProfileInformation from '../components/settings/ProfileInformation';
import AccountVisualization from '../components/settings/AccountVisualization';
import ChangePassword from '../components/settings/ChangePassword';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import WhoCanContactYou from '../components/settings/WhoCanContactYou';
import LogoBlack from '../assets/logo/LogoBlack.png';
import { useLocation } from 'react-router-dom';
import AdminOptionsForm from '../components/settings/AdminOptionsForm';

const Section = styled(Box)({
  marginBottom: '20px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  padding: '20px',
  backgroundColor: '#EEF1F4',
});

const SectionHeader = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '10px',
  color: '#333',
});

const StyledListItem = styled(ListItem)({
  borderBottom: '1px solid #ddd',
  '&:last-child': {
    borderBottom: 'none',
  },
  transition: 'background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    backgroundColor: '#e0e0e0', // Fondo más oscuro al hacer hover
    color: '#333', // Cambio en el color del texto
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Sombra para darle profundidad
    borderRadius: '8px', // Bordes redondeados para darle un efecto más claro
  },
});

const StyledModalBox = styled(Box)({
  width: '300px',
  margin: '100px auto',
  padding: '20px',
  backgroundColor: 'white',
  borderRadius: '8px',
  textAlign: 'center',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
});

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '30px',
});

const Logo = styled('img')({
  height: '55px',
  marginLeft: 'auto',
});

const SettingsAndPrivacy = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
  
    // Verificar si el parámetro `autoNavigateToProfileInfo` es `true`
    const autoNavigateToProfileInfo = params.get('autoNavigateToProfileInfo') === 'true';
    if (autoNavigateToProfileInfo) {
      setSelectedOption('profileInfo');
      // Eliminar el parámetro de la URL
      params.delete('autoNavigateToProfileInfo');
    }
  
    // Verificar si el parámetro `autoNavigateToAdminOptions` es `true`
    const autoNavigateToAdminOptions = params.get('autoNavigateToAdminOptions') === 'true';
    if (autoNavigateToAdminOptions) {
      setSelectedOption('adminOptions');
      // Eliminar el parámetro de la URL
      params.delete('autoNavigateToAdminOptions');
    }
  
    // Actualizar la URL eliminando los parámetros si es necesario
    if (autoNavigateToProfileInfo || autoNavigateToAdminOptions) {
      const newUrl = params.toString() ? `${location.pathname}?${params.toString()}` : location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  }, [location]);

  const handleBack = () => {
    setSelectedOption(null);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmModal = () => {
    setIsModalOpen(false);
    showSnackbar('Correo enviado con éxito', 'success');
  };

  const renderContent = () => {
    switch (selectedOption) {
      case 'changePassword':
        return <ChangePassword onClose={handleBack} />;
      case 'profileInfo':
        return <ProfileInformation onClose={handleBack} />;
      case 'accountVisualization':
        return <AccountVisualization onClose={handleBack} />;
      case 'whoCanContactYou':
        return <WhoCanContactYou onClose={handleBack} />;
      case 'adminOptions':
        return <AdminOptionsForm onClose={handleBack} />;
      default:
        return (
          <>
            <Section>
              <SectionHeader variant="h6">Preferencia de la cuenta</SectionHeader>
              <List>
              <StyledListItem onClick={() => setSelectedOption('profileInfo')}>
                <ListItemText primary="Información del perfil" />
                <ListItemSecondaryAction onClick={() => setSelectedOption('profileInfo')}>
                  <ArrowForwardIosIcon />
                </ListItemSecondaryAction>
              </StyledListItem>
              </List>
            </Section>
            <Section>
              <SectionHeader variant="h6">Inicio de sesión y seguridad</SectionHeader>
              <List>
                <StyledListItem onClick={() => setSelectedOption('changePassword')}>
                  <ListItemText primary="Cambiar contraseña" />
                  <ListItemSecondaryAction onClick={() => setSelectedOption('changePassword')}>
                    <ArrowForwardIosIcon />
                  </ListItemSecondaryAction>
                </StyledListItem>
              </List>
            </Section>
            <Section>
              <SectionHeader variant="h6">Privacidad de datos</SectionHeader>
              <List>
                <StyledListItem  onClick={handleOpenModal}>
                  <ListItemText primary="Obtener una copia de tus datos" />
                  <ListItemSecondaryAction onClick={handleOpenModal}>
                    <ArrowForwardIosIcon />
                  </ListItemSecondaryAction>
                </StyledListItem>
                <StyledListItem onClick={() => setSelectedOption('whoCanContactYou')}>
                  <ListItemText primary="Política de Gestión de Datos Personales" />
                  <ListItemSecondaryAction onClick={() => setSelectedOption('whoCanContactYou')}>
                    <ArrowForwardIosIcon />
                  </ListItemSecondaryAction>
                </StyledListItem>
              </List>
            </Section>
            <Section>
              <SectionHeader variant="h6">Permisos administrativos de tus instituciones</SectionHeader>
              <List>
                  <StyledListItem onClick={() => setSelectedOption('adminOptions')}>
                    <ListItemText primary="Gestión de antecedentes" />
                    <ListItemSecondaryAction>
                      <ArrowForwardIosIcon />
                    </ListItemSecondaryAction>
                  </StyledListItem>
              </List>
            </Section>
          </>
        );
    }
  };

  return (
    <Box sx={{
      padding: '20px',
      backgroundColor: '#fff',
      width: isMobile ? '100%' : '70%',
      margin: '0 auto',
    }}>
      <Header>
        <Divider sx={{ height: 40, width: '3px', bgcolor: '#F67147', mr: 2 }} flexItem />
        <Typography variant="h4" gutterBottom sx={{ color: '#F67147', fontWeight: 'bold' }}>
          Ajustes y Privacidad
        </Typography>
        <Logo src={LogoBlack} alt="Logo"  />
      </Header>
      {renderContent()}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <StyledModalBox>
          <Typography variant="h6" gutterBottom>
            Confirmación
          </Typography>
          <Typography>
            Se le enviará un correo con las indicaciones para obtener una copia de sus datos.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button variant="primary" onClick={handleConfirmModal} sx={{ marginRight: '10px' }}>
              Confirmar
            </Button>
            <Button variant="outlined" onClick={handleCloseModal}>
              Cancelar
            </Button>
          </Box>
        </StyledModalBox>
      </Modal>
    </Box>
  );
};

export default SettingsAndPrivacy;
