import React, { useState } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';

function RelevantExperienceForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [clientName, setClientName] = useState('');
    const [error, setError] = useState(false);

    const handleSubmit = () => {
        if (!clientName) {
            setError(true);
            return;
        }

        onAddAntecedent({
            id: antecedentType.id,
            antecedentType: antecedentType.name,
            clientName: clientName,
        });
    };

    return (
        <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Agregar {antecedentType.name}
            </Typography>
            <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                <Grid item xs={12} sm={4}>
                    <Typography>Cliente (fuiste asesor de)</Typography>
                    <TextField
                        margin="normal"
                        sx={{ width: "100%" }}
                        size="small"
                        id="clientName"
                        label="Escribe aquí el nombre del cliente..."
                        value={clientName}
                        onChange={(event) => setClientName(event.target.value)}
                        error={error && !clientName}
                        helperText={error && !clientName ? '* Este campo es obligatorio' : ''}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                <Button variant="primary" onClick={handleSubmit}>
                    Agregar Antecedente
                </Button>
            </Grid>
        </Box>
    );
}

export default RelevantExperienceForm;
