import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Container, Grid, Typography, Button } from '@mui/material';
import ProfileHeader from '../components/profile/ProfileHeader';
import { publicAxios } from '../apiClients';
import ProfileBody from '../components/profile/ProfileBody';
import ComplaintModal from '../components/complaint/ComplaintModal';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import BackButtonProfile from '../components/profile/BackButtonProfile';
import { useAuth } from './../hooks/useAuth';
import ButonsReportReference from '../components/profile/ButonsReportReference';
import Loading from '../components/Loading';
import { useScrollPosition } from '../components/directory/DirectoryContext';
import BackButton from '../components/BackButton';

function Profile() {
  const { id: paramId } = useParams();
  const location = useLocation();
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [userData, setUserData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState('inherit');
  const [BackButtonStyles, setBackButtonStyles] = useState({});
  const { setScrollPosition, searchParams } = useScrollPosition();
  const [isPublic, setIsPublic] = useState(false);
  const [isUserInstitution, setIsUserInstitution] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    if (user && user?.userRole === 'institutional') {
      const institutionUserIds = JSON.parse(localStorage.getItem('institutionUserIds') || '[]').map(Number); // Convertir todos los elementos a números
      const numericParamId = Number(userData.id);
      if (institutionUserIds.includes(numericParamId)) {
        setIsUserInstitution(true);
      }
    }
  }, [user, userData]);

  const handleMoreBackgrounds = () => {
    if (isUserInstitution) {
      const adminPermissionArray = userData?.adminPermission?.data || [];
      if (adminPermissionArray.length > 0) {
        let findPermission = false;
        adminPermissionArray.forEach(permission => {
          if (permission.institutionID === user?.adminInstitution?.institutionID && permission.institutionType ===  user?.adminInstitution?.institutionType) {
            if (permission.state) {
              localStorage.setItem('paramId', paramId);
              navigate('/choose-document');
            } else {
              showSnackbar('El usuario ha INHABILITADO los permisos administrativos para que la institución pueda subir nuevos antecedentes.', 'error');
            }
            findPermission = true;
          }
        });
        if (!findPermission) {
          localStorage.setItem('paramId', paramId);
          navigate('/choose-document');
        }
      } else {
        localStorage.setItem('paramId', paramId);
        navigate('/choose-document');
      }
    }
  };
  
  useEffect(() => {
    const determineIdAndFetchData = async () => {
      const path = location.pathname;

      let profileId = paramId;
      let publicProfile = false; // Por defecto, el perfil no es público

      // Determina el profileId y publicProfile en función de la ruta
      if (path.includes('/profile-caldery-hasche')) {
        profileId = 1900;
        publicProfile = true;
      } else if (path.includes('/profile-rubilar-suarez')) {
        profileId = 1901;
        publicProfile = true;
      } else if (path.includes('/profile-bravo-valenzuela')) {
        profileId = 1749;
        publicProfile = true;
      } else if (path.includes('/profile-abascal-marin')) {
        profileId = 1134;
        publicProfile = true;
      } else if (path.includes('/profile-sepulveda-matteo')) {
        profileId = 1812;
        publicProfile = true;
      } else if (path.includes('/profile-reyes-espinoza')) {
        profileId = 1902;
        publicProfile = true;
      } else if (path.includes('/profile-del-real-pascual')) {
        profileId = 1844;
        publicProfile = true;
      } else if (path.includes('/profile-kehr-castillo')) {
        profileId = 1903;
        publicProfile = true;
      } else if (path.includes('/profile-lagos-soza')) {
        profileId = 1904;
        publicProfile = true;
      } else if (path.includes('/profile-larrain-jimenez')) {
        profileId = 391;
        publicProfile = true;
      } else if (path.includes('/profile-larrain-siebel')) {
        profileId = 1905;
        publicProfile = true;
      } else if (path.includes('/profile-montero-varas')) {
        profileId = 141;
        publicProfile = true;
      } else if (path.includes('/profile-rodriguez-ariztia')) {
        profileId = 1045;
        publicProfile = true;
      } else if (path.includes('/profile-espinosa-meza')) {
        profileId = 1192;
        publicProfile = true;
      } else if (path.includes('/profile-diaz-ibanez')) {
        profileId = 1906;
        publicProfile = true;
      } else if (path.includes('/profile-carcelen-pacheco')) {
        profileId = 1075;
        publicProfile = true;
      } else if (path.includes('/profile-perez-jimenez')) {
        profileId = 1907;
        publicProfile = true;
      } else if (path.includes('/profile-canales-subercaseaux')) {
        profileId = 1908;
        publicProfile = true;
      } else if (path.includes('/profile-carloza-carmona')) {
        profileId = 236;
        publicProfile = true;
      } else if (path.includes('/profile-honour-manriquez')) {
        profileId = 1909;
        publicProfile = true;
      } else if (path.includes('/profile-troncoso-mella')) {
        profileId = 1910;
        publicProfile = true;
      } else if (path.includes('/profile-spuhr-ramirez')) {
        profileId = 1911;
        publicProfile = true;
      } else if (path.includes('/profile-torres-guzman')) {
        profileId = 1912;
        publicProfile = true;
      } else if (path.includes('/profile-nualart-vittini')) {
        profileId = 177;
        publicProfile = true;
      } else if (path.includes('/profile-veas-mofdi')) {
        profileId = 1913;
        publicProfile = true;
      } else if (path.includes('/profile-wiegand-restrepo')) {
        profileId = 1935;
        publicProfile = true;
      } else if (path.includes('/profile-garcia-de-la-huerta-vial')) {
        profileId = 117;
        publicProfile = true;
      } else if (path.includes('/profile-caorsi-opazo')) {
        profileId = 1936;
        publicProfile = true;
      } else if (path.includes('/profile-rosso-galleguillos')) {
        profileId = 1937;
        publicProfile = true;
      } else if (path.includes('/profile-gomez-moffat')) {
        profileId = 1938;
        publicProfile = true;
      } else if (path.includes('/profile-contente-infante')) {
        profileId = 1939;
        publicProfile = true;
      } else if (path.includes('/profile-ortiz-lagos')) {
        profileId = 100;
        publicProfile = true;
      } else if (path.includes('/profile-aguad-toledo')) {
        profileId = 1940;
        publicProfile = true;
      } else if (path.includes('/profile-plaza-santibanez')) {
        profileId = 1941;
        publicProfile = true;
      } else if (path.includes('/profile-jimenez-salas')) {
        profileId = 1942;
        publicProfile = true;
      } else if (path.includes('/profile-izquierdo-parada')) {
        profileId = 1943;
        publicProfile = true;
      } else if (path.includes('/profile-de-smet-dolbecke-errazuriz')) {
        profileId = 1944;
        publicProfile = true;
      } else if (path.includes('/profile-cifuentes-miranda')) {
        profileId = 94;
        publicProfile = true;
      } else if (path.includes('/profile-bellagamba-baldovino')) {
        profileId = 1945;
        publicProfile = true;
      } else if (path.includes('/profile-undurraga-zanartu')) {
        profileId = 1946;
        publicProfile = true;
      } else if (path.includes('/profile-mitjaew-escobar')) {
        profileId = 1947;
        publicProfile = true;
      } else if (path.includes('/profile-arias-edwards')) {
        profileId = 1948;
        publicProfile = true;
      } else if (path.includes('/profile-gonzalez-montt')) {
        profileId = 103;
        publicProfile = true;
      } else if (path.includes('/profile-palacios-cobo')) {
        profileId = 1949;
        publicProfile = true;
      } else if (path.includes('/profile-galvez-plaza')) {
        profileId = 1950;
        publicProfile = true;
      } else if (path.includes('/profile-carrasco-acuna')) {
        profileId = 1951;
        publicProfile = true;
      } else if (path.includes('/profile-maria-jose-sotomayor')) {
        profileId = 1958;
        publicProfile = true;
      } else {
        profileId = paramId; // Si no está en las opciones, usa el ID del parámetro
      }

      // Fetch data based on the determined id
      try {
        const response = await publicAxios.get(`/users/profileJSON?userID=${profileId}`);
        const data = response.data;
        setUserData(data);

        // Actualiza el color de fondo basado en el rol del usuario
        const bgColor = data?.userRole === 'leyend' ? '#2C3E50' : 'inherit';
        setBackgroundColor(bgColor);

        // Estilos para el botón de retroceso, basado en los datos del usuario
        const buttonStyles = {
          fontSize: '1.5rem', // Tamaño del texto del botón
          padding: '10px 20px', // Espaciado interno del botón
          color: data?.userRole !== 'leyend' ? 'black' : 'white',
          borderRadius: '5px',
          textTransform: 'uppercase',
          textDecoration: 'none',
          margin: '10px', // Espaciado alrededor del botón
        };
        setBackButtonStyles(buttonStyles);

        setIsPublic(publicProfile);
      } catch (error) {
        navigate('/unauthorized');
        console.error('Error fetching data: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    determineIdAndFetchData();
  }, [location.pathname, paramId, navigate]);

  const handleBackClick = () => {
    setScrollPosition(window.scrollY);
    navigate('/directory', { state: { searchParams } }); // Navega de regreso a la página anterior con los filtros y la búsqueda
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
    <Grid container direction="column" alignItems="center" style={{ backgroundColor, minHeight: '100vh' }}>
      <Grid item container direction="row" alignItems="center">
        {user && userData?.userRole !== 'leyend' && !isUserInstitution && (
          <BackButtonProfile
            onClick={handleBackClick}
            style={BackButtonStyles} // Pasa los estilos como prop
          />
        )}
        {userData?.userRole === 'leyend' && (
          <BackButton
            path="/legends"
            style={BackButtonStyles} // Pasa los estilos como prop
          />
        )}
        {user && isUserInstitution && (
          <BackButton
            path="/home-institution"
            style={BackButtonStyles} // Pasa los estilos como prop
          />
        )}
      </Grid>
      <Container maxWidth="md" sx={{ width: "100%", height: 'auto', minHeight: '600px', mt: 1 }}>
        <ProfileHeader
          name={userData?.fullName}
          ExpertiseArea1={userData?.ExpertiseArea1?.name}
          ExpertiseArea2={userData?.ExpertiseArea2?.name}
          location={userData?.Country?.name
            ? (userData?.City?.name ? `${userData.City.name}, ${userData.Country.name}` : userData.Country.name)
            : ''
          }
          imageUrl={userData?.linkPhoto}
          userRole={userData?.userRole}
          qualification={userData?.userQualifications}
          isCurrentUser={false}
          userSenderID={user?.id}
          userReceiverID={paramId}
          publicQualification={isPublic}
        />
        <ProfileBody userData={userData} isCurrentUser={false} />
        {(userData?.userRole !== 'leyend' && user?.userRole !== 'institutional' && !isPublic && user) ? (
          <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
            <ButonsReportReference userRole={user?.userRole} handleOpen={handleOpen} />
            <ComplaintModal open={openModal} handleClose={handleClose} data={userData} />
          </Grid>
        ) : (
          (user?.userRole === 'institutional' && (() => {
            const institutionUserIds = JSON.parse(localStorage.getItem('institutionUserIds') || '[]').map(Number); // Convertir todos los elementos a números
            const numericParamId = Number(paramId); // Convertir paramId a número
            return institutionUserIds.includes(numericParamId);
          })()) && (
            <Grid container justifyContent="center" sx={{ mb: 2, mt: 4, pt: 4, pb: 4 }}>
              <Typography id="complaint-modal-description" sx={{ mt: 1, mr: 10 }}>
                ¿Quieres mejorar tu grado?
              </Typography>
              <Button variant="primary" onClick={handleMoreBackgrounds}>
                Agregar antecedentes
              </Button>
            </Grid>
          )
        )
        }
      </Container>
    </Grid>
    </>
  );
}

export default Profile;
