import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import Logo from "../../utils/LogoBlack.png";
import './QualificationSuccess.css';
import { useAuth } from '../../hooks/useAuth';
// import successImage from '../../utils/success.png';
import logoBlack from '../../assets/logo/LogoBlack.png';
import { publicAxios } from '../../apiClients';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';

function QualificationSuccess() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const { user } = useAuth();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token_ws = query.get('token_ws');
  const params = query.get('qualificationType');
  let qualificationType, userID;
  if (params) {
    const [value, value2] = params.split('-');
    qualificationType = value;
    userID = value2;
  }
  // const [checkTransacion, setCheckTransacion] = useState(false);

  // const handleCheckTransacion = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await publicAxios.post('webpay/check-transaction', { token_ws });
  //     if (response.data.success) {
  //       setCheckTransacion(true);
  //     }
  //   } catch (error) {
  //     console.error('Error checking transaction: ', error);
  //     showSnackbar('Failed to check transaction', 'error');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   handleCheckTransacion();
  // }, []);


  const handlePaymentReturn = async () => {
    setIsLoading(true);
    try {
      const response = await publicAxios.post('webpay/review-payment', { token_ws, userID });
      if (response.data.success) {
        if (!user) {
          navigate('/go-to-qualification');
        }
        showSnackbar('Payment successful', 'success');
        setPaymentSuccess(true);
        const { data: { qualification } } = await publicAxios.get(`/user-qualification/user/${userID}?type=${qualificationType}&verified=${true}`);
        const qualificationResponse = await publicAxios.post('/user-qualification/create', { userID, qualificationType, qualificationID: Number(qualification) });
        if (qualificationResponse.data.success) {
          const params = {
            qualificationType: qualificationType,
          }
          navigate('/show-qualification', { state: params });
        }
      } else {
        if (!user) {
          navigate('/qualification-error');
        }
        console.log('Payment failed: ', response.data.message);
        showSnackbar('Payment failed', 'error');
        setPaymentSuccess(false);
      }
    } catch (error) {
      if (!user) {
        navigate('/qualification-error');
      }
      console.error('Error confirming payment: ', error);
      showSnackbar('Failed to confirm payment', 'error');
      setPaymentSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ minHeight: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (paymentSuccess === false) {
    return (
      <Box sx={{
        minHeight: '700px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
      }}>
        <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mt: 4 }}>
          Revisamos el pago pero ha habido un error
        </Typography>
        <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', mt: 2 }}>
          Por favor, revisa si en tu cuenta fue descontado el saldo, y si es así, contacta al equipo de Soubid lo antes posible.
        </Typography>
        <Button
          variant="primary"
          component={Link}
          to="/myprofile"
          sx={{ mt: 4, bgcolor: '#F67147', color: 'white' }}
        >
          Volver
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{
      minHeight: '700px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ height: 80, width: 80, mb: 8 }} />
      {/* <Box className="checkmark">
        <Box className="checkmark__circle">
          <Box component="img" src={logoBlack} alt="Success" className="checkmark__icon" />
        </Box>
      </Box> */}
      <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mt: 8 }}>
        Revisando transacción...
      </Typography>
      {/* <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', mt: 2 }}>
        Pagado con: Webpay
      </Typography> */}
      <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', mt: 2 }}>
        Haz click en el botón para ver el estado de la transacción.
      </Typography>
      <Button
        variant="primary"
        onClick={handlePaymentReturn}
        // component={Link}
        // to="/show-qualification"
        // state={{ qualificationType: qualificationType }}
        sx={{ 
          mt: 4, 
          bgcolor: '#F67147', 
          color: 'white',
          padding: '12px 30px',
          fontSize: '1.1rem'
        }}
      >
        Ir a revisar
      </Button>
    </Box>
  );
}

export default QualificationSuccess;
