import React from 'react';
import { Box, Button, Typography, styled } from '@mui/material';
import Logo from '../utils/LogoBlack.png'; // Asegúrate de que la ruta a tu imagen del logo sea correcta
import { useNavigate } from 'react-router-dom';

const CustomButton = styled(Button)({
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgrey',
    },
    margin: '10px 8px',
    borderRadius: '6px',
    fontSize: '1.2rem', // Aumenta el tamaño del texto
    padding: '6px 80px', // Aumenta el padding
  });
  

export default function ClaimProfile() {
  const navigate = useNavigate();

  const handleConfirm = () => {
    // Implementar lógica para confirmar el código
    navigate('/signup-claim');
  }

  return (
    <Box sx={{
      height: '80vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ mb: 4, height: 200, width: 200 }} />
      <Typography variant="h4" sx={{ color: 'black', mb: 2, maxWidth: "40ch", alignSelf: "center", textAlign: 'center' }}>
        Para reclamar tu perfil pre-creado, necesitas registrarte y activar tu cuenta
      </Typography>
      <Typography variant="subtitle1" sx={{ color: 'black', mb: 2, maxWidth: "40ch", alignSelf: "center", textAlign: 'center' }}>
        Te enviamos un token a tu correo electrónico. Debes usarlo en la siguiente etapa
      </Typography>
      <Box>
        <CustomButton onClick={handleConfirm} variant="primary">Reclamar</CustomButton>
      </Box>
    </Box>
  );
}
