import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import SelectOption from '../../SelectOption';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { publicAxios } from '../../../apiClients';
import { getFullDate } from '../../../utils/Validators';

function AcademicForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [formData, setFormData] = useState({
        universityID: '',
        universityName: '',
        professorTypeID: '',
        teachingDegreeID: '',
        startDate: null,
        endDate: null,
    });
    const [universities, setUniversities] = useState([]);
    const [professorTypes, setProfessorTypes] = useState([]);
    const [teachingDegrees, setTeachingDegrees] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseUniversities = await publicAxios.get('/static-data/universities');
                const responseProfessorTypes = await publicAxios.get('/static-data/professor-types');
                const responseTeachingDegrees = await publicAxios.get('/static-data/teaching-degrees');
                setUniversities(responseUniversities.data);
                setProfessorTypes(responseProfessorTypes.data);
                setTeachingDegrees(responseTeachingDegrees.data);
            } catch (error) {
                console.error('Error fetching data', error);
                setError(true);
            }
        };
        fetchData();
    }, []);

    const handleDateChange = (date, field) => {
        setFormData({
            ...formData,
            [field]: date
        });
    };

    const handleProfessorTypeChange = (value) => {
        setFormData({
            ...formData,
            professorTypeID: value,
            endDate: value === '9' ? 'present' : null,  // Si es docencia actual, la fecha de término es "present"
        });
    };

    const handleSubmit = () => {
        if (!formData.universityID || !formData.professorTypeID || !formData.teachingDegreeID || !formData.startDate || (antecedentType.id !== 9 && !formData.endDate)) {
            setError(true);
            return;
        }

        onAddAntecedent({
            id: antecedentType.id,
            antecedentType: antecedentType.name,
            universityID: formData.universityID,
            universityName: universities.find(university => university.id === formData.universityID).name,
            professorTypeID: formData.professorTypeID,
            professorTypeName: professorTypes.find(professorType => professorType.id === formData.professorTypeID).name,
            teachingDegreeID: formData.teachingDegreeID,
            startDate: getFullDate(formData.startDate),
            endDate: antecedentType.id === 9 ? 'present' : getFullDate(formData.endDate),
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Agregar {antecedentType.name}
                </Typography>
                <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                    <Grid item xs={12} sm={4}>
                        <Typography>Universidad</Typography>
                        <SelectOption
                            options={universities}
                            onSelectionChange={(value) => setFormData({ ...formData, universityID: value })}
                            name={'Selecciona la universidad'}
                            width="80%"
                            selectedOption={formData.universityID}
                            error={error && !formData.universityID}
                            helperText={error && !formData.universityID ? 'Este campo es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography>Tipo de Profesor</Typography>
                        <SelectOption
                            options={professorTypes}
                            onSelectionChange={handleProfessorTypeChange}
                            name={'Selecciona el tipo'}
                            width="80%"
                            selectedOption={formData.professorTypeID}
                            error={error && !formData.professorTypeID}
                            helperText={error && !formData.professorTypeID ? 'Este campo es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography>Grado en el que enseñaste</Typography>
                        <SelectOption
                            options={teachingDegrees}
                            onSelectionChange={(value) => setFormData({ ...formData, teachingDegreeID: value })}
                            name={'Selecciona el grado'}
                            width="80%"
                            selectedOption={formData.teachingDegreeID}
                            error={error && !formData.teachingDegreeID}
                            helperText={error && !formData.teachingDegreeID ? 'Este campo es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{ mb: 2 }}>Fecha de inicio {antecedentType.id === 9 && '- Presente'}</Typography>
                        <DatePicker
                            label="Fecha de inicio"
                            value={formData.startDate}
                            onChange={(date) => handleDateChange(date, 'startDate')}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: error && !formData.startDate,
                                    helperText: error && !formData.startDate ? 'Este campo es obligatorio' : '',
                                },
                            }}
                        />
                    </Grid>
                    {antecedentType.id !== 9 && (
                        <Grid item xs={12} sm={4}>
                            <Typography sx={{ mb: 2 }}>Fecha de término</Typography>
                            <DatePicker
                                label="Fecha de término"
                                value={formData.endDate}
                                onChange={(date) => handleDateChange(date, 'endDate')}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: error && !formData.endDate,
                                        helperText: error && !formData.endDate ? 'Este campo es obligatorio' : '',
                                    },
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                    <Button variant="primary" onClick={handleSubmit}>
                        Agregar Antecedente
                    </Button>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
}

export default AcademicForm;
