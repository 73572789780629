import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Grid, Box, Avatar, Button, Typography, TextField, Divider, useMediaQuery, Checkbox, FormControlLabel } from '@mui/material';
import image from '../../utils/LogoBlack.png';
import Loading from '../../components/Loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileUploadStatus from '../../components/FileUpload';
import EmailChipsInput from '../../components/EmailChipsInput';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';
import { useAuth } from '../../hooks/useAuth';
import BackButton from '../../components/BackButton';
import { useLocation } from 'react-router-dom';
import { getFullDate } from '../../utils/Validators';
import userAxios from '../../apiClients/userAxios';
import { useNavigate } from 'react-router-dom';

function UploadRelevantExperience() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, description, experience } = location.state || {};
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [clientName, setClientName] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isConfidential, setIsConfidential] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [emails, setEmails] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);

  useEffect(() => {
    if (user) {
      if (user?.userRole === 'institutional' && localStorage.getItem('paramId')) {
        setUserProfileId(localStorage.getItem('paramId'));
      } else {
        setUserProfileId(user.id);
      }
    }
  }, [user]);
  
  useEffect(() => {
    // Hace scroll al inicio al montar el componente
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (experience) {
      setClientName(experience.companyName || '');
      setUrlInput(experience.url || '');
      setSelectedDate(experience.date ? new Date(experience.date) : null);
      setIsConfidential(experience.confidential || false);
    }
  }, [experience]);

  const handleSendRelevantExperience = async () => {
    setIsLoading(true);
    if (!clientName || !selectedDate) {
      setIsLoading(false);
      setError(true);
      return;
    }
    if (!urlInput && !file) {
      setIsLoading(false);
      showSnackbar('Debes adjuntar un archivo o un link', 'error');
      return;
    }
    const experienceData = new FormData();
    experienceData.append('userReceiverID', userProfileId);
    experienceData.append('companyName', clientName);
    if (urlInput) {
      experienceData.append('url', urlInput);
    }
    experienceData.append('documentType', 'relevantExperience');
    experienceData.append('file', file);
    if (emails.length > 0) {
      experienceData.append('emails', JSON.stringify(emails));
    }
    experienceData.append('date', getFullDate(selectedDate));
    experienceData.append('publicVisible', !isConfidential); // Agregar el estado de confidencialidad
    try {
      let response;
      if (experience) {
        experienceData.append('state', 'pending');
        response = await userAxios.patch(`/relevant-experience/${experience.id}`, experienceData);
      } else {
        response = await userAxios.post('/relevant-experience', experienceData);
      }
      if (response.data.success) {
        setIsLoading(false);
        const message = response.data.message === 'update' ? 'Certificación actualizada con éxito' : 'Certificación creada con éxito';
        showSnackbar(message, 'success');
        navigate('/choose-document', { replace: true });
      } else {
        setIsLoading(false);
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al crear o actualizar la certificación', error);
      showSnackbar('Error al crear o actualizar la certificación', 'error');
    }
  };

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  function getValidationMessage() {
    if (selectedDate === null && error) {
      return (
        <Typography variant="caption" color="error" sx={{ ml: 2, mt: 3 }}>
          * La fecha es obligatoria
        </Typography>
      );
    }
    return ""; // No hay errores
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2} direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <BackButton path={"/choose-document"} back={true} />
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Box sx={{ ml: isMobile ? 1 : 10 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                  <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
                  <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                    {title}
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 2 }}>
                  {description}
                </Typography>
                <Typography>Cliente</Typography>
                <TextField
                  margin="normal"
                  sx={{ width: isMobile ? "100%" : "70%", mb: 2 }}
                  size="small"
                  id="clientName"
                  label="Escribe aquí el nombre del cliente..."
                  value={clientName}
                  onChange={(event) => handleInputChange(event, setClientName)}
                  error={error && !clientName}
                  helperText={error && !clientName ? '* Este campo es obligatorio' : ''}
                />
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 0, mb: 3 }}>
                  <Grid item sx={{ mt: 1 }}>
                    <Typography >
                      Fecha
                    </Typography>
                  </Grid>
                  <Grid item container >
                    <Grid item sx={{ mt: 2 }} >
                      <DatePicker
                        label="Selecciona la fecha"
                        value={selectedDate}
                        slotProps={{ textField: { size: 'small' } }}
                        onChange={(newValue) => {
                          setSelectedDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        selectedOption={selectedDate}
                      />
                    </Grid>
                    {getValidationMessage()}
                  </Grid>
                </Grid>
                <Typography>Adjunta Link</Typography>
                <TextField
                  margin="normal"
                  sx={{ width: isMobile ? "100%" : "70%", mb: 2 }}
                  size="small"
                  id="urlInput"
                  label="Link"
                  placeholder="Escribe aquí..."
                  value={urlInput}
                  onChange={(event) => handleInputChange(event, setUrlInput)}
                />
                <Typography sx={{ mb: 1 }}>
                  Documento de respaldo
                  <Tooltip 
                    title="Para acreditar tu experiencia puedes utilizar cualquier antecedente escrito, como un contrato, un escrito judicial, recortes de prensa, etc. El formato también es libre, pudiendo ser fotografías, archivos .pdf, links, o incluso pantallazos ¡Bienvenida la creatividad!"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          maxWidth: isMobile ? '150px' : '400px', // Ancho según si es móvil
                          fontSize: isMobile ? '10px' : '13.15px',  // Tamaño de fuente según si es móvil
                          padding: isMobile ? '6px' : '11px',    // Padding según si es móvil
                          whiteSpace: 'normal', // Ajusta el texto al ancho del tooltip
                          textAlign: 'center',  // Centra el texto en el tooltip
                          backgroundColor: 'rgba(128, 128, 128, 0.975)',
                        },
                      },
                    }}
                  >
                    <HelpOutlineOutlinedIcon sx={{ml: 1}}/>
                  </Tooltip>
                </Typography>
                <FileUploadStatus setFile={setFile} file={file} />
                
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isConfidential}
                      onChange={(e) => setIsConfidential(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Este antecedente es confidencial"
                  sx={{ mt: 2 }}
                />

              </Box>
            </Grid>
            {!isMobile && (
              <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
                <Avatar variant="rounded" src={image} sx={{ width: '200px', height: '200px' }} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ ml: isMobile ? 1 : 10 }}>
                <Typography>Danos el Correo electrónico de personas que puedan ayudar con el proceso de verificación.</Typography>
                <EmailChipsInput setEmails={setEmails} emails={emails} />
              </Box>
            </Grid>
            <Grid item xs={12} container justifyContent="center" sx={{ mb: 3 }}>
              <Button variant="primary" onClick={handleSendRelevantExperience}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}

export default UploadRelevantExperience;
