import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import SelectOption from '../../SelectOption';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { publicAxios } from '../../../apiClients';
import { getFullDate } from '../../../utils/Validators';

function EducationForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [formData, setFormData] = useState({
        universityID: '',
        startDate: null,
        endDate: null,
    });
    const [universities, setUniversities] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchUniversities = async () => {
            try {
                const response = await publicAxios.get(`/static-data/universities`);
                setUniversities(response.data);
            } catch (error) {
                setError(true);
            }
        };
        fetchUniversities();
    }, []);

    const handleSubmit = () => {
        if (!formData.universityID || !formData.startDate || !formData.endDate) {
            setError(true);
            return;
        }
        onAddAntecedent({
            id: antecedentType.id,
            universityID: formData.universityID,
            educationType: antecedentType.name,
            startDate: getFullDate(formData.startDate),
            endDate: getFullDate(formData.endDate),
            universityName: universities.find(university => university.id === formData.universityID).name
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Agregar {antecedentType.name}
                </Typography>
                <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                    <Grid item xs={12} sm={4}>
                        <Typography>Universidad</Typography>
                        <SelectOption
                            options={universities}
                            onSelectionChange={(value) => setFormData({ ...formData, universityID: value })}
                            name={'Escoge universidad'}
                            width="80%"
                            selectedOption={formData.universityID}
                            error={error && !formData.universityID}
                            helperText={error && !formData.universityID ? 'Este campo es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{ mb: 2 }}>Fecha de inicio</Typography>
                        <DatePicker
                            label="Fecha de inicio"
                            value={formData.startDate}
                            onChange={(date) => setFormData({ ...formData, startDate: date })}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: error && !formData.startDate,
                                    helperText: error && !formData.startDate ? 'Este campo es obligatorio' : '',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{ mb: 2 }}>Fecha de término</Typography>
                        <DatePicker
                            label="Fecha de término"
                            value={formData.endDate}
                            onChange={(date) => setFormData({ ...formData, endDate: date })}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: error && !formData.endDate,
                                    helperText: error && !formData.endDate ? 'Este campo es obligatorio' : '',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                    <Button variant="primary" onClick={handleSubmit}>
                        Agregar Antecedente
                    </Button>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
}

export default EducationForm;
