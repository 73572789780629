import React, { useState, useEffect } from 'react';
import { Grid, Box, Button, Typography, TextField, Select, MenuItem, Modal, IconButton, useMediaQuery, Checkbox, FormControlLabel, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'; // Importar el ícono para agregar una foto
import { publicAxios, userAxios } from '../../apiClients';
import Loading from '../Loading';
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from '../snackbar/SnackbarContext';
import PhotoUploadModal from '../PhotoUploadModal'; // Asegúrate de importar correctamente tu componente

const ProfileInformation = ({ onClose }) => {
  const { user, checkCurrentUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [contactNumber, setContactNumber] = useState('');
  const [expertiseArea1, setExpertiseArea1] = useState('');
  const [expertiseArea2, setExpertiseArea2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [lastName, setLastName] = useState('');
  const [secondLastName, setSecondLastName] = useState('');
  const [Profession, setProfession] = useState('');
  const [countryID, setCountryID] = useState('');
  const [profileData, setProfileData] = useState({});
  const [selectedCityID, setSelectedCityID] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // State for PhotoUploadModal
  const [confirmModalVisible, setConfirmModalVisible] = useState(false); // State for confirm modal
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [expertiseAreas, setExpertiseAreas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibility, setVisibility] = useState({
    name: false,
    secondName: false,
    lastName: true,
    secondLastName: false,
  });
  const [fullName, setFullName] = useState('');
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [isCountryEditable, setIsCountryEditable] = useState(true); // Nuevo estado para habilitar/deshabilitar campos

  useEffect(() => {
    const fetchStaticData = async () => {
      setIsLoading(true);
      try {
        const [countriesRes, citiesRes, expertiseRes] = await Promise.all([
          publicAxios.get('/static-data/countries'),
          publicAxios.get('/static-data/cities'),
          publicAxios.get('/static-data/expertise-areas'),
        ]);
        setCountries(countriesRes.data);
        setCities(citiesRes.data);
        setExpertiseAreas(expertiseRes.data);
      } catch (error) {
        console.error('Error fetching static data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStaticData();
  }, []);

  useEffect(() => {
    if (countryID) {
      const filtered = cities.filter(city => city.countryID === countryID);
      setFilteredCities(filtered);
    } else {
      setFilteredCities(cities);
    }
  }, [countryID, cities]);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const response = await userAxios.get(`/users/profileJSON?userID=${user?.id}`);
        setProfileData(response.data);
        setContactNumber(response.data.contactNumber);
        setCountryID(response.data.Country?.id || null);
        setSelectedCityID(response.data.City?.id || null);
        setFullName(response.data.lastName);
        setFirstName(response.data.name);
        setSecondName(response.data.secondName);
        setLastName(response.data.lastName);
        setSecondLastName(response.data.secondLastName);
        setProfession(response.data.Professions?.name);

        // Buscar y asignar los IDs correspondientes a las áreas de especialización
        const expertiseArea1ID = expertiseAreas.find(area => area.name === response.data.ExpertiseArea1?.name)?.id;
        const expertiseArea2ID = expertiseAreas.find(area => area.name === response.data.ExpertiseArea2?.name)?.id;
        setExpertiseArea1(expertiseArea1ID || null);
        setExpertiseArea2(expertiseArea2ID || null);

        // Si no hay CIUDAD en el perfil, permitir la edición
        setIsCountryEditable(!response.data.City?.id);

        initializeVisibility(response.data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setIsLoading(false);
      }
    };
    if (expertiseAreas.length > 0) {
      fetchUserData();
    }
  }, [expertiseAreas]);

  const initializeVisibility = (data) => {
    const initialVisibility = {
      name: data.visibility.name,
      secondName: data.visibility.secondName,
      lastName: data.visibility.lastName,
      secondLastName: data.visibility.secondLastName,
    };
    setVisibility(initialVisibility);
    setFullName(getVisibleName(data, initialVisibility));
  };

  const handleSave = () => {
    if (expertiseArea1 === expertiseArea2 && expertiseArea1) {
      showSnackbar('No puedes seleccionar la misma área de práctica dos veces.', 'error');
      return;
    }
    
    if (validateFullName()) {
      if (isCountryEditable) {
        setConfirmModalVisible(true);
      } else {
        setIsModalVisible(true);
      }
    } else {
      showSnackbar('Debe incluir al menos el primer apellido y uno de los dos nombres en el nombre público.', 'error');
    }
  };

  const validateFullName = () => {
    const hasFirstName = visibility.name && firstName;
    const hasSecondName = visibility.secondName && secondName;
    const hasLastName = visibility.lastName && lastName;

    return hasLastName && (hasFirstName || hasSecondName);
  };

  const handleConfirmSave = async () => {
    setIsModalVisible(false);
    setConfirmModalVisible(false);

    const userData = {
      id: user?.id,
      name: firstName,
      secondName: secondName,
      lastName: lastName,
      secondLastName: secondLastName,
      profession: Profession,
      countryID: countryID,
      cityID: selectedCityID,
      contactNumber: contactNumber,
      expertiseAreaID1: expertiseArea1,
      expertiseAreaID2: expertiseArea2,
      fullName: fullName,
    };

    try {
      const response = await userAxios.patch(`/users/update-profile`, userData);
      if (response.data.success) {
        showSnackbar('Su información ha sido guardada correctamente', 'success');
        checkCurrentUser();
        onClose(); // Close the modal and go back to the main settings view
      }
    } catch (error) {
      console.error('Error while saving your information', error);
    }
  };

  const handleVisibilityChange = (field) => (event) => {
    const newVisibility = { ...visibility, [field]: event.target.checked };
    setVisibility(newVisibility);
    setFullName(getVisibleName(profileData, newVisibility));
  };

  const getVisibleName = (data, visibility) => {
    const visibleNameParts = [];
    if (visibility.name && firstName) visibleNameParts.push(firstName);
    if (visibility.secondName && secondName) visibleNameParts.push(secondName);
    if (visibility.lastName && lastName) visibleNameParts.push(lastName);
    if (visibility.secondLastName && secondLastName) visibleNameParts.push(secondLastName);
    return visibleNameParts.join(' ');
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item container justifyContent="space-between" alignItems="center">
          <IconButton onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Editar tu Perfil</Typography>
          <Box sx={{ width: '48px' }} /> {/* Espacio vacío para mantener el diseño */}
        </Grid>
        <Grid item container spacing={2} justifyContent="center" sx={{ mt: 3 }} direction={isMobile ? "column" : "row"}>
          <Grid item container spacing={2} xs={isMobile ? 12 : 8} justifyContent="center">
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>Primer Nombre</Typography>
              <TextField
                margin="normal"
                sx={{ width: '100%' }}
                size="small"
                label={isCountryEditable ? '' : "Primer Nombre"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                InputLabelProps={{ shrink: !isCountryEditable }}
                disabled={!isCountryEditable}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>Segundo Nombre</Typography>
              <TextField
                margin="normal"
                sx={{ width: '100%' }}
                size="small"
                label={isCountryEditable ? '' : "Segundo Nombre"}
                value={secondName}
                onChange={(e) => setSecondName(e.target.value)}
                InputLabelProps={{ shrink: !isCountryEditable}}
                disabled={!isCountryEditable}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={isMobile ? 12 : 8} justifyContent="center">
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>Primer Apellido</Typography>
              <TextField
                margin="normal"
                sx={{ width: '100%' }}
                size="small"
                label={isCountryEditable ? '' : "Primer Apellido"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                InputLabelProps={{ shrink: !isCountryEditable }}
                disabled={!isCountryEditable}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>Segundo Apellido</Typography>
              <TextField
                margin="normal"
                sx={{ width: '100%' }}
                size="small"
                label={isCountryEditable ? '' : "Segundo Apellido"}
                value={secondLastName}
                onChange={(e) => setSecondLastName(e.target.value)}
                InputLabelProps={{ shrink: !isCountryEditable }}
                disabled={!isCountryEditable}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={isMobile ? 12 : 8} justifyContent="center">
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>País</Typography>
              <Select
                margin="normal"
                sx={{ width: '100%', mt: 2 }}
                size="small"
                value={countryID}
                onChange={(e) => setCountryID(e.target.value)}
                disabled={!isCountryEditable}
              >
                {countries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>Ciudad</Typography>
              <Select
                margin="normal"
                sx={{ width: '100%', mt: 2 }}
                size="small"
                value={selectedCityID}
                onChange={(e) => setSelectedCityID(e.target.value)}
              >
                {filteredCities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={isMobile ? 12 : 8} justifyContent="center">
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>Profesión</Typography>
              <TextField
                margin="normal"
                sx={{ width: '100%' }}
                size="small"
                label={isCountryEditable ? '' : "Profesión"}
                value={Profession}
                onChange={(e) => setProfession(e.target.value)}
                InputLabelProps={{ shrink: !isCountryEditable }}
                disabled
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>Número de Teléfono</Typography>
              <TextField
                margin="normal"
                sx={{ width: '100%' }}
                size="small"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={isMobile ? 12 : 8} justifyContent="center">
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>Área de Práctica 1:</Typography>
              <Select
                margin="normal"
                sx={{ width: '100%' }}
                size="small"
                value={expertiseArea1}
                onChange={(e) => setExpertiseArea1(e.target.value)}
              >
                {expertiseAreas.map((area) => (
                  <MenuItem key={area.id} value={area.id}>
                    {area.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography>Área de Práctica 2:</Typography>
              <Select
                margin="normal"
                sx={{ width: '100%' }}
                size="small"
                value={expertiseArea2}
                onChange={(e) => setExpertiseArea2(e.target.value)}
              >
                {expertiseAreas.map((area) => (
                  <MenuItem key={area.id} value={area.id}>
                    {area.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
        {isMobile ? (
          <Typography variant="h6" sx={{ mt: 3, justifyContent: 'center' }}>
          ¿Quieres modificar tu <Typography component="span" variant="h6" sx={{ fontWeight: 'bold' }}>imagen pública</Typography>?
        </Typography>
        ) : (
        <Divider sx={{ width: '100%', mt: 3 }}>
          <Typography variant="h6">
            ¿Quieres modificar tu <Typography component="span" variant="h6" sx={{ fontWeight: 'bold' }}>imagen pública</Typography>?
          </Typography>
        </Divider>
        )}
        <Grid item container justifyContent="center" sx={{ my: 1 }}>
          <IconButton
            onClick={() => setModalVisible(true)}
            sx={{
              width: '100px',
              height: '100px',
              bgcolor: 'rgba(0, 0, 0, 0.125)', // Fondo gris claro
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AddAPhotoIcon sx={{ fontSize: '40px' }} />
          </IconButton>
        </Grid>
        {isMobile ? (
          <Typography variant="h6" sx={{ mt: 3, mb: 3, justifyContent:"center", alignItems:"center" }}>
            ¿Cuál quieres que sea tu <Typography component="span" variant="h6" sx={{ fontWeight: 'bold' }}>nombre público</Typography>?
          </Typography>  
        ) : (
        <Divider sx={{ width: '100%', my: 3 }}>
          <Typography variant="h6">¿Cuál quieres que sea tu <Typography component="span" variant="h6" sx={{ fontWeight: 'bold' }}>nombre público</Typography>?</Typography>
        </Divider>
        )}  
        <Grid item container spacing={2} justifyContent="center" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={visibility.name}
                onChange={handleVisibilityChange('name')}
                sx={{ color: 'black' }}
              />
            }
            label={<Typography sx={{ color: 'black' }}>Primer Nombre</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={visibility.secondName}
                onChange={handleVisibilityChange('secondName')}
                sx={{ color: 'black' }}
              />
            }
            label={<Typography sx={{ color: 'black' }}>Segundo Nombre</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                onChange={handleVisibilityChange('lastName')}
                sx={{ color: 'black' }}
                disabled={true}
              />
            }
            label={<Typography sx={{ color: 'black' }}>Primer Apellido</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={visibility.secondLastName}
                onChange={handleVisibilityChange('secondLastName')}
                sx={{ color: 'black' }}
              />
            }
            label={<Typography sx={{ color: 'black' }}>Segundo Apellido</Typography>}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, width: isMobile ? '100%' : '50%' }}>
          <Typography variant="h6" align="center">Nombre Público:</Typography>
          <TextField
            margin="normal"
            sx={{
              width: '100%',
              bgcolor: '#FFFACD', // color amarillo opaco
              textAlign: 'center',
              '& .MuiInputBase-input': {
                textAlign: 'center',
              },
            }}
            size="medium"
            value={fullName}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Divider sx={{ width: '100%', my: 3 }}></Divider>
        <Grid item container justifyContent="center" sx={{ mb: 3, mt: 2 }}>
          <Button
            variant="primary"
            color="primary"
            onClick={handleSave}
            sx={{ width: isMobile ? '50%' : '15%' }}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
      <Modal open={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <Box sx={{ width: '300px', margin: '100px auto', padding: '20px', backgroundColor: 'white', borderRadius: '8px', textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            ¿Deseas guardar los cambios?
          </Typography>
          <Button variant="primary" onClick={handleConfirmSave} sx={{ marginRight: '10px' }}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={() => setIsModalVisible(false)}>
            Cancelar
          </Button>
        </Box>
      </Modal>
      <Modal open={confirmModalVisible} onClose={() => setConfirmModalVisible(false)}>
        <Box sx={{ width: '300px', margin: '100px auto', padding: '20px', backgroundColor: 'white', borderRadius: '8px', textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            ¿Estás seguro de que quieres enviar estos datos? No podrás modificarlos en el futuro.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Nombre: {firstName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Segundo Nombre: {secondName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Apellido: {lastName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Segundo Apellido: {secondLastName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            País: {countries.find(country => country.id === countryID)?.name}
          </Typography>
          <Button variant="primary" onClick={handleConfirmSave} sx={{ marginRight: '10px' }}>
            Confirmar
          </Button>
          <Button variant="outlined" onClick={() => setConfirmModalVisible(false)}>
            Cancelar
          </Button>
        </Box>
      </Modal>
      <PhotoUploadModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
    </Box>
  );
};

export default ProfileInformation;
