import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useScrollPosition } from './DirectoryContext'; // Importa el contexto
import { getBestQualificationDirectory, getRatingImage } from '../../utils/functions';
import { useMediaQuery } from '@mui/material';
import user1 from '../../assets/images/user1.png';

const CustomCard = styled(Card)(({ theme }) => ({
  maxWidth: 300,
  height: 570,
  borderRadius: '16px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: '#FFFFFF',
  margin: 'auto',
}));

const ProfileImage = styled(CardMedia)({
  borderRadius: '50%',
  width: 150,
  height: 150,
  margin: '0 auto',
});

const Divider = styled(Box)({
  width: '80%',
  height: '2px',
  backgroundColor: '#E0E0E0',
  margin: '20px auto',
});

const RatingBadge = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: 80,
  height: 80,
  margin: '20px auto',
  color: '#F67147',
  fontSize: '24px',
  fontWeight: 'bold',
});

const ProfileButton = styled(Button)({
  backgroundColor: '#F67147',
  color: '#FFFFFF',
  padding: '10px 20px',
  borderRadius: '24px',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: '#F67147',
  },
});

function ProfileCard({ id, name, university, profession, city, country, linkPhoto, rating, onClick }) {
  const bestQualification = getBestQualificationDirectory(rating);
  const [userRating, setUserRating] = useState('');
  const [superqualified, setSuperqualified] = useState(false);
  const notRating = require('../../assets/images/notQualify.png');
  const notAvailable = require('../../assets/images/difuminado.png');
  const imageRating = (bestQualification === null) ? notRating : getRatingImage(bestQualification);
  const { user } = useAuth();
  const { setScrollPosition } = useScrollPosition(); // Usar el contexto
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  let professionName;
  if (profession === 'Abogado') {
    professionName = 'Lawyer';
  } else {
    professionName = profession;
  }

  let userQualified = false;
  if (user) userQualified = Object.keys(user?.qualification).length !== 0;

  useEffect(() => {
    if (bestQualification) {
      const values = Object.values(bestQualification);
      if (values[0] !== null) {
        setUserRating(values[0]);
      }
      if (userRating === 'AAA+' || userRating === 'AAA' || userRating === 'AAA-') {
        setSuperqualified(true);
      }
    }
  }, [bestQualification]);

  const handleProfileClick = () => {
    setScrollPosition(window.scrollY); // Guardar la posición de desplazamiento actual
    navigate(`/profile/${id}`);
  };

  const handleImageError = (event) => {
    event.target.src = user1; // Reemplaza la imagen con la imagen por defecto en caso de error
  };

  return (
    <CustomCard>
      <ProfileImage component="img" image={linkPhoto ? linkPhoto : user1} alt={name} onError={handleImageError} />
      <CardContent>
        <Typography variant="h6" fontFamily="Poppins" component="div" gutterBottom sx={{ height: '3em', lineHeight: '1.5em', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>
          {name}
        </Typography>
        <Divider sx={{mt:0, mb:1 }}/>
        <Typography variant="body1" fontFamily="Poppins" fontWeight="Bold" color="textPrimary" minHeight={(bestQualification !== null && userQualified) ? 50 : 30}>
          {professionName}
        </Typography>
        <Typography variant="body2" fontFamily="Poppins" color="textSecondary" minHeight={(bestQualification !== null && userQualified) ? 50 : 30}>
          {country ? country : '' }
        </Typography>
        <RatingBadge>
          {userQualified ? (
            <img src={imageRating} alt="professional-rating" style={{ width: bestQualification === null ? '100px' : '120px', height: bestQualification === null ? '100px' : '120px'}} />
          ) : (
          superqualified ? (
              <img src={imageRating} alt="professional-rating" style={{ width: bestQualification === null ? '100px' : '120px', height: bestQualification === null ? '100px' : '120px'}} />
            ) : (
              <Tooltip 
                title="Califícate y podrás ver la calificación de este usuario."
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: isMobile ? '120px' : '180px', // Ancho según si es móvil
                      fontSize: isMobile ? '10px' : '13px',  // Tamaño de fuente según si es móvil
                      padding: isMobile ? '6px' : '10px',    // Padding según si es móvil
                      whiteSpace: 'normal', // Ajusta el texto al ancho del tooltip
                      textAlign: 'center',  // Centra el texto en el tooltip
                    },
                  },
                }}
              >
                <img 
                  src={notAvailable} 
                  alt="professional-rating" 
                  style={{ 
                    width: '100px', 
                    height: '100px' 
                  }} 
                />
              </Tooltip>
            )
          )}
        </RatingBadge>
          {(!userQualified && !superqualified) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <Typography 
                sx={{ 
                  fontSize: isMobile ? '8px' : '10px', 
                  width: '50%', 
                  textAlign: 'center', 
                  fontFamily: "Poppins", 
                  color: "textSecondary" 
                }}
              >
                Calificación no disponible
              </Typography>
            </Box>
          )}
          {(bestQualification === null && userQualified) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <Typography 
                sx={{ 
                  fontSize: isMobile ? '8px' : '10px', 
                  width: '50%', 
                  textAlign: 'center', 
                  fontFamily: "Poppins", 
                  color: "textSecondary" 
                }}
              >
                Usuario aún sin calificación
              </Typography>
            </Box>
          )}
        <ProfileButton 
          variant="primary" 
          onClick={handleProfileClick}
        >
          Ver perfil
        </ProfileButton>
      </CardContent>
    </CustomCard>
  );
}

export default ProfileCard;
