import React from 'react';
import { Box, Typography, Button, Container, Modal, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../utils/LogoBlack.png'; // Asegúrate de que la ruta a tu imagen del logo sea correcta
import qualifications from '../assets/simulator/qualifications.png';

const NotificationQualification = ({ isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleQualify = () => {
    navigate('/qualification');
    handleCloseModal();
  };

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 4, minHeight: '80vh' }}>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "80%",
            maxWidth: 700,
            // height: "auto",
            bgcolor: 'white',
            border: '1px solid #000',
            borderRadius: 5,
            boxShadow: 24,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around', 
            textAlign: 'center',
        }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
            ¡Impulsa tu marca y califícate!
            </Typography>
            <Box component="img" src={Logo} alt="Logo" 
            sx={{ 
                height: 80,
                width: 80, 
                mb: 2 ,
                alignSelf: 'center'
            }} 
            />
            <Typography variant="body1" sx={{ color: 'black', mb: 2 }}>
              ¿Aún no te has calificado? Es rápido y sencillo.
            </Typography>
                <Box component="img" src={qualifications} alt="QR Code" 
                sx={{ 
                    height: 150,
                    width: 200,
                    alignSelf: 'center', 
                    mb: 2 
                }} 
                />
            <Button 
              onClick={handleQualify} 
              sx={{ 
                mt: 3, 
                bgcolor: '#F67147', 
                width: '40%',
                alignSelf: 'center',
                color: 'black', 
                borderRadius: 2, 
                '&:hover': {
                    bgcolor: '#e0e0e0',
                }
              }}
            >
              ¡Califícate ahora!
            </Button>
            <Button onClick={handleCloseModal} sx={{ color: 'black', justifyContent: 'flex-end', mt: 1}}>
              cerrar
            </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default NotificationQualification;
