import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, IconButton, Divider, Avatar, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../hooks/useAuth';
import { userAxios } from '../apiClients';
import { useSnackbar } from './snackbar/SnackbarContext';
import { getBestQualification } from '../utils/getRatingImages';

const Governance = ({ isVisible, onClose }) => {
    const { user } = useAuth();
    const validQualifications = ['AAA+', 'AAA', 'AAA-', 'AA+', 'AA', 'AA-', 'A+', 'A', 'A-'];
    const { showSnackbar } = useSnackbar();

    const handleSendEmail = async () => {
        const bestQualification = getBestQualification(user?.qualification)?.grade;

        if (!bestQualification) {
            showSnackbar("No eres elegible para ser parte del panel de expertos. Debes estar calificado para esta actividad", "warning");
        } else if (validQualifications.includes(bestQualification)) {
            try {
                const response = await userAxios.post('/users/send-email-panel-expertos', { userID: user?.id });
                if (response.status === 200) {
                    showSnackbar("Se ha enviado una encuesta a tu correo electrónico para participar en el panel de calificación", "success");
                    onClose();
                } else {
                    showSnackbar("Error al enviar la encuesta", "error");
                }
            } catch (error) {
                showSnackbar("Error al enviar la encuesta", "error");
            }
        } else {
            showSnackbar("Necesitas subir tu calificación a la categoría A para ser parte del Panel de Expertos.", "info");
        }
    };

    return (
        <Dialog open={isVisible} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar src={user?.linkPhoto} alt={user?.fullName} sx={{ width: 56, height: 56, mr: 2 }} />
                    <Box>
                        <Typography variant="h6">{user?.data?.fullName}</Typography>
                        <Typography variant="body2" color="textSecondary">{user?.place}</Typography>
                    </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h4" gutterBottom>
                    Gobernanza
                </Typography>
                <Typography variant="body1" paragraph>
                    El modelo Soubid es determinado de forma objetiva y descentralizada, con el apoyo de los principales líderes de cada industria.
                </Typography>
                <Typography variant="body1" paragraph>
                    Todos quienes tengan una calificación igual o superior a A son parte de nuestro Panel de Expertos, y pueden influir en el funcionamiento del modelo.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>¡Tus opiniones y sugerencias pueden redefinir los estándares de la excelencia profesional!</strong>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSendEmail} variant="primary">
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Governance;
