import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const UpdateInfoModal = ({ open, handleClose }) => {
    const navigate = useNavigate();

    const handleGo = () => {
        handleClose();
        navigate('/settings?autoNavigateToProfileInfo=true');
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="complaint-modal-title" variant="h5" component="h2">
                    Completa tu perfil
                </Typography>
                <Typography id="complaint-modal-description" sx={{ mt: 2 }}>
                    Necesitas agregar más información a tu perfil para completar el proceso de registro.
                </Typography>
                <Button
                    fullWidth
                    variant="primary"
                    sx={{ mt: 2 }}
                    onClick={handleGo}
                >
                    Agregar información
                </Button>
            </Box>
        </Modal>
    );
};

export default UpdateInfoModal;
