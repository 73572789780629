import React from 'react';
import { Box, Typography, IconButton, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from '@emotion/styled';

const Container = styled(Box)({
  padding: '20px',
  backgroundColor: '#fff',
  width: '70%',
  margin: '0 auto',
  textAlign: 'center',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Sombra para darle profundidad
});

const Section = styled(Box)({
  marginBottom: '20px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  padding: '20px', // Más padding para mejorar la legibilidad
  textAlign: 'left', // Alineación de texto a la izquierda
  backgroundColor: '#EEF1F4', // Color de fondo sutil para diferenciar la sección
});

const Title = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '10px',
  color: '#333',
});

const WhoCanContactYou = ({ onClose }) => {
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item container justifyContent="space-between" alignItems="center">
          <IconButton onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Política de Gestión de Datos Personales de Soubid</Typography>
          <Box sx={{ width: '48px' }} /> {/* Espacio vacío para mantener el diseño */}
        </Grid>
        <Grid item container direction="column" alignItems="center">
          <Container>
            <Section>
              <Typography variant="body2" gutterBottom>
                Última actualización: 26 de julio de 2024
              </Typography>
              <Typography variant="body2" gutterBottom>
                Esta Política de Privacidad establece las prácticas de gestión de datos de Soubid, incluyendo cómo recopilamos, usamos y protegemos la información de nuestros usuarios. Al utilizar nuestra plataforma, usted consiente el tratamiento de sus datos conforme a lo descrito aquí.
              </Typography>

              <Title variant="h5" gutterBottom>
                Definiciones
              </Title>
              <Typography variant="body2" gutterBottom>
                Cuenta: Acceso único al servicio.
                {"\n"}Afiliado: Entidades asociadas o bajo control de Soubid.
                {"\n"}Aplicación: La plataforma software de Soubid.
                {"\n"}Datos Personales: Cualquier información que identifique o pueda identificar a un usuario.
                {"\n"}Servicio: Funcionalidades ofrecidas a través de Soubid.
                {"\n"}Proveedor de Servicios: Terceros que realizan servicios en nombre de Soubid.
                {"\n"}Datos de Uso: Información recogida automáticamente a través del uso de nuestro servicio.
              </Typography>

              <Title variant="h5" gutterBottom>
                Recopilación y Uso de Datos Personales
              </Title>
              <Typography variant="body2" gutterBottom>
                Información Recopilada: Podemos solicitar datos como nombre, contacto, dirección y otros detalles necesarios para el uso de nuestras funcionalidades.
                {"\n"}Uso de la Información: Los datos recopilados se usan para operar y mejorar nuestros servicios, gestionar su cuenta, y comunicar noticias y ofertas.
              </Typography>

              <Title variant="h5" gutterBottom>
                Seguridad y Protección de Datos
              </Title>
              <Typography variant="body2" gutterBottom>
                Implementamos medidas de seguridad para proteger sus datos, aunque ningún sistema es infalible. En caso de brecha de seguridad, actuaremos rápidamente para mitigar cualquier daño.
              </Typography>

              <Title variant="h5" gutterBottom>
                Transferencia de Datos
              </Title>
              <Typography variant="body2" gutterBottom>
                Sus datos pueden ser transferidos y almacenados en ubicaciones fuera de su país, siempre bajo estrictas medidas de seguridad.
              </Typography>

              <Title variant="h5" gutterBottom>
                Sus Derechos
              </Title>
              <Typography variant="body2" gutterBottom>
                Acceso y Control: Puede revisar, modificar o eliminar su información personal en cualquier momento.
                {"\n"}Retirar Consentimiento: Puede retirar su consentimiento al tratamiento de sus datos personales siguiendo el procedimiento especificado en nuestra plataforma.
              </Typography>

              <Title variant="h5" gutterBottom>
                Cambios en la Política de Privacidad
              </Title>
              <Typography variant="body2" gutterBottom>
                Nos reservamos el derecho de modificar esta política. Cualquier cambio será comunicado a través de nuestra plataforma y por correo electrónico.
              </Typography>

              <Title variant="h5" gutterBottom>
                Contacto
              </Title>
              <Typography variant="body2" gutterBottom>
                Si tiene preguntas sobre esta política o sobre cómo gestionamos sus datos personales, contáctenos a support@soubid.com o al +56959856601.
              </Typography>

              <Title variant="h5" gutterBottom>
                Términos y Condiciones para el Uso de Soubid
              </Title>
              <Typography variant="body2" gutterBottom>
                Al utilizar Soubid, usted acepta los siguientes términos y condiciones. Estos términos regulan su acceso y uso de nuestra plataforma y servicios.
              </Typography>

              <Title variant="h5" gutterBottom>
                Uso del Servicio
              </Title>
              <Typography variant="body2" gutterBottom>
                Debe registrar una cuenta y mantener la precisión de la información proporcionada. Soubid tiene el derecho de suspender o terminar cuentas por uso inapropiado o inactividad.
              </Typography>

              <Title variant="h5" gutterBottom>
                Licencia Limitada
              </Title>
              <Typography variant="body2" gutterBottom>
                Se le concede una licencia limitada, no exclusiva e intransferible para utilizar Soubid conforme a estos términos.
              </Typography>

              <Title variant="h5" gutterBottom>
                Propiedad Intelectual
              </Title>
              <Typography variant="body2" gutterBottom>
                Todos los derechos de propiedad intelectual relacionados con la plataforma y sus contenidos pertenecen a Soubid o a sus licenciantes.
              </Typography>

              <Title variant="h5" gutterBottom>
                Restricciones de Uso
              </Title>
              <Typography variant="body2" gutterBottom>
                No está permitido usar la plataforma para cualquier propósito ilegal, y debe abstenerse de intentar copiar, modificar o distribuir cualquier contenido de Soubid.
              </Typography>

              <Title variant="h5" gutterBottom>
                Terminación
              </Title>
              <Typography variant="body2" gutterBottom>
                Puede cerrar su cuenta en cualquier momento. Soubid también puede terminar o suspender el acceso a la plataforma por incumplimientos a estos términos.
              </Typography>

              <Title variant="h5" gutterBottom>
                Modificaciones a los Términos
              </Title>
              <Typography variant="body2" gutterBottom>
                Podemos modificar estos términos en cualquier momento, con notificación previa a través de la plataforma y/o correo electrónico.
              </Typography>

              <Title variant="h5" gutterBottom>
                Jurisdicción y Legislación Aplicable
              </Title>
              <Typography variant="body2" gutterBottom>
                Estos términos se rigen por la legislación local y cualquier disputa será resuelta en los tribunales competentes del país.
              </Typography>

              <Title variant="h5" gutterBottom>
                Contacto
              </Title>
              <Typography variant="body2" gutterBottom>
                Para preguntas o preocupaciones sobre estos términos, contáctenos a dev@soubid.com.
              </Typography>
            </Section>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoCanContactYou;
