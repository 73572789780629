import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, TextField, MenuItem, Select, Checkbox, FormControlLabel } from '@mui/material';
import { userAxios } from '../../apiClients';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { useAuth } from '../../hooks/useAuth';

const certificationType = [
  { id: 1, name: 'Experiencia Educativa', listName: 'EducationExperiences', endpointName: 'education' },
  { id: 2, name: 'Experiencia Profesional', listName: 'ProfessionalExperiences', endpointName: 'professional' },
  { id: 3, name: 'Idiomas', listName: 'IdiomExperiences', endpointName: 'idiom' },
  { id: 4, name: 'Publicaciones', listName: 'Publications', endpointName: 'publication' },
  { id: 5, name: 'Experiencia Académica', listName: 'AcademicExperiences', endpointName: 'academic' },
  { id: 6, name: 'Becas', listName: 'AcademicScholarships', endpointName: 'scholarship' },
  { id: 7, name: 'Experiencias Relevantes', listName: 'RelevantExperiences', endpointName: 'relevantExp' },
  { id: 8, name: 'Habilitación Internacional', listName: 'InternationalAccreditations', endpointName: 'intAccreditations' },
  { id: 9, name: 'Otros Antecedentes', listName: 'OtherBackgrounds', endpointName: 'other' }
];

function ComplaintModal({ open, handleClose, data }) {
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [certificationTypeID, setCertificationTypeID] = useState('');
  const [certifications, setCertifications] = useState([]);
  const [certificationID, setCertificationID] = useState('');
  const [complaintText, setComplaintText] = useState('');
  const [error, setError] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleSendComplaint = async () => {
    if (!certificationTypeID || !certificationID || !complaintText || !agree) {
      setError(true);
      return;
    }
    if (data?.id === user?.id) {
      showSnackbar('No puedes crear una denuncia sobre ti mismo', 'error');
      return;
    }

    const selectedType = certificationType.find(type => type.id === certificationTypeID);

    const complaintData = {
      userReceiverID: data?.id,
      userSenderID: user?.id,
      text: complaintText,
      certificationID: certificationID,
      certificationType: selectedType.endpointName,
      date: new Date().toISOString().slice(0, 10),
    };

    try {
      const response = await userAxios.post('/complain/create', complaintData);
      if (response.data.success) {
        showSnackbar('Denuncia recibida. Revisaremos el caso. Muchas gracias', 'success');
        handleClose();
      } else {
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error al crear la denuncia', error);
      showSnackbar('Error al crear la denuncia', 'error');
    }

    setCertificationTypeID('');
    setCertificationID('');
    setCertifications([]);
    setComplaintText('');
    setAgree(false);
    setError(false);
  };

  useEffect(() => {
    if (certificationTypeID && data) {
      const selectedType = certificationType.find(type => type.id === certificationTypeID);
      if (selectedType && data[selectedType.listName]) {
        const verifiedItems = data[selectedType.listName].filter(item => item.state === 'verified');
        const formattedCertifications = verifiedItems.map(item => {
          switch (selectedType.listName) {
            case 'EducationExperiences':
              return { id: item.id, name: `${item.educationType.charAt(0).toUpperCase() + item.educationType.slice(1)} en ${item.University?.name || 'Universidad desconocida'}, Graduado en ${item.endDate?.slice(0, 4) || 'Fecha desconocida'}` };
            case 'ProfessionalExperiences':
              return { id: item.id, name: `${item.position?.name || 'Posición desconocida'} en ${item.institution?.name || 'Institución desconocida'} (${item.startDate?.slice(0, 4) || 'Fecha desconocida'} - ${item.endDate !== 'present' ? item.endDate?.slice(0, 4) || 'Fecha desconocida' : 'Presente'})` };
            case 'IdiomExperiences':
              return { id: item.id, name: `${item.Idiom?.name || 'Idioma desconocido'} - ${item.CertificationIdiom?.name === 'None' ? 'Experiencia en otro país' : `Certificado por ${item.CertificationIdiom?.name || 'Certificador desconocido'}`}` };
            case 'Publications':
              return { id: item.id, name: `${item.name || 'Publicación desconocida'}, ${item.Editorial?.name || 'Editorial desconocida'} (${item.publicationDate?.slice(0, 4) || 'Fecha desconocida'})` };
            case 'AcademicExperiences':
              return { id: item.id, name: `${item.ProfessorType?.name || 'Tipo de profesor desconocido'} en ${item.University?.name || 'Universidad desconocida'} (${item.startDate?.slice(0, 4) || 'Fecha desconocida'} - ${item.endDate !== 'present' ? item.endDate?.slice(0, 4) || 'Fecha desconocida' : 'Presente'})` };
            case 'AcademicScholarships':
              return { id: item.id, name: `Beca en ${item.ScholarshipType?.name || 'Tipo de beca desconocido'}` };
            case 'RelevantExperiences':
              return { id: item.id, name: `Trabaja en ${item.Company?.name || 'Compañía desconocida'}, involucrado en ${item.MatterType?.name || 'Asunto desconocido'} como ${item.RelevantExpRole?.name || 'Rol desconocido'}` };
            case 'InternationalAccreditations':
              return { id: item.id, name: `Acreditado en ${item.Jurisdiction?.name || 'Jurisdicción desconocida'}` };
            case 'OtherBackgrounds':
              return { id: item.id, name: `${item.OtherPosition?.name || 'Posición desconocida'} en ${item.where || 'Lugar desconocido'}` };
            default:
              return { id: item.id, name: item.name || 'Antecedente desconocido' };
          }
        });
        setCertifications(formattedCertifications);
      } else {
        setCertifications([]);
      }
    } else {
      setCertifications([]);
    }
  }, [certificationTypeID, data]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="complaint-modal-title" aria-describedby="complaint-modal-description">
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2, maxHeight: '500px', overflowY: 'auto' }}>
        <Typography id="complaint-modal-title" variant="h5" component="h2">
          Denuncia
        </Typography>
        <Typography id="complaint-modal-description" sx={{ mt: 2 }}>
          Si has detectado alguna información incorrecta, equívoca o derechamente falsa, por favor denúnciala.
        </Typography>
        <Select
          fullWidth
          value={certificationTypeID}
          onChange={(e) => {
            setCertificationTypeID(e.target.value);
            setCertificationID('');
          }}
          displayEmpty
          sx={{ mt: 2 }}
          error={error && !certificationTypeID}
        >
          <MenuItem value="" disabled>Selecciona el tipo</MenuItem>
          {certificationType.filter(type => data?.[type.listName]?.some(exp => exp.state === 'verified')).map(type => (
            <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
          ))}
        </Select>
        <Select
          fullWidth
          value={certificationID}
          onChange={(e) => setCertificationID(e.target.value)}
          displayEmpty
          sx={{ mt: 2 }}
          error={error && !certificationID}
          disabled={!certificationTypeID}
        >
          <MenuItem value="" disabled>Selecciona el antecedente</MenuItem>
          {certifications.map(cert => (
            <MenuItem key={cert.id} value={cert.id}>{cert.name}</MenuItem>
          ))}
        </Select>
        <Typography id="complaint-modal-description" sx={{ mt: 2 }}>
          Describe el asunto.
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Escribe aquí..."
          value={complaintText}
          onChange={(e) => setComplaintText(e.target.value)}
          sx={{ mt: 2 }}
          error={error && !complaintText}
          helperText={error && !complaintText ? 'Este campo es obligatorio' : ''}
        />
        <Typography sx={{ mt: 2 }}>
          Esta denuncia es anónima. Por favor sé cuidadoso. Las denuncias infundadas serán sancionadas. 
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={agree} onChange={(e) => setAgree(e.target.checked)} />}
          label="De acuerdo"
          sx={{ mt: 2 }}
          error={error && !agree}
        />
        <Button fullWidth variant="primary" sx={{ mt: 2 }} onClick={handleSendComplaint}>
          Enviar
        </Button>
      </Box>
    </Modal>
  );
}

export default ComplaintModal;
