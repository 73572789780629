// SingUp.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, TextField, Button, Typography, styled } from '@mui/material';
import Logo from '../utils/LogoBlack.png';
import { signUp as signUpAws } from 'aws-amplify/auth';
import ConfirmMail from './ConfirmMail';
import { publicAxios } from '../apiClients';
import { useSnackbar } from '../components/snackbar/SnackbarContext';


const CustomTextField = styled(TextField)({
  // Tus estilos personalizados...
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '6px',
  },
  '& input::placeholder': {
    color: 'black', // Cambia este color según tus necesidades
    opacity: 0.75, // Asegura que el color se vea claramente
    fontSize: '16px', // Ajusta el tamaño de la fuente del placeholder
  },
});

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '400px',
  '& > :not(style)': { m: theme.spacing(1), width: '100%' }, // Aplica un margen a todos los hijos
}));

function SignUp() {
  const { showSnackbar } = useSnackbar();
  // Estado para cada campo de registro
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  // Manejadores de eventos para cada campo
  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleConfirmPasswordChange = (event) => setConfirmPassword(event.target.value);

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Contraseñas no coinciden!");
      return;
    }
    const mailIsValid = await publicAxios.get(`/users/validate-email/${email}`);
    if (!mailIsValid.data.success) {
      showSnackbar(mailIsValid.data.message, 'error');
      return;
    }
    try {
      const { isSignUpComplete, userId, nextStep } = await signUpAws({
        username: email,
        password,
        options: {
          autoSignIn: true
        }
      });
      const data = {
        name: firstName,
        lastName: lastName,
        email: email,
        cognitoUserID: userId
      }
      await publicAxios.post(`/users`, data);
      setShowConfirm(true); // Cambia a la vista de confirmación
    } catch (error) {
      showSnackbar(error.message, 'error');
      console.error('Error signing up:', error);
    }
  };

  if (showConfirm) {
    const userData = {
      email,
      password,
    }
    return <ConfirmMail userData={userData} />;
  }

  return (
    <Box sx={{
      minHeight: '700px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ height: 120, width: 120, mb: 4 }} />
      <FormContainer>
        <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mb: 4 }}>
          Create an account
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomTextField
            placeholder="Nombres"
            variant="outlined"
            value={firstName}
            onChange={handleFirstNameChange}
            sx={{ width: '48%' }} // Ajusta el ancho para que se ajuste al lado del apellido
          />
          <CustomTextField
            placeholder="Apellidos"
            variant="outlined"
            value={lastName}
            onChange={handleLastNameChange}
            sx={{ width: '48%' }} // Igual aquí
          />
        </Box>
        <CustomTextField
          placeholder="Correo electrónico"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={handleEmailChange}
        />
        <CustomTextField
          placeholder="Contraseña"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <CustomTextField
          placeholder="Confirmar contraseña"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
        <Button
          variant="primary"
          fullWidth
          sx={{ mt: 2, bgcolor: 'black', color: 'white' }}
          onClick={handleSignUp}
        >
          Crear cuenta
        </Button>
        {/* <Typography sx={{ mt: 2, color: 'white', textAlign: 'center' }}>
          Already have an account?
          <Link component={Link} to="/signin" sx={{ color: 'white' }}>
            Log in
          </Link>
        </Typography> */}
      </FormContainer>
    </Box>
  );
}

export default SignUp;
