import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import SelectOption from '../../SelectOption';
import { publicAxios } from '../../../apiClients';

function ScholarshipForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [formData, setFormData] = useState({
        scholarshipID: '',
        scholarshipName: '',
    });
    const [scholarshipTypes, setScholarshipTypes] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchScholarshipTypes = async () => {
            try {
                const response = await publicAxios.get(`/static-data/scholarship-types`);
                setScholarshipTypes(response.data);
            } catch (error) {
                setError(true);
            }
        };
        fetchScholarshipTypes();
    }, []);

    const handleSubmit = () => {
        if (!formData.scholarshipID || (formData.scholarshipID === 1 && !formData.scholarshipName)) {
            setError(true);
            return;
        }
        onAddAntecedent({
            id: antecedentType.id,
            antecedentType: antecedentType.name,
            scholarshipID: formData.scholarshipID,
            scholarshipName: formData.scholarshipName || scholarshipTypes.find(scholarship => scholarship.id === formData.scholarshipID).name,
        });
    };

    return (
        <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Agregar {antecedentType.name}
            </Typography>
            <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                <Grid item xs={12} sm={4}>
                    <Typography>Beca</Typography>
                    <SelectOption
                        options={scholarshipTypes}
                        onSelectionChange={(value) => setFormData({ ...formData, scholarshipID: value })}
                        name={'Selecciona una beca'}
                        width="100%"
                        selectedOption={formData.scholarshipID}
                        error={error && !formData.scholarshipID}
                        helperText={error && !formData.scholarshipID ? 'Este campo es obligatorio' : ''}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                <Button variant="primary" onClick={handleSubmit}>
                    Agregar Antecedente
                </Button>
            </Grid>
        </Box>
    );
}

export default ScholarshipForm;
