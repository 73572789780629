import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';
import image from '../utils/image.png'; // Asegúrate de que la ruta de la imagen es correcta.

// Define la animación usando keyframes de MUI
const logoloader = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// Componente funcional que devuelve el Loader centrado
const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed', // Usar fixed para posicionar relativo a la ventana del navegador
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.5)', // Opcional: un fondo ligero si necesitas oscurecer el fondo detrás del loader
        zIndex: 1500, // Asegurarse de que está encima de otros elementos
      }}
    >
      <Box
        component="img"
        src={image}
        alt="Loading..."
        sx={{
          animation: `${logoloader} 1s ease-in-out infinite`,
          borderRadius: '50%',
          height: '6rem',
          width: '6rem',
          display: 'inline-block',
        }}
      />
    </Box>
  );
};

export default Loading;
