import React, { useState, useEffect } from 'react';
import { Grid, Box, Avatar, Button, Typography, TextField, Input, Divider, useMediaQuery } from '@mui/material';
import image from '../../utils/LogoBlack.png';
import SelectOption from '../../components/SelectOption';
import { userAxios, publicAxios } from '../../apiClients';
import Loading from '../../components/Loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileUploadStatus from '../../components/FileUpload';
import EmailChipsInput from '../../components/EmailChipsInput';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';
import { useAuth } from '../../hooks/useAuth';
import BackButton from '../../components/BackButton';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getFullDate } from '../../utils/Validators';

function UploadPublication() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, description, experience } = location.state || {};
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [publicationTypes, setPublicationTypes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editorials, setEditorials] = useState([]);
  const [publicationType, setPublicationType] = useState('');
  const [publicationTitle, setPublicationTitle] = useState('');
  const [editorial, setEditorial] = useState('');
  const [publicationDate, setPublicationDate] = useState(null);
  const [role, setRole] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [editorialName, setEditorialName] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [emails, setEmails] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);

  useEffect(() => {
    if (user) {
      if (user?.userRole === 'institutional' && localStorage.getItem('paramId')) {
        setUserProfileId(localStorage.getItem('paramId'));
      } else {
        setUserProfileId(user.id);
      }
    }
  }, [user]);

  useEffect(() => {
    // Hace scroll al inicio al montar el componente
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const responsePublicationTypes = await publicAxios.get(`/static-data/publication-types`);
        const responsePublicationRoles = await publicAxios.get(`/static-data/publication-roles`);
        const responseEditorials = await publicAxios.get(`/static-data/editorials`);
        setPublicationTypes(responsePublicationTypes.data);
        setRoles(responsePublicationRoles.data);
        setEditorials(responseEditorials.data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (experience) {
      setPublicationType(experience.PublicationType?.id || '');
      setPublicationTitle(experience.name || '');
      setEditorial(experience.Editorial?.id || '');
      setPublicationDate(experience.publicationDate ? new Date(experience.publicationDate) : null);
      setRole(experience.PublicationRole?.id || '');
      setUrlInput(experience.url || '');
      setEditorialName(experience.editorialName || '');
    }
  }, [experience]);

  const handleSendPublication = async () => {
    setIsLoading(true);
    if (!publicationType || !publicationTitle || !editorial || !publicationDate || !role || !urlInput || (editorial === 1 && !editorialName)) {
      setIsLoading(false);
      setError(true);
      return;
    }

    const publicationData = new FormData();
    publicationData.append('userID', userProfileId);
    publicationData.append('publicationTypeID', publicationType);
    publicationData.append('name', publicationTitle);
    publicationData.append('editorialID', editorial);
    publicationData.append('editorialName', editorialName);
    publicationData.append('publicationDate', getFullDate(publicationDate));
    publicationData.append('publicationRoleID', role);
    publicationData.append('url', urlInput);
    publicationData.append('documentType', 'publication');
    if (emails.length > 0) {
      publicationData.append('emails', JSON.stringify(emails));
    }

    try {
      let response;
      if (experience) {
        publicationData.append('state', 'pending');
        response = await userAxios.patch(`/publication/${experience.id}`, publicationData);
      } else {
        response = await userAxios.post(`/publication`, publicationData);
      }

      if (response.data.success) {
        setIsLoading(false);
        const message = response.data.message === 'update' ? 'Certificación actualizada con éxito' : 'Certificación creada con éxito';
        showSnackbar(message, 'success');
        navigate('/choose-document', { replace: true });


      } else {
        setIsLoading(false);
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al crear o actualizar la certificación', error);
      showSnackbar('Error al crear o actualizar la certificación', 'error');
    }
  };

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2} direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <BackButton path={"/choose-document"} back={true} />
          </Grid>
          <Grid item container spacing={2} alignItems="center" >
            <Grid item xs={12} sm={6} >
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item xs={12} >
                  <Box sx={{ display: 'flex', alignItems: 'center', direction: 'column', mb: 0 }}>
                    <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', mb: 4 }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 4 }}>
                    {description}
                  </Typography>
                </Grid>
                <Grid item >
                  <Typography >
                    Tipo de publicación
                  </Typography>
                </Grid>
                <Grid item >
                  <SelectOption
                    options={publicationTypes}
                    onSelectionChange={setPublicationType}
                    name={'Escoge el tipo'}
                    width={isMobile ? "100%" : "70%"}
                    selectedOption={publicationType}
                    helperText={error && !publicationType ? 'Este campo es obligatorio' : ''}
                    error={error && !publicationType}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                <Grid item xs={6} sx={{ mt: 2 }} >
                  <Grid item >
                    <Typography >
                      Título de la publicación
                    </Typography>
                  </Grid>
                  <TextField
                    margin="normal"
                    sx={{ width: isMobile ? "100%" : "70%" }}
                    size="small"
                    id="referenceText"
                    label="Escribe el título aquí..."
                    value={publicationTitle}
                    onChange={(event) => handleInputChange(event, setPublicationTitle)}
                    error={error && !publicationTitle}
                    helperText={error && !publicationTitle ? '* Este campo es obligatorio' : ''}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item >
                  <Typography >
                    Revista o Editorial
                  </Typography>
                </Grid>
                <Grid item >
                  <SelectOption
                    options={editorials}
                    onSelectionChange={setEditorial}
                    name={'Escoge una'}
                    width={isMobile ? "100%" : "70%"}
                    selectedOption={editorial}
                    error={error && !editorial}
                    helperText={error && !editorial ? 'Este campo es obligatorio' : ''}
                  />
                </Grid>
              </Grid>
              {editorial === 1 && (
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                  <Grid item xs={6} sx={{ mt: 2 }} >
                    <Grid item >
                      <Typography >
                        Nombre de la Editorial o Revista (y País)
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: isMobile ? "100%" : "70%" }}
                      size="small"
                      id="referenceText"
                      label="Escribe aquí..."
                      value={editorialName}
                      onChange={(event) => handleInputChange(event, setEditorialName)}
                      error={error && !editorialName}
                      helperText={error && !editorialName ? 'Este campo es obligatorio' : ''}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item >
                  <Typography >
                    Rol en la publicación
                  </Typography>
                </Grid>
                <Grid item >
                  <SelectOption
                    options={roles}
                    onSelectionChange={setRole}
                    name={'Escoge el rol'}
                    width={isMobile ? "100%" : "70%"}
                    selectedOption={role}
                    error={error && !role}
                    helperText={error && !role ? '* Este campo es obligatorio' : ''}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                <Grid item sx={{ mt: 1 }}>
                  <Typography >
                    Fecha de publicación
                  </Typography>
                </Grid>
                <Grid item container xs={12} sx={{ mt: 2, mr: -2 }} >
                  <DatePicker
                    label="Selecciona la fecha"
                    value={publicationDate}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(newValue) => {
                      setPublicationDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    selectedOption={publicationDate}
                    />
                    {publicationDate === null && error && (
                      <Typography variant="caption" color="error" sx={{ mt: 1, ml: 2 }}>
                      * La fecha de publicación es obligatoria
                      </Typography>
                    )}                    
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }} >
                <Grid item container >
                  <Grid item xs={6} sx={{ mt: 2, mr: -2 }} >
                    <Grid item >
                      <Typography >
                        Adjunta Link
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: isMobile ? "100%" : "70%" }}
                      size="small"
                      id="referenceText"
                      placeholder="Escribe aquí..."
                      label="Link"
                      value={urlInput}
                      onChange={(event) => handleInputChange(event, setUrlInput)}
                      error={error && !urlInput}
                      helperText={error && !urlInput ? '* Este campo es obligatorio' : ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile &&
              <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
                <Avatar variant="rounded" src={image} sx={{ width: '200px', height: '200px' }} />
              </Grid>
            }
            <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }} >
              <Grid item sx={{ mb: 2 }}>
                <Typography >
                  Danos el Correo electrónico de personas que puedan ayudar con el proceso de verificación.
                </Typography>
              </Grid>
              <Grid item >
              <EmailChipsInput setEmails={setEmails} emails={emails} />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} sx={{ ml: isMobile ? 1 : 10, mb: 3 }} >
              <Button
                variant="primary"
                onClick={handleSendPublication}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>

  );
};

export default UploadPublication;
