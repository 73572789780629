import React, { useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useSnackbar } from '../components/snackbar/SnackbarContext';

const CountrySelector = ({ onChange }) => {
  const options = countryList().getData();
  const { showSnackbar } = useSnackbar();
  
  // Estado para controlar el país seleccionado
  const [selectedCountry, setSelectedCountry] = useState(options.find(option => option.value === 'CL'));

  // Manejar el cambio de selección
  const handleChange = (selectedOption) => {
    if (selectedOption.value !== 'CL') {
      showSnackbar('Por favor, selecciona el país de tu ubicación geográfica', 'error');
      // Revertir a Chile
      setSelectedCountry(options.find(option => option.value === 'CL'));
    } else {
      setSelectedCountry(selectedOption);
      onChange(selectedOption);  // Llama a la función onChange solo si es Chile
    }
  };

  return (
    <Select
      options={options}
      value={selectedCountry}  // Controlar el valor seleccionado
      onChange={handleChange}  // Usa la función handleChange
      getOptionLabel={(e) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={`https://flagcdn.com/w20/${e.value.toLowerCase()}.png`}
            alt=""
            style={{ marginRight: '8px' }}
          />
          <span style={{ fontSize: '14px' }}>{e.label}</span>
        </div>
      )}
      getOptionValue={(e) => e.value}
      placeholder="Selecciona país"
      isSearchable={false}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      styles={{
        control: (base) => ({
          ...base,
          backgroundColor: '#ffffff',
          borderWidth: '2px',
          minHeight: '30px',
          fontSize: '14px',
          padding: '4px 8px',
        }),
        option: (base) => ({
          ...base,
          display: 'flex',
          alignItems: 'center',
          padding: '4px 8px',
          color: '#000000', // Texto en negro
          backgroundColor: '#ffffff', // Fondo blanco
          fontSize: '14px',
          '&:hover': {
            backgroundColor: '#cc0000', // Fondo rojo al pasar el mouse
            color: '#ffffff', // Texto blanco al pasar el mouse
          },
        }),
        singleValue: (base) => ({
          ...base,
          color: '#000000', // Texto en negro
          display: 'flex',
          alignItems: 'center',
        }),
      }}
    />
  );
};

export default CountrySelector;
