// SnackbarContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import SnackbarCustomizado from './SnackbarCustomizado';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success',
  });

  const showSnackbar = (message, type) => {
    setSnackbar({ open: true, message, type });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, handleClose, snackbar }}>
      {children}
      <SnackbarCustomizado />
    </SnackbarContext.Provider>
  );
};
