import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Box, TextField, Button, Typography, IconButton, InputAdornment, useTheme, useMediaQuery } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Logo from '../utils/LogoBlack.png';
import { styled } from '@mui/material/styles';
import { useAuth } from '../hooks/useAuth';
import { publicAxios } from '../apiClients';
import { resendSignUpCode } from 'aws-amplify/auth';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import ConfirmMail from './ConfirmMail';
import Loading from '../components/Loading';

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '6px',
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    borderRadius: '6px',
  },
  '& .MuiInputAdornment-root': {
    backgroundColor: 'white',
  },
  '& .MuiIconButton-root': {
    padding: 15,
    color: 'black',
  },
  '& .MuiFormHelperText-root': {
    color: 'black', // Cambia el color del helper text
  },
  '& input::placeholder': {
    color: 'black', // Cambia este color según tus necesidades
    opacity: 0.75, // Asegura que el color se vea claramente
    fontSize: '16px', // Ajusta el tamaño de la fuente del placeholder
  },
});

const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '400px',
});

function SignIn() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const handleSignIn = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let hasError = false;
    if (!email) {
      setEmailError(true);
      hasError = true;
    }
    if (!password) {
      setPasswordError(true);
      hasError = true;
    }
    if (hasError) {
      setIsLoading(false);
      return;
    }

    const checkEmail = await publicAxios.get(`/back-office/check-email-mvp?email=${email}`);
    if (checkEmail.data.success) {
      if (checkEmail.data.preload) {
        // Perfil pre-creado sin reclamar
        setIsLoading(false);
        showSnackbar('Este correo tiene un perfil pre-creado', 'info');
        navigate(`/exclusive/${checkEmail.data.token}`);
        return;
      }
    }

    try {
      const response = await signIn({ email, password });
      if (!response) {
        setIsLoading(false);
        showSnackbar('Correo o contraseña inválida', 'error');
        return;
      }
      const { isSignedIn, nextStep } = response;
      if (isSignedIn) {
        setIsLoading(false);
        // navigate('/');
        const { from } = location.state || { from: { pathname: '/' } };
        navigate(from, { replace: true });
      } else if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        setIsLoading(false);
        await resendSignUpCode({ username: email });
        setShowConfirm(true);
      }
    } catch (error) {
      setIsLoading(false);
      showSnackbar(`Error: ${error.message}`, 'error');
    }
  };

  if (showConfirm) {
    const userData = { email, password };
    return <ConfirmMail userData={userData} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{
      minHeight: '80vh',
      minWidth: "100%",
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F67147',
      m: 0,
      p: 0,
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        p: 4
      }}>
        <Box
          component="img"
          sx={{
            height: '30%',
            width: '30%',
            mb: 2,
          }}
          alt="Logo"
          src={Logo}
        />
        <Typography variant="h3" sx={{ color: 'black', textAlign: 'center', maxWidth: "80vh", m: 0, p: 1 }}>
          The Rating Network
        </Typography>
        {!isMobile &&
          <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', maxWidth: "50vh", m: 0, p: 3 }}>
            Soubid es la primera Red Calificadora de Profesionales a nivel global. Funciona de manera descentralizada, con la participación activa de la comunidad. Aquí no hay conflictos de interés, prejuicios, ni subjetividades. Sólo ciencia.
          </Typography>
        }
      </Box>
      <Box sx={{
        width: isMobile ? 350 : 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: isMobile ? 2 : 4,
        flex: 1,
      }}>
        <FormContainer>
          <CustomTextField
            placeholder="Correo electrónico"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError ? 'Este campo es obligatorio' : ''}
          />
          <CustomTextField
            placeholder="Contraseña"
            variant="outlined"
            fullWidth
            margin="normal"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
            helperText={passwordError ? 'Este campo es obligatorio' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="primary"
            fullWidth
            sx={{ mt: 2, bgcolor: 'black', color: 'white' }}
            onClick={handleSignIn}
          >
            Iniciar Sesión
          </Button>
          <Link to="/forgot-password" variant="body2" style={{ color: 'white', marginTop: '10px' }}>
            ¿Olvidaste tu contraseña?
          </Link>
          <Button
            component={Link}
            to="/request-access"
            fullWidth
            variant="secondary"
            sx={{ mt: 2, color: 'white', borderColor: 'white' }}
          >
            Solicitar Acceso
          </Button>
        </FormContainer>
      </Box>
      {isMobile &&
        <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', maxWidth: "50vh", m: 0, p: 3 }}>
          Soubid es la primera Red Calificadora de Profesionales a nivel global. Funciona de manera descentralizada, con la participación activa de la comunidad. Aquí no hay conflictos de interés, prejuicios, ni subjetividades. Sólo ciencia.
        </Typography>
      }
    </Box>
  );
}

export default SignIn;
