import React from 'react';
import { Box, Button, Typography, styled } from '@mui/material';
import Logo from '../utils/LogoBlack.png'; // Asegúrate de que la ruta a tu imagen del logo sea correcta

const CustomButton = styled(Button)({
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgrey',
    },
    margin: '10px 8px',
    borderRadius: '6px',
    fontSize: '1.2rem', // Aumenta el tamaño del texto
    padding: '6px 80px', // Aumenta el padding
  });
  

export default function VideoCall() {
  return (
    <Box sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ mb: 4, height: 200, width: 200 }} />
      <Typography variant="h4" sx={{ color: 'black', mb: 2, maxWidth: "40ch", alignSelf: "center", textAlign: 'center' }}>
        To start the process we need to verify your identity through a videocall. Please have your ID at hand when joining one of the following sessions
      </Typography>
      <Box>
        <CustomButton variant="primary">Zoom</CustomButton>
        <CustomButton variant="primary">Meet</CustomButton>
        <CustomButton variant="primary">Teams</CustomButton>
      </Box>
    </Box>
  );
}
