import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Avatar, Divider, useMediaQuery } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { getRatingImageForType } from '../../utils/getRatingImages';

const ShowQualification = () => {
    const [showGift, setShowGift] = useState(true);
    const { user, checkCurrentUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { qualificationType } = location.state || {};
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const textQualification = qualificationType === 'academic' ? 'Académico' : 'Profesional';

    useEffect(() => {
        setShowGift(true);
        checkCurrentUser();
        setTimeout(() => {
            setShowGift(false);
        }, 10000);
    }, []);

    const handleGoProfile = async () => {
        await checkCurrentUser();
        navigate('/myprofile', { state: { userID: user.id } });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Box sx={{ width: '70%', padding: 4 }}>
                {/* <ProfileHeader
                    name={`${user?.name} ${user?.lastName}`}
                    location={user?.Country?.name ? `${user?.City?.name}, ${user?.Country?.name}` : ''}
                    imageUrl={user?.linkPhoto}
                    credits={user?.credits}
                    userRole={user?.userRole}
                    qualification={user?.qualification}
                    isCurrentUser={true}
                /> */}
                <Box sx={{ textAlign: 'left', my: 2 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>
                    Calificación {textQualification}  
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ bgcolor: 'gray', width: "2%" }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}>
                    {showGift ? (
                        <img src={require('../../assets/gifs/qualification.gif')} alt="search-grade-gif" style={{ width: '70%', height: '60%' }} />
                    ) : (
                        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Avatar
                                alt="Rating Image"
                                src={getRatingImageForType(qualificationType, user?.qualification[qualificationType]?.grade)}
                                sx={{ width: 300, height: 300 }}
                            />
                            {user?.qualification[qualificationType]?.grade.includes('A') ? (
                                <Typography variant="body2" sx={{ fontFamily: 'Poppins', mt:2 }}>
                                    Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>{user?.qualification[qualificationType]?.percentage || `..`}%</Typography> de abogados calificados en el país.
                                </Typography>
                            ) : (
                                <Typography variant="body2" sx={{ fontFamily: 'Poppins', mt:2 }}>
                                    Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>1%</Typography> de los abogados de su generación.
                                </Typography>
                            )}
                        </Box>
                    )}
                    <Button variant="primary" onClick={handleGoProfile} sx={{ mt: 3, backgroundColor: '#757575', color: 'white' }}>
                        Go to Profile
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ShowQualification;
