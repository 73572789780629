import React from 'react';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Logo from '../utils/LogoBlack.png';
import { useNavigate } from 'react-router-dom';

const NumberBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid black',
  borderRadius: '4px',
  width: '100px',
  height: '50px',
  backgroundColor: 'white',
});

const SmallNumberBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid black',
  borderRadius: '4px',
  width: '60px',
  height: '30px',
  backgroundColor: 'white',
});

const Divider = styled(Box)({
  width: '20%',
  height: '2px',
  backgroundColor: 'black',
  margin: '20px 0',
});

function RequestSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentQueueNumber, userQueueNumber } = location.state || {};

  const handleRequestAccess = () => {
    navigate('/');

  }

  return (
    <Box sx={{
      minHeight: '800px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
      padding: 3
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ height: 120, width: 120, mb: 4 }} />
      <Typography variant="h3" sx={{ color: 'black', textAlign: 'center', mb: 2 }}>
        The Rating Network
      </Typography>
      <Divider />
      <Grid container spacing={2} alignItems="center" justifyContent="center" marginY={5}>
        <Grid item>
          <Typography variant="h6" sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold'  }}>
            Tu número en cola:
          </Typography>
        </Grid>
        <Grid item>
          <NumberBox>
            <Typography variant="h5" sx={{ color: 'black' }}>
              {userQueueNumber}
            </Typography>
          </NumberBox>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
            Número actual en cola:
          </Typography>
        </Grid>
        <Grid item>
          <SmallNumberBox>
            <Typography variant="body2" sx={{ color: 'black' }}>
              {currentQueueNumber}
            </Typography>
          </SmallNumberBox>
        </Grid>
      </Grid>
      <Button
          variant="primary"
          fullWidth
          sx={{ mt: 15, bgcolor: 'black', color: 'white', width: '10%' }}
          onClick={handleRequestAccess}
        >
          Inicio
        </Button>
    </Box>
  );
}

export default RequestSuccess;
