import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Grid, Box, Avatar, Button, Typography, TextField, Divider, useMediaQuery } from '@mui/material';
import image from '../../utils/LogoBlack.png';
import SelectOption from '../../components/SelectOption';
import { userAxios, publicAxios } from '../../apiClients';
import Loading from '../../components/Loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileUploadStatus from '../../components/FileUpload';
import EmailChipsInput from '../../components/EmailChipsInput';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';
import { useAuth } from '../../hooks/useAuth';
import BackButton from '../../components/BackButton';
import { useLocation } from 'react-router-dom';
import { getFullDate } from '../../utils/Validators';
import { useNavigate } from 'react-router-dom';


function UploadOtherBackground() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, description, experience } = location.state || {};
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedPositionName, setSelectedPositionName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [urlInput, setUrlInput] = useState('');
  const [where, setWhere] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [emails, setEmails] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);

  useEffect(() => {
    if (user) {
      if (user?.userRole === 'institutional' && localStorage.getItem('paramId')) {
        setUserProfileId(localStorage.getItem('paramId'));
      } else {
        setUserProfileId(user.id);
      }
    }
  }, [user]);

  useEffect(() => {
    // Hace scroll al inicio al montar el componente
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const positionResponse = await publicAxios.get('/static-data/other-positions');
        setPositions(positionResponse.data);
      } catch (error) {
        console.error('Error fetching institutions', error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (experience) {
      setSelectedPosition(experience.OtherPosition?.id || '');
      setSelectedPositionName(experience.positionName || '');
      setStartDate(experience.startDate ? new Date(experience.startDate) : null);
      setEndDate(experience.endDate ? new Date(experience.endDate) : null);
      setUrlInput(experience.url || '');
      setWhere(experience.whereWork || '');
    }
  }, [experience]);

  function getValidationMessage() {
    if (startDate === null && endDate === null && error) {
      return <Typography variant="caption" color="error" >
      * Las fechas de inicio y finalización son obligatorias
      </Typography>
    } else if (startDate === null && error) {
      return <Typography variant="caption" color="error" >
      * La fecha de inicio es obligatoria
      </Typography>
    } else if (endDate === null && error) {
      return <Typography variant="caption" color="error" >
      * La fecha de finalización es obligatoria
      </Typography>
    }
    return "";
  }

  const validateURL = (url) => {
    return !url.includes('linkedin');
  };

  const handleSendOtherBackground = async () => {
    setIsLoading(true);
    if (!selectedPosition || !startDate || !endDate || (selectedPosition === 1 && !selectedPositionName)) {
      setIsLoading(false);
      setError(true);
      return;
    }
    if (!urlInput && !file) {
      setIsLoading(false);
      showSnackbar('Debes adjuntar un archivo o un link', 'error');
      return;
    }

    const otherBackgroundData = new FormData();
    otherBackgroundData.append('userID', userProfileId);
    otherBackgroundData.append('otherPositionID', selectedPosition);
    if (selectedPosition === 1) {
      otherBackgroundData.append('positionName', selectedPositionName);
    }
    if (emails.length > 0) {
      otherBackgroundData.append('emails', JSON.stringify(emails));
    }
    otherBackgroundData.append('whereWork', where);
    otherBackgroundData.append('startDate', getFullDate(startDate));
    otherBackgroundData.append('endDate', getFullDate(endDate));

    if (urlInput && validateURL(urlInput)) {
      otherBackgroundData.append('url', urlInput);
    }

    otherBackgroundData.append('documentType', 'otherBackground');
    if (file) {
      otherBackgroundData.append('file', file);
    }

    try {
      let response;
      if (experience) {
        otherBackgroundData.append('state', 'pending');
        response = await userAxios.patch(`/other-background/${experience.id}`, otherBackgroundData);
      } else {
        response = await userAxios.post('/other-background', otherBackgroundData);
      }

      if (response.data.success) {
        setIsLoading(false);
        const message = response.data.message === 'update' ? 'Certificación actualizada con éxito' : 'Certificación creada con éxito';
        showSnackbar(message, 'success');
        navigate('/choose-document', { replace: true });


      } else {
        setIsLoading(false);
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al crear o actualizar la certificación', error);
      showSnackbar('Error al crear o actualizar la certificación', 'error');
    }
  };

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2} direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <BackButton path={"/choose-document"} back={true} />
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', direction: 'column', mb: 0 }}>
                    <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 4 }}>
                    {description}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Posición
                  </Typography>
                </Grid>
                <Grid item>
                  <SelectOption
                    options={positions}
                    onSelectionChange={setSelectedPosition}
                    name={'Elija una posición'}
                    width={isMobile ? "100%" : "70%"}
                    selectedOption={selectedPosition}
                    helperText={error && !selectedPosition ? 'Este campo es obligatorio' : ''}
                    error={error && !selectedPosition}
                  />
                </Grid>
              </Grid>
              {selectedPosition === 1 && (
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                  <Grid item xs={6} sx={{ mt: 2 }} >
                    <Grid item >
                      <Typography >
                        Nombre de la Posición
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: isMobile ? "100%" : "70%" }}
                      size="small"
                      id="referenceText"
                      label="Escribe aquí..."
                      value={selectedPositionName}
                      onChange={(event) => handleInputChange(event, setSelectedPositionName)}
                      error={error && !selectedPositionName}
                      helperText={error && !selectedPositionName ? 'Este campo es obligatorio' : ''}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                <Grid item xs={6} sx={{ mt: 2 }}>
                  <Grid item>
                    <Typography>
                      ¿Dónde hiciste eso? (institución/empresa/organización)
                    </Typography>
                  </Grid>
                  <TextField
                    margin="normal"
                    sx={{ width: isMobile ? "100%" : "87%" }}
                    size="small"
                    id="referenceText"
                    label="Escribe aquí..."
                    value={where}
                    onChange={(event) => handleInputChange(event, setWhere)}
                    error={error && !where}
                    helperText={error && !where ? '* Este campo es obligatorio' : ''}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                <Grid item sx={{ mt: 1 }}>
                  <Typography>
                    Fecha de inicio
                  </Typography>
                </Grid>
                <Grid item container>
                  <Grid item sx={{ mt: 2, mr: 3 }}>
                    <DatePicker
                      label="Fecha de inicio"
                      value={startDate}
                      slotProps={{ textField: { size: 'small' } }}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      selectedOption={startDate}
                    />
                  </Grid>
                  <Grid item sx={{ mt: 2, mr: 3 }}>
                    <DatePicker
                      label="Fecha de término"
                      value={endDate}
                      slotProps={{ textField: { size: 'small' } }}
                      border="red"
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} />
                      )}
                      selectedOption={endDate}
                    />
                  </Grid>
                  <Grid item sx={{ mr: 3 }}>
                    {getValidationMessage()}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                <Grid item container>
                  <Grid item xs={6} sx={{ mt: 2, mr: -2 }}>
                    <Grid item>
                      <Typography>
                        Adjuntar Link
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: '87%' }}
                      size="small"
                      id="referenceText"
                      placeholder="Escribe aquí..."
                      label="Link"
                      value={urlInput}
                      onChange={(event) => handleInputChange(event, setUrlInput)}
                      helperText={error && urlInput && !validateURL(urlInput) ? 'URL no válida' : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} sx={{ mt: 2, mr: 3 }}>
                    <Grid item sx={{ mb: 2 }}>
                      <Typography>
                      Documento de respaldo
                      <Tooltip 
                          title="Para acreditar tu experiencia puedes utilizar cualquier antecedente escrito, como un contrato, un escrito judicial, recortes de prensa, etc. El formato también es libre, pudiendo ser fotografías, archivos .pdf, links, o incluso pantallazos ¡Bienvenida la creatividad!"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                maxWidth: isMobile ? '150px' : '400px', // Ancho según si es móvil
                                fontSize: isMobile ? '10px' : '13.15px',  // Tamaño de fuente según si es móvil
                                padding: isMobile ? '6px' : '11px',    // Padding según si es móvil
                                whiteSpace: 'normal', // Ajusta el texto al ancho del tooltip
                                textAlign: 'center',  // Centra el texto en el tooltip
                                backgroundColor: 'rgba(128, 128, 128, 0.975)',
                              },
                            },
                          }}
                        >
                          <HelpOutlineOutlinedIcon sx={{ml: 1}}/>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FileUploadStatus setFile={setFile} file={file} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile &&
              <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
                <Avatar variant="rounded" src={image} sx={{ width: '200px', height: '200px' }} />
              </Grid>
            }
            <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
              <Grid item sx={{ mb: 2 }}>
                <Typography>
                  Danos el Correo electrónico de personas que puedan ayudar con el proceso de verificación.
                </Typography>
              </Grid>
              <Grid item>
              <EmailChipsInput setEmails={setEmails} emails={emails} />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} sx={{ ml: isMobile ? 1 : 10, mb: 3 }}>
              <Button
                variant="primary"
                onClick={handleSendOtherBackground}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default UploadOtherBackground;