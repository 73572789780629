import React, { useState } from 'react';
import { Box, Button, Grid, Typography, styled, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../utils/LogoBlack.png';
import { publicAxios } from '../apiClients';
import { useSnackbar } from '../components/snackbar/SnackbarContext';

const NumberBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid black',
  borderRadius: '4px',
  width: '100px',
  height: '50px',
  backgroundColor: 'white',
});

const SmallNumberBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid black',
  borderRadius: '4px',
  width: '60px',
  height: '30px',
  backgroundColor: 'white',
});

const Divider = styled(Box)({
  width: '20%',
  height: '2px',
  backgroundColor: 'black',
  margin: '20px 0',
});

function RequestState() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [currentQueueNumber, setCurrentQueueNumber] = useState(null);
  const [userQueueNumber, setUserQueueNumber] = useState(null);
  const [step, setStep] = useState(1);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCheckQueue = async () => {
    try {
      const response = await publicAxios.post('/back-office/check-queue', { email });

      if (response.data.success) {
        const { currentQueueNumber, userQueueNumber } = response.data;
        setCurrentQueueNumber(currentQueueNumber);
        setUserQueueNumber(userQueueNumber);
        setStep(2);
      } else {
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error al consultar el estado de la cola:', error);
      showSnackbar('Error al consultar el estado de la cola.', 'error');
    }
  };

  const handleRequestAccess = () => {
    navigate('/');
  };

  return (
    <Box sx={{
      minHeight: '80vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
      padding: 3
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ height: 120, width: 120, mb: 4 }} />
      {step === 1 ? (
        <>
          <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mb: 4 }}>
            Consultar Estado de la Cola
          </Typography>
          <TextField
            label="Correo electrónico"
            variant="outlined"
            width="100%"
            value={email}
            onChange={handleEmailChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
              '& .MuiOutlinedInput-input': {
                color: 'black',
                backgroundColor: 'white',
                borderRadius: '6px',
              },
              marginBottom: 2,
              width: 400, // o el ancho que prefieras
              
            }}
          />
          <Button
            variant="primary"
            width="100%"
            sx={{ bgcolor: 'black', color: 'white' }}
            onClick={handleCheckQueue}
          >
            Consultar Estado
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h3" sx={{ color: 'black', textAlign: 'center', mb: 2 }}>
            The Rating Network
          </Typography>
          <Divider />
          <Grid container spacing={2} alignItems="center" justifyContent="center" marginY={5}>
            <Grid item>
              <Typography variant="h6" sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                Tu número en cola:
              </Typography>
            </Grid>
            <Grid item>
              <NumberBox>
                <Typography variant="h5" sx={{ color: 'black' }}>
                  {userQueueNumber}
                </Typography>
              </NumberBox>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                Número actual en cola:
              </Typography>
            </Grid>
            <Grid item>
              <SmallNumberBox>
                <Typography variant="body2" sx={{ color: 'black' }}>
                  {currentQueueNumber}
                </Typography>
              </SmallNumberBox>
            </Grid>
          </Grid>
          <Button
            variant="primary"
            fullWidth
            sx={{ mt: 15, bgcolor: 'black', color: 'white', width: '10%' }}
            onClick={handleRequestAccess}
          >
            Inicio
          </Button>
        </>
      )}
    </Box>
  );
}

export default RequestState;
