// Función para validar un URL
const isValidUrl = (urlString) => {
    try {
        const url = new URL(urlString);
        return url.protocol === "http:" || url.protocol === "https:" || url.protocol === "ftp:";
    } catch (error) {
        return false;
    }
};

// Función para validar si el valor es un número entero
const isValidInteger = (number) => {
    const num = parseInt(number);
    // Verifica que num sea un entero, no sea NaN, y que tenga exactamente 9 dígitos
    return !isNaN(num) && Number.isInteger(num) && num.toString() === number.trim() && number.trim().length === 9;
};


export const handleValidations = (contactNumber) => {
    const errors = {};

    if (contactNumber) {
        if (!isValidInteger(contactNumber)) {
            errors.contactNumber = "Número no válido";
        }
    }
    // if (linkPhoto) {
    //     if (!isValidUrl(linkPhoto)) {
    //         errors.linkPhoto = "URL no válida";
    //     }

    // }

    return errors;
}

export function getFullDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses empiezan en 0, por lo que debes sumar 1
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}
