import React from 'react';
import { Box, Drawer, IconButton, Avatar, Typography, List, ListItem, ListItemIcon, ListItemText, Button, useMediaQuery } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SettingsIcon from '@mui/icons-material/Settings';
import PhoneIcon from '@mui/icons-material/Phone';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SchoolIcon from '@mui/icons-material/School';
import styled from '@emotion/styled';
import ShareIcon from '@mui/icons-material/Share';
import scoin from '../../assets/scoin/scoin.png';
import { getBestQualification, getRatingImageForType } from '../../utils/functions';
import AlertTriangleIcon from '@mui/icons-material/Warning';
import { userAxios } from '../../apiClients';
import Search from '@mui/icons-material/Search';

const ProfileContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderBottom: '1px solid #ddd',
  position: 'relative',
  width: '100%',
  boxSizing: 'border-box',
});

const ProfileImage = styled(Avatar)({
  width: 100,
  height: 100,
  border: '2px solid #ddd',
});

const ProfileInfo = styled(Box)({
  textAlign: 'center',
  marginTop: 20,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ProfileName = styled(Typography)({
  fontWeight: 'bold',
});

const ProfileLocation = styled(Typography)({
  color: 'gray',
});

const CreditsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: 10,
  justifyContent: 'center',
});

const CoinIcon = styled('img')({
  width: 25,
  height: 25,
  marginRight: 5,
});

const RatingBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #ddd',
  borderRadius: '10px',
  padding: '10px',
  margin: '20px 0',
  width: '80%',
});

const RatingText = styled(Typography)({
  flex: 1,
  textAlign: 'center',
});

const FullWidthList = styled(List)({
  width: '100%',
});

const FullWidthListItem = styled(ListItem)({
  width: '100%',
});

const SideMenu = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  let userIsQualified = false;
  if (user) {
    userIsQualified = Object.keys(user?.qualification).length !== 0;
  }

  const handleSignOut = async () => {
    try {
      localStorage.removeItem('institutionUserIds');
      localStorage.removeItem('paramId');
      await signOut(); // Asegúrate de que el usuario se ha desconectado correctamente
      navigate('/'); // Navega a la página de inicio después del cierre de sesión
      onClose(); // Cierra cualquier modal u otro componente
    } catch (error) {
      console.error("Error signing out: ", error);
      // Manejo de errores si el cierre de sesión falla
    }
  };

  const handleSelected = (route, action) => {
    onClose();
    if (action) {
      action();
    }
    navigate(route);
  };


  const renderQualification = () => {
    if (userIsQualified) {
      const bestQualification = getBestQualification(user?.qualification);
      const imageQualification = getRatingImageForType(bestQualification.type, bestQualification.grade);
      return (
        <RatingBox>
          <img src={imageQualification} alt="Qualification" style={{ width: '80px', height: '80px', marginRight: '10px' }} />
          {bestQualification?.grade?.includes('A') ? (
            <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '80px' : '100px', fontSize: isMobile ? '0.5rem' : '0.7rem' }}>
              Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>{bestQualification?.percentage || `..`}%</Typography> de abogados calificados en el país.
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ fontFamily: 'Poppins', width: isMobile ? '80px' : '100px', fontSize: isMobile ? '0.5rem' : '0.7rem' }}>
              Dentro del <Typography component="span" sx={{ fontWeight: 'bold' }}>1%</Typography> de los abogados de su generación.
            </Typography>
          )}
          <IconButton>
            <ShareIcon />
          </IconButton>
        </RatingBox>
      );
    } else {
      return (
        <RatingBox>
          <AlertTriangleIcon sx={{ color: '#F67147', marginRight: '10px' }} />
          {user?.userRole === 'queue' ? (
            <RatingText>Aún no te has calificado</RatingText>
          ) : (
            <RatingText>Aún tienes que
              <Typography
                component="span"
                sx={{ fontWeight: 'bold', color: '#F67147', cursor: 'pointer' }}
                onClick={() => {
                  onClose();
                  navigate('/qualification');
                }}
              >
                {' '}calificarte
              </Typography>
            </RatingText>
          )}
        </RatingBox>
      );
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ sx: { width: isMobile ? '100%' : '35%' } }}>
      <Box>
        <IconButton onClick={onClose} sx={{ position: 'relative' }}>
          <CloseIcon />
        </IconButton>
        <ProfileContainer>
          <ProfileImage src={user?.linkPhoto} />
          <ProfileInfo>
            <ProfileName variant="h6">{user?.data.fullName}</ProfileName>
            <ProfileLocation variant="body2">{user?.place}</ProfileLocation>
              {user?.userRole !== 'institutional' && (
                <>
                <CreditsContainer>
                  <CoinIcon src={scoin} alt="coin" />
                  <Typography variant="h6">{user?.credits}</Typography>
                </CreditsContainer>
                <Button onClick={() => handleSelected('/myprofile')} variant="primary" sx={{ mt: 2 }}>Ver Perfil</Button>
                {renderQualification()}
                </>
              )}
          </ProfileInfo>
        </ProfileContainer>
        <FullWidthList>
          {isMobile && (
            <><FullWidthListItem button onClick={() => handleSelected('/directory')}>
              <ListItemIcon><Search /></ListItemIcon>
              <ListItemText primary="Directorio" />
            </FullWidthListItem>
            </>
          )}
          {user?.userRole !== 'institutional' && (
            <>
              <FullWidthListItem button onClick={() => handleSelected('/choose-document')}>
                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                <ListItemText primary="Subir antecedentes" />
              </FullWidthListItem>
              {user?.userRole !== 'queue' && (
                <>
                  <FullWidthListItem button onClick={() => handleSelected('/qualification')}>
                    <ListItemIcon><StarIcon /></ListItemIcon>
                    <ListItemText primary="Calificación" />
                  </FullWidthListItem>
                  <FullWidthListItem button onClick={() => handleSelected('/simulator')}>
                    <ListItemIcon><SchoolIcon /></ListItemIcon>
                    <ListItemText primary="Simulador" />
                  </FullWidthListItem>
                  <FullWidthListItem button onClick={() => handleSelected('/recommendations')}>
                    <ListItemIcon><ThumbUpIcon /></ListItemIcon>
                    <ListItemText primary="Recomendaciones" />
                  </FullWidthListItem>
                  <FullWidthListItem button onClick={() => handleSelected('/settings')}>
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                    <ListItemText primary="Ajustes y privacidad" />
                  </FullWidthListItem>
                  <FullWidthListItem button onClick={() => handleSelected('/question-answer')}>
                    <ListItemIcon><QuestionAnswerIcon /></ListItemIcon>
                    <ListItemText primary="Preguntas frecuentes" />
                  </FullWidthListItem>
                </>
              )}
            </>
          )}

          <FullWidthListItem button onClick={() => handleSelected('/contact')}>
            <ListItemIcon><PhoneIcon /></ListItemIcon>
            <ListItemText primary="Contacto" />
          </FullWidthListItem>
          <FullWidthListItem button onClick={handleSignOut}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="Cerrar sesión" />
          </FullWidthListItem>
        </FullWidthList>
      </Box>
    </Drawer>
  );
};

export default SideMenu;
