import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Box, TextField, Button, Typography, styled, Avatar, InputAdornment, IconButton, Modal, Paper } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Logo from '../utils/LogoBlack.png';
import { publicAxios } from '../apiClients';
import { signUp as signUpAws } from 'aws-amplify/auth';
import ConfirmMail from './ConfirmMail';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import Loading from '../components/Loading';

const customTextFieldStyles = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#EEF1F4',
    borderRadius: '6px',
    border: 'none',
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    padding: '18.5px 14px',
  },
  '& input::placeholder': {
    color: 'black',
    opacity: 0.75,
    fontSize: '16px',
  },
  '& .MuiInputAdornment-root': {
    backgroundColor: '#EEF1F4',
  },
  '& .MuiIconButton-root': {
    padding: 2,
    color: 'black',
  },
  '& .MuiFormHelperText-root': {
    color: 'black',
  },
};

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'none',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'none',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    backgroundColor: '#EEF1F4',
    borderRadius: '6px',
    border: 'none',
  },
  '& input::placeholder': {
    color: 'black',
    opacity: 0.75,
    fontSize: '16px',
  },
});

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '400px',
  '& > :not(style)': { m: theme.spacing(1), width: '100%' },
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '480px',
  maxHeight: '480px',
  width: '100%',
  position: 'relative',
  boxShadow: 'none',  // Eliminamos la sombra para evitar bordes
  border: 'none',  // Aseguramos que no haya bordes
  outline: 'none',  // Eliminamos cualquier outline que pueda aparecer en el foco
  '&:focus': {
    outline: 'none',  // Aseguramos que no haya ningún borde al recibir foco
  },
}));

function SignUpExclusive() {
  const { showSnackbar } = useSnackbar();
  const { token } = useParams();
  const [isValidToken, setIsValidToken] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [linkPhoto, setLinkPhoto] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [cognitoUserID, setCognitoUserID] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      setIsLoading(true);
      try {
        const response = await publicAxios.post('/users/verify-token', { token });
        if (response.data.success) {
          setFirstName(response.data?.user?.firstName);
          setLastName(response.data?.user?.lastName);
          setEmail(response.data?.user?.email);
          setLinkPhoto(response.data?.user?.linkPhoto);
          setIsValidToken(true);
          setIsLoading(false);
        } else { 
          setIsLoading(false);
          navigate('/invalid-token');
        }
      } catch (error) {
        setIsLoading(false);
        navigate('/invalid-token');
      }
    }

    verifyToken();
  }, [token, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenModal(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleSignUp = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!validatePassword(password)) {
      setIsLoading(false);
      return; 
    }
    try {
      const { userId } = await signUpAws({
        username: email,
        password,
        options: {
          autoSignIn: true
        }
      });
      if (userId) {
        setCognitoUserID(userId);
        setIsLoading(false);
        setShowConfirm(true);
      }
    }
    catch (error) {
      setIsLoading(false);
      showSnackbar('Código erróneo', 'error');
    }
  };

  const validatePassword = (password) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;

    if (password !== confirmPassword) {
      showSnackbar('Las contraseñas no coinciden', 'error');
      return false;
    }

    if (password.length < 8) {
      showSnackbar('La contraseña debe tener al menos 8 caracteres.', 'error', 'Error');
      return false;
    }
    
    if (!hasNumber.test(password)) {
      showSnackbar('La contraseña debe contener al menos un número.', 'error', 'Error');
      return false;
    }
    if (!hasSpecialChar.test(password)) {
      showSnackbar('La contraseña debe contener al menos un carácter especial.', 'error', 'Error');
      return false;
    }
    if (!hasUpperCase.test(password)) {
      showSnackbar('La contraseña debe contener al menos una letra mayúscula.', 'error', 'Error');
      return false;
    }
    if (!hasLowerCase.test(password)) {
      showSnackbar('La contraseña debe contener al menos una letra minúscula.', 'error', 'Error');
      return false;
    }
    return true;
  };

  if (!isValidToken) {
    return null;
  }

  if (showConfirm) {
    const userData = {
      firstName,
      lastName,
      email,
      password,
      token,
      cognitoUserID,
    };
    return <ConfirmMail userData={userData} type="exclusive" />;
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleConfirmPasswordChange = (event) => setConfirmPassword(event.target.value);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
    }}>
      <Avatar
        alt={'awd'}
        src={linkPhoto}
        sx={{
          width: '150px',
          height: '150px',
          marginRight: '16px',
          my:4,
          backgroundColor: 'white',
        }}
      />
      <FormContainer sx={{ mb: 5 }}>
        <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mb: 4 }}>
          Estás a un paso de unirte a Soubid
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomTextField
            placeholder="Nombres"
            variant="outlined"
            value={firstName}
            onChange={handleFirstNameChange}
            sx={{ width: '48%' }}
            disabled
          />
          <CustomTextField
            placeholder="Apellidos"
            variant="outlined"
            value={lastName}
            onChange={handleLastNameChange}
            sx={{ width: '48%' }} 
            disabled
          />
        </Box>
        <CustomTextField
          placeholder="Correo electrónico"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={handleEmailChange}
          disabled
        />
        <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.5)', textAlign: 'flex-start', position: 'relative', top: '-4px' }}>
          *Esta dirección de correo acredita su identidad
        </Typography>
        <CustomTextField
          placeholder="Contraseña"
          variant="outlined"
          fullWidth
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={customTextFieldStyles}
        />
        <CustomTextField
          placeholder="Confirmar Contraseña"
          variant="outlined"
          fullWidth
          margin="normal"
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={handleClickShowConfirmPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={customTextFieldStyles}
        />
        <Button
          variant="primary"
          fullWidth
          sx={{ mt: 2, bgcolor: 'black', color: 'white' }}
          onClick={handleSignUp}
        >
          Reclamar perfil
        </Button>
      </FormContainer>

      <StyledModal
        open={openModal}
        onClose={handleCloseModal}
      >
        <ModalContent>
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 12 }}
            onClick={handleCloseModal}
          >
            ✖
          </IconButton>
          <Typography variant="h6" sx={{ mb: 2, color: '#F67147', fontWeight: 'bold' }}>
            ¿Por qué usar Soubid?
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, textAlign: 'justify' }}>
            Soubid ayuda a los profesionales a posicionar su <strong>marca personal</strong> a partir del mérito individual. Sin subjetividades ni conflictos de interés, sino que a través de un modelo científico de calificación. 
            <br />
            <br />
            Los usuarios deben cargar sus antecedentes profesionales y académicos, los que luego son <strong>verificados y calificados</strong>.
          </Typography>
          <Typography variant="h6" sx={{ mb: 2, color: '#F67147', fontWeight: 'bold' }}>
            ¿Cómo resguardamos tus datos?
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'justify' }}>
            Utilizamos las técnicas más avanzadas de <strong>encriptación y seguridad</strong> en los datos personales de nuestros usuarios, para resguardarlos de terceros no autorizados.
          </Typography>
        </ModalContent>
      </StyledModal>
    </Box>
  );
}

export default SignUpExclusive;
