// userAxios.js
import axios from "axios";

const userAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "x-Request-Source": "user",
  },
});

userAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers["authorization"] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

userAxios.interceptors.response.use(
  (response) => {
    return response; // Si la respuesta es exitosa, simplemente la retornamos
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      // Aquí manejamos el error 403 mostrando una alerta
      alert('No tienes permiso para realizar esta acción.');
    }
    return Promise.reject(error); // Retorna el error para que las promesas sigan funcionando
  }
);

export default userAxios;