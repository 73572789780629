import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Button,
    Divider,
    Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const RatingsComparison = ({ qualification }) => {
    const navigate = useNavigate();

    const getImprovementMessage = () => {
        return "Para saber cómo mejorar, utiliza nuestro simulador. Ello te permitirá evaluar en abstracto tus distintas opciones profesionales y académicas, como en la búsqueda de un trabajo, o en la elección de Universidad.";
    };

    const handleGoToSimulator = () => {
        // agregar al local storage el path anterior
        localStorage.setItem('simulatorPath', window.location.pathname);
        navigate('/simulator');
    };

    return (
        <Box sx={{ padding: 2, borderRadius: 1, borderColor: '#ddd', borderWidth: 1, borderStyle: 'solid', margin: '20px 20px 0 20px' }}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>¿Qué significa tu calificación?</Typography>
                <Typography variant="h7" sx={{ color: '#333' }}>
                    Conoce el significado de las calificaciones {' '}
                    <Link component={RouterLink} to="/ratings" sx={{ color: '#F67147', textDecoration: 'none', fontWeight: 'bold' }}>
                        AQUÍ
                    </Link>
                </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>¿Cómo mejorar mi calificación?</Typography>
                <Typography variant="body1">{getImprovementMessage()}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                    variant="outlined"
                    sx={{ color: '#F67147', borderColor: '#F67147' }}
                    onClick={handleGoToSimulator}
                >
                    Ir al simulador
                </Button>
            </Box>
        </Box>
    );
};

export default RatingsComparison;
