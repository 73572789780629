import React from 'react';
import { Grid, Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function BackButtonProfile({ onClick, style = {} }) {
    
    return (
        <Grid item>
            <Button
                onClick={onClick}
                startIcon={<ArrowBackIosNewIcon />}
                sx={{
                    ...style, // Aplica los estilos adicionales pasados como prop
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontWeight: 'normal', // Ajusta el peso de la fuente como '400' para no-bold
                }}
            >
                Volver
            </Button>
        </Grid>
    );
}

export default BackButtonProfile;
