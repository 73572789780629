import React from 'react';
import { Box, Grid, Typography, Divider, useMediaQuery } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import logo from '../utils/LogoBlack.png';
import QRApp from '../assets/images/qr-app.png';

const Credits = () => {
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box>
      <Grid container alignItems="center" direction="column" sx={{ p: 4, backgroundColor: '#F67147', minHeight: 300 }}>
        <Grid item sx={{ mb: 2 }}>
          <Box component="img" src={logo} alt="Logo" sx={{
            height: { xs: 80, md: 120 },
            width: { xs: 80, md: 120 },
          }} />
        </Grid>
        <Grid item sx={{ textAlign: 'center' }}>
          <Typography variant="h5" sx={{ fontSize: { xs: '24px', md: '32px' }, color: '#fff', fontWeight: 'bold', mb: 2 }}>
            Tienes
          </Typography>
          <Box sx={{
            p: 2,
            backgroundColor: '#fff',
            borderRadius: 2,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            maxWidth: '300px',
            width: '100%',
            mx: 'auto'
          }}>
            <Typography variant="h4" sx={{ fontSize: { xs: '28px', md: '36px' }, color: '#F67147', fontWeight: 'bold' }}>
              {user.credits} Créditos
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4, mx: isMobile ? 0 : '10%' }}>
        <Grid container alignItems="center" sx={{ mb: 2 }}>
          <Grid item>
            <Divider orientation="vertical" sx={{ height: '40px', borderWidth: 2, borderColor: '#F67147', mr: 2 }} />
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ textAlign: 'left', fontSize: { xs: '24px', md: '32px' }, fontWeight: 'bold' }}>
              Productos
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body1" sx={{ ml: isMobile ? 0 : '2rem', mr: isMobile ? 0 : '10%', textAlign: 'left', fontSize: { xs: '18px', md: '22px' }, mb: 3 }}>
          Todas las actividades desplegadas en la plataforma que beneficien a la comunidad, generan una recompensa en créditos para el usuario. 
          Los créditos pueden ser utilizados en la App de Soubid, adquiriendo los productos y servicios que en ella se detallan.
        </Typography>
        <Grid 
          container 
          spacing={2} 
          sx={{ p: 2, justifyContent: 'center' }}
        >
          {[
            { icon: 'iconSubscription.png', title: 'Prioridad', description: 'Canjea tus créditos por mayor visibilidad en la plataforma, consiguiendo un mejor posicionamiento y fortaleciendo tu marca personal.' },
            { icon: 'iconApplied.png', title: 'Applied Law', description: 'Escribe en alguna de las áreas de práctica de The Legal Industry Reviews que se encuentren disponibles en tu país.' },
            { icon: 'iconDesk.png', title: 'International Desk', description: 'Lleva a tu firma al siguiente nivel, escribiendo en cualquiera de los cinco continentes, de la mano de The Legal Industry Reviews.' },
            { icon: 'iconCases.png', title: 'Featured Cases', description: 'Dale visibilidad a los logros de tu firma, publicándolos en la sección que The Legal Industry Reviews ofrece para hacerlo.' },
            { icon: 'challenges.png', title: 'Desafíos', description: 'Ponte a prueba y perfecciona tus conocimientos a través de las evaluaciones preparadas por los líderes de la industria.' }
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={2.4} key={index}>
              <Box sx={{ 
                p: 2, 
                backgroundColor: '#F3F3F3', 
                borderRadius: 2, 
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', // Align items at the top of the box
                height: '100%'
              }}>
                <Box 
                  component="img" 
                  src={require(`../assets/incentives/${item.icon}`)} 
                  alt={item.title} 
                  sx={{ height: 60, width: 60, mb: 2, minHeight: 60 }} // Set a minimum height for the icons
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: { xs: '18px', md: '20px' }, mb: 2 }}>
                  {item.title}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: { xs: '16px', md: '18px' }, textAlign: 'center' }}>
                  {item.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider sx={{ my: 4 }} />
      <Box sx={{ mt: 4, mx: isMobile ? 0 : '10%', textAlign: 'center' }}>
        <Typography variant="body1" sx={{ fontSize: { xs: '18px', md: '22px' } }}>
          Para mayor información, visita nuestra App
        </Typography>
        <Box 
          component="img" 
          src={QRApp} 
          alt="QR App" 
          sx={{ height: 100, width: 100, mt: 2, mb: 6 }} 
        />
      </Box>
    </Box>
  );
};

export default Credits;
