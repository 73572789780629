// PendingDocument.js
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import ProfileHeader from '../components/profile/ProfileHeader';
import { userAxios } from '../apiClients';
import ProfileBody from '../components/profile/ProfileBody';
import AcademicSection from '../components/profile/AcademicSection';
import { useAuth } from '../hooks/useAuth';
import PendingModal from '../components/pending/InfoModal';
import Loading from '../components/Loading';

function PendingDocument() {
  const { user } = useAuth();
  const id = user?.id;
  const [userData, setUserData] = useState({});
  const [selectedDocument, setSelectedDocument] = useState({});
  const [isModalPendingOpen, setIsModalPendingOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleModalPendingOpen = () => setIsModalPendingOpen(true);
  const handleModalPendingClose = () => setIsModalPendingOpen(false);

  const handleSeeMoreClick = (item) => {
    setSelectedDocument(item);
    handleModalPendingOpen(); // Suponiendo que este es el modal que deseas abrir.
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userAxios.get(`/users/solicitations?userID=${id}`);
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }


  return (
    <Container maxWidth="md" sx={{ width: "100%", mt: 4, height: 'auto', minHeight: '600px' }}> {/* Añade margen superior al container general */}
      <ProfileHeader
        name={userData?.name + " " + userData?.lastName}
        location={userData?.City?.name}
        grade={userData?.Qualification?.name}
        position="3"
        image={userData?.linkPhoto}
      />
      <ProfileBody
        title="Professional Experience"
        items={userData}
        pending={true}
        onSeeMoreClick={handleSeeMoreClick} // Pasas la función como prop
      />
      <PendingModal
        open={isModalPendingOpen}
        handleClose={handleModalPendingClose}
        item={selectedDocument}
      />
    </Container>
  );
}

export default PendingDocument;
