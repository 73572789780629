import React, { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, IconButton, Link, useMediaQuery, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import questions from '../filesjson/questions.json'; // Asegúrate de que la ruta sea correcta
import { useAuth } from '../hooks/useAuth';        // Asegúrate de que la ruta sea correcta
import { Link as RouterLink } from 'react-router-dom';

const QuestionAnswer = ({ onClose }) => {
    const { user } = useAuth();
    const [expanded, setExpanded] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePress = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const renderAnswer = (answer) => {
        if (answer.includes("Las notas son 45, y van desde AAA+")) {
            return (
                <Box>
                    <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>{answer}
                        <Box>
                            Para más detalle pinche {' '}
                            <Link component={RouterLink} to="/ratings" sx={{ color: '#F67147', textDecoration: 'none', fontWeight: 'bold' }}>
                                AQUÍ
                            </Link>
                        </Box>
                    </Typography>
                </Box>
            );
        }
        return <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>{answer}</Typography>;
    };

    const filteredQuestions = questions.filter((question) =>
        question.question.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', p: 2, bgcolor: '#fff', maxWidth: '1400px', width: '100%', margin: 'auto', mt: 5 }}>
            <Box sx={{
                flexBasis: isMobile ? '100%' : '25%',
                pr: isMobile ? 0 : 2,
                position: isMobile ? 'relative' : 'fixed',
                left: isMobile ? '0' : 0,
                top: isMobile ? '0' : '50%',
                transform: isMobile ? 'none' : 'translateY(-50%)',
                width: isMobile ? '100%' : '400px',
                bgcolor: '#fff',
                p: isMobile ? 2 : 4,
                borderRadius: 2,
                boxShadow: isMobile ? 0 : 3,
                border: isMobile ? 'none' : `2px solid #F67147`,
                mb: isMobile ? 2 : 0,
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                    <IconButton disabled sx={{ color: '#F67147', fontSize: '3rem' }}>
                        <SearchIcon fontSize="large" sx={{ color: '#F67147', fontSize: '3rem' }} />
                    </IconButton>
                </Box>
                <TextField 
                    fullWidth 
                    label="Buscar preguntas..." 
                    variant="outlined" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                    sx={{
                        bgcolor: '#fff', 
                        borderRadius: 1, 
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#F67147',
                            },
                            '&:hover fieldset': {
                                borderColor: '#F67147',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#F67147',
                            },
                            fontSize: '1.2rem',
                        }
                    }}
                />
            </Box>
            <Box sx={{ flexBasis: isMobile ? '100%' : '75%', ml: isMobile ? 0 : '420px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                    <Box sx={{ height: 30, width: 4, bgcolor: '#F67147', mr: 2 }} />
                    <Typography variant="h4" sx={{ color: '#F67147', fontSize: '2.5rem', fontWeight: 'bold' }}>Preguntas Frecuentes</Typography>
                </Box>
                {filteredQuestions.map((question, index) => (
                    <Accordion 
                        expanded={expanded === index} 
                        onChange={handlePress(index)} 
                        key={index} 
                        sx={{ mb: 3, borderRadius: 2, bgcolor: '#f9f9f9', boxShadow: 2 }}
                    >
                        <AccordionSummary 
                            expandIcon={<ExpandMoreIcon sx={{ color: '#F67147', fontSize: '2rem' }} />}
                            sx={{ '& .MuiAccordionSummary-content': { flexDirection: 'column' } }}
                        >
                            <Typography 
                                sx={{ 
                                    fontWeight: 'bold', 
                                    fontSize: '1.3rem', 
                                    minWidth: '80%', 
                                    flexShrink: 0, 
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    display: 'inline'
                                }}
                            >
                                {question.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ fontSize: '1.2rem' }}>
                                {renderAnswer(question.answer)}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

            </Box>
        </Box>
    );
};

export default QuestionAnswer;
