import React, { useState } from 'react';
import { Box, TextField, Button, Typography, styled } from '@mui/material';
import Logo from '../utils/LogoBlack.png'; // Asegúrate de que la ruta a tu imagen del logo sea correcta
import { useNavigate } from 'react-router-dom';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import { useAuth } from '../hooks/useAuth';
import { publicAxios } from '../apiClients';
import Loading from '../components/Loading';

// Estilos personalizados para TextField y Button como en vistas anteriores
const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '6px',
  },
  '& input::placeholder': {
    color: 'black', // Cambia este color según tus necesidades
    opacity: 0.75, // Asegura que el color se vea claramente
    fontSize: '16px', // Ajusta el tamaño de la fuente del placeholder
  },
});

function ConfirmMail({ userData, type = 'request-access' }) {
  const { firstName, lastName, email, password, securityCode, token, cognitoUserID } = userData;
  const { showSnackbar } = useSnackbar();
  const [code, setCode] = useState('');
  const navigate = useNavigate(); // Obtener el objeto history
  const { signIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleNewCode = async () => {
    try {
      await resendSignUpCode({
        username: email
      });
      showSnackbar('Código enviado nuevamente', 'success');
    } catch (error) {
      console.error('Error al reenviar el código de verificación: ', error);
      showSnackbar('Error al reenviar el código', 'error');
    }
  }

  const handleConfirm = async (event) => {
    setIsLoading(true);
    try {
      // signOut()
      const { isSignUpComplete } = await confirmSignUp({
        username: email,
        confirmationCode: code
      });
      if (isSignUpComplete) {
        let response
        if (type === 'exclusive') {
          response = await publicAxios.post('/users/signup-exclusive', {
            firstName,
            lastName,
            email,
            securityCode,
            token,
            cognitoUserID,
          });

        } else if (type === 'invite') {
          response = await publicAxios.post('/users/signup-invite', {
            firstName,
            lastName,
            email,
            securityCode,
            cognitoUserID,
          });

        } else if (type === 'request-access') {
          response = await publicAxios.post('/users/signup-request-access', {
            firstName,
            lastName,
            secondLastName: userData.secondLastName,
            email,
            professionID: userData.professionID,
            cognitoUserID,
          });
        }
        if (response.data.success) {
          const { isSignedIn } = await signIn({ email: email, password: password });
          if (isSignedIn) {
            setIsLoading(false);
            showSnackbar('Tu cuenta ha sido verificada', 'success');
            if (type === 'request-access') {
              navigate('/');
            } else {
              navigate('/onboarding');
            }
          }
      }
      } else {
        setIsLoading(false);
        showSnackbar('Por favor, intentalo nuevamente. Recuerda que puedes pedir otro código.', 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al analizar el token, por favor inicia sesión', error);
      showSnackbar('Por favor, intentalo nuevamente. Recuerda que puedes pedir otro código.', 'error');
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{
      flex: 1,
      minHeight: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
      padding: '0 16px', // Padding para mejorar la vista en móviles
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{
        height: { xs: 100, md: 200 }, // Tamaño del logo más pequeño en móviles
        width: { xs: 100, md: 200 }, // Tamaño del logo más pequeño en móviles
        mb: 2,
      }} />
      <Typography variant="h5" sx={{ color: 'white', mb: 2, textAlign: 'center' }}>
        ¡Te has registrado con éxito en Soubid! 
      </Typography>
      <Typography variant="subtitle1" sx={{ color: 'white', mb: 4, textAlign: 'center' }}>
        Te hemos enviado un código de verificación a tu correo electrónico. Por favor ingrésalo a continuación.
      </Typography>
      <CustomTextField
        placeholder="Ingresa el código de verificación"
        variant="outlined"
        value={code}
        onChange={handleCodeChange}
        sx={{
          mb: 2,
          width: { xs: '100%', sm: 'auto' }, // Ajustar ancho en móviles
          'input': {
            color: 'black',
            backgroundColor: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
          },
          borderRadius: '6px',
        }}
      />
      <Button
        variant="primary"
        onClick={handleConfirm}
        sx={{
          bgcolor: 'black',
          color: 'white',
          '&:hover': {
            bgcolor: 'darkgrey',
          },
          borderRadius: '6px',
        }}
      >
        Ingresar
      </Button>
      <Typography sx={{ mt: 2, color: 'white', textAlign: 'center' }}>
        ¿No recibiste el código?
        <Button
          variant="text"
          fullWidth
          sx={{
            color: 'black',
            textTransform: 'none',
            '&:hover': {
              bgcolor: 'transparent',
              textDecoration: 'underline',
            },
          }}
          onClick={handleNewCode}
        >
          <Typography variant="body2">Reenviar código</Typography>
        </Button>
      </Typography>
    </Box>
  );
}

export default ConfirmMail;
