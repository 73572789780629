import React, { useState } from 'react';
import { Box, Modal, Typography, Button, TextField, Alert, useMediaQuery } from '@mui/material';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import PhotoUpload from '../components/PhotoUpload';
import { userAxios } from '../apiClients';
import { useAuth } from '../hooks/useAuth';

const PhotoUploadModal = ({ modalVisible, setModalVisible }) => {
    const { user, setUser } = useAuth();
    const [file, setFile] = useState(null);
    const { showSnackbar } = useSnackbar();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const handlePictureUpload = async () => {
        if (!file) {
            showSnackbar('Por favor sube una imagen', 'error');
            return;
        }

        const userData = new FormData();
        userData.append('id', user?.id);

        if (file) {
            userData.append('documentType', 'profileImage');
            userData.append('file', file);
        }

        try {
            const response = await userAxios.patch(`/users/update-profile`, userData);
            if (response.data.success) {
                showSnackbar('Tu información se guardó exitosamente', 'success');
                if (response.data.user.linkPhoto) {
                    setUser((prevUser) => ({
                        ...prevUser,
                        linkPhoto: response.data.user.linkPhoto,
                    }));
                }
                setModalVisible(false);
            } else {
                console.error(userData);
                console.log(response.data)
                showSnackbar(response.data, 'error');
            }
        } catch (error) {
            console.error('Error al guardar tu información', error);
            showSnackbar('Error al guardar tu información', 'error');
        }
    };

    return (
        <Modal
            open={modalVisible}
            onClose={() => setModalVisible(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isMobile ? '90%' : '30%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography id="modal-title" variant="h5" component="h2" sx={{ mb: 2, fontWeight:'bold' }}>
                        Subir Foto
                    </Typography>
                </Box>
                <Typography id="modal-description" sx={{ mb: 4, textAlign: 'center' }}>
                    Para actualizar tu foto de perfil, elige una imagen de tu galería o toma una nueva foto.
                </Typography>
                <Box>
                    <PhotoUpload setFile={setFile} file={file} onClose={() => setModalVisible(false)} />
                </Box>
                <Button
                    fullWidth
                    variant="primary"
                    sx={{ mt: 2 }}
                    onClick={handlePictureUpload}
                >
                    Guardar
                </Button>
                <Button
                    fullWidth
                    variant="secondary"
                    sx={{ mt: 2 }}
                    onClick={() => setModalVisible(false)}
                >
                    Cancelar
                </Button>
            </Box>
        </Modal>
    );
};

export default PhotoUploadModal;
