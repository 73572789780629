import React, { useState, useEffect } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Grid, Box, Avatar, Button, Typography, TextField, Input, Divider, useMediaQuery } from '@mui/material';
import image from '../../utils/LogoBlack.png';
import SelectOption from '../../components/SelectOption';
import { userAxios, publicAxios } from '../../apiClients';
import Loading from '../../components/Loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EmailChipsInput from '../../components/EmailChipsInput';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';
import { useAuth } from '../../hooks/useAuth';
import BackButton from '../../components/BackButton';
import { useLocation } from 'react-router-dom';
import { getFullDate } from '../../utils/Validators';
import { useNavigate } from 'react-router-dom';

function UploadRanking() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, description, experience } = location.state || {};
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [rankings, setRankings] = useState([]);
  const [selectedRanking, setSelectedRanking] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [urlInput, setUrlInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [userProfileId, setUserProfileId] = useState(null);
  const [rankingBands, setRankingBands] = useState([]);
  const [selectedBands, setSelectedBands] = useState([]);
  const [selectedBand, setSelectedBand] = useState('');

  useEffect(() => {
    if (user) {
      if (user?.userRole === 'institutional' && localStorage.getItem('paramId')) {
        setUserProfileId(localStorage.getItem('paramId'));
      } else {
        setUserProfileId(user.id);
      }
    }
  }, [user]);

  useEffect(() => {
    const fetchRankings = async () => {
      setIsLoading(true);
      try {
        const response = await publicAxios.get('/static-data/rankings');
        setRankings(response.data);
        const bandsResponse = await publicAxios.get(`/static-data/ranking-band`);
        setRankingBands(bandsResponse.data);
      } catch (error) {
        console.error('Error fetching rankings', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRankings();
  }, []);

  useEffect(() => {
    if (selectedRanking) {
      const bands = rankingBands.filter(band => band.rankingID === selectedRanking);
      setSelectedBands(bands);
    }
  }, [selectedRanking]);

  useEffect(() => {
    if (experience) {
      setSelectedRanking(experience.Ranking?.id || '');
      setStartDate(experience.date ? new Date(experience.date) : null);
      setUrlInput(experience.url || '');
    }
  }, [experience]);

  const handleSendRankingNomination = async () => {
    setIsLoading(true);
    if (!selectedRanking || !urlInput || (!selectedBand && (selectedRanking === 1 || selectedRanking === 3))) {
      setIsLoading(false);
      setError(true);
      return;
    }

    const rankingData = new FormData();
    rankingData.append('userID', userProfileId);
    rankingData.append('rankingID', selectedRanking);
    if (selectedBand) {
      rankingData.append('rankingBandID', selectedBand);
    }
    rankingData.append('url', urlInput);
    rankingData.append('date', getFullDate(startDate));

    try {
      let response;
      if (experience) {
        rankingData.append('state', 'pending');
        response = await userAxios.patch(`/ranking/${experience.id}`, rankingData);
      } else {
        response = await userAxios.post(`/ranking`, rankingData);
      }

      if (response.data.success) {
        setIsLoading(false);
        const message = response.data.message === 'update' ? 'Certificación actualizada con éxito' : 'Certificación creada con éxito';
        showSnackbar(message, 'success');
        navigate('/choose-document', { replace: true });


      } else {
        setIsLoading(false);
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al crear o actualizar la certificación', error);
      showSnackbar('Error al crear o actualizar la certificación', 'error');
    }
  };

  function getValidationMessage() {
    if (startDate === null && error) {
      return <Typography variant="caption" color="error" sx={{ mt: 3 }}>
        * La fecha de publicación es obligatoria
      </Typography>
    }
    return ""; // No hay errores
  }

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2} direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <BackButton path={"/choose-document"} back={true} />
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', direction: 'column', mb: 0 }}>
                    <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 4 }}>
                    {description}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Ranking
                  </Typography>
                </Grid>
                <Grid item>
                  <SelectOption
                    options={rankings}
                    onSelectionChange={setSelectedRanking}
                    name={'Seleccionar el ranking'}
                    width={isMobile ? "100%" : "70%"}
                    selectedOption={selectedRanking}
                    helperText={error && !selectedRanking ? '* Este campo es obligatorio' : ''}
                    error={error && !selectedRanking}
                  />
                </Grid>
                {(selectedRanking === 1 || selectedRanking === 3) && (
                  <>
                    <Grid item>
                      <Typography>
                        Banda
                      </Typography>
                    </Grid>
                    <Grid item>
                      <SelectOption
                        options={selectedBands}
                        onSelectionChange={setSelectedBand}
                        name={'Seleccionar la banda'}
                        width={isMobile ? "100%" : "70%"}
                        selectedOption={selectedBand}
                        helperText={error && !selectedBand ? '* Este campo es obligatorio' : ''}
                        error={error && !selectedBand}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                <Grid item sx={{ mt: 1 }}>
                  <Typography >
                    ¿Cuándo fue rankeado?
                  </Typography>
                </Grid>
                <Grid item container >
                  <Grid item sx={{ mt: 2, mr: 3 }} >
                    <DatePicker
                      label="Selecciona la fecha"
                      value={startDate}
                      slotProps={{ textField: { size: 'small' } }}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      selectedOption={startDate}
                    />
                  </Grid>
                  {getValidationMessage()}
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                <Grid item container>
                  <Grid item xs={6} sx={{ mt: 2, mr: -2 }}>
                    <Grid item>
                      <Typography>
                        Adjuntar Link
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: '87%' }}
                      size="small"
                      id="referenceText"
                      placeholder="Escribe aquí..."
                      label="Link"
                      value={urlInput}
                      onChange={(event) => handleInputChange(event, setUrlInput)}
                      error={error && !urlInput}
                      helperText={error && !urlInput ? '* Este campo es obligatorio' : ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile &&
              <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
                <Avatar variant="rounded" src={image} sx={{ width: '200px', height: '200px' }} />
              </Grid>
            }
            <Grid container item justifyContent="center" xs={12} sx={{ ml: isMobile ? 1 : 10, mb: 3 }}>
              <Button
                variant="primary"
                onClick={handleSendRankingNomination}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}

export default UploadRanking;
