/* eslint-disable default-case */
export const getBestQualification = (userQualification) => {
  const types = ['professional', 'academic', 'student'];
  let bestType = null;
  let bestGrade = null;

  types.forEach(type => {
    if (userQualification[type]) {
      if (!bestGrade || compareGrades(userQualification[type].grade, bestGrade)) {
        bestGrade = userQualification[type].grade;
        bestType = type;
      }
    }
  });

  return { type: bestType, grade: bestGrade, percentage: userQualification[bestType]?.percentage };
};

export const getBestQualificationDirectory = (userQualification) => {
  let bestType = null;
  let bestGrade = null;
  if (!userQualification) return null;

  for (const [type, qualification] of Object.entries(userQualification)) {
    if (qualification.visible && (bestGrade === null || compareGrades(qualification.grade, bestGrade))) {
      bestGrade = qualification.grade;
      bestType = type;
    }
  }
  return bestType ? { [bestType]: bestGrade } : null;
};

const compareGrades = (grade1, grade2) => {
  const gradeOrder = [
    'E-', 'E', 'E+', 'EE-', 'EE', 'EE+', 'EEE-', 'EEE', 'EEE+',
    'D-', 'D', 'D+', 'DD-', 'DD', 'DD+', 'DDD-', 'DDD', 'DDD+',
    'C-', 'C', 'C+', 'CC-', 'CC', 'CC+', 'CCC-', 'CCC', 'CCC+',
    'B-', 'B', 'B+', 'BB-', 'BB', 'BB+', 'BBB-', 'BBB', 'BBB+',
    'A-', 'A', 'A+', 'AA-', 'AA', 'AA+', 'AAA-', 'AAA', 'AAA+'
  ];

  return gradeOrder.indexOf(grade1) < gradeOrder.indexOf(grade2);
};

export const getRatingProfessional = (rating) => {
  switch (rating) {
    case 'AAA+': return require('./../assets/qualificationProfessional/AAA+.png');
    case 'AAA': return require('./../assets/qualificationProfessional/AAA.png');
    case 'AAA-': return require('./../assets/qualificationProfessional/AAA-.png');
    case 'AA+': return require('./../assets/qualificationProfessional/AA+.png');
    case 'AA': return require('./../assets/qualificationProfessional/AA.png');
    case 'AA-': return require('./../assets/qualificationProfessional/AA-.png');
    case 'A+': return require('./../assets/qualificationProfessional/A+.png');
    case 'A': return require('./../assets/qualificationProfessional/A.png');
    case 'A-': return require('./../assets/qualificationProfessional/A-.png');
    case 'BBB+': return require('./../assets/qualificationProfessional/BBB+.png');
    case 'BBB': return require('./../assets/qualificationProfessional/BBB.png');
    case 'BBB-': return require('./../assets/qualificationProfessional/BBB-.png');
    case 'BB+': return require('./../assets/qualificationProfessional/BB+.png');
    case 'BB': return require('./../assets/qualificationProfessional/BB.png');
    case 'BB-': return require('./../assets/qualificationProfessional/BB-.png');
    case 'B+': return require('./../assets/qualificationProfessional/B+.png');
    case 'B': return require('./../assets/qualificationProfessional/B.png');
    case 'B-': return require('./../assets/qualificationProfessional/B-.png');
    case 'CCC+': return require('./../assets/qualificationProfessional/CCC+.png');
    case 'CCC': return require('./../assets/qualificationProfessional/CCC.png');
    case 'CCC-': return require('./../assets/qualificationProfessional/CCC-.png');
    case 'CC+': return require('./../assets/qualificationProfessional/CC+.png');
    case 'CC': return require('./../assets/qualificationProfessional/CC.png');
    case 'CC-': return require('./../assets/qualificationProfessional/CC-.png');
    case 'C+': return require('./../assets/qualificationProfessional/C+.png');
    case 'C': return require('./../assets/qualificationProfessional/C.png');
    case 'C-': return require('./../assets/qualificationProfessional/C-.png');
    case 'DDD+': return require('./../assets/qualificationProfessional/DDD+.png');
    case 'DDD': return require('./../assets/qualificationProfessional/DDD.png');
    case 'DDD-': return require('./../assets/qualificationProfessional/DDD-.png');
    case 'DD+': return require('./../assets/qualificationProfessional/DD+.png');
    case 'DD': return require('./../assets/qualificationProfessional/DD.png');
    case 'DD-': return require('./../assets/qualificationProfessional/DD-.png');
    case 'D+': return require('./../assets/qualificationProfessional/D+.png');
    case 'D': return require('./../assets/qualificationProfessional/D.png');
    case 'D-': return require('./../assets/qualificationProfessional/D-.png');
    case 'EEE+': return require('./../assets/qualificationProfessional/EEE+.png');
    case 'EEE': return require('./../assets/qualificationProfessional/EEE.png');
    case 'EEE-': return require('./../assets/qualificationProfessional/EEE-.png');
    case 'EE+': return require('./../assets/qualificationProfessional/EE+.png');
    case 'EE': return require('./../assets/qualificationProfessional/EE.png');
    case 'EE-': return require('./../assets/qualificationProfessional/EE-.png');
    case 'E+': return require('./../assets/qualificationProfessional/E+.png');
    case 'E': return require('./../assets/qualificationProfessional/E.png');
    case 'E-': return require('./../assets/qualificationProfessional/E-.png');
  }
};

export const getRatingAcademic = (rating) => {
  switch (rating) {
    case 'AAA+': return require('./../assets/qualificationAcademic/AAA+.png');
    case 'AAA': return require('./../assets/qualificationAcademic/AAA.png');
    case 'AAA-': return require('./../assets/qualificationAcademic/AAA-.png');
    case 'AA+': return require('./../assets/qualificationAcademic/AA+.png');
    case 'AA': return require('./../assets/qualificationAcademic/AA.png');
    case 'AA-': return require('./../assets/qualificationAcademic/AA-.png');
    case 'A+': return require('./../assets/qualificationAcademic/A+.png');
    case 'A': return require('./../assets/qualificationAcademic/A.png');
    case 'A-': return require('./../assets/qualificationAcademic/A-.png');
    case 'BBB+': return require('./../assets/qualificationAcademic/BBB+.png');
    case 'BBB': return require('./../assets/qualificationAcademic/BBB.png');
    case 'BBB-': return require('./../assets/qualificationAcademic/BBB-.png');
    case 'BB+': return require('./../assets/qualificationAcademic/BB+.png');
    case 'BB': return require('./../assets/qualificationAcademic/BB.png');
    case 'BB-': return require('./../assets/qualificationAcademic/BB-.png');
    case 'B+': return require('./../assets/qualificationAcademic/B+.png');
    case 'B': return require('./../assets/qualificationAcademic/B.png');
    case 'B-': return require('./../assets/qualificationAcademic/B-.png');
    case 'CCC+': return require('./../assets/qualificationAcademic/CCC+.png');
    case 'CCC': return require('./../assets/qualificationAcademic/CCC.png');
    case 'CCC-': return require('./../assets/qualificationAcademic/CCC-.png');
    case 'CC+': return require('./../assets/qualificationAcademic/CC+.png');
    case 'CC': return require('./../assets/qualificationAcademic/CC.png');
    case 'CC-': return require('./../assets/qualificationAcademic/CC-.png');
    case 'C+': return require('./../assets/qualificationAcademic/C+.png');
    case 'C': return require('./../assets/qualificationAcademic/C.png');
    case 'C-': return require('./../assets/qualificationAcademic/C-.png');
    case 'DDD+': return require('./../assets/qualificationAcademic/DDD+.png');
    case 'DDD': return require('./../assets/qualificationAcademic/DDD.png');
    case 'DDD-': return require('./../assets/qualificationAcademic/DDD-.png');
    case 'DD+': return require('./../assets/qualificationAcademic/DD+.png');
    case 'DD': return require('./../assets/qualificationAcademic/DD.png');
    case 'DD-': return require('./../assets/qualificationAcademic/DD-.png');
    case 'D+': return require('./../assets/qualificationAcademic/D+.png');
    case 'D': return require('./../assets/qualificationAcademic/D.png');
    case 'D-': return require('./../assets/qualificationAcademic/D-.png');
    case 'EEE+': return require('./../assets/qualificationAcademic/EEE+.png');
    case 'EEE': return require('./../assets/qualificationAcademic/EEE.png');
    case 'EEE-': return require('./../assets/qualificationAcademic/EEE-.png');
    case 'EE+': return require('./../assets/qualificationAcademic/EE+.png');
    case 'EE': return require('./../assets/qualificationAcademic/EE.png');
    case 'EE-': return require('./../assets/qualificationAcademic/EE-.png');
    case 'E+': return require('./../assets/qualificationAcademic/E+.png');
    case 'E': return require('./../assets/qualificationAcademic/E.png');
    case 'E-': return require('./../assets/qualificationAcademic/E-.png');
  }
};

export const getRatingStudent = (rating) => {
  switch (rating) {
    case 'AAA+': return require('./../assets/qualificationStudent/AAA.png');
    case 'AAA': return require('./../assets/qualificationStudent/AAA.png');
    case 'AAA-': return require('./../assets/qualificationStudent/AAA.png');
    case 'AA+': return require('./../assets/qualificationStudent/AA+.png');
    case 'AA': return require('./../assets/qualificationStudent/AA.png');
    case 'AA-': return require('./../assets/qualificationStudent/AA.png');
    case 'A+': return require('./../assets/qualificationStudent/A+.png');
    case 'A': return require('./../assets/qualificationStudent/A.png');
    case 'A-': return require('./../assets/qualificationStudent/A.png');
    case 'BBB+': return require('./../assets/qualificationStudent/BBB.png');
    case 'BBB': return require('./../assets/qualificationStudent/BBB.png');
    case 'BBB-': return require('./../assets/qualificationStudent/BBB.png');
    case 'BB+': return require('./../assets/qualificationStudent/BB+.png');
    case 'BB': return require('./../assets/qualificationStudent/BB.png');
    case 'BB-': return require('./../assets/qualificationStudent/BB.png');
    case 'B+': return require('./../assets/qualificationStudent/B+.png');
    case 'B': return require('./../assets/qualificationStudent/B.png');
    case 'B-': return require('./../assets/qualificationStudent/B.png');
    case 'CCC+': return require('./../assets/qualificationStudent/CCC.png');
    case 'CCC': return require('./../assets/qualificationStudent/CCC.png');
    case 'CCC-': return require('./../assets/qualificationStudent/CCC.png');
    case 'CC+': return require('./../assets/qualificationStudent/CC+.png');
    case 'CC': return require('./../assets/qualificationStudent/CC.png');
    case 'CC-': return require('./../assets/qualificationStudent/CC.png');
    case 'C+': return require('./../assets/qualificationStudent/C+.png');
    case 'C': return require('./../assets/qualificationStudent/C.png');
    case 'C-': return require('./../assets/qualificationStudent/C.png');
    case 'DDD+': return require('./../assets/qualificationStudent/DDD.png');
    case 'DDD': return require('./../assets/qualificationStudent/DDD.png');
    case 'DDD-': return require('./../assets/qualificationStudent/DDD.png');
    case 'DD+': return require('./../assets/qualificationStudent/DD+.png');
    case 'DD': return require('./../assets/qualificationStudent/DD.png');
    case 'DD-': return require('./../assets/qualificationStudent/DD.png');
    case 'D+': return require('./../assets/qualificationStudent/D+.png');
    case 'D': return require('./../assets/qualificationStudent/D.png');
    case 'D-': return require('./../assets/qualificationStudent/D.png');
    case 'EEE+': return require('./../assets/qualificationStudent/EEE.png');
    case 'EEE': return require('./../assets/qualificationStudent/EEE.png');
    case 'EEE-': return require('./../assets/qualificationStudent/EEE.png');
    case 'EE+': return require('./../assets/qualificationStudent/EE+.png');
    case 'EE': return require('./../assets/qualificationStudent/EE.png');
    case 'EE-': return require('./../assets/qualificationStudent/EE.png');
    case 'E+': return require('./../assets/qualificationStudent/E+.png');
    case 'E': return require('./../assets/qualificationStudent/E.png');
    case 'E-': return require('./../assets/qualificationStudent/E.png');
  }
};

export const getRatingImageForType = (type, grade) => {
  switch (type) {
    case 'professional':
      return getRatingProfessional(grade);
    case 'student':
      return getRatingStudent(grade);
    case 'academic':
      return getRatingAcademic(grade);
    default:
      return null;
  }
};

export const getRatingImage = (userQualification) => {
  if (!userQualification) return null
  const [type, grade] = Object.entries(userQualification)[0];

  switch (type) {
    case 'professional':
      return getRatingProfessional(grade);
    case 'student':
      return getRatingStudent(grade);
    case 'academic':
      return getRatingAcademic(grade);
    default:
      return null;
  }
};

export const checkUserBackground = (user) => {
  const educationTypes = ['magister', 'undergraduate', 'diplomat', 'title', 'course', 'doctorate'];

  // Verificar tipos de educación
  const educationResult = educationTypes.reduce((acc, type) => {
    acc[type] = user?.EducationExperiences?.some(exp => exp?.educationType === type);
    return acc;
  }, {});

  // Verificar experiencias profesionales
  const hasCurrent = user?.ProfessionalExperiences?.some(exp => exp.endDate === 'present');
  const hasPast = user?.ProfessionalExperiences?.some(exp => exp.endDate !== 'present');

  const hasCurrentTeaching = user?.AcademicExperiences?.some(exp => exp.endDate === 'present');
  const hasPastTeaching = user?.AcademicExperiences?.some(exp => exp.endDate !== 'present');

  // Crear el objeto final combinando todas las verificaciones
  return {
    publications: user.Publications && user.Publications.length > 0,
    otherBackground: user.OtherBackgrounds && user.OtherBackgrounds.length > 0,
    educationExperiences: user.EducationExperiences && user.EducationExperiences.length > 0,
    professionalExperiences: user.ProfessionalExperiences && user.ProfessionalExperiences.length > 0,
    academicExperiences: user.AcademicExperiences && user.AcademicExperiences.length > 0,
    relevantExperiences: user.RelevantExperiences && user.RelevantExperiences.length > 0,
    rankingNominations: user.RankingNominations && user.RankingNominations.length > 0,
    idiomExperiences: user.IdiomExperiences && user.IdiomExperiences.length > 0,
    academicScholarships: user.AcademicScholarships && user.AcademicScholarships.length > 0,
    internationalAccreditations: user.InternationalAccreditations && user.InternationalAccreditations.length > 0,
    ...educationResult,
    currentProfessional: hasCurrent,
    pastProfessional: hasPast,
    currentTeaching: hasCurrentTeaching,
    pastTeaching: hasPastTeaching,
  };
}

// emailValidation.js

const forbiddenDomains = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'icloud.com',
  'live.com',
  'aol.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
  'gmx.com',
  'mail.com',
];

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return 'El correo no está en formato correcto.';
  }

  const domain = email.split('@')[1].toLowerCase();
  if (forbiddenDomains.includes(domain)) {
    return 'No se permiten correos personales como Gmail, Yahoo, etc.';
  }

  return true; // El correo es válido y no pertenece a un dominio prohibido
};

export const processUserAntecedents = (data) => {

  const antecedentTypes = {
    1: { name: 'Título profesional', key: 'title' },
    2: { name: 'Pregrado', key: 'undergraduate' },
    3: { name: 'Doctorado', key: 'doctorate' },
    4: { name: 'Magíster', key: 'magister' },
    5: { name: 'Curso', key: 'course' },
    6: { name: 'Diplomado', key: 'diplomat' },
    7: { name: 'Beca', key: 'scholarship' },
    8: { name: 'Docencia pasada', key: 'teachingPast' },
    9: { name: 'Docencia actual', key: 'teachingCurrent' },
    10: { name: 'Publicación', key: 'publication' },
    11: { name: 'Habilitación internacional', key: 'internationalHabilitation' },
    12: { name: 'Idioma', key: 'idiom' },
    13: { name: 'Puesto anterior', key: 'previousPosition' },
    14: { name: 'Puesto actual', key: 'currentPosition' },
    15: { name: 'Experiencia relevante', key: 'relevantExperience' },
    16: { name: 'Ranking internacional', key: 'internationalRanking' },
    17: { name: 'Otros antecedentes', key: 'otherAntecedents' }
  };

  const verifiedAntecedents = [];

  // Procesar otros antecedentes de Educación
  const educationAntecedents = data.EducationExperiences?.filter(exp =>
    exp.state === 'verified'
  ) || [];
  educationAntecedents.forEach(exp => {
    if (exp.educationType === 'title' && exp.endDate) {
      verifiedAntecedents.push({
        id: 1,
        educationType: antecedentTypes[1].name,
        endDate: exp.endDate,
        verified: true,
      });
    } else if (exp.educationType && exp.University?.id && exp.University?.name && exp.startDate && exp.endDate) {
      const antecedentTypeId = Object.keys(antecedentTypes).find(key =>
        antecedentTypes[key].key === exp.educationType
      );
      if (antecedentTypeId) {
        verifiedAntecedents.push({
          id: parseInt(antecedentTypeId),
          universityID: exp.University.id,
          educationType: antecedentTypes[antecedentTypeId].name,
          startDate: exp.startDate,
          endDate: exp.endDate,
          universityName: exp.University.name,
          verified: true,
        });
      }
    }
  });

  // Procesar Experiencias Académicas
  const academicExperiences = data.AcademicExperiences?.filter(exp =>
    exp.state === 'verified'
  ) || [];
  academicExperiences.forEach(exp => {
    const antecedentTypeId = exp.endDate === 'present' ? 9 : 8;
    if (exp.University?.id && exp.University?.name && exp.ProfessorType?.id && exp.ProfessorType?.name && exp.TeachingDegree?.id && exp.startDate && exp.endDate) {
      verifiedAntecedents.push({
        id: antecedentTypeId,
        antecedentType: antecedentTypes[antecedentTypeId].name,
        universityID: exp.University.id,
        universityName: exp.University.name,
        professorTypeID: exp.ProfessorType.id,
        professorTypeName: exp.ProfessorType.name,
        teachingDegreeID: exp.TeachingDegree.id,
        startDate: exp.startDate,
        endDate: exp.endDate,
        verified: true,
      });
    }
  });

  // Procesar Idiomas
  const idiomExperiences = data.IdiomExperiences?.filter(exp =>
    exp.state === 'verified'
  ) || [];
  idiomExperiences.forEach(exp => {
    if (exp.Idiom?.name && exp.CertificationIdiom?.name) {
      verifiedAntecedents.push({
        id: 12,
        antecedentType: antecedentTypes[12].name,
        idiomName: exp.Idiom.name,
        certificationName: exp.CertificationIdiom.name,
        verified: true,
      });
    }
  });

  // Procesar Habilitaciones Internacionales
  const intAccreditations = data.InternationalAccreditations?.filter(acc =>
    acc.state === 'verified'
  ) || [];
  intAccreditations.forEach(acc => {
    if (acc.Jurisdiction?.name) {
      verifiedAntecedents.push({
        id: 11,
        antecedentType: antecedentTypes[11].name,
        jurisdictionName: acc.Jurisdiction.name,
        verified: true,
      });
    }
  });

  // Procesar Experiencias Profesionales
  const professionalExperiences = data.ProfessionalExperiences?.filter(exp =>
    exp.state === 'verified'
  ) || [];
  professionalExperiences.forEach(exp => {
    const antecedentTypeId = exp.endDate === 'present' ? 14 : 13;
    if (exp.institutionType === 'Independient') {
      verifiedAntecedents.push({
        id: antecedentTypeId,
        institutionType: exp.institutionType,
        startDate: exp?.startDate,
        endDate: exp?.endDate,
        verified: true,
      });
    } else if (exp.institutionType && exp.institution?.name && exp.institutionID && exp.position?.name && exp.positionID && exp.endDate) {
      verifiedAntecedents.push({
        id: antecedentTypeId,
        institutionType: exp.institutionType,
        publicServiceType: exp?.publicServiceType,
        institutionID: exp.institutionID,
        institutionName: exp.institution.name,
        positionID: exp.positionID,
        positionName: exp.position.name,
        startDate: exp.startDate,
        endDate: exp.endDate,
        verified: true,
      });
    }
  });

  // Procesar Publicaciones
  const publications = data.Publications?.filter(pub =>
    pub.state === 'verified'
  ) || [];
  publications.forEach(pub => {
    if (pub.PublicationType?.id && pub.PublicationType?.name && pub.Editorial?.id && pub.publicationDate && pub.PublicationRole?.id) {
      verifiedAntecedents.push({
        id: 10,
        antecedentType: antecedentTypes[10].name,
        publicationTypeID: pub.PublicationType.id,
        publicationTypeName: pub.PublicationType.name,
        editorialID: pub.Editorial.id,
        roleID: pub.PublicationRole?.id,
        publicationDate: pub.publicationDate,
        verified: true,
      });
    }
  });

  // Procesar Becas
  const scholarships = data.AcademicScholarships?.filter(sch =>
    sch.state === 'verified'
  ) || [];
  scholarships.forEach(sch => {
    if (sch.ScholarshipType?.id && sch.ScholarshipType?.name) {
      verifiedAntecedents.push({
        id: 7,
        antecedentType: antecedentTypes[7].name,
        scholarshipID: sch.ScholarshipType.id,
        scholarshipName: sch.ScholarshipType.name,
        verified: true,
      });
    }
  });

  // Procesar Rankings
  const rankings = data.RankingNominations?.filter(rn =>
    rn.state === 'verified'
  ) || [];
  rankings.forEach(rn => {
    if (rn?.category) {
      verifiedAntecedents.push({
        id: 16,
        antecedentType: antecedentTypes[16].name,
        rankingName: rn?.Ranking?.name,
        category: rn.category,
        date: rn?.date,
        verified: true,
      });
    }
  });

  return verifiedAntecedents;
}
