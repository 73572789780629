import React, { useState } from 'react';
import { Box, Typography, Button, Container, Modal, IconButton, Grid, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import Logo from '../utils/LogoBlack.png'; // Asegúrate de que la ruta a tu imagen del logo sea correcta
import { useAuth } from '../hooks/useAuth';
import AppleStoreBadge from '../assets/home/appstore.png'; // Asegúrate de que la ruta a la imagen sea correcta
import GooglePlayBadge from '../assets/home/google.png'; // Asegúrate de que la ruta a la imagen sea correcta
import QRApp from '../assets/images/qr-app.png'; // Asegúrate de que la ruta a la imagen sea correcta

const gifs = [
  {
    id: 1,
    source: require('../assets/gifs/networking.gif'),
    title: 'Rating Network',
    description: 'La red exclusiva para profesionales, donde tu calificación te abrirá oportunidades únicas. Únete ahora, colabora y posiciónate en el mercado.',
  },
  {
    id: 2,
    source: require('../assets/gifs/medallas.gif'),
    title: 'Verificación Cruzada',
    description: 'Los antecedentes son verificados, evitando fraudes, engaños y exageraciones. En Soubid cada usuario existe, y es quien dice ser.',
  },
  {
    id: 3,
    source: require('../assets/gifs/qualification.gif'),
    title: 'Calificación',
    description: 'Soubid hace una evaluación matemática y objetiva de los antecedentes académicos y profesionales ya verificados, ubicando a cada usuario en alguna de las 45 categorías que contempla el modelo.',
  },
];

const Indicator = styled(Box)(({ theme, active }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  margin: '0 5px',
  backgroundColor: active ? theme.palette.primary.main : 'gray',
}));

const OnboardingExclusive = ({ name }) => {
  const isLargeScreen = useMediaQuery('(min-width:600px)'); 
  const { user } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (gifs.length + 1));
  };

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + gifs.length + 1) % (gifs.length + 1));
  };

  const handleSkip = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate('/');
  };

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 4, minHeight: '80vh' }}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        {currentIndex === 0 ? (
          <>
            <Box component="img" src={Logo} alt="Logo" sx={{
              height: { xs: 100, md: 200 },
              width: { xs: 100, md: 200 },
              mb: 2,
            }} />
            <Typography variant="h5" sx={{ color: 'black', mb: 2 }}>
              ¡Bienvenido(a) a Soubid, {user?.name}!
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'black', mb: 4 }}>
              Gracias por unirte a la plataforma de profesionales. Te ayudaremos a posicionarte en el mercado y acceder a oportunidades únicas.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              {gifs[currentIndex - 1].title}
            </Typography>
            <Box component="img" src={gifs[currentIndex - 1].source} alt={gifs[currentIndex - 1].title} sx={{ width: '80%', height: 'auto', maxHeight: '50vh' }} />
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              {gifs[currentIndex - 1].description}
            </Typography>
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        {[...Array(gifs.length + 1)].map((_, index) => (
          <Indicator key={index} active={currentIndex === index} />
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 2 }}>
        {currentIndex > 0 && (
          <Button onClick={handleBack} sx={{ marginRight: 'auto' }}>
            Anterior
          </Button>
        )}
        {currentIndex < gifs.length && (
          <Button onClick={handleNext} sx={{ marginLeft: 'auto' }}>
            {currentIndex === gifs.length ? 'Iniciar Sesión' : 'Siguiente'}
          </Button>
        )}
        {currentIndex === gifs.length && (
          <Button variant="primary" onClick={handleSkip} sx={{ marginLeft: 'auto', backgroundColor: "#F67147" }}>
            Home
          </Button>
        )}
      </Box>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "80%",
            maxWidth: 700,
            // height: "60%",
            bgcolor: '#F67147',
            border: '1px solid #000',
            borderRadius: 5,
            boxShadow: 24,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around', 
            textAlign: 'center',
        }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
            Conoce nuestra APP
            </Typography>
            <Box component="img" src={Logo} alt="Logo" 
            sx={{ 
                height: 100,
                width: 100, 
                mb: 2 ,
                alignSelf: 'center'
            }} 
            />
            {isLargeScreen ? (
            <>
                <Typography variant="body1" sx={{ color: 'black', mb: 4 }}>
                    Escanea el QR y descarga directamente nuestra app.
                </Typography>
                <Box component="img" src={QRApp} alt="QR Code" 
                sx={{ 
                    height: 150, 
                    width: 150, 
                    alignSelf: 'center', 
                    mb: 2 
                }} 
                />
            </>
            ) : (
            <>
                <Typography variant="body1" sx={{ color: 'black', mb: 4 }}>
                    Haz clic en la sección inferior para ir a nuestra aplicación.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <a href="https://apps.apple.com/cl/app/soubid/id6578461746" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={AppleStoreBadge} alt="Download on the App Store" sx={{ height: 40, marginRight: 2 }} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.soubid.NewSoubid" target="_blank" rel="noopener noreferrer">
                    <Box component="img" src={GooglePlayBadge} alt="Get it on Google Play" sx={{ height: 40 }} />
                </a>
                </Box>
            </>

            )}
            <Button onClick={handleCloseModal} sx={{ color: 'black', justifyContent: 'flex-end', mb: 1}}>
            cerrar
            </Button>
        </Box>
      </Modal>

    </Container>
  );
};

export default OnboardingExclusive;
