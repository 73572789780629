import React, { useState } from 'react';
import { Modal, Box, Typography, Button, IconButton, useMediaQuery } from '@mui/material';
import { Close as CloseIcon, TaskAltRounded, AccessTimeRounded, Cancel as CancelIcon, DoNotDisturbAlt, EditNote, MoreVert } from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import { userAxios } from '../../apiClients';

const getIconProps = (state) => {
  switch (state) {
    case 'verified':
      return { icon: <TaskAltRounded color="success" sx={{ fontSize: '6rem' }} />, color: '#4CAF50' };
    case 'pending':
      return { icon: <AccessTimeRounded sx={{ fontSize: '6rem', stroke: '#ffd24d' }} />, color: '#FFC107' };
    case 'rejected':
      return { icon: <DoNotDisturbAlt color="error" sx={{ fontSize: '6rem' }} />, color: '#F44336' };
    case 'preloaded':
      return { icon: <DoNotDisturbAlt color="disabled" sx={{ fontSize: '6rem' }} />, color: '#9E9E9E' };
    case 'document':
      return {
        icon: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '2px solid black',
              width: '8rem',
              height: '8rem',
              borderRadius: '25px' // Opcional, puedes ajustar el radio si quieres esquinas redondeadas
            }}
          >
            <MoreVert sx={{ fontSize: '6rem', color: '#9E9E9E' }} />
          </Box>
        ),
        color: '#9E9E9E'
      };
    default:
      return { icon: null, color: null }; // Sin icono para la introducción
  }
};

const carouselData = [
  {
    description: (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3 }}>
          <TaskAltRounded color="success" sx={{ fontSize: '2.5rem', mx: 1 }} />
          <AccessTimeRounded sx={{ fontSize: '2.5rem', mx: 1, color: '#FFC107', stroke: '#ffd24d' }} />
          <DoNotDisturbAlt sx={{ fontSize: '2.5rem', mx: 1, color: '#9E9E9E' }} />
          <DoNotDisturbAlt color="error" sx={{ fontSize: '2.5rem', mx: 1 }} />
        </Box>
        <Typography variant="body1" sx={{ mt: 3 }}>
          Descubre cuál es el <strong>significado</strong> de cada uno de los <strong>iconos</strong> que pueden aparecer junto a tus antecedentes.
        </Typography>
      </>
    ),
  },
  {
    title: 'Verificado',
    state: 'verified',
    description: (
      <>
        Este antecedente ya está verificado, y puede ser sometido a calificación.
        <br /><br />
        Si el antecedente tiene un <strong>Documento y/o un Link de respaldo</strong> verás este ícono <MoreVert sx={{ fontSize: '25px', verticalAlign: 'middle' }} /> a un costado derecho. Presionándolo podrás acceder a esta información.
      </>
    ),
  },
  {
    title: 'Pendiente',
    state: 'pending',
    description: 'Este antecedente aún no está verificado, y no puede ser sometido a calificación.',
  },
  {
    title: 'Precargado',
    state: 'preloaded',
    description: (
      <>
        Este antecedente está precargado, y aún debe ser completado para ser verificado y luego calificado.
        <br /><br />
        Para cargar la información faltante, debes apretar en el siguiente ícono: <EditNote sx={{ fontSize: '2rem', verticalAlign: 'middle' }} /> (costado derecho del antecedente).
      </>
    ),
  },
  {
    title: 'Rechazado',
    state: 'rejected',
    description: 'Este antecedente no pudo ser verificado, y no podrá ser sometido a calificación.',
  },
];

function CarouselModal({ isOpen, handleClose }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { user } = useAuth();
  const iconProps = getIconProps(carouselData[currentIndex].state);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleNeverShowAgain = async () => {
    try {
      // Enviar la solicitud al backend para actualizar `webNotifications`
      await userAxios.post('/notifications/notification-web', { module: 'myprofile' });
      handleClose(); // Cierra el modal
    } catch (error) {
      console.error('Error updating web notifications:', error);
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? 350 : 500, // Mantiene el mismo ancho
          height: isMobile ? 400 : 450, // Mantiene la misma altura siempre
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4, // Añadimos padding para que el contenido no quede pegado a los bordes
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {iconProps.icon} {/* Usando el ícono con el tamaño y color según el estado */}
          </Box>
          <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}> {/* Título en negrita y naranja */}
            {carouselData[currentIndex].title}
          </Typography>
          <Typography sx={{ fontSize: '0.85rem', lineHeight: 1.3, mt: 1, mb: 0, px: 2 }}> {/* Texto más pequeño y con menos espacio entre líneas */}
            {carouselData[currentIndex].description}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.5, mt: 0 }}> {/* Botones más juntos y cerca del texto */}
          {currentIndex < carouselData.length - 1 ? (
            <Button variant="primary" onClick={handleNext} sx={{ fontSize: '0.75rem', py: 0.5, px: 1.5 }}> {/* Botón más pequeño */}
              Siguiente
            </Button>
          ) : (
            <>
              <Button
                onClick={handleNeverShowAgain}
                sx={{
                  fontSize: '0.75rem',
                  py: 0.5,
                  px: 2,
                  mr: 2,
                  backgroundColor: 'transparent',
                  color: '#F67147',
                  textTransform: 'none',
                  boxShadow: 'none',
                  border: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)', // Fondo con opacidad al hacer hover
                  },
                  minWidth: 'auto',
                }}
              >
                No volver a mostrar
              </Button>
              <Button variant="primary" onClick={handleClose} sx={{ fontSize: '0.75rem', py: 0.5, px: 1.5 }}>
                Aceptar
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default CarouselModal;
