import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ProfileHeader from '../components/profile/ProfileHeader';
import ProfileBody from '../components/profile/ProfileBody';
import UpdateInfoModal from '../components/profile/UpdateInfoModal';
import NotificationApp from './NotificationApp';
import NotificationQualification from './NotificationQualification';
import CarouselModal from '../components/profile/CarouselMyProfile';
import { useAuth } from '../hooks/useAuth';
import { userAxios } from '../apiClients';
import Loading from '../components/Loading';

function MyProfile() {
  const { user } = useAuth();
  const id = user?.id;
  const [userData, setUserData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationQualificationModal, setNotificationQualificationModal] = useState(false);
  const [notificationAppModal, setNotificationAppModal] = useState(false);
  const [isCarouselModalOpen, setIsCarouselModalOpen] = useState(false);
  const [isModalInstructionVisible, setIsModalInstructionVisible] = useState(false);
  const navigate = useNavigate(); // <-- Agrega esta línea

  useEffect(() => {
    const notificationAppProfile = JSON.parse(localStorage.getItem('notificationAppProfile'));
    const notificationQualificationProfile = JSON.parse(localStorage.getItem('notificationQualificationProfile'));
    if (!notificationAppProfile?.active && notificationAppProfile?.count % 10 === 0 && notificationAppProfile?.count !== 0) {
        setNotificationAppModal(true);
    }
    if (notificationQualificationProfile?.active && notificationQualificationProfile?.count % 16 === 0 && notificationQualificationProfile?.count !== 0) {
        setNotificationQualificationModal(true);
    }

    const newCountApp = notificationAppProfile?.count + 1;
    const newCountQualification = notificationQualificationProfile?.count + 1;
    localStorage.setItem('notificationAppProfile', JSON.stringify({ active: notificationAppProfile?.active, count: newCountApp }));
    localStorage.setItem('notificationQualificationProfile', JSON.stringify({ active: notificationQualificationProfile?.active, count: newCountQualification }));
  }, [user]);

  const checkInstructionModal = () => {
    if (user?.webNotifications?.myprofile === true) setIsModalInstructionVisible(true);
  };

  useEffect(() => {
    checkInstructionModal();
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user?.userRole === 'institutional') {
          navigate('/home-institution');
        } else {
          const response = await userAxios.get(`/users/profileJSON?userID=${id}`);
          setUserData(response.data);
          if (response.data?.Country === null) {
            setOpenModal(true);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error('Unauthorized access - possibly due to expired token');
          navigate('/myprofile'); // <-- Aquí se usa navigate
        } else {
          console.error('Error fetching data: ', error);
          navigate('/unauthorized'); // <-- Aquí también se usa navigate
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    } 
  }, [id, navigate]);

  useEffect(() => {
    if (isModalInstructionVisible) {
      const timer = setTimeout(() => {
        setIsCarouselModalOpen(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isModalInstructionVisible]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container maxWidth="md" sx={{ width: "100%", mt: 4, height: 'auto', minHeight: '600px' }}>
      <ProfileHeader
        name={userData?.fullName}
        ExpertiseArea1={userData?.ExpertiseArea1?.name}
        ExpertiseArea2={userData?.ExpertiseArea2?.name}
        location={userData?.Country?.name 
          ? (userData?.City?.name ? `${userData.City.name}, ${userData.Country.name}` : userData.Country.name)
          : ''
        }
        imageUrl={user?.linkPhoto}
        credits={userData?.credits}
        userRole={user?.userRole}
        qualification={userData?.userQualifications}
        isCurrentUser={true}
      />
      <ProfileBody userData={userData} isCurrentUser={true} />
      {user?.userRole !== 'institutional' && (
        <Grid container justifyContent="center" sx={{ mb: 2, mt: 4, pt: 4, pb: 4 }}>
          <Typography id="complaint-modal-description" sx={{ mt: 1, mr: 10 }}>
          ¿Quieres mejorar tu grado?
          </Typography>
          <Button variant="primary" component={Link} to="/choose-document">
            Agrega antecedentes
          </Button>
        </Grid>
      )}
      <UpdateInfoModal open={openModal} handleClose={() => setOpenModal(false)} />
      {notificationAppModal && <NotificationApp isModalOpen={notificationAppModal} setIsModalOpen={setNotificationAppModal} />}
      {notificationQualificationModal && <NotificationQualification isModalOpen={notificationQualificationModal} setIsModalOpen={setNotificationQualificationModal} />}
      <CarouselModal isOpen={isCarouselModalOpen} handleClose={() => setIsCarouselModalOpen(false)} />
    </Container>
  );
}

export default MyProfile;
