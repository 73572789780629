import React from 'react';
import { Grid, Divider, Box, Typography, Button, Avatar, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { userAxios } from '../../apiClients';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { useAuth } from '../../hooks/useAuth';

const RecommendationView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, imageUrl, userSenderID, userReceiverID } = location.state || {};
  const { showSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { user } = useAuth();

  const handleRecommend = async ({ userSenderID, userReceiverID }) => {
    if (userSenderID == userReceiverID) {
      showSnackbar('No puedes recomendarte a ti mismo', 'error');
      return;
    }
    try {
      if (Object.keys(user?.qualification).length === 0) {
        showSnackbar('Debes calificarte para poder recomendar a alguien', 'info');
        return;
      }

      const sentRecommendations = await userAxios.get(`/references/all-sent?userID=${userSenderID}`);
      if (sentRecommendations.data.length >= 3) {
        showSnackbar('Has alcanzado el límite de recomendaciones', 'error');
        return;
      }

      const checkResponse = await userAxios.post('/references/check-previous-reference', { userSenderID, userReceiverID });
      
      if (checkResponse.data.success.success) {
        const response = await userAxios.post('/references', { userSenderID, userReceiverID });
        if (response.data.success) {
          showSnackbar('La recomendación fue entregada exitosamente', 'success');
          navigate(-1); 
        } else {
          showSnackbar(response.data.message, 'error');
        }
      } else {
        showSnackbar('Ya has enviado una recomendación a este usuario.', 'error');
      }
    } catch (error) {
      console.error('Error al crear la recomendación', error);
      showSnackbar('Error al crear la recomendación', 'error');
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ padding: isMobile ? 2 : 4, textAlign: 'center', position: 'relative' }}>
      <Button
        onClick={handleCancel}
        startIcon={<ArrowBackIosNewIcon />}
        sx={{
          textTransform: 'none',
          color: 'inherit',
          textDecoration: 'none',
          fontWeight: 'bold',
          position: 'absolute', top: 16, left: 16
        }}
      >
        Atrás
      </Button>
      <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
        <Avatar src={imageUrl} sx={{ width: isMobile ? 100 : 150, height: isMobile ? 100 : 150, margin: 2 }} />
      </Box>
      <Grid container justifyContent="left" sx={{ my: 6 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "2%" }} />
          <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
            Recomendación
          </Typography>
        </Box>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', marginBottom: 2 }}>
            La recomendación es la valoración positiva que un usuario tiene de otro, respecto de sus aptitudes profesionales.
          </Typography>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', marginBottom: 2 }}>
            Las recomendaciones cumplen un rol fundamental en la calificación de las personas, y mientras más recomendaciones tenga una persona, más alta será su calificación.
          </Typography>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', marginBottom: 4 }}>
            Las recomendaciones son limitadas. También son anónimas, por lo que la persona a la que recomiendas no sabrá que eres tú quien lo está haciendo.
          </Typography>
          <Typography variant="h5" sx={{ fontFamily: 'Poppins', marginBottom: 4 }}>
            ¿Estás seguro que deseas recomendar a esta persona?
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', gap: 2, marginBottom: 2 }}>
            <Button variant="primary" onClick={() => handleRecommend({ userSenderID, userReceiverID })} sx={{ marginBottom: isMobile ? 2 : 0 }}>
              Sí
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancelar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecommendationView;
