import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { userAxios } from '../apiClients';
import { useSnackbar } from './snackbar/SnackbarContext';
import { validateEmail } from '../utils/functions';

const EmailInputListModal = ({ visible, onClose, userId }) => {
    const { showSnackbar } = useSnackbar();
    const [emails, setEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [error, setError] = useState('');

    const addEmail = () => {
        const validationMessage = validateEmail(emailInput);
        if (validationMessage === true) {
            if (!emails.includes(emailInput)) {
                setEmails([...emails, emailInput]);
                setEmailInput('');
                setError(''); // Limpiar el mensaje de error si se agregó correctamente
            }
        } else {
            setError(validationMessage); // Mostrar el mensaje de error si no es válido
        }
    };

    const removeEmail = (index) => {
        const newEmails = emails.filter((_, i) => i !== index);
        setEmails(newEmails);
    };

    const handleSubmit = async () => {
        if (emails.length === 0) {
            showSnackbar('Debes agregar al menos un correo (apreta el bóton de la derecha)', 'error');
            return;
        }
        if (emailInput !== '') {
            addEmail();
            return;
        }
        try {
            const { data: { success } } = await userAxios.post('/references/request', { userId, emails });
            if (!success) {
                onClose();
                showSnackbar('Error al enviar al solicitar recomendación', 'error');
            } else {
                setEmails([]);
                onClose();
                showSnackbar('Recomendación solicitada exitosamente', 'success');
            }
        } catch (error) {
            console.error('Error sending recommendation requests: ', error);
            onClose();
            showSnackbar('Error al enviar al solicitar recomendación', 'error');
        }
    };

    const handleInputChange = (event) => {
        setEmailInput(event.target.value);
        if (error) {
            setError(''); // Limpiar el mensaje de error cuando el usuario empieza a escribir de nuevo
        }
    };

    return (
        <Modal
            open={visible}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                }}
            >
                <Box
                    sx={{
                        width: '80%',
                        maxWidth: 400,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        border: '1px solid black',
                    }}
                >
                    <Typography id="modal-title" variant="h6" sx={{ mb: 2, fontFamily: 'Poppins', fontWeight: 'bold' }}>
                        Solicitar recomendación
                    </Typography>
                    <Box
                        sx={{
                            height: 50,
                            width: 2,
                            bgcolor: 'black',
                            position: 'absolute',
                            left: '15%',
                            top: '6%',
                        }}
                    />
                    <Typography id="modal-description" sx={{ mb: 2, fontFamily: 'Poppins', fontWeight: 'regular' }}>
                        Agrega los correos electrónicos de las personas que podrían recomendarte, y nosotros les escribiremos por ti
                    </Typography>
                    {emails.map((email, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, bgcolor: '#f0f0f0', p: 1, borderRadius: 1, width: '100%' }}>
                            <Typography sx={{ flex: 1, fontSize: 14, fontFamily: 'Poppins' }}>{email}</Typography>
                            <IconButton onClick={() => removeEmail(index)}>
                                <DeleteOutlineRoundedIcon size={24} color="gray" />
                            </IconButton>
                        </Box>
                    ))}
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={emailInput}
                            onChange={handleInputChange}
                            placeholder="Ecribe acá"
                            type="email"
                            sx={{ mr: 2 }}
                            error={Boolean(error)} // Mostrar el error en el TextField
                            helperText={error} // Mostrar el mensaje de error
                        />
                        <IconButton onClick={addEmail}>
                            <AddCircleRoundedIcon size={24} color="#F67147" />
                        </IconButton>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                            sx={{
                                bgcolor: '#F67147',
                                padding: '10px 0',
                                borderRadius: 1,
                                mb: 1,
                                width: '60%',
                                '&:hover': {
                                    bgcolor: '#e05e35',
                                },
                            }}
                        >
                            <Typography sx={{ color: 'white', fontWeight: 'bold' }}>Enviar</Typography>
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={onClose}
                            sx={{
                                bgcolor: '#666',
                                padding: '10px 0',
                                borderRadius: 1,
                                width: '60%',
                                '&:hover': {
                                    bgcolor: '#555',
                                },
                            }}
                        >
                            <Typography sx={{ color: 'white', fontWeight: 'bold' }}>Cancelar</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default EmailInputListModal;
