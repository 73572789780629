import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import SelectOption from '../../SelectOption';
import { publicAxios } from '../../../apiClients';

function IdiomForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [formData, setFormData] = useState({
        idiomID: '',
        certificationID: '',
    });
    const [languages, setLanguages] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchLanguagesAndCertifications = async () => {
            try {
                const responseLanguages = await publicAxios.get(`/static-data/languages`);
                setLanguages(responseLanguages.data);
                
                const responseCertifications = await publicAxios.get(`/static-data/idiom-certifications`);
                setCertifications(responseCertifications.data);
            } catch (error) {
                setError(true);
            }
        };
        fetchLanguagesAndCertifications();
    }, []);

    const handleSubmit = () => {
        if (!formData.idiomID || !formData.certificationID) {
            setError(true);
            return;
        }
        onAddAntecedent({
            id: antecedentType.id,
            antecedentType: antecedentType.name,
            idiomID: formData.idiomID,
            idiomName: languages.find(language => language.id === formData.idiomID).name,
            certificationID: formData.certificationID,
            certificationName: certifications.find(certification => certification.id === formData.certificationID).name,
        });
    };

    return (
        <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Agregar {antecedentType.name}
            </Typography>
            <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                <Grid item xs={12} sm={4}>
                    <Typography>Idioma</Typography>
                    <SelectOption
                        options={languages}
                        onSelectionChange={(value) => setFormData({ ...formData, idiomID: value })}
                        name={'Selecciona un idioma'}
                        width="100%"
                        selectedOption={formData.idiomID}
                        error={error && !formData.idiomID}
                        helperText={error && !formData.idiomID ? 'Este campo es obligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography>Certificación</Typography>
                    <SelectOption
                        options={certifications}
                        onSelectionChange={(value) => setFormData({ ...formData, certificationID: value })}
                        name={'Selecciona una certificación'}
                        width="100%"
                        selectedOption={formData.certificationID}
                        error={error && !formData.certificationID}
                        helperText={error && !formData.certificationID ? 'Este campo es obligatorio' : ''}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                <Button variant="primary" onClick={handleSubmit}>
                    Agregar Antecedente
                </Button>
            </Grid>
        </Box>
    );
}

export default IdiomForm;
