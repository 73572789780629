import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import SelectOption from '../../SelectOption';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { publicAxios } from '../../../apiClients';
import { getFullDate } from '../../../utils/Validators';

function RankingForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [formData, setFormData] = useState({
        rankingID: '',
        rankingBandID: '',
        date: null,
    });
    const [rankings, setRankings] = useState([]);
    const [rankingBands, setRankingBands] = useState([]);
    const [selectedBands, setSelectedBands] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchRankings = async () => {
            try {
                const response = await publicAxios.get(`/static-data/rankings`);
                setRankings(response.data);
                const bandsResponse = await publicAxios.get(`/static-data/ranking-band`);
                setRankingBands(bandsResponse.data);
            } catch (error) {
                setError(true);
            }
        };
        fetchRankings();
    }, []);

    useEffect(() => {
        if (formData.rankingID) {
            const bands = rankingBands.filter(band => band.rankingID === formData.rankingID);
            setSelectedBands(bands);
        }
        console.log(formData.rankingID);
    }, [formData.rankingID]);

    const handleSubmit = () => {
        if (!formData.rankingID || !formData.date || (!formData.rankingBandID && (formData.rankingID === 1 || formData.rankingID === 3))) {
            setError(true);
            return;
        }
        onAddAntecedent({
            id: antecedentType.id,
            antecedentType: antecedentType.name,
            rankingID: formData.rankingID,
            rankingName: rankings.find(ranking => ranking.id === formData.rankingID).name,
            rankingBandID: formData.rankingBandID,
            date: getFullDate(formData.date),
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Agregar {antecedentType.name}
                </Typography>
                <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                    <Grid item xs={12} sm={4}>
                        <Typography>Ranking</Typography>
                        <SelectOption
                            options={rankings}
                            onSelectionChange={(value) => setFormData({ ...formData, rankingID: value })}
                            name={'Escoge un ranking'}
                            width="100%"
                            selectedOption={formData.rankingID}
                            error={error && !formData.rankingID}
                            helperText={error && !formData.rankingID ? 'Este campo es obligatorio' : ''}
                        />
                    </Grid>
                    {(formData.rankingID === 1 || formData.rankingID === 3) && (
                        <Grid item xs={12} sm={4}>
                            <Typography>Banda</Typography>
                            <SelectOption
                                options={selectedBands}
                                onSelectionChange={(value) => setFormData({ ...formData, rankingBandID: value })}
                                name={'Escoge una banda'}
                                width="100%"
                                selectedOption={formData.rankingBandID}
                                error={error && !formData.rankingBandID}
                                helperText={error && !formData.rankingBandID ? 'Este campo es obligatorio' : ''}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{ mb: 2 }}>Fecha</Typography>
                        <DatePicker
                            label="Selecciona la fecha"
                            value={formData.date}
                            onChange={(date) => setFormData({ ...formData, date })}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: error && !formData.date,
                                    helperText: error && !formData.date ? 'Este campo es obligatorio' : '',
                                },
                            }} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                    <Button variant="primary" onClick={handleSubmit}>
                        Agregar Antecedente
                    </Button>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
}

export default RankingForm;
