import React, { useState } from 'react';
import { Box, Typography, Switch, Button, Modal, IconButton, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../../hooks/useAuth';
import { userAxios } from '../../apiClients';
import { useSnackbar } from '../snackbar/SnackbarContext';
import styled from '@emotion/styled';

const Container = styled(Box)({
  padding: '20px',
  backgroundColor: '#fff',
  width: '70%', // Ajuste del ancho al 70%
  margin: '0 auto', // Centrar el contenedor
  textAlign: 'center',
});

const OptionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px 0',
  borderBottom: '1px solid #ccc',
  width: '100%',
});

const OptionButton = styled(Button)({
  marginTop: '10px',
  backgroundColor: '#f0f0f0',
  borderRadius: '5px',
  width: '100%', // Asegura que el botón ocupe todo el ancho disponible
});

const AccountVisualization = ({ onClose }) => {
  const { user, checkCurrentUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [isVisibleInDirectory, setIsVisibleInDirectory] = useState(user?.isVisibleInDirectory || false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '', action: null });

  const handleToggleVisibility = async () => {
    setIsVisibleInDirectory(!isVisibleInDirectory);

    try {
      const response = await userAxios.patch('/user-qualification/visible');

      if (response.data.success) {
        showSnackbar(`Tu visibilidad en el directorio ha sido ${!isVisibleInDirectory ? 'activada' : 'desactivada'}`, 'success');
        checkCurrentUser();
        setModalVisible(false);

      } else {
        showSnackbar(response.data.message || 'Hubo un problema al cambiar tu visibilidad', 'error');
      }
    } catch (error) {
      console.error('Error changing visibility:', error);
      showSnackbar('Hubo un problema al cambiar tu visibilidad', 'error');
    }
  };

  const handlePauseAccount = async () => {
    try {
      const response = await userAxios.post('/users/change-state', {
        userID: user.id,
        state: 'waiting',
      });

      if (response.data.success) {
        setModalVisible(false);
        showSnackbar('Tu cuenta ha sido pausada', 'success');
        checkCurrentUser(); // Actualiza el usuario después de pausar la cuenta
      } else {
        showSnackbar(response.data.message || 'Hubo un problema al pausar tu cuenta', 'error');
      }
    } catch (error) {
      console.error('Error pausing account:', error);
      showSnackbar('Hubo un problema al pausar tu cuenta', 'error');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await userAxios.post('/users/change-state', {
        userID: user.id,
        state: 'deleted',
      });

      if (response.data.success) {
        setModalVisible(false);
        showSnackbar('Tu cuenta ha sido eliminada', 'success');
        // Aquí puedes manejar cualquier lógica adicional después de eliminar la cuenta, como cerrar la sesión o redirigir al usuario
      } else {
        showSnackbar(response.data.message || 'Hubo un problema al eliminar tu cuenta', 'error');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      showSnackbar('Hubo un problema al eliminar tu cuenta', 'error');
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <IconButton onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '0 auto' }}>Visualización de la Cuenta</Typography>
          <Box sx={{ width: '48px' }} /> {/* Espacio vacío para mantener el diseño */}
        </Grid>

        <Grid item container direction="column" alignItems="center" sx={{ width: '100%' }}>
          <OptionContainer>
            <Typography>Calificación visible</Typography>
            <Switch
              checked={isVisibleInDirectory}
              onChange={() => {
                setModalContent({
                  title: 'Confirmar Cambio de Visibilidad',
                  message: `¿Estás seguro de ${!isVisibleInDirectory ? 'activar' : 'desactivar'} tu visibilidad en el directorio?`,
                  action: handleToggleVisibility,
                });
                setModalVisible(true);
              }}
            />
          </OptionContainer>

          <OptionButton
            variant="primary"
            onClick={() => {
              setModalContent({
                title: 'Confirmar Pausa de Cuenta',
                message: '¿Estás seguro de que quieres pausar tu cuenta?',
                action: handlePauseAccount,
              });
              setModalVisible(true);
            }}
          >
            Pausar la Cuenta
          </OptionButton>

          <OptionButton
            variant="primary"
            onClick={() => {
              setModalContent({
                title: 'Confirmar Eliminación de Cuenta',
                message: '¿Estás seguro de que quieres eliminar tu cuenta? Esta acción no se puede deshacer.',
                action: handleDeleteAccount,
              });
              setModalVisible(true);
            }}
          >
            Eliminar la Cuenta
          </OptionButton>
        </Grid>
      </Grid>

      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <Box sx={{ width: '300px', margin: '100px auto', padding: '20px', backgroundColor: 'white', borderRadius: '8px', textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>{modalContent.title}</Typography>
          <Typography>{modalContent.message}</Typography>
          <Button variant="primary" onClick={modalContent.action} sx={{ marginRight: '10px' }}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={() => setModalVisible(false)}>
            Cancelar
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default AccountVisualization;
