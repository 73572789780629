import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import SelectOption from '../../SelectOption';
import { publicAxios } from '../../../apiClients';

function OtherBackgroundForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [formData, setFormData] = useState({
        positionID: '',
        positionName: '',
        whereWork: '',
    });
    const [positions, setPositions] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchPositions = async () => {
            try {
                const response = await publicAxios.get(`/static-data/other-positions`);
                setPositions(response.data);
            } catch (error) {
                setError(true);
            }
        };
        fetchPositions();
    }, []);

    const handleSubmit = () => {
        if (!formData.positionID || !formData.whereWork || (formData.positionID === 1 && !formData.positionName)) {
            setError(true);
            return;
        }
        onAddAntecedent({
            id: antecedentType.id,
            antecedentType: antecedentType.name,
            positionID: formData.positionID,
            positionName: formData.positionName || positions.find(position => position.id === formData.positionID).name,
            whereWork: formData.whereWork,
        });
    };

    return (
        <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Agregar {antecedentType.name}
            </Typography>
            <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                <Grid item xs={12} sm={4}>
                    <Typography>Posición</Typography>
                    <SelectOption
                        options={positions}
                        onSelectionChange={(value) => setFormData({ ...formData, positionID: value })}
                        name={'Elija una posición'}
                        width="100%"
                        selectedOption={formData.positionID}
                        error={error && !formData.positionID}
                        helperText={error && !formData.positionID ? 'Este campo es obligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography sx={{ mb: 2 }}>¿Dónde trabajaste?</Typography>
                    <TextField
                        value={formData.whereWork}
                        onChange={(event) => setFormData({ ...formData, whereWork: event.target.value })}
                        size="small"
                        fullWidth
                        error={error && !formData.whereWork}
                        helperText={error && !formData.whereWork ? 'Este campo es obligatorio' : ''}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                <Button variant="primary" onClick={handleSubmit}>
                    Agregar Antecedente
                </Button>
            </Grid>
        </Box>
    );
}

export default OtherBackgroundForm;
