import React, { useState, useEffect } from 'react';
import { userAxios } from '../../apiClients';
import { FormControlLabel, Checkbox, Button, Box, IconButton, Grid, Typography, Modal } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from '../snackbar/SnackbarContext';
import CloseIcon from '@mui/icons-material/Close';

const AdminOptionsForm = ({ onClose }) => {
    const { user } = useAuth();
    const [adminOptions, setAdminOptions] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [openModal, setOpenModal] = useState(false); // Estado para controlar el modal de confirmación
    const uniqueInstitutions = new Set(); // Set para evitar duplicados por institutionID e institutionType
    const [infoBoxVisible, setInfoBoxVisible] = useState(true);
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        const actualProfessionalExperience = user?.data.ProfessionalExperiences.filter(
            experience => experience.endDate === 'present' && experience.state === 'verified'
        );

        if (actualProfessionalExperience.length > 0 && user?.userRole !== 'institutional') {
            actualProfessionalExperience.forEach(experience => {
                const uniqueKey = `${experience.institutionID}-${experience.institutionType}`; // Clave única con institutionID e institutionType
                        
                // Solo se añade si la combinación de institutionID e institutionType no ha sido añadida
                if (!uniqueInstitutions.has(uniqueKey)) {
                    uniqueInstitutions.add(uniqueKey); // Agregar la combinación de ID y Type al Set
                    
                    userAxios.get(`/users/search-institution-administrator?institutionType=${experience.institutionType}&institutionID=${experience.institutionID}`)
                    .then(res => {
                        const hasAdmin = res.data;
                        const adminPermissionArray = user.adminPermission?.data || [];
                        const permission = adminPermissionArray.find(
                            permission => permission.institutionID === experience.institutionID && permission.institutionType === experience.institutionType
                        );
                        setAdminOptions(prevOptions => [
                            ...prevOptions,
                            {
                                institutionID: experience.institutionID,
                                institutionType: experience.institutionType,
                                institutionName: experience.institution?.name,
                                hasAdmin, // Variable que indica si tiene o no administrador
                                state: permission ? permission.state : true
                            }
                        ]);
                        setPermissions(prevPermissions => ({
                            ...prevPermissions,
                            [`${experience.institutionID}-${experience.institutionType}`]: permission ? permission.state : true
                        }));
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            });
        }
    }, [user]);

    const handlePermissionChange = (institutionID, institutionType, value, type) => {
        // Si "Habilitar" está marcado, inhabilitamos el checkbox de "Inhabilitar", y viceversa
        if (type === 'enable') {
            setPermissions(prevPermissions => ({
                ...prevPermissions,
                [`${institutionID}-${institutionType}`]: value // Habilitar si es true, inhabilitar si es false
            }));
        } else if (type === 'disable') {
            setPermissions(prevPermissions => ({
                ...prevPermissions,
                [`${institutionID}-${institutionType}`]: !value // Inhabilitar si es true, habilitar si es false
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpenModal(true); // Abre el modal de confirmación
    };

    const confirmAction = () => {
        // Filtrar solo las opciones donde hasAdmin es true
        let updatedPermissions = adminOptions
            .filter(option => option.hasAdmin) // Solo incluir las que tienen un administrador
            .map(option => ({
                institutionID: option.institutionID,
                institutionType: option.institutionType,
                state: permissions[`${option.institutionID}-${option.institutionType}`]
            }));

        // Enviar la petición solo si hay permisos que actualizar
        if (updatedPermissions.length > 0) {
            updatedPermissions = { data: updatedPermissions };
            userAxios.patch('/users/update-admin-permission', { userID: user.id, jsonData: updatedPermissions })
            .then(response => {
                console.log('Permissions updated successfully');
                showSnackbar('Permisos actualizados correctamente', 'success');
            })
            .catch(error => {
                console.log('Error updating permissions', error);
            });
        } else {
            console.log('No permissions to update.');
        }
        
        setOpenModal(false); // Cierra el modal
        onClose();
    };

    const handleCloseModal = () => {
        setOpenModal(false); // Cierra el modal sin confirmar
    };

    const handleInfoBoxClose = () => {
        setInfoBoxVisible(false);
    };

    return (
        <>
        <Grid container spacing={3} direction="column" alignItems="center" sx={{ mb: 2 }}>
            <Grid item container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <IconButton onClick={onClose}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Administrar tus antecedentes</Typography>
                <Box sx={{ width: '70px' }} />
            </Grid>
            {infoBoxVisible && (
                <Box sx={{ p: 3, bgcolor: 'rgba(255,255,204,0.9)', borderRadius: 1, mb: 5, position: 'relative', width: '75%' }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2" sx={{ textAlign: 'justify', mx: 2 }}>
                            Estimado usuario:
                            <br />
                            <br />
                            Con esta funcionalidad, al seleccionar 'Habilitar' estarás autorizando a la institución la facultad de gestionar tus antecedentes, lo que incluye visualizar el estado de todos tus antecedentes, subir nuevos y poder actualizar aquellos antecedentes que han sido precargados por Soubid pero se encuentran incompletos. Si seleccionas 'Inhabilitar', la institución no podrá realizar estas acciones.
                        </Typography>
                        <IconButton onClick={handleInfoBoxClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Box>
            )}
            <form onSubmit={handleSubmit}>
                {adminOptions.map(option => (
                    <Box key={`${option.institutionID}-${option.institutionType}`} mb={2}>
                        <Grid container alignItems="center" justifyContent="center" spacing={1}>
                            <Grid item xs={5} sx={{ justifyContent: 'flex-start', width: '100%' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
                                    {option.institutionName}:
                                </Typography>
                            </Grid>
                            {option.hasAdmin ? (
                                <>
                                    <Grid item xs={3.5}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={permissions[`${option.institutionID}-${option.institutionType}`] === true}
                                                    onChange={(e) => handlePermissionChange(option.institutionID, option.institutionType, e.target.checked, 'enable')}
                                                />
                                            }
                                            label="Habilitar"
                                        />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={permissions[`${option.institutionID}-${option.institutionType}`] === false}
                                                    onChange={(e) => handlePermissionChange(option.institutionID, option.institutionType, e.target.checked, 'disable')}
                                                />
                                            }
                                            label="Inhabilitar"
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={7}>
                                    <Typography variant="body2" color="textSecondary" >
                                        Esta institución no tiene un administrador designado para gestionar antecedentes.
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                ))}
                <Box textAlign="center" mt={4}>
                    <Button type="submit" variant="primary">
                        Guardar
                    </Button>
                </Box>
            </form>
        </Grid>

        {/* Modal de confirmación */}
        <Modal open={openModal} onClose={handleCloseModal}>
            <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 1, maxWidth: 400, margin: 'auto', mt: '10%', alignItems: 'center', textAlign: 'center' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    ¿Estás seguro de que deseas actualizar tus permisos?
                </Typography>
                <Button variant="primary" onClick={confirmAction} sx={{ mr: 2 }}>
                    Confirmar
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                    Cancelar
                </Button>
            </Box>
        </Modal>
        </>
    );
};

export default AdminOptionsForm;
