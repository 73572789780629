import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchComponentInstitutions from '../components/institutions/SearchComponentInstitutions';
import ProfileCard from '../components/directory/ProfileCard';
import Pagination from '../components/directory/Pagination';
import { publicAxios, userAxios } from '../apiClients';
import { useAuth } from '../hooks/useAuth';

const HomeInstitution = () => {
    const [profiles, setProfiles] = useState([]);
    const { user } = useAuth();
    const navigate = useNavigate();
    const [pages, setPages] = useState({
        currentPage: 1,
        totalItems: 1,
        totalPages: 1,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchParams, setSearchParams] = useState({ search: '', page: 1, adminInstitution: user.adminInstitution });

    const saveUserIdsToLocalStorage = (userIds) => {
        const existingUserIds = localStorage.getItem('institutionUserIds');
        if (!existingUserIds) {
            localStorage.setItem('institutionUserIds', JSON.stringify(userIds));
        }
    };

    const performSearch = async (params) => {
        setIsLoading(true);
        try {
            const response = await userAxios.get(`/users/search-users-institutionals`, { params });
            if (response.data.users.length === 0) {
                setPages({
                    currentPage: 1,
                });
            } else {
                setPages({
                    currentPage: response.data.currentPage,
                    totalItems: response.data.totalItems,
                    totalPages: response.data.totalPages,
                });
            }

            setProfiles(response.data.users.map(profile => ({
                id: profile.id,
                name: profile.name,
                city: profile.city,
                country: profile.country,
                linkPhoto: profile?.linkPhoto,
                profession: profile.profession,
                university: profile.university,
                rating: profile.qualification,
            })));

            saveUserIdsToLocalStorage(response.data.userIds);
        } catch (error) {
            console.error('Error during API call:', error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderProfileCard = (profile, index) => (
        <Grid item xs={12} sm={6} md={4} lg={2.3} key={profile.id} style={index >= 20 ? blurStyle : null}>
            <Box>
                <ProfileCard {...profile} />
            </Box>
        </Grid>
    );

    return (
        <Box>
            <SearchComponentInstitutions
                onPerformSearch={performSearch}
                currentPage={pages.currentPage}
                searchQuery={searchParams.search}
                adminInstitution={user.adminInstitution}
            />
            <Box sx={{ backgroundColor: '#F67147', pt: 10, pb: 5, position: 'relative' }}>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={2} justifyContent="center">
                        {profiles.slice(0, 20).map((profile, index) => renderProfileCard(profile, index))}
                    </Grid>
                )}
            </Box>
            <Pagination pages={pages} setPages={setPages} />
        </Box>
    );
};

const blurStyle = {
    filter: 'blur(8px)',
    userSelect: 'none',
    pointerEvents: 'none'
};

export default HomeInstitution;
