import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Grid, Box, Avatar, Button, Typography, TextField, Input, Divider, useMediaQuery } from '@mui/material';
import image from '../../utils/LogoBlack.png';
import SelectOption from '../../components/SelectOption';
import { userAxios, publicAxios } from '../../apiClients';
import Loading from '../../components/Loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import FileUploadStatus from '../../components/FileUpload';
import EmailChipsInput from '../../components/EmailChipsInput';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';
import { useAuth } from '../../hooks/useAuth';
import BackButton from '../../components/BackButton';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function UploadIntAccreditation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, description, experience } = location.state || {};
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [jurisdictionID, setJurisdictionID] = useState('');
  const [jurisdictions, setJurisdictions] = useState([]);
  const [jurisdictionsName, setJurisdictionsName] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [userProfileId, setUserProfileId] = useState(null);

  useEffect(() => {
    if (user) {
      if (user?.userRole === 'institutional' && localStorage.getItem('paramId')) {
        setUserProfileId(localStorage.getItem('paramId'));
      } else {
        setUserProfileId(user.id);
      }
    }
  }, [user]);

  useEffect(() => {
    // Hace scroll al inicio al montar el componente
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchJurisdiction = async () => {
      setIsLoading(true);
      try {
        const response = await publicAxios.get(`/static-data/jurisdictions`);
        setJurisdictions(response.data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJurisdiction();
  }, []);

  useEffect(() => {
    if (experience) {
      setJurisdictionID(experience.Jurisdiction?.id || '');
      setJurisdictionsName(experience.jurisdictionName || '');
      setUrlInput(experience.url || '');
    }
  }, [experience]);

  const handleSendInternationalAccreditation = async () => {
    setIsLoading(true);
    if (!jurisdictionID || !file || (jurisdictionID === 1 && !jurisdictionsName)) {
      setIsLoading(false);
      setError(true);
      return;
    }

    const accreditationData = new FormData();
    accreditationData.append('userID', userProfileId);
    accreditationData.append('jurisdictionID', jurisdictionID);
    if (jurisdictionID === 1) {
      accreditationData.append('jurisdictionName', jurisdictionsName);
    }
    if (urlInput) {
      accreditationData.append('url', urlInput);
    }
    if (emails.length > 0) {
      accreditationData.append('emails', JSON.stringify(emails));
    }
    accreditationData.append('documentType', 'international_accreditation');
    accreditationData.append('file', file);

    try {
      let response;
      if (experience) {
        accreditationData.append('state', 'pending');
        response = await userAxios.patch(`/international-accreditation/${experience.id}`, accreditationData);
      } else {
        response = await userAxios.post(`/international-accreditation`, accreditationData);
      }

      if (response.data.success) {
        setIsLoading(false);
        const message = response.data.message === 'update' ? 'Certificación actualizada con éxito' : 'Certificación creada con éxito';
        showSnackbar(message, 'success');
        navigate('/choose-document', { replace: true });


      } else {
        setIsLoading(false);
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al crear o actualizar la certificación', error);
      showSnackbar('Error al crear o actualizar la certificación', 'error');
    }
  };

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2} direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <BackButton path={"/choose-document"} back={true} />
          </Grid>
          <Grid item container spacing={2} alignItems="center" >
            <Grid item xs={12} sm={6} >
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', direction: 'column' }}>
                    <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 4 }}>
                    {description}
                  </Typography>
                </Grid>
                <Grid item >
                  <Typography >
                    Jurisdicción
                  </Typography>
                </Grid>
                <Grid item >
                  <SelectOption
                    options={jurisdictions}
                    onSelectionChange={setJurisdictionID}
                    name={'Escoge la jurisdicción'}
                    width={isMobile ? "100%" : "70%"}
                    selectedOption={jurisdictionID}
                    helperText={error && !jurisdictionID ? 'Este campo es obligatorio' : ''}
                    error={error && !jurisdictionID}
                  />
                </Grid>
                {jurisdictionID === 1 && (
                  <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ mb: 2 }}>
                    <Grid item xs={6} sx={{ mt: 2 }} >
                      <Grid item >
                        <Typography >
                          Nombre de la jurisdicción
                        </Typography>
                      </Grid>
                      <TextField
                        margin="normal"
                        sx={{ width: isMobile ? "100%" : "70%" }}
                        size="small"
                        id="referenceText"
                        label="Escribe aquí..."
                        value={jurisdictionsName}
                        onChange={(event) => handleInputChange(event, setJurisdictionsName)}
                        error={error && !jurisdictionsName}
                        helperText={error && !jurisdictionsName ? 'Este campo es obligatorio' : ''}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }} >
                <Grid item container >
                  <Grid item xs={6} sx={{ mt: 2, mr: -2 }} >
                    <Grid item >
                      <Typography >
                        Adjunta Link (Opcional)
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: isMobile ? "100%" : "87%" }}
                      size="small"
                      id="referenceText"
                      placeholder="Escribe aquí..."
                      label="Link"
                      value={urlInput}
                      onChange={(event) => handleInputChange(event, setUrlInput)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} sx={{ mt: 2, mr: 3 }}>
                    <Grid item sx={{ mb: 2 }}>
                      <Typography >
                        Documento de respaldo
                        <Tooltip 
                          title="Para acreditar tu experiencia puedes utilizar cualquier antecedente escrito, como un contrato, un escrito judicial, recortes de prensa, etc. El formato también es libre, pudiendo ser fotografías, archivos .pdf, links, o incluso pantallazos ¡Bienvenida la creatividad!"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                maxWidth: isMobile ? '150px' : '400px', // Ancho según si es móvil
                                fontSize: isMobile ? '10px' : '13.15px',  // Tamaño de fuente según si es móvil
                                padding: isMobile ? '6px' : '11px',    // Padding según si es móvil
                                whiteSpace: 'normal', // Ajusta el texto al ancho del tooltip
                                textAlign: 'center',  // Centra el texto en el tooltip
                                backgroundColor: 'rgba(128, 128, 128, 0.975)',
                              },
                            },
                          }}
                        >
                          <HelpOutlineOutlinedIcon sx={{ml: 1}}/>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item >
                      <FileUploadStatus setFile={setFile} file={file} />
                      {error && !file && <Typography variant="caption" color="error">* Sube un documento para comprobar esta certificación</Typography>}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile &&
              <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
                <Avatar variant="rounded" src={image} sx={{ width: '200px', height: '200px' }} />
              </Grid>
            }
            <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }} >
              <Grid item sx={{ mb: 2 }}>
                <Typography >
                  Danos el Correo electrónico de personas que puedan ayudar con el proceso de verificación.
                </Typography>
              </Grid>
              <Grid item >
              <EmailChipsInput setEmails={setEmails} emails={emails} />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} sx={{ ml: isMobile ? 1 : 10, mb: 3 }} >
              <Button
                variant="primary"
                onClick={handleSendInternationalAccreditation}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default UploadIntAccreditation;