import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Grid, Box, Avatar, Button, Typography, TextField, Divider, useMediaQuery } from '@mui/material';
import image from '../../utils/LogoBlack.png';
import SelectOption from '../../components/SelectOption';
import Loading from '../../components/Loading';
import { useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileUploadStatus from '../../components/FileUpload';
import EmailChipsInput from '../../components/EmailChipsInput';
import { useSnackbar } from '../../components/snackbar/SnackbarContext';
import { userAxios, publicAxios } from '../../apiClients';
import { useAuth } from '../../hooks/useAuth';
import BackButton from '../../components/BackButton';
import { getFullDate } from '../../utils/Validators';
import { useNavigate } from 'react-router-dom';

function UploadProfessional() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const { title, description, previus, experience } = location.state || {};
  console.log('experience', experience)
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [institutionType, setInstitutionType] = useState('');
  const [positions, setPositions] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [institution, setInstitution] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [publicInstitutions, setPublicInstitutions] = useState([]);
  const [publicInstitution, setPublicInstitution] = useState('');
  const [position, setPosition] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(previus ? null : 'present');
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [userProfileId, setUserProfileId] = useState(null);

  useEffect(() => {
    if (user) {
      if ((user?.userRole === 'institutional' || user?.state === 'admin') && localStorage.getItem('paramId')) {
        setUserProfileId(localStorage.getItem('paramId'));
      } else {
        setUserProfileId(user.id);
      }
    }
  }, [user]);

  const institutionTypes = [
    { id: 'Firm', name: 'Firma Legal' },
    { id: 'Company', name: 'Compañía' },
    { id: 'PublicService', name: 'Servicio Público' },
    { id: 'Independient', name: 'Independiente' },
  ];

  useEffect(() => {
    // Hace scroll al inicio al montar el componente
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (institutionType === 'Firm') {
          const firmsResponse = await publicAxios.get('/static-data/firms');
          setInstitutions(firmsResponse.data);
          const positionsResponse = await publicAxios.get('/static-data/positions?institution=Firm');
          setPositions(positionsResponse.data);
        } else if (institutionType === 'Company') {
          const companiesResponse = await publicAxios.get('/static-data/companies');
          setInstitutions(companiesResponse.data);
          const positionsResponse = await publicAxios.get('/static-data/positions?institution=Company');
          setPositions(positionsResponse.data);
        } else if (institutionType === 'PublicService') {
          const publicServiceResponse = await publicAxios.get('/static-data/public-service-type');
          setInstitutions(publicServiceResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data', error);
        setError(true);
      }
    };

    fetchData();
  }, [institutionType]);

  useEffect(() => {
    if (experience) {
      setInstitutionType(experience.institutionType || '');
      if (experience.institutionType !== 'PublicService') {
        setInstitution(experience.institutionID || '');
      } else {
        setPublicInstitution(experience.institutionID || '');
      }
      setInstitutionName(experience.institutionName || '');
      setPosition(experience.positionID || '');
    }
  }, [experience]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (institution !== '' && institutionType === 'PublicService') {
          const institutionsResponse = await publicAxios.get(`/static-data/public-institutions?institutionTypeID=${institution}`);
          setPublicInstitutions(institutionsResponse.data);
          const positionsResponse = await publicAxios.get(`/static-data/public-positions?institutionTypeID=${institution}`);
          setPositions(positionsResponse.data);
        }
      } catch (error) {
        console.error('Error fetching languages or certifications', error);
      }
    };

    fetchData();
  }, [institution]);

  function getValidationMessage() {
    if (startDate === null && endDate === null && error) {
      return <Typography variant="caption" color="error" >
        * Las fechas de inicio y finalización son requeridas
      </Typography>
    } else if (startDate === null && error) {
      return <Typography variant="caption" color="error" sx={{ mt: 3 }}>
        * La fecha de inicio es requerida
      </Typography>
    } else if (endDate === null && error) {
      return <Typography variant="caption" color="error" sx={{ mt: 3 }} >
        * La fecha de finalización es requerida
      </Typography>
    }
    return ""; // No hay errores
  }

  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  const validateURL = (url) => {
    return !url.includes('linkedin');
  };

  const handleSendJobExperience = async () => {
    setIsLoading(true);
    if (user.state !== 'admin') {
      if (!institutionType || (institutionType !== 'Independient' && !institution) || (institutionType !== 'Independient' && !position) || (!validateURL(urlInput) && urlInput !== '') || !startDate || (!previus && !endDate) || !file) {
        setIsLoading(false);
        setError(true);
        return;
      }
    }

    const jobData = new FormData();
    if (user.state !== 'admin') {
      jobData.append('institutionType', institutionType);
      if (institutionType !== 'Independient') {
        if (institutionType !== 'PublicService') {
          jobData.append('institutionID', institution);
        } else {
          jobData.append('institutionID', publicInstitution);
        }
        jobData.append('positionID', position);
      }
      if (institution === 1) {
        jobData.append('institutionName', institutionName);
      }
      if (emails.length > 0) {
        jobData.append('emails', JSON.stringify(emails));
      }
      jobData.append('startDate', getFullDate(startDate));
      if (previus) {
        jobData.append('endDate', getFullDate(endDate));
      } else {
        jobData.append('endDate', endDate);
      }
    }
    jobData.append('userID', userProfileId);
    if (urlInput !== '') {
      jobData.append('url', urlInput);
    }
    jobData.append('documentType', previus ? 'previousJob' : 'currentJob');
    if (file) {
      jobData.append('file', file);
    }

    try {
      let response;
      if (user.state === 'admin') {
        response = await userAxios.patch(`/professional/${experience.id}`, jobData);
      } else {
        response = await userAxios.post(`/professional`, jobData);
      }
      if (response.data.success) {
        setIsLoading(false);
        const message = response.data.message === 'update' ? 'Certificación actualizada con éxito' : 'Certificación creada con éxito';
        showSnackbar(message, 'success');
        navigate('/choose-document', { replace: true });


      } else {
        setIsLoading(false);
        showSnackbar(response.data.message, 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error while creating job certification', error);
      showSnackbar('Error al crear la certificación de experiencia laboral', 'error');
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2} direction="column">
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <BackButton path={"/choose-document"} back={true} />
          </Grid>
          <Grid item container spacing={2} alignItems="center" >
            <Grid item xs={12} sm={6} >
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                <Grid item xs={12} sx={{ my: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', direction: 'column' }}>
                    <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 4 }}>
                    {description}
                  </Typography>
                </Grid>
                <Grid item >
                  <Grid item >
                    <Typography >
                      Lugar de trabajo
                    </Typography>
                  </Grid>
                  <SelectOption
                    options={institutionTypes}
                    onSelectionChange={setInstitutionType}
                    name={'Escoge uno'}
                    width={isMobile ? "100%" : "70%"}
                    selectedOption={institutionType}
                    error={error && !institutionType}
                    helperText={error && !institutionType ? 'Este campo es requerido' : ''}
                  />
                </Grid>
              </Grid>
              {institutionType !== 'Independient' && (
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }} >
                  <Grid item >
                    <Typography >
                      Dónde?
                    </Typography>
                  </Grid>
                  <Grid item >
                    <SelectOption
                      options={institutions}
                      onSelectionChange={setInstitution}
                      name={'Escoge uno'}
                      width={isMobile ? "100%" : "70%"}
                      selectedOption={institution}
                      error={error && !institution}
                      helperText={error && !institution ? 'Este campo es requerido' : ''}
                    />
                  </Grid>
                </Grid>
              )}
              {institution === 1 && (
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }}>
                  <Grid item xs={6} >
                    <Grid item >
                      <Typography >
                        Nombre de la Institución/Firma/Empresa
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: isMobile ? "100%" : "70%" }}
                      size="small"
                      id="referenceText"
                      label="Escribe aquí..."
                      value={institutionName}
                      onChange={(event) => handleInputChange(event, setInstitutionName)}
                      error={error && !institutionName}
                      helperText={error && !institutionName ? 'Este campo es obligatorio' : ''}
                    />
                  </Grid>
                </Grid>
              )}
              {institutionType === 'PublicService' && (
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }} >
                  <Grid item >
                    <Typography >
                      Escoge una institución
                    </Typography>
                  </Grid>
                  <Grid item >
                    <SelectOption
                      options={publicInstitutions}
                      onSelectionChange={setPublicInstitution}
                      name={'Escoge una'}
                      width={isMobile ? "100%" : "70%"}
                      selectedOption={publicInstitution}
                      error={error && !publicInstitution}
                      helperText={error && !publicInstitution ? 'Este campo es requerido' : ''}
                    />
                  </Grid>
                </Grid>
              )}
              {institutionType !== 'Independient' && (
                <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }} >
                  <Grid item >
                    <Typography >
                      Posición
                    </Typography>
                  </Grid>
                  <Grid item >
                    <SelectOption
                      options={positions}
                      onSelectionChange={setPosition}
                      name={'Escoge una'}
                      width={isMobile ? "100%" : "70%"}
                      selectedOption={position}
                      error={error && !position}
                      helperText={error && !position ? 'Este campo es requerido' : ''}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }}>
                <Grid item sx={{ mt: 1 }}>
                  <Typography >
                    {previus ? "Fecha de Inicio" : "Fecha de Inicio hasta hoy"}
                  </Typography>
                </Grid>
                <Grid item container >
                  <Grid item sx={{ mt: 2, mr: 3 }} >
                    <DatePicker
                      label="Fecha de Inicio"
                      value={startDate}
                      slotProps={{ textField: { size: 'small' } }}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      selectedOption={startDate}
                    />
                  </Grid>
                  {previus && (
                    <>
                      <Grid item sx={{ mt: 2, mr: 3 }}>
                        <DatePicker
                          label="Fecha de Finalización"
                          value={endDate}
                          slotProps={{ textField: { size: 'small' } }}
                          border="red"
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} />
                          )}
                          selectedOption={endDate}
                        />
                      </Grid>
                    </>
                  )}
                  {getValidationMessage()}
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10, mb: 2 }} >
                <Grid item container >
                  <Grid item xs={6} sx={{ mt: 2, mr: -2 }} >
                    <Grid item >
                      <Typography >
                        Adjunta Link (Opcional)
                      </Typography>
                    </Grid>
                    <TextField
                      margin="normal"
                      sx={{ width: isMobile ? "100%" : "87%" }}
                      size="small"
                      id="referenceText"
                      placeholder="Escribe aquí..."
                      label="Link"
                      value={urlInput}
                      onChange={(event) => handleInputChange(event, setUrlInput)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} sx={{ mt: 2, mr: 3 }}>
                    <Grid item sx={{ mb: 2 }}>
                      <Typography >
                        Documento de respaldo
                        <Tooltip 
                          title="Para acreditar tu experiencia puedes utilizar cualquier antecedente escrito, como un contrato, un escrito judicial, recortes de prensa, etc. El formato también es libre, pudiendo ser fotografías, archivos .pdf, links, o incluso pantallazos ¡Bienvenida la creatividad!"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                maxWidth: isMobile ? '150px' : '400px', // Ancho según si es móvil
                                fontSize: isMobile ? '10px' : '13.15px',  // Tamaño de fuente según si es móvil
                                padding: isMobile ? '6px' : '11px',    // Padding según si es móvil
                                whiteSpace: 'normal', // Ajusta el texto al ancho del tooltip
                                textAlign: 'center',  // Centra el texto en el tooltip
                                backgroundColor: 'rgba(128, 128, 128, 0.975)',
                              },
                            },
                          }}
                        >
                          <HelpOutlineOutlinedIcon sx={{ml: 1}}/>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item >
                      <FileUploadStatus setFile={setFile} file={file} />
                      {error && !file && <Typography variant="caption" color="error">* Sube un documento para comprobar esta certificación</Typography>}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile &&
              <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
                <Avatar variant="rounded" src={image} sx={{ width: '200px', height: '200px' }} />
              </Grid>
            }
            <Grid item container direction="column" xs={12} justifyContent="flex-start" sx={{ ml: isMobile ? 1 : 10 }} >
              <Grid item sx={{ mb: 2 }}>
                <Typography >
                  Danos el Correo electrónico de personas que puedan ayudar con el proceso de verificación.
                </Typography>
              </Grid>
              <Grid item >
                <EmailChipsInput setEmails={setEmails} emails={emails} />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} sx={{ ml: isMobile ? 1 : 10, mb: 3 }} >
              <Button
                variant="primary"
                onClick={handleSendJobExperience}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default UploadProfessional;
