import React from 'react';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { Link } from 'react-router-dom';

function ButtonsReportReference({ userRole, handleModalOpen, handleOpen }) {
    const isUnverified = userRole === 'unverified';

    const verificationTooltip = (
        <>
            <Typography>Tienes que estar verificado para realizar esta acción.</Typography>
            <Button 
                component={Link} 
                to="/video-call" 
                size="small" 
                sx={{ mt: 1 }}
            >
                Ir a verificarse
            </Button>
        </>
    );

    return (
        <>
            <Tooltip 
                title={isUnverified ? verificationTooltip : ''}
                arrow
                interactive
            >
                <span> {/* Tooltip requires a non-disabled child */}
                    <IconButton
                        disabled={isUnverified}
                        sx={{
                            position: 'relative',
                            ml: 3,
                            '&:disabled': {
                                color: 'inherit',
                                '& .MuiSvgIcon-root': {
                                    opacity: 0.5, // Difuminar el ícono
                                    zIndex: 2 // Coloca el ícono al frente
                                }
                            }
                        }}
                    >
                        <Button variant="secondary" onClick={handleOpen} sx={{ zIndex: 1 }}>
                            Reportar
                        </Button>
                        {isUnverified && <LockIcon sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '1.5rem' }} />}
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
}

export default ButtonsReportReference;
