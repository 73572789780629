import React, { useState, startTransition } from 'react';
import { Button, Typography, Container, Box, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert } from '@mui/material';
import BackButton from '../components/BackButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { userAxios } from '../apiClients';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import simuladorGif from '../assets/gifs/Simulador_v3.gif';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'

const Simulator = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const isQualified = Object.keys(user?.qualification).length > 0 ? true : false;
    const [isNotQualifiedModalVisible, setIsNotQualifiedModalVisible] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' });

    const handleGoToSimulator = () => {
        startTransition(() => {
            if (isQualified || user.userRole === 'viewer') {
                navigate('/use-simulator');
            } else {
                setIsNotQualifiedModalVisible(true);
            }
        });
    };

    const handleQualifyMe = () => {
        startTransition(() => {
            setIsNotQualifiedModalVisible(false);
            navigate('/qualification');
        });
    };

    const handlePayCredits = () => {
        startTransition(() => {
            setIsNotQualifiedModalVisible(false);
            if (user.credits < 2000) {
                showSnackbar('No tienes suficientes créditos para pagar', 'error');
                return;
            }
        });
    };

    return (
        <Box sx={{ flexGrow: 1, padding: 2, width: '100%' }}>
            <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item container direction="row" alignItems="center" spacing={2}>
                    <BackButton path={localStorage.getItem('simulatorPath') ? localStorage.getItem('simulatorPath') : '/myprofile'} />
                </Grid>
                <Box m={4} textAlign="center">
                    <Typography variant="h4" component="h1" style={{ color: '#F67147', fontWeight: 'bold' }} gutterBottom>Simular mi Calificación</Typography>
                    <Typography variant="h5" component="h2" gutterBottom><strong>¡Explora el Potencial del Simulador de Calificación!</strong></Typography>
                    <Box my={2}>
                        <img src={simuladorGif} alt="Simulador" style={{ width: '20%', height: '20%' }} />
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        En el Simulador Soubid podrás evaluar en abstracto tus distintas opciones profesionales y académicas, y tomar mejores decisiones (por ejemplo, buscando trabajo o eligiendo Universidad).
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        ¡Haz clic en <span style={{ color: '#F67147' }}>Simulador</span> y comienza tu viaje para perfeccionar tu perfil profesional!
                    </Typography>
                    <Box textAlign="center" my={2}>
                        <Button variant="primary" onClick={handleGoToSimulator}>
                            Simulador
                        </Button>
                    </Box>
                </Box>

                <Dialog open={isNotQualifiedModalVisible} onClose={() => setIsNotQualifiedModalVisible(false)}>
                    <DialogTitle>
                        Acceso Restringido
                        <IconButton
                            aria-label="close"
                            onClick={() => setIsNotQualifiedModalVisible(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Solo los usuarios calificados pueden acceder al simulador.
                            Puedes calificarte ahora o pagar 2000 créditos para acceder.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleQualifyMe} color="primary">Calificarme</Button>
                        <Button onClick={handlePayCredits} color="secondary">Pagar 2000 créditos</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ ...alert, open: false })}>
                    <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            </Grid>
        </Box>
    );
};

export default Simulator;
