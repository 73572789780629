import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { CssBaseline } from '@mui/material';
import qualification2 from '../assets/gifs/qualification2.gif';
import ratings from '../assets/gifs/ratings.gif'; 
import simulador from '../assets/gifs/Simulador_v3.gif';
import VerificacionCruzada from '../assets/gifs/Verificacion_cruzada_v3.gif';

function HowWorks() {
    const sections = [
        {
        imgSrc: qualification2,
        title: "Crea tu cuenta",
        text: "Para comenzar deberás crearte una cuenta y aportar tus antecedentes personales, académicos y profesionales. Esos antecedentes serán sometidos a un proceso de verificación descentralizada, llevado a cabo por otros usuarios de la plataforma. Así nos aseguramos de que en Soubid todas las personas existan, y sean quienes dicen que son."
        },
        {
        imgSrc: ratings,
        title: "Califícate",
        text: "Una vez verificada tu información, podrás someterla al modelo de calificación de Soubid, el cuál ha sido definido en base a parámetros científicos y objetivos, con la ayuda de los principales líderes de la industria. Como es obvio, la calificación sólo considera los antecedentes que ya se encuentren verificados."
        },
        {
        imgSrc: VerificacionCruzada,
        title: "Verifica",
        text: "Como miembro tendrás la posibilidad de participar en la verificación descentralizada de antecedentes, y con ello ayudar a Soubid a mantenerse como el espacio seguro y confiable que es. Para motivar a los usuarios a que verifiquen, cada acción que ellos realicen en beneficio de la comunidad será recompensada con créditos que podrán ser utilizados en la misma plataforma."
        },
        {
        imgSrc: simulador,
        title: "Participa y gana",
        text: "Participa activamente en la plataforma y gana créditos por ayudar a verificar y mantener la comunidad segura. Tus esfuerzos serán recompensados y podrás usar estos créditos dentro de Soubid para acceder a beneficios exclusivos."
        }
    ];

    const titleColor = '#F67147'; // Color principal
    const textColor = '#333'; // Color del texto
    const boxBackgroundColor = '#F3F6FA'; // Fondo de los boxes

    return (
        <>
        <CssBaseline />
        <Container maxWidth="xl" disableGutters sx={{ bgcolor: '#fff', px: 2, pt: 5 }}>
            <Typography variant="h3" align="center" gutterBottom sx={{ color: titleColor, fontWeight: 'bold', mt: 4 }}>
            ¿Cómo funciona?
            </Typography>
            <Typography variant="h5" align="center" sx={{ color: textColor, mb: 8, maxWidth: '800px', mx: 'auto' }}>
            Soubid funciona gracias a sus usuarios. Ellos nos ayudan con las dos tareas sobre las que descansa el modelo: la verificación y la calificación.
            </Typography>
            {sections.map((section, index) => (
            <Grid container key={index} spacing={4} sx={{ alignItems: 'center', mb: 5 }}>
                <Grid item xs={12} md={12}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: boxBackgroundColor,
                    borderRadius: '30px',
                    p: 4,
                    boxSizing: 'border-box',
                    flexDirection: { xs: 'column', md: index % 2 === 0 ? 'row' : 'row-reverse' },
                    gap: { xs: 2, md: 4 } // Aumenta el espacio entre la imagen y el texto
                }}>
                    <Box sx={{ flexBasis: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                        <Box component="img" src={section.imgSrc} alt={`Imagen de ${section.title}`} sx={{ maxWidth: '100%', borderRadius: '30px' }} />
                    </Box>
                    <Box sx={{ flexBasis: '50%', maxWidth: '600px', p: 2 }}>
                    <Typography variant="h3" sx={{ textAlign: 'left', color: titleColor, fontWeight: 'bold', mb: 2 }}>
                        {section.title}
                    </Typography>
                    <Typography variant="h5" sx={{ textAlign: 'left', color: textColor }}>
                        {section.text}
                    </Typography>
                    </Box>
                </Box>
                </Grid>
            </Grid>
            ))}
        </Container>
        </>
    );
}

export default HowWorks;
