import React, { useState } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { getFullDate } from '../../../utils/Validators';

function TitleForm({ onAddAntecedent }) {
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (!endDate) {
      setError(true);
      return;
    }
    onAddAntecedent({
      id: 1,
      educationType: 'Título profesional',
      endDate: getFullDate(endDate),
    });
    setError(false); // Reset error if submission is successful
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Agregar Título Profesional
        </Typography>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} sm={6}>
            <Typography sx={{ mb: 2 }}>Fecha del título profesional</Typography>
            <DatePicker
              label="Fecha del título"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              slotProps={{
                textField: {
                  size: 'small',
                  error: error && !endDate,
                  helperText: error && !endDate ? 'Este campo es obligatorio' : '',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
          <Button variant="primary" onClick={handleSubmit}>
            Agregar Antecedente
          </Button>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}

export default TitleForm;
