import React from 'react';
import { Box, Button, Stepper, Step, StepLabel, Typography, styled } from '@mui/material';
import Logo from '../utils/LogoBlack.png'; // Asegúrate de que la ruta a tu imagen del logo sea correcta
import { useNavigate } from 'react-router-dom';

const steps = [
  'Crea tu cuenta',
  'Verifica tu ID',
  'Descarga la App',
];

// Estilización del componente Stepper
const CustomStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepIcon-root.Mui-completed': {
    color: 'white', // Color del icono completado
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: 'white', // Color del icono activo
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: 'white', // Color del texto del paso completado
  },
  '& .MuiStepLabel-label.Mui-active': {
    color: 'white', // Color del texto del paso activo
  },
  '& .MuiStepConnector-line': {
    borderColor: 'white', // Color de la línea del conector
  },
}));

export default function VerifiedAccount() {
  const navigate = useNavigate();

  const handleVerifyProcess = () => {
    navigate('/video-call');
  };

  const handleLater = () => {
    navigate('/download-app');
  }

  return (
    <Box sx={{
      height: '90vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ height: 200, width: 200, mb: 4 }} />
      <Typography variant="h4" sx={{ color: 'white', mb: 2 }}>
        ¡Tu correo electrónico ha sido verificado!
      </Typography>
      <CustomStepper activeStep={1} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={index === 0}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </CustomStepper>
      <Box sx={{ display: 'flex', mt: 4 }}>
        <Button variant="primary" sx={{ mr: 2, bgcolor: 'black', color: 'white', '&:hover': { bgcolor: 'darkgrey' } }} onClick={handleVerifyProcess}>
          Iniciar el proceso de verificación
        </Button>
        <Button variant="outlined" sx={{ color: 'white', borderColor: 'white', '&:hover': { bgcolor: 'white', color: 'black' } }} onClick={handleLater}>
          Más tarde
        </Button>
      </Box>
    </Box>
  );
}
