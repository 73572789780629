import React from 'react';
import { Modal, Box, Typography, IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { userAxios } from '../../apiClients';
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from '../snackbar/SnackbarContext';


const InfoModal = ({ open, handleClose, item }) => {
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();

  const handleOpenDocument = async () => {
    const response = await userAxios.get(`/users/get-aws-url?antecedentID=${item.id}&type=${item.type}&userID=${user.id}`);
    const data = response.data;
    if (data.success) {
      window.open(data.url, '_blank', 'noopener,noreferrer');
    } else {
      showSnackbar(data.message, 'error');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          position: 'relative'
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <CheckCircleOutlineIcon color="success" sx={{ mr: 2 }} />Información del antecedente
        </Typography>

        {item.url && (
          <Typography sx={{ mt: 2 }}>
            Url de acreditación:
            <Link
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: '#0271b6', textDecoration: 'underline', ml: 1 }}
            >
              Abrir
            </Link>
          </Typography>
        )}

        {item.urlAWS && (
          <Typography sx={{ mt: 2 }}>
            Documento de respaldo:
            <Link
              component="button" // Usar un botón para manejar el click
              onClick={handleOpenDocument}
              sx={{ color: '#0271b6', textDecoration: 'underline', ml: 1 }}
            >
              Abrir
            </Link>
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default InfoModal;
