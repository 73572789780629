import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ResponsiveAppBar from './views/NavBar';
import ResponsiveFootBar from './views/FootBar';
import SettingsAndPrivacy from './views/Settings';
import UploadDocument from './views/UploadDocuments/UploadDocument';
import { Box } from '@mui/material';
import MyProfile from './views/MyProfile.js';
import Directory from './views/Directory.js';
import Profile from './views/Profile.js';
import SignIn from './views/SignIn.js';
import SignUp from './views/SignUp.js';
import RequestAccess from './views/RequestAccess.js';
import RequestSuccess from './views/RequestSuccess.js';
import RequestState from './views/RequestState.js';
import VerifiedAccount from './views/VerifiedAccount.js';
import VideoCall from './views/VideoCall.js';
import DownloadApp from './views/DownloadApp.js';
import { SnackbarProvider } from './components/snackbar/SnackbarContext.js';
import UploadEducation from './views/UploadDocuments/UploadEducation.js';
import UploadProfessional from './views/UploadDocuments/UploadProfessional.js';
import UploadProfessionalTitle from './views/UploadDocuments/UploadProfessionalTitle.js';
import UploadScholarship from './views/UploadDocuments/UploadScholarship.js';
import UploadIntAccreditation from './views/UploadDocuments/UploadIntAccreditation.js';
import UploadOtherBackground from './views/UploadDocuments/UploadOtherBackground.js';
import UploadAcademic from './views/UploadDocuments/UploadAcademic.js';
import UploadRanking from './views/UploadDocuments/UploadRanking.js';
import UploadPublication from './views/UploadDocuments/UploadPublication.js';
import UploadRelevantExperience from './views/UploadDocuments/UploadRelevantExperience.js';
import UploadIdiom from './views/UploadDocuments/UploadIdiom.js';
import ClaimProfile from './views/ClaimProfile.js';
import ReferenceUser from './components/reference/ReferenceUser.js';
import PendingDocument from './views/PendingDocument.js';
import { QueueRoute, RoleBasedRoute, RoleAllRoute, RoleBasedRouteSI, RoleInstitutional, ProtectedRoute } from './security/RoleBasedRoute';
import PrivacyPolicy from './views/PrivacyPolicy.js';
import Recommendations from './components/reference/Recommendations.js';
import QualificationSuccess from './views/payment/QualificationSuccess.js';
import Qualification from './components/qualification/Qualification.js';
import ShowQualification from './components/qualification/ShowQualification.js';
import InstructionView from './components/qualification/InstructionView.js';
import { AuthProvider } from './hooks/useAuth.js';
import Home from './views/Home.js';
import HomeInstitution from './views/HomeInstitution.js';
import HomeUser from './views/HomeUser.js';
import HowWorks from './views/HowWorks.js';
import TermsAndConditions from './views/TermsAndConditions.js';
import ContactView from './views/ContactView.js';
import RatingsView from './views/RatingsView.js';
import Legends from './views/Legends/Legends.js';
import Simulator from './views/Simulator.js';
import RequalifyView from './components/qualification/RequalifyView.js'
import SimulatorView from './components/simulator/SimulatorView.js';
import AdminOptionsForm from './components/settings/AdminOptionsForm.js';

// Amplify
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import '@aws-amplify/ui-react/styles.css';
import UnauthorizedPage from './views/UnauthorizedPage.js';
import SignUpInvite from './views/SignUpInvite.js';
import ForgotPassword from './views/ForgotPassword.js';
import OnboardingExclusive from './views/OnboardingExclusive.js';
import SignUpExclusive from './views/SignUpExclusive.js';
import Credits from './views/Credits.js';
import QuestionAnswers from './views/QuestionAnswer.js';

import { ScrollPositionProvider } from './components/directory/DirectoryContext.js';
import OnbordingRequestAccess from './views/OnbordingRequestAccess.js';
Amplify.configure(amplifyconfig); // Importa el contexto

const App = () => {
  const location = useLocation();
  const hideNavbarRoutes = ['/qualification-success'];
  const hideFooterRoutes = ['/onboarding', '/qualification-success'];

  return (
    <>
      <AuthProvider>
        <SnackbarProvider>
          <ScrollPositionProvider>
            {!hideNavbarRoutes.includes(location.pathname) && <ResponsiveAppBar />}
            <Box sx={{ minHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
              <Routes>
                <Route path='/' element={<Home />}></Route>
                <Route path='/signin' element={<SignIn />}></Route>
                <Route path='/signup' element={<SignUp />}></Route>

                <Route path='/profile-caldery-hasche' element={<Profile />} />
                <Route path='/profile-rubilar-suarez' element={<Profile />} />
                <Route path='/profile-bravo-valenzuela' element={<Profile />} />
                <Route path='/profile-abascal-marin' element={<Profile />} />
                <Route path='/profile-sepulveda-matteo' element={<Profile />} />
                <Route path='/profile-reyes-espinoza' element={<Profile />} />
                <Route path='/profile-del-real-pascual' element={<Profile />} />
                <Route path='/profile-kehr-castillo' element={<Profile />} />
                <Route path='/profile-lagos-soza' element={<Profile />} />
                <Route path='/profile-larrain-jimenez' element={<Profile />} />
                <Route path='/profile-larrain-siebel' element={<Profile />} />
                <Route path='/profile-montero-varas' element={<Profile />} />
                <Route path='/profile-rodriguez-ariztia' element={<Profile />} />
                <Route path='/profile-espinosa-meza' element={<Profile />} />
                <Route path='/profile-diaz-ibanez' element={<Profile />} />
                <Route path='/profile-carcelen-pacheco' element={<Profile />} />
                <Route path='/profile-perez-jimenez' element={<Profile />} />
                <Route path='/profile-canales-subercaseaux' element={<Profile />} />
                <Route path='/profile-carloza-carmona' element={<Profile />} />
                <Route path='/profile-honour-manriquez' element={<Profile />} />
                <Route path='/profile-troncoso-mella' element={<Profile />} />
                <Route path='/profile-spuhr-ramirez' element={<Profile />} />
                <Route path='/profile-torres-guzman' element={<Profile />} />
                <Route path='/profile-nualart-vittini' element={<Profile />} />
                <Route path='/profile-veas-mofdi' element={<Profile />} />

                <Route path='/profile-wiegand-restrepo' element={<Profile />} />
                <Route path='/profile-garcia-de-la-huerta-vial' element={<Profile />} />
                <Route path='/profile-caorsi-opazo' element={<Profile />} />
                <Route path='/profile-rosso-galleguillos' element={<Profile />} />
                <Route path='/profile-gomez-moffat' element={<Profile />} />
                <Route path='/profile-contente-infante' element={<Profile />} />
                <Route path='/profile-ortiz-lagos' element={<Profile />} />
                <Route path='/profile-aguad-toledo' element={<Profile />} />
                <Route path='/profile-plaza-santibanez' element={<Profile />} />
                <Route path='/profile-jimenez-salas' element={<Profile />} />
                <Route path='/profile-izquierdo-parada' element={<Profile />} />
                <Route path='/profile-de-smet-dolbecke-errazuriz' element={<Profile />} />
                <Route path='/profile-cifuentes-miranda' element={<Profile />} />
                <Route path='/profile-bellagamba-baldovino' element={<Profile />} />
                <Route path='/profile-undurraga-zanartu' element={<Profile />} />
                <Route path='/profile-mitjaew-escobar' element={<Profile />} />
                <Route path='/profile-arias-edwards' element={<Profile />} />
                <Route path='/profile-gonzalez-montt' element={<Profile />} />
                <Route path='/profile-palacios-cobo' element={<Profile />} />
                <Route path='/profile-galvez-plaza' element={<Profile />} />
                <Route path='/profile-carrasco-acuna' element={<Profile />} />
                <Route path='/profile-maria-jose-sotomayor' element={<Profile />} />

                <Route path='/forgot-password' element={<ForgotPassword />}></Route>
                <Route path='/request-access' element={<RequestAccess />}></Route>
                <Route path='/request-success' element={<RequestSuccess />}></Route>
                <Route path='/request-state' element={<RequestState />}></Route>
                <Route path="/verified-account" element={<VerifiedAccount />}></Route>
                <Route path='/video-call' element={<VideoCall />}></Route>
                <Route path='/exclusive/:token' element={<SignUpExclusive />}></Route>
                <Route path='/signup-invite' element={<SignUpInvite />}></Route>
                <Route path='/directory' element={<RoleBasedRouteSI component={Directory} />}></Route>
                <Route path='/onboarding' element={<RoleBasedRouteSI component={OnboardingExclusive} />} />
                <Route path='/onboarding-new' element={<QueueRoute component={OnbordingRequestAccess} />}></Route>
                <Route path='/home' element={<Home />}></Route>
                <Route path='/home-user' element={<RoleBasedRouteSI component={HomeUser} />}></Route>
                <Route path='/how-works' element={<HowWorks />} />
                <Route path='/credits' element={<RoleBasedRouteSI component={Credits} />} />
                <Route path='/qualification-success' element={<QualificationSuccess />} />
                <Route path='/legends' element={<Legends />} />
                <Route path='/go-to-qualification' element={<QualificationSuccess />} />
                <Route path='/qualification-error' element={<QualificationSuccess />} />
                <Route path='/qualification' element={<RoleBasedRouteSI component={Qualification} />} />
                <Route path='/question-answer' element={<QuestionAnswers />} />
                <Route path='/show-qualification' element={<RoleBasedRouteSI component={ShowQualification} />} />
                <Route path='/instruction-qualification' element={<InstructionView />} />
                <Route path='/requalify' element={<ProtectedRoute component={RequalifyView} />} />
                <Route path='/settings-email-autoNavigateToAdminOptions' element={<ProtectedRoute component={SettingsAndPrivacy} />} />
                {/* <Route path='/profile/:id' element={<RoleBasedRoute component={Profile} />} /> */}
                <Route path='/profile/:id' element={<Profile />} />
                <Route path='/myprofile' element={<RoleAllRoute component={MyProfile} />} />
                <Route path='/settings' element={<RoleAllRoute component={SettingsAndPrivacy} />} />
                <Route path='/choose-document' element={<RoleAllRoute component={UploadDocument} />} />
                <Route path='/upload-education' element={<RoleAllRoute component={UploadEducation} />} />
                <Route path='/upload-academic' element={<RoleAllRoute component={UploadAcademic} />} />
                <Route path='/upload-int-accreditation' element={<RoleAllRoute component={UploadIntAccreditation} />} />
                <Route path='/upload-other-background' element={<RoleAllRoute component={UploadOtherBackground} />} />
                <Route path='/upload-relevant-experience' element={<RoleAllRoute component={UploadRelevantExperience} />} />
                <Route path='/upload-ranking' element={<RoleAllRoute component={UploadRanking} />} />
                <Route path='/upload-scholarship' element={<RoleAllRoute component={UploadScholarship} />} />
                <Route path='/upload-professional' element={<RoleAllRoute component={UploadProfessional} />} />
                <Route path='/upload-professional-title' element={<RoleAllRoute component={UploadProfessionalTitle} />} />
                <Route path='/upload-publication' element={<RoleAllRoute component={UploadPublication} />} />
                <Route path='/upload-idiom' element={<RoleAllRoute component={UploadIdiom} />} />
                <Route path='/claim-profile' element={<RoleBasedRoute component={ClaimProfile} />} />
                <Route path='/reference-user' element={<RoleBasedRouteSI component={ReferenceUser} />} />
                <Route path='/recommendations' element={<RoleBasedRouteSI component={Recommendations} />} />
                <Route path='/use-simulator' element={<RoleBasedRouteSI component={SimulatorView} />} />
                <Route path='/ratings' element={<RatingsView />} />
                <Route path='/pendings' element={<RoleBasedRoute component={PendingDocument} />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/contact' element={<ContactView />} />
                <Route path='/unauthorized' element={<UnauthorizedPage />}></Route>
                <Route path='/simulator' element={<RoleBasedRouteSI component={Simulator} />} />
                <Route path='/app' element={<DownloadApp />}></Route>
                <Route path='/home-institution' element={<RoleInstitutional component={HomeInstitution} />} />
              </Routes>
            </Box>
            {!hideFooterRoutes.includes(location.pathname) && <ResponsiveFootBar />}
          </ScrollPositionProvider>
        </SnackbarProvider>
      </AuthProvider>
    </>
  );
};

export default App;
