import React from 'react';
import { Pagination, Stack, useMediaQuery, useTheme } from '@mui/material';

function MyPagination({ pages, setPages }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Manejador para cambiar la página
  const handleChange = (event, value) => {
    setPages(prev => ({ ...prev, currentPage: value }));
  };

  return (
    <Stack
      spacing={2}
      alignItems="center"
      sx={{
        p: isMobile ? 2 : 5, // Menor padding en móvil
      }}
    >
      <Pagination
        count={pages.totalPages}
        page={pages.currentPage}
        onChange={handleChange}
        color="primary"
        showFirstButton={!isMobile} // Oculta el botón "Primero" en móvil
        showLastButton={!isMobile} // Oculta el botón "Último" en móvil
        shape="rounded"
        sx={{
          // Ajusta el tamaño y el espaciado de los botones de paginación en móvil
          '& .MuiPaginationItem-root': {
            fontSize: isMobile ? '0.75rem' : '1rem', // Tamaño de fuente más pequeño en móvil
            width: isMobile ? '32px' : '40px', // Ancho de los botones más pequeño en móvil
            height: isMobile ? '32px' : '40px', // Alto de los botones más pequeño en móvil
          },
        }}
      />
    </Stack>
  );
}

export default MyPagination;
