import React from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const CustomModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '16px',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  textAlign: 'center',
  maxWidth: '400px',
  width: '100%',
}));

const CancelButton = styled(Button)(({ theme }) => ({
  borderColor: 'black',
  color: 'black',
  borderRadius: '24px',
  padding: theme.spacing(1, 3),
  marginTop: theme.spacing(2),
}));

const Divider = styled(Box)(({ theme }) => ({
  width: '80%',
  height: '1px',
  backgroundColor: '#E0E0E0',
  margin: theme.spacing(2, 0),
}));

function ModalHome({ open, handleClose }) {
  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalContent>
        <Typography variant="h5" id="modal-title" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          ¿Aún no eres miembro?
        </Typography>
        <Typography variant="body1" id="modal-description" sx={{ color: 'gray', marginBottom: 4 }}>
          Si deseas continuar con tu búsqueda, debes iniciar sesión.
        </Typography>
        <Button
          component={Link}
          to="/signin"
          fullWidth
          sx={{ mt: 2, bgcolor: '#F67147', color: 'white' }} // Ajusta el color de fondo del botón
        >
          Iniciar Sesión
        </Button>
        <Typography variant="body1" sx={{ color: 'gray', marginTop: 3, marginBottom: 1 }}>
          Si no tienes una cuenta, puedes crear una aquí
        </Typography>
        <Button
          component={Link}
          to="/request-access"
          fullWidth
          sx={{ mt: 2, color: 'white', bgcolor: '#5A5A5A', '&:hover': { bgcolor: '#333' } }} // Ajusta el color de fondo del botón
        >
          Solicitar Acceso
        </Button>
        <Divider />
        <CancelButton variant="outlined" onClick={handleClose}>
          Cancelar
        </CancelButton>
      </ModalContent>
    </CustomModal>
  );
}

export default ModalHome;
