import React from 'react';
import { Box, Typography, Button, Avatar, useMediaQuery, useTheme } from '@mui/material';

const QualificationCard = ({ name, type, description, imageSource, buttonColor, handleQualification }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        marginBottom: 2,
        borderColor: '#ddd',
        borderWidth: 1,
        borderRadius: 2,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: isMobile ? 'center' : 'flex-start',
        boxShadow: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: isMobile ? 2 : 0,
          marginRight: isMobile ? 0 : 2,
        }}
      >
        <Avatar
          src={imageSource}
          sx={{
            width: 90,
            height: 90,
            marginRight: isMobile ? 0 : 2,
            marginBottom: isMobile ? 2 : 0,
          }}
        />
        <Box sx={{ flex: 1, textAlign: isMobile ? 'center' : 'left' }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: '#666', fontFamily: 'Poppins' }}>
            {description}
          </Typography>
        </Box>
      </Box>
      <Button
        variant="primary"
        onClick={() => handleQualification({ type })}
        sx={{
          backgroundColor: buttonColor,
          color: '#fff',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          alignSelf: isMobile ? 'center' : 'flex-end',
          marginTop: isMobile ? 2 : 0,
        }}
      >
        Calificarme
      </Button>
    </Box>
  );
};

const QualificationBody = ({ handleQualification }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ padding: 2, backgroundColor: '#EEF1F4', borderRadius: 2, width: isMobile ? '100%' : '70%', margin: '0 auto' }}>
      <QualificationCard
        name="CALIFICACIÓN PROFESIONAL"
        type="professional"
        description="La calificación profesional es para abogados que ejercen el derecho."
        imageSource={require('../../assets/qualificationProfessional/A.png')}
        buttonColor="#F67147"
        handleQualification={handleQualification}
      />
      <QualificationCard
        name="CALIFICACIÓN ACADÉMICA"
        type="academic"
        description="La calificación académica es para abogados que se dedican total o parcialmente a la academia"
        imageSource={require('../../assets/qualificationAcademic/A.png')}
        buttonColor="#F67147"
        handleQualification={handleQualification}
      />
      <QualificationCard
        name="CALIFICACIÓN DE ESTUDIANTE"
        type="student"
        description="La calificación de estudiante es para aquellos que aún no han recibido su título o licencia de derecho."
        imageSource={require('../../assets/qualificationStudent/A.png')}
        buttonColor="#F67147"
        handleQualification={handleQualification}
      />
    </Box>
  );
};

export default QualificationBody;
