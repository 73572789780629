import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import SelectOption from '../../SelectOption';
import { publicAxios } from '../../../apiClients';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function PublicationForm({ antecedentType, onAddAntecedent, isMobile }) {
    const [formData, setFormData] = useState({
        publicationTypeID: '',
        editorialID: '',
        roleID: '',
        publicationDate: null,
    });
    const [publicationTypes, setPublicationTypes] = useState([]);
    const [editorials, setEditorials] = useState([]);
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responsePublicationTypes = await publicAxios.get(`/static-data/publication-types`);
                const responseEditorials = await publicAxios.get(`/static-data/editorials`);
                const responseRoles = await publicAxios.get(`/static-data/publication-roles`);
                setPublicationTypes(responsePublicationTypes.data);
                setEditorials(responseEditorials.data);
                setRoles(responseRoles.data);
            } catch (error) {
                setError(true);
            }
        };
        fetchData();
    }, []);

    const handleSubmit = () => {
        if (!formData.publicationTypeID || !formData.editorialID || !formData.roleID || !formData.publicationDate) {
            setError(true);
            return;
        }
        onAddAntecedent({
            id: antecedentType.id,
            antecedentType: antecedentType.name,
            publicationTypeID: formData.publicationTypeID,
            publicationTypeName: publicationTypes.find(publicationType => publicationType.id === formData.publicationTypeID).name,
            editorialID: formData.editorialID,
            roleID: formData.roleID,
            publicationDate: formData.publicationDate.toISOString().split('T')[0], // formateo de fecha
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ backgroundColor: '#EEF1F4', p: 4, pt: 2, borderRadius: 5, width: '100%', mt: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Agregar {antecedentType.name}
                </Typography>
                <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
                    <Grid item xs={12} sm={4}>
                        <Typography>Tipo de Publicación</Typography>
                        <SelectOption
                            options={publicationTypes}
                            onSelectionChange={(value) => setFormData({ ...formData, publicationTypeID: value })}
                            name={'Selecciona un tipo'}
                            width="100%"
                            selectedOption={formData.publicationTypeID}
                            error={error && !formData.publicationTypeID}
                            helperText={error && !formData.publicationTypeID ? 'Este campo es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography>Editorial</Typography>
                        <SelectOption
                            options={editorials}
                            onSelectionChange={(value) => setFormData({ ...formData, editorialID: value })}
                            name={'Selecciona una editorial'}
                            width="100%"
                            selectedOption={formData.editorialID}
                            error={error && !formData.editorialID}
                            helperText={error && !formData.editorialID ? 'Este campo es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography>Rol en la Publicación</Typography>
                        <SelectOption
                            options={roles}
                            onSelectionChange={(value) => setFormData({ ...formData, roleID: value })}
                            name={'Selecciona un rol'}
                            width="100%"
                            selectedOption={formData.roleID}
                            error={error && !formData.roleID}
                            helperText={error && !formData.roleID ? 'Este campo es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{mb: 2}}>Fecha de Publicación</Typography>
                        <DatePicker
                            label="Selecciona la fecha"
                            value={formData.publicationDate}
                            onChange={(newValue) => setFormData({ ...formData, publicationDate: newValue })}
                            renderInput={(params) => <TextField {...params} />}
                            selectedOption={formData.publicationDate}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: error && !formData.publicationDate,
                                    helperText: error && !formData.publicationDate ? 'Este campo es obligatorio' : '',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
                    <Button variant="primary" onClick={handleSubmit}>
                        Agregar Antecedente
                    </Button>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
}

export default PublicationForm;
