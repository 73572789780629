import React, { useState } from 'react';
import { Box, Typography, IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuth } from '../../hooks/useAuth';
import { userAxios } from '../../apiClients';
import { getRatingImageForType } from '../../utils/getRatingImages';
// import { shareLink } from '../../utils/functions';

const RenderQualification = ({ type, qualification, isMultiple }) => {
  const { user, checkCurrentUser } = useAuth();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const userNotQualified = Object.keys(user?.qualification).length === 0;

  const toggleMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const handleNotVisible = async ({ id }) => {
    try {
      await userAxios.patch(`/user-qualification/${id}`, { visible: !qualification.visible });
      checkCurrentUser();
      closeMenu();
    } catch (error) {
      console.error('Error al actualizar la visibilidad de la calificación:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F4F4F4',
        borderRadius: '20px',
        padding: 2,
        marginY: 2,
        marginX: isMultiple ? 1 : 0,
      }}
    >
      <Avatar
        alt="Imagen de Calificación"
        src={userNotQualified ? '/path/to/notRatingImage.png' : getRatingImageForType(type, qualification.grade)}
        sx={{
          width: isMultiple ? 200 : 300,
          height: isMultiple ? 200 : 300,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          paddingY: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: 14,
            color: '#333',
            fontFamily: 'Poppins',
            width: '60%',
            textAlign: 'center',
          }}
        >
          Dentro del <Typography component="span" sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>Top {qualification?.percentage || `..`}%</Typography> de abogados calificados en el país.
        </Typography>
        <IconButton onClick={toggleMenu} sx={{ padding: 1 }}>
          <MoreVertIcon sx={{ color: '#333' }} />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={closeMenu}
          sx={{ mt: 1 }}
        >
          <MenuItem onClick={() => handleNotVisible({ id: qualification.id })}>
            Hacer {qualification.visible ? 'No Visible' : 'Visible'}
          </MenuItem>
          {/* <MenuItem onClick={() => shareLink({ name: user?.name, lastName: user?.lastName })}>
            Share
          </MenuItem> */}
        </Menu>
      </Box>
    </Box>
  );
};

export default RenderQualification;
