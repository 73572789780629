// AcademicSection.js
import React from 'react';
import { Typography, Paper, List, ListItem, ListItemText, ListItemIcon, Divider, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'; // Este es para el estado de pendiente
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
// add a divider from MUI

function AcademicSection({ title, user, pending, onSeeMoreClick}) {

  const { 
    AcademicExperiences = [], 
    Publications = [], 
    EducationExperiences = []
  } = user || {}; 
    return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h5" color="textPrimary">{title}</Typography>
      <Divider />
      {AcademicExperiences && AcademicExperiences.length > 0 && (
        <List>
          <Typography variant="h7" color="textPrimary">{"Academic Experiences"}</Typography>
          {AcademicExperiences?.map((item, index) => (
            <React.Fragment key={item.id}>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="success" />
                </ListItemIcon>
                <ListItemText primary={"Professor " + item.ProfessorType.name} secondary={item.University.name} />
                {pending &&
                <React.Fragment>
                  <Typography component="span" color="textSecondary">
                    {item.state === 'pending' && <HourglassEmptyIcon color="gray" /> }
                    {item.state === 'verified' && <CheckCircleOutlineIcon color="success" /> }
                    {item.state === 'rejected' && <DoNotDisturbAltIcon color="error" /> }
                    {item.state}
                  </Typography>
                  <Button variant="outlined" size="small" sx={{ ml: 2 }} onClick={() => onSeeMoreClick(item)} >
                    See more
                  </Button>
                </React.Fragment>
              }
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      )}
      {Publications && Publications.length > 0 && (
        <List >
          <Typography variant="h7" color="textPrimary">{"Publications"}</Typography>
          {Publications?.map((item, index) => (
            <React.Fragment key={item.id}>
              {index !== 0 && <Divider variant="inset" component="li" />}
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="success" />
                </ListItemIcon>
                <ListItemText primary={item.PublicationType?.name + " " + item.name} secondary={item.year + " " + item.editorial} />
                {pending &&
                <React.Fragment>
                  <Typography component="span" color="textSecondary">
                    {item.state === 'pending' && <HourglassEmptyIcon color="gray" /> }
                    {item.state === 'verified' && <CheckCircleOutlineIcon color="success" /> }
                    {item.state === 'rejected' && <DoNotDisturbAltIcon color="error" /> }
                    {item.state}
                  </Typography>
                  <Button variant="outlined" size="small" sx={{ ml: 2 }} onClick={() => onSeeMoreClick(item)} >
                    See more
                  </Button>
                </React.Fragment>
              }
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      )}
      {EducationExperiences && EducationExperiences.length > 0 && (
        <List >
          <Typography variant="h7" color="textPrimary">{"Education Experiences"}</Typography>
          {EducationExperiences?.map((item, index) => (
            <React.Fragment key={item.id}>
              {index !== 0 && <Divider variant="inset" component="li" />}
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="success" />
                </ListItemIcon>
                <ListItemText primary={item.educationType + " " + item.University.name} secondary={item.endYear} />
                {pending &&
                <React.Fragment>
                  <Typography component="span" color="textSecondary">
                    {item.state === 'pending' && <HourglassEmptyIcon color="gray" /> }
                    {item.state === 'verified' && <CheckCircleOutlineIcon color="success" /> }
                    {item.state === 'rejected' && <DoNotDisturbAltIcon color="error" /> }
                    {item.state}
                  </Typography>
                  <Button variant="outlined" size="small" sx={{ ml: 2 }} onClick={() => onSeeMoreClick(item)} >
                    See more
                  </Button>
                </React.Fragment>
              }
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      )}
    </Paper>
  );
}

AcademicSection.defaultProps = {
  pending: false,
  onSeeMoreClick: () => {},
};

export default AcademicSection;
