import React, { useState } from 'react';
import { AppBar, Toolbar, Box, TextField, InputAdornment, Button, Modal, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LaurelLeft from '../../assets/images/laurel-crown-left.png';
import LaurelRight from '../../assets/images/laurel-crown-right.png';
import LogoBlack from '../../assets/logo/LogoBlack.png';
import AddLegend from './AddLegend';

const HeaderLegends = ({ onSearch }) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearch(searchTerm);
    }
  };

  const handleSearchClick = () => {
    onSearch(searchTerm);
  };

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: '#2C3E50', padding: '60px 0' }}>
        <Toolbar style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 40, width: '100%' }}>
            <img src={LaurelLeft} alt="Laurel Izquierdo" style={{ width: 70, height: 'auto', filter: 'invert(1)' }} />
            <img src={LogoBlack} alt="Logo" style={{ margin: '0 40px', width: 150, height: 'auto' }} />
            <img src={LaurelRight} alt="Laurel Derecho" style={{ width: 70, height: 'auto', filter: 'invert(1)' }} />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <TextField
              variant="outlined"
              placeholder="Busca legendarios..."
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: '#FFF' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={handleSearchClick}
                      style={{
                        backgroundColor: searchTerm ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.2)',
                        color: searchTerm ? '#000' : '#FFF',
                        border: 'none',
                        transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
                      }}
                    >
                      Buscar
                    </Button>
                  </InputAdornment>
                ),
                style: { color: '#FFF' },
              }}
              InputLabelProps={{
                style: { color: '#FFF' },
              }}
              style={{ 
                width: '100%', 
                maxWidth: 600, 
                borderColor: '#FFF', 
                borderWidth: 2,
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.2)', 
                marginBottom: 20
              }}
            />
          </Box>
          <Button 
            variant="primary" 
            color="primary" 
            sx={{ marginTop: '10px' }} 
            onClick={handleClickOpen}
          >
            Añadir Persona
          </Button>
        </Toolbar>
      </AppBar>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 20 }}
      >
        <AddLegend onClose={handleClose} />
      </Modal>
    </>
  );
};

export default HeaderLegends;
