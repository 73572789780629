import React from 'react';
import SelectOption from '../SelectOption';

function AntecedentSelector({ setSelectedAntecedent, selectedAntecedent, isMobile }) {
    const antecedentTypes = [
        // Experiencias
        { id: 1, name: 'Título profesional', category: 'Experiencia' },

        // Antecedentes Académicos
        { id: 2, name: 'Pregrado', category: 'Académico' },
        { id: 3, name: 'Doctorado', category: 'Académico' },
        { id: 4, name: 'Magíster', category: 'Académico' },
        { id: 5, name: 'Curso', category: 'Académico' },
        { id: 6, name: 'Diplomado', category: 'Académico' },
        { id: 7, name: 'Beca', category: 'Académico' },
        { id: 8, name: 'Docencia pasada', category: 'Académico' },
        { id: 9, name: 'Docencia actual', category: 'Académico' },
        { id: 10, name: 'Publicación', category: 'Académico' },
        { id: 11, name: 'Habilitación internacional', category: 'Académico' },
        { id: 12, name: 'Idioma', category: 'Académico' },

        // Experiencias
        { id: 13, name: 'Puesto anterior', category: 'Experiencia' },
        { id: 14, name: 'Puesto actual', category: 'Experiencia' },
        // { id: 15, name: 'Experiencia relevantes', category: 'Experiencia' },
        { id: 16, name: 'Ranking internacional', category: 'Experiencia' },
        // { id: 17, name: 'Otros antecedentes', category: 'Experiencia' }
    ];



    const handleSelectionChange = (selectedOptionId) => {
        const selectedAntecedent = antecedentTypes.find(antecedent => antecedent.id === selectedOptionId);
        setSelectedAntecedent(selectedAntecedent);
    };

    return (
        <SelectOption
            options={antecedentTypes}
            onSelectionChange={handleSelectionChange}
            name={'Selecciona un tipo de antecedente'}
            width={isMobile ? "90%" : "60%"}
            selectedOption={selectedAntecedent?.id}
        />
    );
}

export default AntecedentSelector;
