import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Divider, useMediaQuery, Button, Avatar } from '@mui/material';
import AntecedentSelector from './AntecedentSelector';
import AntecedentForm from './AntecedentForm';
import AntecedentsList from './AntecedentsList';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import BackButton from '../BackButton';
import { userAxios } from '../../apiClients';
import { getRatingImageForType } from '../../utils/getRatingImages';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { useAuth } from '../../hooks/useAuth';
import { processUserAntecedents } from '../../utils/functions';
import Loading from '../Loading';

function SimulatorView() {
    const [selectedAntecedent, setSelectedAntecedent] = useState(null);
    const [antecedents, setAntecedents] = useState([]);
    const qualificationType = 'professional';
    const [qualification, setQualification] = useState(null);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { showSnackbar } = useSnackbar();
    const { user } = useAuth();
    const initialAntecedents = processUserAntecedents(user?.data);
    const [isLoading, setIsLoading] = useState(false);
    // Hacer un useEffect inicial
    useEffect(() => {
        setAntecedents(initialAntecedents);
    }, [user]);

    const handleAddAntecedent = (newAntecedent) => {
        setAntecedents([newAntecedent, ...antecedents]);
        console.log(antecedents);
        setSelectedAntecedent(null); // Reset the selected antecedent
    };

    const handleSimulate = async () => {
        try {
            setIsLoading(true);
            if (user.userRole !== 'viewer') {
                const isQualified = Object.keys(user?.qualification).length > 0 ? true : false;
                if (!isQualified) {
                    setIsLoading(false);
                    showSnackbar('No tienes acceso a esta funcionalidad. No estás calificado', 'error');
                    return;
                }

                if (user.credits < 5) {
                    // Mostrar Snackbar si el usuario no tiene suficientes créditos
                    setIsLoading(false);
                    showSnackbar('No tienes suficientes créditos para realizar la simulación', 'error');
                    return; // Detener la simulación
                }
            }
            const hasProfessionalTitle = antecedents.some(antecedent => antecedent.id === 1); // id 1 representa 'Título profesional'
            if (!hasProfessionalTitle) {
                // Mostrar Snackbar si no existe un título profesional
                setIsLoading(false);
                showSnackbar('Debes agregar por lo menos un título profesional', 'info');
                return; // Detener la simulación
            }

            const response = await userAxios.post('user-qualification/simulate', { antecedents });
            console.log(response);
            if (!response.data.error && response.data !== '') {
                setIsLoading(false);
                setQualification(response.data);
                showSnackbar('Simulación realizada correctamente', 'success');
                return;
            } else {
                if (response.data.error) {
                    setIsLoading(false);
                    showSnackbar('No tienes suficientes créditos para realizar la simulación', 'error');
                    return;
                } else {
                    setIsLoading(false);
                    showSnackbar('No es posible la simulación, agrega más antecedentes', 'error');
                    return;
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error al simular la calificación:', error);
            showSnackbar('Error al simular la calificación', 'error');
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ flexGrow: 1, padding: 2 }}>
                <Grid item container direction="row" alignItems="center" spacing={2}>
                    <BackButton back={true} />
                </Grid>
                <Grid container spacing={2} direction="column" sx={{ pl: isMobile ? 1 : 10, pr: isMobile ? 1 : 10, mt: 1 }}>
                    <Grid item container spacing={2} alignItems="center" direction="row">
                        <Grid item xs={12} md={8}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', direction: 'column', mb: 0 }}>
                                    <Divider orientation="vertical" flexItem sx={{ height: '30px', mr: 1, bgcolor: 'gray', width: "0.5%" }} />
                                    <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', mb: 4 }}>
                                        Simulador
                                    </Typography>
                                </Box>
                                <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 1 }}>
                                    Evalúa en abstracto distintas opciones profesionales y académicas, para tomar mejores decisiones (por ejemplo, buscando trabajo o eligiendo Universidad). Encuentra el mejor camino para tu futuro.
                                </Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Poppins', mb: 4, fontWeight: 'bold', color: '#F44336', fontSize: 16 }}>
                                    Cada simulación tiene un costo de 5 créditos.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} container justifyContent="center" alignItems="center" direction='column'>
                            {qualification && (
                                <>
                                    <Avatar
                                        alt="Rating Image"
                                        src={getRatingImageForType(qualificationType, qualification)}
                                        sx={{ width: 150, height: 150 }}
                                    />
                                    <Typography sx={{ fontFamily: 'Poppins', mt: 2, fontSize: 14 }}>
                                        Calificación Recibida
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2} alignItems="center">
                        <Grid item xs={12} md={8}>
                            <Typography>
                                Tipo de antecedente
                            </Typography>
                            <AntecedentSelector selectedAntecedent={selectedAntecedent} setSelectedAntecedent={setSelectedAntecedent} isMobile={isMobile} />
                        </Grid>
                        <Grid item xs={12} md={4} container direction="column" alignItems="center">
                            <Grid item>
                                <Button
                                    variant="secondary"
                                    onClick={() => setAntecedents(initialAntecedents)}
                                    sx={{ mr: 2 }}
                                >
                                    Restablecer
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={handleSimulate}
                                >
                                    Simular Calificación
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 4 }}>
                        {selectedAntecedent && (
                            <AntecedentForm
                                antecedentType={selectedAntecedent}
                                onAddAntecedent={handleAddAntecedent}
                                isMobile={isMobile}
                            />
                        )}
                    </Grid>
                    {antecedents.length > 0 && (
                        <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                            <AntecedentsList antecedents={antecedents} setAntecedents={setAntecedents} />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </LocalizationProvider>
    );
}

export default SimulatorView;
