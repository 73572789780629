import React from 'react';
import { Box, Typography, IconButton, Button, Paper, useMediaQuery } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTheme } from '@mui/material/styles';

const types = ['Profesional', 'Académico', 'Estudiante'];
const typeMapping = {
  'Profesional': 'professional',
  'Académico': 'academic',
  'Estudiante': 'student'
};

const TypeFilterComponent = ({ selectedTypes, onSelectType, setIsTypeFilterVisible, buttonPosition }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectType = (type) => {
    const mappedType = typeMapping[type];
    if (selectedTypes.includes(mappedType)) {
      onSelectType(selectedTypes.filter(t => t !== mappedType));
    } else {
      onSelectType([...selectedTypes, mappedType]);
    }
  };

  return (
    <Paper sx={{ 
      position: 'absolute', 
      top: buttonPosition.top + 15, // Ajusta la posición hacia abajo
      left: buttonPosition.left, // Ajuste manual para alinearlo mejor
      transform: isSmallScreen ? 'translateX(-5%)' : 'none', // Ajuste de transformación en pantallas pequeñas
      width: isSmallScreen ? '90%' : 'auto', // Ajusta el ancho para pantallas pequeñas
      minWidth: isSmallScreen ? 'auto' : 300, // Ancho mínimo en pantallas grandes
      backgroundColor: '#333333', 
      p: 2, 
      borderRadius: 2,
      zIndex: 10
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: '#FFFFFF' }}>
          Tipos de calificación
        </Typography>
        <IconButton onClick={() => setIsTypeFilterVisible(false)}>
          <CloseRoundedIcon sx={{ color: '#FFFFFF' }} />
        </IconButton>
      </Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isSmallScreen ? 'column' : 'row', 
        justifyContent: isSmallScreen ? 'center' : 'space-around', 
        mt: 2 
      }}>
        {types.map((type, index) => (
          <Button
            key={index}
            variant={selectedTypes.includes(typeMapping[type]) ? "contained" : "outlined"}
            onClick={() => handleSelectType(type)}
            sx={{
              color: '#FFFFFF',
              backgroundColor: selectedTypes.includes(typeMapping[type]) ? '#F67147' : 'transparent',
              borderColor: '#FFFFFF',
              '&:hover': {
                backgroundColor: selectedTypes.includes(typeMapping[type]) ? '#F67147' : 'rgba(255, 255, 255, 0.1)'
              },
              height: 30,
              borderRadius: 20,
              mb: isSmallScreen ? 1 : 0,
              mx: isSmallScreen ? 'auto' : 1 // Centra los botones en pantallas pequeñas
            }}
          >
            {type}
          </Button>
        ))}
      </Box>
    </Paper>
  );
};

export default TypeFilterComponent;
