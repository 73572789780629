import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Loading from '../components/Loading';

// Cola y logeado
const QueueRoute = ({ component: Component, ...rest }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }
  if (!user || user?.userRole !== 'queue') {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Component {...rest} />;
};

// Logeado y no cola
const RoleBasedRoute = ({ component: Component, ...rest }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  if (!user || user?.userRole === 'queue') {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Component {...rest} />;
};

// Logeado
const RoleAllRoute = ({ component: Component, ...rest }) => {
    const { user, loading } = useAuth();
  
    if (loading) {
      return <Loading />;
    }
  
    if (!user) {
      return <Navigate to="/unauthorized" replace />;
    }
  
    return <Component {...rest} />;
};

// Logeado y no cola y sin institución
const RoleBasedRouteSI = ({ component: Component, ...rest }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  if (!user || user?.userRole === 'queue' || user?.userRole === 'institutional') {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Component {...rest} />;
};

// Institución
const RoleInstitutional = ({ component: Component, ...rest }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  if (!user || user?.userRole !== 'institutional') {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Component {...rest} />;
};

// No loggeado pero con un path diferente
const ProtectedRoute = ({ component: Component }) => {
  const location = useLocation();
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    // Redirige al usuario a la página de inicio de sesión si no está autenticado
    return <Navigate to="/signin" state={{ from: location }} replace />;
  } else {
    if (location.pathname === '/settings-email-autoNavigateToAdminOptions') {
      return <Navigate to="/settings?autoNavigateToAdminOptions=true" />;
    }
    return <Component />;
  }

  // Si está autenticado, renderiza el componente protegido
};

export { QueueRoute, RoleBasedRoute, RoleAllRoute, ProtectedRoute, RoleBasedRouteSI, RoleInstitutional };

