import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, styled } from '@mui/material';
import Logo from '../utils/LogoBlack.png';
import { publicAxios } from '../apiClients';
import { signUp as signUpAws } from 'aws-amplify/auth';
import ConfirmMail from './ConfirmMail';
import Loading from '../components/Loading';
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import { set } from 'date-fns';

const CustomTextField = styled(TextField)({
  // Tus estilos personalizados...
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '6px',
  },
  '& input::placeholder': {
    color: 'black', // Cambia este color según tus necesidades
    opacity: 0.75, // Asegura que el color se vea claramente
    fontSize: '16px', // Ajusta el tamaño de la fuente del placeholder
  },
});

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '400px',
  '& > :not(style)': { m: theme.spacing(1), width: '100%' }, // Aplica un margen a todos los hijos
}));

function SignUpInvite() {
  const { showSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [securityCodeError, setSecurityCodeError] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate(); // Obtener el objeto history
  const [cognitoUserID, setCognitoUserID] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validatePassword = (password) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;

    if (password !== confirmPassword) {
      showSnackbar('Las contraseñas no coinciden', 'error');
      return false;
    }

    if (password.length < 8) {
      showSnackbar('La contraseña debe tener al menos 8 caracteres.', 'error', 'Error');
      return false;
    }
    
    if (!hasNumber.test(password)) {
      showSnackbar('La contraseña debe contener al menos un número.', 'error', 'Error');
      return false;
    }
    if (!hasSpecialChar.test(password)) {
      showSnackbar('La contraseña debe contener al menos un carácter especial.', 'error', 'Error');
      return false;
    }
    if (!hasUpperCase.test(password)) {
      showSnackbar('La contraseña debe contener al menos una letra mayúscula.', 'error', 'Error');
      return false;
    }
    if (!hasLowerCase.test(password)) {
      showSnackbar('La contraseña debe contener al menos una letra minúscula.', 'error', 'Error');
      return false;
    }
    return true;
  };


  const handleSignUp = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!validatePassword(password)) {
      setIsLoading(false);
      setPasswordError(true);
      return;
    } else {
      setIsLoading(false);
      setPasswordError(false);
    }
    try {
      const checkSecurityCode = await publicAxios.post('/users/check-invitation-code', { 
        code: securityCode,
      });
      if (!checkSecurityCode.data.success) {
        setIsLoading(false);
        showSnackbar(checkSecurityCode.data.message, 'error');
        return;
      }
      const { userId } = await signUpAws({
        username: email,
        password,
        options: {
          autoSignIn: true
        }
      });

      if (userId) {
        setCognitoUserID(userId);
        setIsLoading(false);
        setShowConfirm(true);
      }

      // const data = {
      //   name: firstName,
      //   lastName: lastName,
      //   email: email,
      //   cognitoUserID: userId
      // }
      // await publicAxios.post(`/users`, data);
    }
    catch (error) {
      setIsLoading(false);
      showSnackbar('Código de invitación no válido', 'error');
      setSecurityCodeError(true);
    }
  };

  if (showConfirm) {
    const userData = {
      firstName,
      lastName,
      email,
      password,
      securityCode,
      cognitoUserID,
    }
    return <ConfirmMail userData={userData} type="invite" />;
  }

  // Manejadores de eventos para cada campo
  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleConfirmPasswordChange = (event) => setConfirmPassword(event.target.value);
  const handleSecurityCodeChange = (event) => setSecurityCode(event.target.value);

  return (
    <Box sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F67147',
    }}>
      <Box component="img" src={Logo} alt="Logo" sx={{ height: 120, width: 120, mb: 4 }} />
        <FormContainer>
          <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mb: 4 }}>
            Crear una cuenta por invitación
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomTextField
              placeholder="Nombres"
              variant="outlined"
              value={firstName}
              onChange={handleFirstNameChange}
              sx={{ width: '48%' }} // Ajusta el ancho para que se ajuste al lado del apellido
            />
            <CustomTextField
              placeholder="Apellidos"
              variant="outlined"
              value={lastName}
              onChange={handleLastNameChange}
              sx={{ width: '48%' }} // Igual aquí
            />
          </Box>
          <CustomTextField
            placeholder="Correo electrónico (Institucional)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={handleEmailChange}
          />
          <CustomTextField
            placeholder="Contraseña"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
            helperText={passwordError ? "La contraseña no coincide" : ""}
          />
          <CustomTextField
            placeholder="Confirmar contraseña"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={passwordError}
            helperText={passwordError ? "La contraseña no coincide" : ""}
          />
          <CustomTextField
            placeholder="Ingresa el código"
            variant="outlined"
            fullWidth
            margin="normal"
            type="token"
            value={securityCode}
            onChange={handleSecurityCodeChange}
          />
          <Button
            variant="primary"
            fullWidth
            sx={{ mt: 2, bgcolor: 'black', color: 'white' }}
            onClick={handleSignUp}
          >
            Crear cuenta
          </Button>
        </FormContainer>
    </Box>
  );
}

export default SignUpInvite;
