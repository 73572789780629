import React, { useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
  padding: '20px'
});

export default function DownloadApp() {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detecta si es iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com/cl/app/soubid/id6578461746";
    }
    // Detecta si es Android
    else if (/android/i.test(userAgent)) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.soubid.NewSoubid";
    }
    // Redirige a un sitio web si no se detecta iOS o Android
    else {
      window.location.href = "https://soubid.com";
    }
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Redirigiendo a la tienda de aplicaciones...
      </Typography>
      <Typography variant="body1">
        Si no se redirige automáticamente, por favor haz clic en el enlace correcto a continuación.
      </Typography>
      <Box mt={4}>
        <a href="https://apps.apple.com/cl/app/soubid/id6578461746" style={{ textDecoration: 'none', color: '#007aff' }}>App Store (iOS)</a><br />
        <a href="https://play.google.com/store/apps/details?id=com.soubid.NewSoubid" style={{ textDecoration: 'none', color: '#3ddc84' }}>Google Play (Android)</a>
      </Box>
    </Container>
  );
}
