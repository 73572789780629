import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Button, Typography, Divider, InputAdornment, Grid, IconButton } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'; 
import { useAuth } from '../../hooks/useAuth';

function SearchComponentInstitutions({ onPerformSearch, currentPage, searchQuery = '', adminInstitution }) {
  const [text, setText] = useState(searchQuery);
  const [toSearch, setToSearch] = useState(searchQuery.trim() !== '');
  const [searchParams, setSearchParams] = useState({ search: searchQuery, page: currentPage, adminInstitution: adminInstitution });
  const { user } = useAuth();

  const handleTextChange = (value) => {
    setText(value);
    setToSearch(value.trim() !== '');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => { 
    setSearchParams({
      search: text,
      page: currentPage,
      adminInstitution: adminInstitution,
    });

    const searchParams = {
      search: text,
      page: currentPage,
      adminInstitution: adminInstitution,
    };
    onPerformSearch(searchParams);
  };

  const resetFilters = () => {
    setText('');
    setToSearch(false);
    setSearchParams({
      search: '',
      page: 1,
      adminInstitution: adminInstitution,
    });
    const searchParams = {
      search: '',
      page: 1,
      adminInstitution: adminInstitution,
    };
    onPerformSearch(searchParams);
  };

  useEffect(() => {
    handleSearch();
  }, [currentPage]);

  const hasActiveFilters = text;

  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ p: 4, backgroundColor: '#2C2C2C', height: 350 }}>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box component="img" src={user.linkPhoto} alt="Logo" sx={{
            height: { xs: 100, md: 150 },
            width: { xs: 100, md: 150 },
            mb: 2,
            borderRadius: '50%',
          }} />
      </Grid>
      <Grid item xs={12} sm={8} container direction="column" sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}} >
        <Grid item sx={{ textAlign: 'center'}}>
          <Grid item ml="8px" mb="20px">
            <Typography variant="h3" sx={{ flexGrow: 1, fontFamily: 'Poppins', fontStyle: 'italic', color: 'white' }}>
              Lista de colaboradores
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems='flex-start' spacing={2}>
          <Grid item xs={12} container direction={ "row"} alignItems="center" sx={{ width: "74%", maxWidth: '700px' }}
            spacing={1} >
            <Grid item xs>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Name"
                value={text}
                onChange={(e) => handleTextChange(e.target.value)}
                onKeyDown={handleKeyDown}
                InputProps={{
                  style: { backgroundColor: '#fff', color: '#000', borderRadius: 20, height: 40 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRoundedIcon sx={{ color: '#000' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {toSearch && (
              <Grid item sx={{ ml: 0 }}>
                <Button
                  variant="primary"
                  onClick={handleSearch}
                  sx={{
                    borderRadius: 20,
                    backgroundColor: 'white',
                    color: '#000',
                    height: 40,
                    minWidth: 100,
                    '&:hover': {
                      backgroundColor: '#333',
                    },
                  }}
                >
                  <SearchRoundedIcon sx={{ color: '#000' }} />  Buscar
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} container direction="row" alignItems="center" justifyContent="space-between" spacing={0} sx={{ width: "70%" }}>
            {hasActiveFilters ? (
              <Grid item>
                <IconButton onClick={resetFilters} sx={closeButtonStyles}>
                  <CloseRoundedIcon sx={{ color: '#fff', fontSize: 20 }} />
                </IconButton>
              </Grid>
            ) : (
              <Grid item sx={{ width: '20px' }} />  // Agrega un Grid vacío con el mismo ancho
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const closeButtonStyles = {
  borderRadius: '50%', // Hacer el botón circular
  height: 32, // Altura del botón
  width: 32, // Ancho del botón
  border: '2px solid #fff', // Borde del botón
  color: '#fff', // Color del icono
  '&:hover': {
    backgroundColor: '#d32f2f', // Color de fondo al pasar el cursor
  },
  padding: 1, // Espacio alrededor del icono para hacer el botón más grande
};

export default SearchComponentInstitutions;
