import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Button, Typography, Divider, InputAdornment, Grid, IconButton, Drawer, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'; 
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import logo from '../../utils/LogoBlack.png';
import AutocompleteSelect from './AutocompleteOption';
import CloseIcon from '@mui/icons-material/Close';
import { useScrollPosition } from './DirectoryContext';

function SearchComponent({ onPerformSearch, data, currentPage, selectedTypes = [], setSelectedType, isTypeFilterVisible, setIsTypeFilterVisible, selectedQualifications = [], setSelectedQualifications, isQualificationVisible, setIsQualificationVisible, setButtonPosition1, setButtonPosition2, filtrar, searchQuery = '', selectedUniversity = [], selectedCountry = [], selectedFirm = [], selectedExpertiseAreas = [], showModal, setShowModal }) {
  const navigate = useNavigate();
  const [text, setText] = useState(searchQuery);
  const [toSearch, setToSearch] = useState(searchQuery.trim() !== '');
  const [university, setUniversity] = useState(selectedUniversity);
  const [country, setCountry] = useState(selectedCountry);
  const [firm, setFirm] = useState(selectedFirm);
  const [expertiseAreas, setExpertiseAreas] = useState(selectedExpertiseAreas);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const buttonRefType = useRef(null);
  const buttonRefQualification = useRef(null);
  const { setSearchParams } = useScrollPosition();

  const handleTextChange = (value) => {
    setText(value);
    setToSearch(value.trim() !== '');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleTypeFilter = () => {
    if (buttonRefType.current) {
      const rect = buttonRefType.current.getBoundingClientRect();
      setButtonPosition1({ top: rect.bottom, left: isMobile ? '10%' : rect.left });
    }
    setIsTypeFilterVisible(!isTypeFilterVisible);
  };

  const toggleQualificationFilter = () => {
    if (!filtrar) {
      setShowModal(true);
      return;
    }
    if (buttonRefQualification.current) {
      const rect = buttonRefQualification.current.getBoundingClientRect();
      setButtonPosition2({ top: rect.bottom, left: isMobile ? '15%' : rect.left });
    }
    setIsQualificationVisible(!isQualificationVisible);
  };

  const handleSearch = () => { 
    setSearchParams({
      search: text,
      university: university,
      country: country,
      firm: firm,
      expertiseAreas: expertiseAreas,
      qualification: selectedQualifications,
      qualificationType: selectedTypes,
      page: currentPage,
    });
    const universityIds = university.length ? university.map(option => option.id) : [];
    const countryIds = country.length ? country.map(option => option.id) : [];
    const firmIds = firm.length ? firm.map(option => option.id) : [];
    const expertiseAreasIds = expertiseAreas.length ? expertiseAreas.map(option => option.id) : [];

    const searchParams = {
      search: text,
      university: universityIds,
      country: countryIds,
      firm: firmIds,
      expertiseAreas: expertiseAreasIds,
      qualification: selectedQualifications,
      qualificationType: selectedTypes,
      page: currentPage,
    };
    onPerformSearch(searchParams);
  };

  const resetFilters = () => {
    setUniversity([]);
    setCountry([]);
    setFirm([]);
    setSelectedQualifications([]);
    setSelectedType([]);
    setExpertiseAreas([]);
    setText('');
    setToSearch(false);
    selectedQualifications = [];
    selectedTypes = [];
    handleSearch();
  };

  useEffect(() => {
    handleSearch();
  }, [university, country, firm, expertiseAreas, selectedQualifications, selectedTypes, currentPage]);

  const hasActiveFilters = text || university.length || country.length || firm.length || expertiseAreas.length || selectedQualifications.length || selectedTypes.length;

  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ p: 4, backgroundColor: '#F67147', height: isMobile ? 540 : 350 }}>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box component="img" src={logo} alt="Logo" sx={{
            height: { xs: 100, md: 150 },
            width: { xs: 100, md: 150 },
            mb: 2,
          }} />
      </Grid>
      <Grid item xs={12} sm={8} container direction="column" sx={{justifyContent: isMobile ? 'center': 'flex-start', alignItems: isMobile ? 'center':'flex-start'}} >
        <Grid item sx={{ textAlign: 'center'}}>
          <Grid item ml="8px" mb="20px">
            <Typography variant="h3" sx={{ flexGrow: 1, fontFamily: 'Poppins', fontStyle: 'italic' }}>
              The Rating Network
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems={isMobile ? 'center': 'flex-start'} spacing={2}>
          <Grid item xs={12} container direction={ "row"} alignItems="center" sx={{ width: isMobile ? "100%" : "74%", maxWidth: '700px' }}
            spacing={1} >
            <Grid item xs>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Name"
                value={text}
                onChange={(e) => handleTextChange(e.target.value)}
                onKeyDown={handleKeyDown}
                InputProps={{
                  style: { backgroundColor: '#000', color: '#fff', borderRadius: 20, height: 40 },
                  startAdornment: (
                    <InputAdornment position="start">
                      {!isMobile && <SearchRoundedIcon sx={{ color: '#fff' }} /> }
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {toSearch && (
              <Grid item sx={{ ml: 0 }}>
                <Button
                  variant="primary"
                  onClick={handleSearch}
                  sx={{
                    borderRadius: 20,
                    backgroundColor: '#000',
                    color: '#fff',
                    height: 40,
                    minWidth: 100,
                    '&:hover': {
                      backgroundColor: '#333',
                    },
                  }}
                >
                  <SearchRoundedIcon sx={{ color: '#fff' }} /> {!isMobile && 'Buscar'}
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} container direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="space-between" spacing={isMobile ? 1 : 0} sx={{ width: isMobile ? "100%" : "70%" }}>
            <Grid item>
              <Button ref={buttonRefType} variant={isTypeFilterVisible ? "contained" : "outlined"} onClick={toggleTypeFilter} sx={buttonStyles}>
                Tipo <ArrowDropDownIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button ref={buttonRefQualification} variant={isQualificationVisible ? "contained" : "outlined"} onClick={toggleQualificationFilter} sx={buttonStyles}>
                Calificación <ArrowDropDownIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => setFiltersVisible(!filtersVisible)} sx={buttonStyles}>
                + Filtros
              </Button>
            </Grid>
            {hasActiveFilters && !isMobile ? (
              <Grid item>
                <IconButton onClick={resetFilters} sx={closeButtonStyles}>
                  <CloseRoundedIcon sx={{ color: '#fff', fontSize: 20 }} />
                </IconButton>
              </Grid>
            ) : (
              <Grid item sx={{ width: '20px' }} />  // Agrega un Grid vacío con el mismo ancho
            )}
            {/* {!isMobile && (
            <Grid item>
              <Divider orientation="vertical" flexItem sx={{ alignSelf: 'center', width: 3, height: 32, backgroundColor: '#000' }} />
            </Grid>
            )} */}
          </Grid>
        </Grid>
        <Drawer
          anchor="right"
          open={filtersVisible}
          onClose={() => setFiltersVisible(false)}
          sx={{
            zIndex: 10,
            '& .MuiDrawer-paper': {
              backgroundColor: '#f9f9f9', // Fondo suave
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Sombra agradable
              width: 320, // Ajusta el ancho según sea necesario
              padding: 2, // Espaciado interno
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }
          }}
        >
          <Box sx={{ width: '100%', p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2 }}>
              <IconButton onClick={() => setFiltersVisible(false)}>
                <CloseRoundedIcon sx={{ color: '#000' }} />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>  {/* Espaciado entre la etiqueta y la entrada */}
                  Universidad
                </Typography>
                <AutocompleteSelect
                  options={data.universities || []}
                  selectedOptions={university}
                  onSelectionChange={setUniversity}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>  {/* Espaciado entre la etiqueta y la entrada */}
                  País
                </Typography>
                <AutocompleteSelect
                  options={data.countries || []}
                  selectedOptions={country}
                  onSelectionChange={setCountry}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>  {/* Espaciado entre la etiqueta y la entrada */}
                  Firma
                </Typography>
                <AutocompleteSelect
                  options={data.firms || []}
                  selectedOptions={firm}
                  onSelectionChange={setFirm}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>  {/* Espaciado entre la etiqueta y la entrada */}
                  Áreas de Práctica
                </Typography>
                <AutocompleteSelect
                  options={data.areas || []}
                  selectedOptions={expertiseAreas}
                  onSelectionChange={setExpertiseAreas}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="primary"
                onClick={resetFilters}
                sx={{
                  backgroundColor: '#F67147',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#e55d3c',
                  },
                  borderRadius: 2,
                }}
              >
                Borrar Filtros
              </Button>
            </Box>
          </Box>
        </Drawer>
      </Grid>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>
          Acceso Restringido
          <IconButton
            aria-label="close"
            onClick={() => setShowModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>Debes calificarte para utilizar estas opciones de filtro.</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={() => navigate('/qualification')} color="primary">
            Califícate
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const buttonStyles = {
  borderRadius: 3,
  backgroundColor: '#000',
  color: '#fff',
  minWidth: '180px',  // Ensure all buttons have a minimum width
  height: 30, // Altura del botón
  '&:hover': {
    backgroundColor: '#333',
  },
};

const closeButtonStyles = {
  borderRadius: '50%', // Hacer el botón circular
  height: 32, // Altura del botón
  width: 32, // Ancho del botón
  border: '2px solid #fff', // Borde del botón
  color: '#fff', // Color del icono
  '&:hover': {
    backgroundColor: '#d32f2f', // Color de fondo al pasar el cursor
  },
  padding: 1, // Espacio alrededor del icono para hacer el botón más grande
};

export default SearchComponent;
