import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useSnackbar } from './SnackbarContext.js'; // Asegúrate que la ruta es correcta

const SnackbarCustomizado = () => {
  const { snackbar, handleClose } = useSnackbar();

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={snackbar.type}
        sx={{
          width: '100%',
          bgcolor: snackbar.type === 'success' ? '#4caf50' : snackbar.type === 'error' ? '#f44336' : snackbar.type === 'info' ? 'gray' : 'gray',  // Cambia los colores aquí
          color: '#fff',  // Cambia el color del texto aquí
        }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarCustomizado;
