export const getRatingProfessional = (rating) => {
  switch (rating) {
    case 'AAA+': return require('./../assets/qualificationProfessional/AAA+.png');
    case 'AAA': return require('./../assets/qualificationProfessional/AAA.png');
    case 'AAA-': return require('./../assets/qualificationProfessional/AAA-.png');
    case 'AA+': return require('./../assets/qualificationProfessional/AA+.png');
    case 'AA': return require('./../assets/qualificationProfessional/AA.png');
    case 'AA-': return require('./../assets/qualificationProfessional/AA-.png');
    case 'A+': return require('./../assets/qualificationProfessional/A+.png');
    case 'A': return require('./../assets/qualificationProfessional/A.png');
    case 'A-': return require('./../assets/qualificationProfessional/A-.png');
    case 'BBB+': return require('./../assets/qualificationProfessional/BBB+.png');
    case 'BBB': return require('./../assets/qualificationProfessional/BBB.png');
    case 'BBB-': return require('./../assets/qualificationProfessional/BBB-.png');
    case 'BB+': return require('./../assets/qualificationProfessional/BB+.png');
    case 'BB': return require('./../assets/qualificationProfessional/BB.png');
    case 'BB-': return require('./../assets/qualificationProfessional/BB-.png');
    case 'B+': return require('./../assets/qualificationProfessional/B+.png');
    case 'B': return require('./../assets/qualificationProfessional/B.png');
    case 'B-': return require('./../assets/qualificationProfessional/B-.png');
    case 'CCC+': return require('./../assets/qualificationProfessional/CCC+.png');
    case 'CCC': return require('./../assets/qualificationProfessional/CCC.png');
    case 'CCC-': return require('./../assets/qualificationProfessional/CCC-.png');
    case 'CC+': return require('./../assets/qualificationProfessional/CC+.png');
    case 'CC': return require('./../assets/qualificationProfessional/CC.png');
    case 'CC-': return require('./../assets/qualificationProfessional/CC-.png');
    case 'C+': return require('./../assets/qualificationProfessional/C+.png');
    case 'C': return require('./../assets/qualificationProfessional/C.png');
    case 'C-': return require('./../assets/qualificationProfessional/C-.png');
    case 'DDD+': return require('./../assets/qualificationProfessional/DDD+.png');
    case 'DDD': return require('./../assets/qualificationProfessional/DDD.png');
    case 'DDD-': return require('./../assets/qualificationProfessional/DDD-.png');
    case 'DD+': return require('./../assets/qualificationProfessional/DD+.png');
    case 'DD': return require('./../assets/qualificationProfessional/DD.png');
    case 'DD-': return require('./../assets/qualificationProfessional/DD-.png');
    case 'D+': return require('./../assets/qualificationProfessional/D+.png');
    case 'D': return require('./../assets/qualificationProfessional/D.png');
    case 'D-': return require('./../assets/qualificationProfessional/D-.png');
    case 'EEE+': return require('./../assets/qualificationProfessional/EEE+.png');
    case 'EEE': return require('./../assets/qualificationProfessional/EEE.png');
    case 'EEE-': return require('./../assets/qualificationProfessional/EEE-.png');
    case 'EE+': return require('./../assets/qualificationProfessional/EE+.png');
    case 'EE': return require('./../assets/qualificationProfessional/EE.png');
    case 'EE-': return require('./../assets/qualificationProfessional/EE-.png');
    case 'E+': return require('./../assets/qualificationProfessional/E+.png');
    case 'E': return require('./../assets/qualificationProfessional/E.png');
    case 'E-': return require('./../assets/qualificationProfessional/E-.png');
  }
};

export const getRatingAcademic = (rating) => {
  switch (rating) {
    case 'AAA+': return require('./../assets/qualificationAcademic/AAA+.png');
    case 'AAA': return require('./../assets/qualificationAcademic/AAA.png');
    case 'AAA-': return require('./../assets/qualificationAcademic/AAA-.png');
    case 'AA+': return require('./../assets/qualificationAcademic/AA+.png');
    case 'AA': return require('./../assets/qualificationAcademic/AA.png');
    case 'AA-': return require('./../assets/qualificationAcademic/AA-.png');
    case 'A+': return require('./../assets/qualificationAcademic/A+.png');
    case 'A': return require('./../assets/qualificationAcademic/A.png');
    case 'A-': return require('./../assets/qualificationAcademic/A-.png');
    case 'BBB+': return require('./../assets/qualificationAcademic/BBB+.png');
    case 'BBB': return require('./../assets/qualificationAcademic/BBB.png');
    case 'BBB-': return require('./../assets/qualificationAcademic/BBB-.png');
    case 'BB+': return require('./../assets/qualificationAcademic/BB+.png');
    case 'BB': return require('./../assets/qualificationAcademic/BB.png');
    case 'BB-': return require('./../assets/qualificationAcademic/BB-.png');
    case 'B+': return require('./../assets/qualificationAcademic/B+.png');
    case 'B': return require('./../assets/qualificationAcademic/B.png');
    case 'B-': return require('./../assets/qualificationAcademic/B-.png');
    case 'CCC+': return require('./../assets/qualificationAcademic/CCC+.png');
    case 'CCC': return require('./../assets/qualificationAcademic/CCC.png');
    case 'CCC-': return require('./../assets/qualificationAcademic/CCC-.png');
    case 'CC+': return require('./../assets/qualificationAcademic/CC+.png');
    case 'CC': return require('./../assets/qualificationAcademic/CC.png');
    case 'CC-': return require('./../assets/qualificationAcademic/CC-.png');
    case 'C+': return require('./../assets/qualificationAcademic/C+.png');
    case 'C': return require('./../assets/qualificationAcademic/C.png');
    case 'C-': return require('./../assets/qualificationAcademic/C-.png');
    case 'DDD+': return require('./../assets/qualificationAcademic/DDD+.png');
    case 'DDD': return require('./../assets/qualificationAcademic/DDD.png');
    case 'DDD-': return require('./../assets/qualificationAcademic/DDD-.png');
    case 'DD+': return require('./../assets/qualificationAcademic/DD+.png');
    case 'DD': return require('./../assets/qualificationAcademic/DD.png');
    case 'DD-': return require('./../assets/qualificationAcademic/DD-.png');
    case 'D+': return require('./../assets/qualificationAcademic/D+.png');
    case 'D': return require('./../assets/qualificationAcademic/D.png');
    case 'D-': return require('./../assets/qualificationAcademic/D-.png');
    case 'EEE+': return require('./../assets/qualificationAcademic/EEE+.png');
    case 'EEE': return require('./../assets/qualificationAcademic/EEE.png');
    case 'EEE-': return require('./../assets/qualificationAcademic/EEE-.png');
    case 'EE+': return require('./../assets/qualificationAcademic/EE+.png');
    case 'EE': return require('./../assets/qualificationAcademic/EE.png');
    case 'EE-': return require('./../assets/qualificationAcademic/EE-.png');
    case 'E+': return require('./../assets/qualificationAcademic/E+.png');
    case 'E': return require('./../assets/qualificationAcademic/E.png');
    case 'E-': return require('./../assets/qualificationAcademic/E-.png');
  }
};

export const getRatingStudent = (rating) => {
  switch (rating) {
    case 'AAA+': return require('./../assets/qualificationStudent/AAA.png');
    case 'AAA': return require('./../assets/qualificationStudent/AAA.png');
    case 'AAA-': return require('./../assets/qualificationStudent/AAA.png');
    case 'AA+': return require('./../assets/qualificationStudent/AA+.png');
    case 'AA': return require('./../assets/qualificationStudent/AA.png');
    case 'AA-': return require('./../assets/qualificationStudent/AA.png');
    case 'A+': return require('./../assets/qualificationStudent/A+.png');
    case 'A': return require('./../assets/qualificationStudent/A.png');
    case 'A-': return require('./../assets/qualificationStudent/A.png');
    case 'BBB+': return require('./../assets/qualificationStudent/BBB.png');
    case 'BBB': return require('./../assets/qualificationStudent/BBB.png');
    case 'BBB-': return require('./../assets/qualificationStudent/BBB.png');
    case 'BB+': return require('./../assets/qualificationStudent/BB+.png');
    case 'BB': return require('./../assets/qualificationStudent/BB.png');
    case 'BB-': return require('./../assets/qualificationStudent/BB.png');
    case 'B+': return require('./../assets/qualificationStudent/B+.png');
    case 'B': return require('./../assets/qualificationStudent/B.png');
    case 'B-': return require('./../assets/qualificationStudent/B.png');
    case 'CCC+': return require('./../assets/qualificationStudent/CCC.png');
    case 'CCC': return require('./../assets/qualificationStudent/CCC.png');
    case 'CCC-': return require('./../assets/qualificationStudent/CCC.png');
    case 'CC+': return require('./../assets/qualificationStudent/CC+.png');
    case 'CC': return require('./../assets/qualificationStudent/CC.png');
    case 'CC-': return require('./../assets/qualificationStudent/CC.png');
    case 'C+': return require('./../assets/qualificationStudent/C+.png');
    case 'C': return require('./../assets/qualificationStudent/C.png');
    case 'C-': return require('./../assets/qualificationStudent/C.png');
    case 'DDD+': return require('./../assets/qualificationStudent/DDD.png');
    case 'DDD': return require('./../assets/qualificationStudent/DDD.png');
    case 'DDD-': return require('./../assets/qualificationStudent/DDD.png');
    case 'DD+': return require('./../assets/qualificationStudent/DD+.png');
    case 'DD': return require('./../assets/qualificationStudent/DD.png');
    case 'DD-': return require('./../assets/qualificationStudent/DD.png');
    case 'D+': return require('./../assets/qualificationStudent/D+.png');
    case 'D': return require('./../assets/qualificationStudent/D.png');
    case 'D-': return require('./../assets/qualificationStudent/D.png');
    case 'EEE+': return require('./../assets/qualificationStudent/EEE.png');
    case 'EEE': return require('./../assets/qualificationStudent/EEE.png');
    case 'EEE-': return require('./../assets/qualificationStudent/EEE.png');
    case 'EE+': return require('./../assets/qualificationStudent/EE+.png');
    case 'EE': return require('./../assets/qualificationStudent/EE.png');
    case 'EE-': return require('./../assets/qualificationStudent/EE.png');
    case 'E+': return require('./../assets/qualificationStudent/E+.png');
    case 'E': return require('./../assets/qualificationStudent/E.png');
    case 'E-': return require('./../assets/qualificationStudent/E.png');
  }
};

export const getRatingImageForType = (type, grade) => {
  switch (type) {
    case 'professional':
      return getRatingProfessional(grade);
    case 'student':
      return getRatingStudent(grade);
    case 'academic':
      return getRatingAcademic(grade);
    default:
      return null;
  }
};

export const getBestQualification = (userQualification) => {
  const types = ['professional', 'academic', 'student'];
  let bestType = null;
  let bestGrade = null;

  types.forEach(type => {
    if (userQualification[type] && userQualification[type].visible) {
      if (!bestGrade || compareGrades(userQualification[type].grade, bestGrade)) {
        bestGrade = userQualification[type].grade;
        bestType = type;
      }
    }
  });

  return { type: bestType, grade: bestGrade, percentage: userQualification[bestType]?.percentage };
};

const compareGrades = (grade1, grade2) => {
  const gradeOrder = [
    'AAA', 'AA+', 'AA', 'A+', 'A', 'BBB', 'BB+', 'BB', 'B+', 'B',
    'CCC', 'CC+', 'CC', 'C+', 'C', 'DDD', 'DD+', 'DD', 'D+', 'D',
    'EEE', 'EE+', 'EE', 'E+', 'E'
  ];

  return gradeOrder.indexOf(grade1) < gradeOrder.indexOf(grade2);
};