import React from 'react';
import { Container, Box, Typography, Paper, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ backgroundColor: '#F67147' }}>
        <Container
        sx={{
            backgroundColor: '#F67147', // Color de fondo para toda la pantalla
            minHeight: '100vh',
            padding: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        >
        <Paper
            sx={{
            backgroundColor: '#fff',
            color: '#000',
            padding: '30px',
            borderRadius: '10px',
            maxWidth: '800px',
            width: '100%',
            }}
        >
            <Typography variant="h4" sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
            Política de Privacidad para Soubid
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Última actualización: 26 de julio de 2024
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de su información cuando utiliza el Servicio y le informa sobre sus derechos de privacidad y cómo la ley lo protege.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Usamos sus datos personales para proporcionar y mejorar el Servicio. Al usar el Servicio, acepta la recopilación y el uso de información de acuerdo con esta Política de Privacidad. Esta Política de Privacidad ha sido creada con la ayuda del Generador de Políticas de Privacidad.
            </Typography>

            <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Interpretación y Definiciones
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de si aparecen en singular o en plural.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Definiciones
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            A los efectos de esta Política de Privacidad:
            <ul>
                <li>
                <strong>Cuenta:</strong> significa una cuenta única creada para que usted acceda a nuestro Servicio o partes de nuestro Servicio.
                </li>
                <li>
                <strong>Afiliado:</strong> significa una entidad que controla, es controlada o está bajo control común con una parte, donde "control" significa la propiedad del 50% o más de las acciones, participación accionaria u otros valores con derecho a voto para la elección de directores u otra autoridad administrativa.
                </li>
                <li>
                <strong>Aplicación:</strong> se refiere a Soubid, el programa de software proporcionado por la Compañía.
                </li>
                <li>
                <strong>Compañía:</strong> (referido como "la Compañía", "nosotros" o "nuestro" en este Acuerdo) se refiere a Soubid.
                </li>
                <li>
                <strong>País:</strong> se refiere a Chile.
                </li>
                <li>
                <strong>Dispositivo:</strong> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.
                </li>
                <li>
                <strong>Datos Personales:</strong> es cualquier información que se relacione con un individuo identificado o identificable.
                </li>
                <li>
                <strong>Servicio:</strong> se refiere a la Aplicación.
                </li>
                <li>
                <strong>Proveedor de Servicios:</strong> significa cualquier persona natural o jurídica que procese los datos en nombre de la Compañía. Se refiere a las empresas o personas de terceros empleadas por la Compañía para facilitar el Servicio, proporcionar el Servicio en nombre de la Compañía, realizar servicios relacionados con el Servicio o ayudar a la Compañía a analizar cómo se utiliza el Servicio.
                </li>
                <li>
                <strong>Datos de Uso:</strong> se refiere a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o de la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).
                </li>
                <li>
                <strong>Usted:</strong> se refiere a la persona que accede o utiliza el Servicio, o la empresa, u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda.
                </li>
            </ul>
            </Typography>

            {/* Sección para Colecta y Uso de Datos Personales */}
            <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Recopilación y Uso de Sus Datos Personales
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Tipos de Datos Recopilados
            </Typography>
            <Typography sx={{ marginBottom: '10px' }}>
            <strong>Datos Personales</strong>
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Mientras usa nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que puede usarse para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros:
            <ul>
                <li>Dirección de Correo electrónico</li>
                <li>Nombre y apellido</li>
                <li>Número de teléfono</li>
                <li>Dirección, Estado, Provincia, Código postal/ZIP, Ciudad</li>
                <li>Datos de Uso</li>
            </ul>
            </Typography>

            <Typography sx={{ marginBottom: '10px' }}>
            <strong>Datos de Uso</strong>
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Los Datos de Uso se recopilan automáticamente al usar el Servicio.
            Los Datos de Uso pueden incluir información como la dirección del Protocolo de Internet de su Dispositivo (por ejemplo, dirección IP), tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, identificadores de dispositivos únicos y otros datos de diagnóstico.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Información Recopilada al Usar la Aplicación
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Mientras usa Nuestra Aplicación, para proporcionar características de Nuestra Aplicación, podemos recopilar, con su permiso previo:
            <ul>
                <li>Fotos y otra información de la biblioteca de fotos y la cámara de su Dispositivo</li>
            </ul>
            Usamos esta información para proporcionar características de Nuestro Servicio, para mejorar y personalizar Nuestro Servicio. La información puede ser cargada a los servidores de la Compañía y/o al servidor de un Proveedor de Servicios o puede ser simplemente almacenada en su dispositivo.
            Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de su Dispositivo.
            </Typography>

            {/* Sección de Uso de Datos */}
            <Typography variant="h5" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Uso de Sus Datos Personales
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            La Compañía puede usar los Datos Personales para los siguientes propósitos:
            <ul>
                <li>
                <strong>Para proporcionar y mantener nuestro Servicio:</strong> incluyendo el monitoreo del uso de nuestro Servicio.
                </li>
                <li>
                <strong>Para gestionar su Cuenta:</strong> para gestionar su registro como usuario del Servicio. Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para usted como usuario registrado.
                </li>
                <li>
                <strong>Para el cumplimiento de un contrato:</strong> el desarrollo, cumplimiento y realización del contrato de compra de los productos, artículos o servicios que ha comprado o de cualquier otro contrato con Nosotros a través del Servicio.
                </li>
                <li>
                <strong>Para contactarlo:</strong> Para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como las notificaciones push de una aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluyendo las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.
                </li>
                <li>
                <strong>Para proporcionarle noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos:</strong> que sean similares a los que ya ha comprado o preguntado, a menos que haya optado por no recibir dicha información.
                </li>
                <li>
                <strong>Para gestionar sus solicitudes:</strong> Para atender y gestionar sus solicitudes hacia Nosotros.
                </li>
                <li>
                <strong>Para transferencias comerciales:</strong> Podemos usar su información para evaluar o realizar una fusión, desinversión, reestructuración, reorganización, disolución u otra venta o transferencia de algunos o todos nuestros activos, ya sea como una empresa en marcha o como parte de una quiebra, liquidación o procedimiento similar, en el cual los Datos Personales en poder de Nosotros sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos.
                </li>
                <li>
                <strong>Para otros fines:</strong> Podemos usar su información para otros fines, como análisis de datos, identificar tendencias de uso, determinar la eficacia de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia.
                </li>
            </ul>
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Retención de Sus Datos Personales
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            La Compañía retendrá sus Datos Personales solo durante el tiempo que sea necesario para los propósitos establecidos en esta Política de Privacidad. Retendremos y usaremos sus Datos Personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            La Compañía también retendrá Datos de Uso para fines de análisis interno. Los Datos de Uso generalmente se retienen durante un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o para mejorar la funcionalidad de Nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Transferencia de Sus Datos Personales
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Su información, incluidos los Datos Personales, se procesa en las oficinas operativas de la Compañía y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Esto significa que esta información puede ser transferida a — y mantenida en — computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Su consentimiento a esta Política de Privacidad seguido de su envío de dicha información representa su acuerdo con esa transferencia.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean tratados de forma segura y de acuerdo con esta Política de Privacidad y no se realizará ninguna transferencia de sus Datos Personales a una organización o país a menos que existan controles adecuados en su lugar, incluida la seguridad de sus datos y otra información personal.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Eliminar Sus Datos Personales
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Tiene derecho a eliminar o solicitar que lo ayudemos a eliminar los Datos Personales que hemos recopilado sobre usted.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Nuestro Servicio puede darle la capacidad de eliminar cierta información sobre usted desde dentro del Servicio.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Puede actualizar, enmendar o eliminar su información en cualquier momento iniciando sesión en su Cuenta, si tiene una, y visitando la sección de configuración de la cuenta que le permite gestionar su información personal. También puede contactarnos para solicitar acceso a, corregir o eliminar cualquier información personal que nos haya proporcionado.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Sin embargo, tenga en cuenta que podemos necesitar retener cierta información cuando tengamos una obligación legal o una base legal para hacerlo.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Divulgación de Sus Datos Personales
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            <strong>Transacciones comerciales:</strong> Si la Compañía está involucrada en una fusión, adquisición o venta de activos, sus Datos Personales pueden ser transferidos. Proporcionaremos aviso antes de que sus Datos Personales sean transferidos y estén sujetos a una Política de Privacidad diferente.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            <strong>Aplicación de la ley:</strong> Bajo ciertas circunstancias, la Compañía puede estar obligada a divulgar sus Datos Personales si así lo requiere la ley o en respuesta a solicitudes válidas de autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            <strong>Otros requisitos legales:</strong> La Compañía puede divulgar sus Datos Personales de buena fe, creyendo que dicha acción es necesaria para:
            <ul>
                <li>Cumplir con una obligación legal</li>
                <li>Proteger y defender los derechos o la propiedad de la Compañía</li>
                <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
                <li>Proteger la seguridad personal de los Usuarios del Servicio o del público</li>
                <li>Protegerse contra responsabilidad legal</li>
            </ul>
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Seguridad de Sus Datos Personales
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            La seguridad de sus Datos Personales es importante para Nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos Personales, no podemos garantizar su seguridad absoluta.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Privacidad de los Niños
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No recopilamos conscientemente información de identificación personal de nadie menor de 13 años. Si usted es un padre o tutor y sabe que su hijo nos ha proporcionado Datos Personales, por favor contáctenos. Si nos damos cuenta de que hemos recopilado Datos Personales de cualquier persona menor de 13 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de Nuestros servidores.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Si necesitamos basarnos en el consentimiento como base legal para procesar su información y su país requiere el consentimiento de un padre, podemos requerir el consentimiento de su padre antes de recopilar y usar esa información.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Enlaces a Otros Sitios Web
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros. Si hace clic en un enlace de un tercero, será dirigido a ese sitio de terceros. Le recomendamos encarecidamente que revise la Política de Privacidad de cada sitio que visite.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Cambios a esta Política de Privacidad
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos de cualquier cambio publicando la nueva Política de Privacidad en esta página.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Le informaremos a través de Correo electrónico y/o un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la fecha de "Última actualización" en la parte superior de esta Política de Privacidad.
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Se le recomienda revisar esta Política de Privacidad periódicamente para ver si hay cambios. Los cambios a esta Política de Privacidad son efectivos cuando se publican en esta página.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Contacto
            </Typography>
            <Typography sx={{ marginBottom: '15px' }}>
            Si tiene alguna pregunta sobre esta Política de Privacidad, puede contactarnos:
            </Typography>
            <Box sx={{
            marginTop: '20px',
            padding: '10px',
            backgroundColor: '#f5f5f5',
            borderRadius: '10px',
            }}>
            <Typography>
                Por correo electrónico: <Link href="mailto:support@soubid.com">support@soubid.com</Link>
            </Typography>
            <Typography>
                Por número de teléfono: +56959856601
            </Typography>
            </Box>
        </Paper>
        </Container>
    </Box>
  );
};

export default PrivacyPolicy;
