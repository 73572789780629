import React from 'react';
import { Box, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import soubidPluma from '../../assets/images/soubid-pluma-sin-fondo.png';

export default function TestimonialCarousel() {
    const testimonials = [
        {
            text: "<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: left;'>“</span>Me gusta que en Soubid haya espacio para todos, y que sobresalir no dependa de nadie más que de uno.<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: right;'>”</span>",
            author: "Ignacio, Chile"
        },
        {
            text: "<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: left;'>“</span>Nunca antes sentí tanta consideración por el mérito personal.<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: right;'>”</span>",
            author: "Andrea, Colombia"
        },
        {
            text: "<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: left;'>“</span>Aquí te comparas con tus pares y eso motiva a esforzarse por sobresalir. Promueve una competencia transparente y sana.<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: right;'>”</span>",
            author: "Sandra, México"
        },
        {
            text: "<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: left;'>“</span>Valoro mucho que en Soubid no haya información falsa. Que las personas realmente sean quienes dicen ser.<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: right;'>”</span>",
            author: "Federico, Uruguay"
        },
        {
            text: "<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: left;'>“</span>Me ayudó mucho para decidir dónde estudiar mi magíster.<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: right;'>”</span>",
            author: "Marcelo, Perú"
        },
        {
            text: "<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: left;'>“</span>No se me ocurre una mejor manera para desarrollar la marca personal.<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: right;'>”</span>",
            author: "Juan Pablo, Paraguay"
        },
        {
            text: "<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: left;'>“</span>La ciencia me da tranquilidad. Saber que no existen atajos ni conflictos de interés.<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: right;'>”</span>",
            author: "Antonia, Chile"
        },
        {
            text: "<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: left;'>“</span>Soubid te muestra el camino profesional desde el principio, y eso ayuda a tomar mejores decisiones.<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: right;'>”</span>",
            author: "Rodrigo, Chile"
        },
        {
            text: "<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: left;'>“</span>Nunca había figurado en un ranking y era como si no existiera. Como si no hubiera hecho nada en mi vida profesional.<span style='font-size: 1.5em; font-weight: bold; display: block; text-align: right;'>”</span>",
            author: "Emilia, Argentina"
        }
    ];

    // Divide the testimonials into 3 stages, each containing 3 testimonials
    const stages = [
        testimonials.slice(0, 3),
        testimonials.slice(3, 6),
        testimonials.slice(6, 9)
    ];

    return (
        <Box sx={{ width: '100%', maxWidth: '1200px', margin: 'auto', mt: 4 }}>
            <Carousel
                navButtonsAlwaysInvisible={true}
                indicators={true}
                animation="slide"
                interval={8000}
                indicatorContainerProps={{
                    style: {
                        marginTop: '40px', // Space above the indicators
                        display: 'flex',
                        justifyContent: 'center',
                    }
                }}
                indicatorIconButtonProps={{
                    style: {
                        padding: 0,
                        width: 14,
                        height: 14,
                        margin: '0 8px',
                        color: '#D8D8D8',
                    }
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        padding: 0,
                        width: 14,
                        height: 14,
                        margin: '0 8px',
                        color: 'black'
                    }
                }}
            >
                {stages.map((stage, index) => (
                    <Box 
                        key={index}
                        sx={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: 7, // Increased gap between carousel elements
                            px: 2,
                        }}
                    >
                        {stage.map((testimonial, i) => (
                            <Box 
                                key={i}
                                sx={{ 
                                    p: 2,
                                    border: '1px solid #fff',
                                    borderRadius: 4, // Aumentar el radio del borde para esquinas más redondeadas
                                    backgroundColor: '#fff',
                                    width: '28%',
                                    height: 180, // Adjust height to auto
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative', // Added for absolute positioning of child elements
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Añadir una sombra ligera
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: -13, // Increased size
                                        left: '15%', // Moved to the right
                                        transform: 'translateX(-50%)',
                                        width: 0,
                                        height: 0,
                                        borderLeft: '15px solid transparent', // Reduced width
                                        borderRight: '15px solid transparent', // Reduced width
                                        borderTop: '15px solid #fff', // Same length
                                    }
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: '60%',
                                }}>
                                    <Typography
                                        variant="body2" 
                                        sx={{ 
                                            mb: 2, 
                                            lineHeight: 1.5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            height: '100%',
                                        }} 
                                        dangerouslySetInnerHTML={{ __html: testimonial.text }}
                                    />
                                    <Typography 
                                        variant="caption" 
                                        color="textSecondary"
                                        sx={{
                                            position: 'absolute',
                                            bottom: 18,
                                            left: 16,
                                        }}
                                    >
                                        {testimonial.author}
                                    </Typography>
                                </Box>
                                <Box 
                                    component="img" 
                                    src={soubidPluma} 
                                    alt="Feather" 
                                    sx={{ 
                                        height: 40, 
                                        width: 50,
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 7,
                                    }} 
                                />
                            </Box>
                        ))}
                    </Box>
                ))}
            </Carousel>
        </Box>
    );
}
