import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollPositionContext = createContext();

export const ScrollPositionProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [searchParams, setSearchParams] = useState({
    search: '',
    university: [],
    country: [],
    firm: [],
    company: [],
    qualification: [],
    qualificationType: [],
    page: 1,
  });
  const [shouldRestore, setShouldRestore] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/profile/')) {
      setShouldRestore(true);
    } else {
      setShouldRestore(false);
      resetScrollPositionAndSearchParams();
    }
  }, [location.pathname]);

  const resetScrollPositionAndSearchParams = () => {
    setScrollPosition(0);
    setSearchParams({
      search: '',
      university: [],
      country: [],
      firm: [],
      company: [],
      qualification: [],
      qualificationType: [],
      page: 1,
    });
  };

  return (
    <ScrollPositionContext.Provider value={{ scrollPosition, setScrollPosition, searchParams, setSearchParams, shouldRestore }}>
      {children}
    </ScrollPositionContext.Provider>
  );
};

export const useScrollPosition = () => useContext(ScrollPositionContext);
