// NotFoundPage.js
import React from "react";
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

function UnauthorizedPage() {
    return (
        <Container component="main" maxWidth="sm" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    401 - No autorizado
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Oops! No tienes permisos para realizar esta acción.
                </Typography>
                <Button variant="primary" component={Link} to="/">
                    Volver a la página de inicio
                </Button>
            </Paper>
        </Container>
    );
}

export default UnauthorizedPage;
